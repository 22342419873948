import React from 'react'
import Papa from 'papaparse'
import {saveAs} from 'file-saver'
import {store} from '../../utilities'

export default function CSVTemplate() {
  const state = store.getState()
  const [type, template] = [state.fields.projectType, state.bulk.csvtemplate]
  const t = new Blob([Papa.unparse({fields: template})])

  return (
    <div style={{display: `flex`, flexFlow: `row wrap`}}>
      <div>Click</div>
      <div
        onClick={() => saveAs(t, `${type.title} template for bulk projects.csv`)}
        style={{color: `blue`, padding: `0 0.3rem 0 0.3rem`, cursor: `pointer`}}
      >
        here
      </div>
      <div style={{padding: `0 0.3rem 0 0.3rem`}}>to download a CSV template;</div>
      <div>fill it out, and then upload it by dragging it into this box</div>
    </div>
  )
}
