import {validateProject} from './validateProject'

export async function finalize(project) {
  try {
    const valid = await validateProject(project)
    if (!valid) throw new Error(`project failed to validate`)
    return project
  } catch (e) {
    console.log(`form did not validate`, e)
    throw new Error(`the form failed to validate`)
  }
}
