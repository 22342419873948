import React from 'react'

export default function InspectorBrief({icon = 'https://picsum.photos/100/100', name = 'none', type = 'Inspector'}) {
  return (
    <div
      className="flex-row align-center justify-space-between"
      style={{
        height: '10rem',
        borderRadius: '0 0 0.3rem 0.3rem',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
      }}
    >
      <div className="full-height">
        <img src={icon} alt="inspector avatar" className="padding-2 full-height" style={{borderRadius: '50%'}} />
      </div>
      <div className="flex-column" style={{flex: 5}}>
        <div>{name}</div>
      </div>
      <div style={{flex: 1, padding: '0 2rem 0 2rem'}}>{type}</div>
    </div>
  )
}
