// TEST THIS CODE

import {auth} from '../Auth'
import DB from '../DAL/DB'

const projects = new DB.Projects()

export default async function getProjects(form, rubric, client, created, updated) {
  const filters = [
    ['intake.id', '==', form],
    ['rubric.id', '==', rubric],
    ['status', '==', 'Completed'],
    ['organization.id', '==', auth.sunkaizenUser.organization.id],
  ]

  if (client) filters.push(['client.id', '==', client])

  if (created.before) filters.push(['created', '<', created.before.toDate()])
  if (created.after) filters.push(['created', '>', created.after.toDate()])
  if (updated.before) filters.push(['updated', '<', updated.before.toDate()])
  if (updated.after) filters.push(['updated', '>', updated.after.toDate()])

  return await projects.get(null, {filters})
}
