import moment from 'moment'

function getRecency(project, today) {
  if (project.status === 'Completed') return 'N/A'
  return Math.abs(project.updated.diff(today, 'days')) < 7 ? 'Yes' : 'No'
}

export default function transform(projects) {
  const today = moment()

  const transformed = projects.map((p) => ({
    updated: p.updated,
    completed_on: p.completed_on,
    status: p.status,
    recent: getRecency(p, today),
  }))

  return transformed
}
