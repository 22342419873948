import React, {useContext, useEffect} from 'react'
import {ReducerContext} from './reducer'
import {Button} from 'antd'
import DeleteButton from '../../DeleteButton'

const HeaderIconButton = ({children, ...rest}) => (
  <Button className="header-icon-button" size="large" {...rest}>
    {children}
  </Button>
)

export function HeaderBar({finish}) {
  const {state, dispatch} = useContext(ReducerContext)
  const {selected, active, originals, pictures} = state

  // freezes the background when the photo viewer is opened
  useEffect(function () {
    document.body.style.overflow = `hidden`
  }, [])

  const numSelected = Object.values(selected).filter((x) => x).length

  let anySelectedIsMarked = false
  for (let id in originals) {
    if (originals[id].marked) {
      anySelectedIsMarked = true
      break
    }
  }

  const markSelected = (mark = true) => {
    const marked = {}

    for (let k in state.selected) {
      if (state.selected[k]) {
        const image = state.pictures[k]
        marked[image.name] = {...image, ...{marked: mark}}
      }
    }
    dispatch({type: `changeStatus`, pictures: marked})
  }

  const deleteSelected = () => {
    const deletables = {}
    for (let k in selected) {
      deletables[pictures[k].name] = {...pictures[k], delete: true}
    }
    dispatch({type: `changeStatus`, pictures: deletables})
    finish('delete')
  }

  return (
    <div className="header-bar">
      <div className="images-selected">
        {`${numSelected} of ${active.length} ${active.length === 1 ? `image` : `images`} selected`}
      </div>
      <HeaderIconButton disabled={numSelected <= 0} icon="tag" onClick={() => markSelected(true)}>
        Mark Selected
      </HeaderIconButton>
      <HeaderIconButton disabled={!anySelectedIsMarked} icon="close" onClick={() => markSelected(false)}>
        Remove Marks
      </HeaderIconButton>
      <HeaderIconButton disabled={numSelected <= 0} onClick={() => dispatch({type: `clearSelected`})} icon="minus">
        Clear Selection
      </HeaderIconButton>
      <DeleteButton
        customRender
        confirmationDisplay="Delete selected images?"
        popconfirmProps={{
          placement: `bottom`,
          getPopupContainer: (trigger) => trigger.parentNode,
        }}
        onConfirm={() => {
          deleteSelected()
          document.body.style.overflow = `auto`
        }}
      >
        <HeaderIconButton icon="delete" disabled={numSelected <= 0}>
          Delete Images
        </HeaderIconButton>
      </DeleteButton>

      <Button
        className="done-button"
        onClick={() => {
          finish(`close`)
          document.body.style.overflow = `auto`
        }}
        size="large"
        type="primary"
      >
        Done
      </Button>
    </div>
  )
}
