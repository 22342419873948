import {auth} from '../../../Auth'

export default function or() {
  if (['quality-manager'].includes(auth.sunkaizenUser.type))
    return [{match: {'organization.id': auth.sunkaizenUser.organization.id}}]
  if (['field-user'].includes(auth.sunkaizenUser.type))
    return [{match: {[`user_index.${auth.sunkaizenUser.id}`]: 'inspector'}}]
  if (['installer'].includes(auth.sunkaizenUser.type))
  return [{match: {[`user_index.${auth.sunkaizenUser.id}`]: 'installer'}}]

  if (auth.sunkaizenUser.clients) { // access by clients
    console.info('sunkaizenUser', auth.sunkaizenUser)

    const clients = Object.keys(auth.sunkaizenUser.clients)
    const clientQueries = clients.map((id) => {
      return [
        {match: {[`user_index.${id}`]: 'client'}},
        {match: {[`user_index.${id}`]: 'inspector'}},
        {match: {[`user_index.${id}`]: 'installer'}},
      ]
    })

    return [].concat.apply([], clientQueries)
  } else { // no clients
    return [
      {match: {'organization.id': auth.sunkaizenUser.organization.id}},
      {match: {[`user_index.${auth.sunkaizenUser.organization.id}`]: 'client'}},
      {match: {[`user_index.${auth.sunkaizenUser.organization.id}`]: 'inspector'}},
      {match: {[`user_index.${auth.sunkaizenUser.organization.id}`]: 'installer'}},
    ]
  }
}
