import {firebase} from '../../utilities'
import {auth} from '../../Auth'

export default function stampMetadata(form) {
  if (!form.created) form.created = firebase.firestore.Timestamp.now()
  form.updated = firebase.firestore.Timestamp.now()
  if (!form.createdBy) {
    const {id, name} = auth.sunkaizenUser
    form.createdBy = {id, name}
  }
  if (!form.organization) {
    form.organization = auth.sunkaizenUser.organization
  }
}
