export default function makeCalendarMap(calendar) {
  const calendarMap = {}

  for (let appointment in calendar) {
    const a = calendar[appointment]
    const {date: timestamp} = a
    if(!timestamp)  continue
    const date = timestamp.toDate()
    const [year, month, day] = [date.getFullYear(), date.getMonth(), date.getDate()]

    if (!calendarMap[year]) calendarMap[year] = {}
    if (!calendarMap[year][month]) calendarMap[year][month] = {}

    const old = calendarMap[year][month][day] || []
    calendarMap[year][month][day] = [...old, a].filter((a) => a)
  }

  return calendarMap
}
