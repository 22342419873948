import React from 'react'

export default function PopoverPermission({name, permissions}) {
  return (
    <div className="flex-column padding-top-1">
      <div className="bold">{name}</div>
      <div className="flex-row justify-start">
        {(permissions || []).map((permission) => (
          <div className="user-popover-permissions">{permission}</div>
        ))}
      </div>
    </div>
  )
}
