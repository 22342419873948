import React, {forwardRef} from 'react'

import {store} from '../utilities'
import {Provider} from 'react-redux'
import BulkProjectImport from './BulkProjectImport'

const EntryPoint = forwardRef((props, ref) => (
  <Provider store={store}>
    <BulkProjectImport ref={ref} {...props} />
  </Provider>
))

export default EntryPoint
