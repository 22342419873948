import React from 'react'
import AutocompleteField from '../../AutocompleteField'
import {Button, Spin, InputNumber, Form, Input, Checkbox} from 'antd'
import {fieldNameConverter} from '../../validators'
import {Required} from '../utilities'
import {auth} from '../../Auth'
import {setField} from '../action_creators'
import {connect} from 'react-redux'

const mapStateToProps = (state) => ({
  fields: state.fields,
  edit: !!state.projectId,
})
const mapDispatchToProps = (dispatch) => ({
  setField: (field) => (value) => {
    dispatch(setField(field, value))
  },
})

class SiteOwnerInformation extends React.Component {
  componentDidMount = async () => {
    this.autocompleteRef = React.createRef()
    this.mapRef = React.createRef()

    await this.loaded()
  }

  componentWillUnmount = () => {
    if (this.state.mapsListener) this.state.mapsListener.remove()
    if (this.state.windowListener) window.removeEventListener('storage', this.state.windowListener)
  }

  state = {
    autocomplete: {},
    loaded: false,
    verifyAddress: false,
    latitude: null,
    longitude: null,
    useCoordinates: false,
    marker: null,
    addressAutoCompleted: false,
  }

  loaded = async () => {
    if (window.google && window.google.maps) {
      window.removeEventListener('storage', this.setLoaded)
      await this.setState({loaded: true})
      await this.initMap()
    } else {
      console.log('google maps is not yet loaded, listening')
      window.addEventListener('storage', this.setLoaded)
    }
  }

  setLoaded = async (e) => {
    if (e.key === 'googleMapsLoaded') {
      if (e.newValue === 'true') {
        this.setState({loaded: true})
        await this.initMap()
      }
    }
  }

  initMap = () => {
    const {center, zoom} = (() => {
      let center, zoom

      if (auth.sunkaizenUser.geometry) {
        center = auth.sunkaizenUser.geometry.location
        zoom = 11
      } else if (auth.sunkaizenUser.geocode_results && auth.sunkaizenUser.geocode_results.geometry) {
        center = auth.sunkaizenUser.geocode_results.geometry.location
        zoom = 11
      } else {
        center = {lat: 38.90046, lng: -77.012525}
        zoom = 6
      }
      return {center, zoom}
    })()

    if (!this.mapRef.current) return
    const map = new window.google.maps.Map(this.mapRef.current, {center, zoom})

    const mapsListener = window.google.maps.event.addDomListener(map, 'click', async (e) => {
      if (this.state.marker) {
        this.state.marker.setMap(null)
        await this.setState({marker: null})
      }

      this.props.setField('latitude')(e.latLng.lat())
      this.props.setField('longitude')(e.latLng.lng())

      const marker = new window.google.maps.Marker({
        position: e.latLng,
        map,
        icon: <i className="material-icons">gps_fixed</i>,
      })

      this.setState({marker})
    })

    this.setState({map, mapsListener})
  }

  confirmMessage = () => (
    <div style={{padding: '0.5rem'}}>
      <div
        style={{padding: '0.5rem 0 0.5rem 0'}}
      >{`Use this address? ${this.state.verifyAddress[0].formatted_address}`}</div>
      <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
        <Button style={{flex: 1, margin: '0.5rem'}} onClick={this.addressVerified}>
          Yes
        </Button>
        <Button style={{flex: 1, margin: '0.5rem'}} onClick={this.rejectAddress}>
          No
        </Button>
      </div>
    </div>
  )

  setCoordinates = (type, value) => this.setState({[type]: value})

  setAddress = (address, addressAutocompleted) => {
    console.log(0)

    if (!Array.isArray(address) || !address.length) {
      this.props.setField('siteAddress')(null)
      this.props.setField('geocode_results')(null)
      return
    }

    this.props.setField('siteAddress')(address[0].formatted_address)
    this.props.setField('geocode_results')(address[0])

    this.setState({addressAutocompleted})
  }

  toggleMapInput = async () => {
    const toggle = !this.state.useCoordinates

    if (!toggle) {
      await this.props.setField('latitude')(null)
      await this.props.setField('longitude')(null)
      if (this.state.marker) {
        this.state.marker.setMap(null)
        this.setState({marker: null})
      }
    }

    this.setState({useCoordinates: toggle})
  }

  checkAutocomplete = () => {
    const {siteAddress, geocode_results = {}} = this.props.fields || {}
    if (siteAddress && Object.keys(geocode_results).length < 1) this.fillAddress()
  }

  render = () => {
    let {fields, setField} = this.props
    if (!fields) fields = {}

    return (
      <div className="flex-column align-start padding-2" style={{background: '#F4F6F8'}}>
        <h3>Site Owner Information</h3>
        <div className="full-width">
          <Required />
          <div>
            <Input
              size="large"
              placeholder={fieldNameConverter['siteOwnerName']}
              value={fields.siteOwnerName}
              onChange={(e) => setField('siteOwnerName')(e.target.value)}
              data-cy="siteOwner"
              autoComplete="new-password"
            />
            {!this.state.useCoordinates && (
              <div>
                <Required />
                {!this.state.addressAutocompleted && fields.siteAddress && !this.props.edit && (
                  <div className="flex-row align-center" style={{color: 'red'}}>
                    <div style={{padding: '0 0 0 0.5rem'}}>Please select an address from the dropdown</div>
                  </div>
                )}
                <AutocompleteField
                  edit={true}
                  full={true}
                  submit={this.setAddress}
                  onChange={(e) => setField('dummyAddress')(e)}
                  old_address={fields.siteAddress}
                  //   geocode_results={this.props.fields.geocode_results}
                  data-cy="address"
                />
              </div>
            )}
            {!this.state.loaded ? (
              <Spin size="large" />
            ) : (
              <div style={{display: this.state.useCoordinates ? 'initial' : 'none'}}>
                <div ref={this.mapRef} className="full-width margin-top-2 margin-bottom-2" style={{height: '25rem'}}>
                  Map
                </div>
                <div
                  style={{
                    padding: '0.5rem',
                    width: '100%',
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                    justifyContent: 'space-betwen',
                  }}
                >
                  <div className="flex-column" style={{flex: 15, padding: '0.5rem 0 0.5rem 0'}}>
                    <Required />
                    <InputNumber
                      className="full-width"
                      placeholder={fieldNameConverter['latitude']}
                      value={fields.latitude}
                      onChange={(e) => setField('latitude')(e)}
                    />
                  </div>
                  <div style={{flex: 1}}></div>
                  <div className="flex-column" style={{flex: 15, padding: '0.5rem 0 0.5rem 0'}}>
                    <Required />
                    <InputNumber
                      className="full-width"
                      placeholder={fieldNameConverter['longitude']}
                      value={fields.longitude}
                      onChange={(e) => setField('longitude')(e)}
                    />
                  </div>
                </div>
                <div style={{padding: '1rem 0 0 0.5rem'}}>
                  <Input
                    size="large"
                    onChange={(e) => setField('dummyAddress')(e.target.value)}
                    placeholder="Enter an Address for Recordkeeping Purposes"
                  />
                </div>
              </div>
            )}

            <Button className="margin-top-1" onClick={this.toggleMapInput}>
              {this.state.useCoordinates ? 'Input an Address' : 'Choose a lat/lng pair'}
            </Button>
          </div>
          <div className="flex-column align-start">
            <Form.Item style={{margin: 0}}>
              <Checkbox
                checked={fields.sameRep}
                value={fields.sameRep}
                onChange={(e) => setField('sameRep')(e.target.checked)}
                data-cy="sameRep"
              >
                Owner is different from site contact person
              </Checkbox>
            </Form.Item>
          </div>
        </div>
      </div>
    )
  }
}

const WrappedSiteOwnerInformation = connect(mapStateToProps, mapDispatchToProps)(SiteOwnerInformation)
export default WrappedSiteOwnerInformation
