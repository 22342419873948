import React from 'react'
import {Modal} from 'antd'
import CancelProjectModal from '../DetailedProjectView/CancelProjectModal'

export default function CancelModal({id, visible, setModalVisible}) {
  return (
    <Modal
      title="Select a Reason for Cancelling this Project"
      visible={visible}
      onOk={() => setModalVisible(false)}
      onCancel={() => setModalVisible(false)}
      footer={null}
    >
      <CancelProjectModal
        id={id}
        visible={visible}
        done={() => setModalVisible(false)}
        setModalVisible={setModalVisible}
      />
    </Modal>
  )
}
