import {baseStore} from './utilities'
import DB from '../../DAL/DB'

const users = new DB.Users()

export default function avatar(parentID) {
  async function saveToDB(avatar) {
    await users.update(parentID, null, {avatar})
  }
  const store = baseStore(`users/${parentID}/avatars/`)

  return {store, saveToDB}
}
