export default function fillAddress(autocomplete, submit) {
  return async function () {
    if (!autocomplete) return

    let place = await autocomplete.getPlace()
    const geocoder = new window.google.maps.Geocoder()

    if (place && place.formatted_address) {
      geocoder.geocode({address: place.formatted_address}, (results) => {
        submit(JSON.parse(JSON.stringify(results)))
      })
    } else submit(null)
  }
}
