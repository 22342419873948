import React from 'react'
import {Input} from 'antd'

export default function DecoratedInput({disabled, type, typeProps, style, title, placeholder, value, change}) {
  return (
    <div className="flex-column field-wrapper" style={style}>
      <div className="info-text">{title}</div>
      <Input
        {...typeProps}
        disabled={disabled}
        type={type ? type : 'text'}
        style={{border: 'none'}}
        size="large"
        placeholder={placeholder ? placeholder : title}
        value={value}
        onChange={(e) => change(e)}
      />
    </div>
  )
}
