import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { saveImages } from './utilities';
import DnDWrapper from './DnDWrapper';
import { Spin } from 'antd';
import { resetState, fetchFormDetails } from './action_creators';
import { connect } from 'react-redux';
import { subscribe } from '../HOC/Firebase/SubscribeHOC';
import { useLoggerEffect, getLock } from '../effects';
import useProcessProject from './useProcessProject';

const stop = (state) => ({
  loading: state.status.loading,
  intakeTitle: state.project.intake ? state.project.intake.title : 'Intake',
  formId: state.project.intake.id, // Ensure formId is in state
  formLogo: state.project.formLogo, // Ensure formLogo is in state
});
const dtop = { resetState, fetchFormDetails };

function DoIntake({ match, loading, resetState, makeSubscription, subscribeResources, intakeTitle, formId, fetchFormDetails, formLogo }) {
  getLock(match.params.id);
  const logger = useLoggerEffect(`/projects/${match.params.id}`);
  const readonly = useProcessProject(logger, subscribeResources);

  useEffect(() => {
    makeSubscription({ paths: [{ collection: 'projects', doc: match.params.id }] }, 'project');
    window.addEventListener('beforeunload', saveImages);
    return () => {
      window.removeEventListener('beforeunload', saveImages);
      resetState();
    };
  }, []);

  useEffect(() => {
    if (formId && !formLogo) {
      fetchFormDetails(formId);
    }
  }, [formId, formLogo, fetchFormDetails]);

  return (
    <div>
      {loading && <Spin size="large" />}
      <div className="padding-2">
        {readonly ? (
          <h1 className="page-header">This project is in review. You cannot modify it.</h1>
        ) : (
          <div className="flex-column" style={{ textAlign: 'left' }}>
            <div className="form-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {formLogo && <img src={formLogo} alt="Form Logo" className="form-logo" />}
              <h1 className="page-header bold padding-bottom-2" style={{ flex: 1 }}>
                {intakeTitle}
              </h1>
              <Link to={`/projects/${match.params.id}/manage`} style={{ marginLeft: 'auto' }}>
                Back to Project
              </Link>
            </div>
            <DnDWrapper />
          </div>
        )}
      </div>
    </div>
  );
}

const WrappedDoIntake = subscribe()(connect(stop, dtop)(DoIntake));
export default WrappedDoIntake;
