import React, {useEffect, useState} from 'react'
import DB from '../../../DAL/DB'
import {subscribe} from '../../../HOC/Firebase/SubscribeHOC'
import {Button, Input, Tooltip} from 'antd'
import {CopyOutlined} from '@ant-design/icons'

const users = new DB.Users()

export function APIAccess({makeSubscription, subscribeResources, user = {}}) {
  const [whitelist, setWhitelist] = useState([])
  const [apiKey, setApiKey] = useState(null)

  useEffect(function () {
    const u1 = makeSubscription({paths: [{collection: 'meta', doc: 'api_user_types'}]}, 'whitelist')
    const u2 = makeSubscription({paths: [{collection: 'oauth2_client', doc: user.id}]}, 'oauthClient')

    return () => {
      u1()
      u2()
    }
  }, [])

  useEffect(
    function () {
      if (!subscribeResources.whitelist) return
      const {
        whitelist: [{whitelist: wl}],
      } = subscribeResources
      setWhitelist(wl)
    },
    [subscribeResources.whitelist]
  )

  useEffect(
    function () {
      console.log('subscribeResources:', subscribeResources)
      if (!subscribeResources.oauthClient) return
      if (subscribeResources.oauthClient.length != 1) return

      console.log('setting API Key')
      const {
        oauthClient: [{clientId}],
      } = subscribeResources
      setApiKey(clientId)
    },
    [subscribeResources.oauthClient]
  )

  return (
    <div style={{textAlign: `left`}}>
      {apiKey && whitelist && whitelist.includes(user.type) ? (
        <div className="flex-row">
          <Input id="apiInput" disabled={true} value={apiKey} overflow="scroll" />
          <div className="padding-right-1"></div>
          <Tooltip title="Copy Key to Clipboard">
            <CopyOutlined
              style={{fontSize: 28}}
              onClick={() => {
                navigator.clipboard.writeText(apiKey).then(() => console.log(`Copied Text to Clipboard`))
              }}
            />
          </Tooltip>
        </div>
      ) : (
        <div></div>
      )}
      <br />
      <Button
        onClick={() => {
          if (user.id) users.update(user.id, null, {request_key: true})
        }}
      >
        Generate New API Key
      </Button>
    </div>
  )
}

const WrappedAPIAccess = subscribe()(APIAccess)
export default WrappedAPIAccess
