import React, {useEffect, useReducer, useContext} from 'react'
import SystemLogger from '../SystemLogger'
import {auth} from '../Auth'

const initialState = {logs: {}, subscribers: {}, user: null}

function reducer(state, action) {
  switch (action.type) {
    case 'logs':
      return {...state, logs: action.value}
    case 'log':
      return {...state, logs: {...state.logs, [action.value]: true}}
    case 'deleteLog':
      const ll = {...state.logs}
      delete ll[action.value]
      return {...state, logs: ll}
    case 'subscribers':
      return {...state, subscribers: action.value}
    case 'subscriber':
      return {...state, subscribers: {...state.subscribers, [action.message]: action.timeout}}
    case 'user':
      return {...state, user: action.value}
  }
}

const ReducerContext = React.createContext()

export default function Provider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ReducerContext.Provider value={{dispatch, state}}>
      <SystemNotifier {...props} />
    </ReducerContext.Provider>
  )
}

export function SystemNotifier() {
  const {dispatch, state} = useContext(ReducerContext)

  useEffect(function () {
    return auth.sunkaizenUserSubscribe((user) => dispatch({type: 'user', value: user}))
  }, [])
  useEffect(
    function () {
      if (!state.user) return

      return SystemLogger.subscribe(state.user.id)((message) => {
        dispatch({type: 'log', value: message})
        const timeoutId = setTimeout(() => dispatch({type: 'deleteLog', value: message}), 5000)

        dispatch({type: 'subscriber', message, timeout: timeoutId})
      })
    },
    [state.user]
  )

  function clear(message) {
    clearTimeout(state.subscribers[message])
    dispatch({type: 'deleteLog', value: message})
  }

  return (
    <div className="flex-column padding-top-2" style={{position: 'fixed', left: '15%', zIndex: 10000}}>
      {Object.keys(state.logs).map((log, index) => (
        <div
          className="color-black padding-1 pointer"
          style={{
            backgroundColor: 'lightyellow',
            margin: '0.1rem',
            borderRadius: '0.5rem',
            fontSize: '2rem',
          }}
          onClick={() => clear(log)}
          key={`logs:${index}`}
        >
          {log}
        </div>
      ))}
    </div>
  )
}
