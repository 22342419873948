import React, { useEffect } from 'react'
import NewTable from '../UnifiedList/Provider'
import { useLocation  } from 'react-router-dom'
import {saveIntake} from '../DoIntake/utilities'
import {statusLogs} from '../DoIntake/Details/statusLogs'
import { history } from '../history'


export default function ProjectsList(props) {

  const location = useLocation()

  useEffect(() => {
    const performActions = async () => {
      if (location?.state?.sourcePage === 'details') {
        const { done } = location.state;
        try {
          await saveIntake(done);
          history.replace({ state: {} }); // Clear state to avoid re-triggering
        } catch (e) {
          console.error('Error:', e);
        }
      }
    };
  
    if (location.state?.sourcePage === 'details') {
      performActions();
    }
  }, [location.state]);
  


  return (
    <div className="flex-column padding-2 align-start">
      <NewTable {...props} strategy="projects" />
    </div>
  )
}
