import React from 'react'
import {Checkbox} from 'antd' //	Form, Input,

const Field = ({name, value}) => (
  <div className="flex-column padding-top-1 full-width" style={{borderBottom: `0.1rem solid #C4CDD5`}}>
    <div className="padding-top-1 flex-row justify-start" style={{fontSize: `65%`}}>
      {name}
    </div>
    <div className="flex-row justify-start" style={{padding: `0.5rem 0 1rem 0`}}>
      {value}
    </div>
  </div>
)

function Coordinate({c, label = ''}) {
  return (
    <div
      className="flex-column padding-top-1 padding-bottom-1"
      style={{flex: 15, borderBottom: `0.1rem solid #C4CDD5`}}
    >
      <div className="flex-row justify-start" style={{fontSize: `65%`, padding: `1rem 0 0.5rem 0`}}>
        {label}
      </div>
      <div>{c}</div>
    </div>
  )
}

export default function SiteOwnerInformation({
  geocode_results = {geometry: {location: {}}},
  siteOwnerName = `none`,
  siteAddress = `not provided`,
}) {
  const {lat, lng} = geocode_results.geometry.location

  return (
    <div className="flex-column align-start background-white padding-2 margin-bottom-1" style={{fontAlignt: `left`}}>
      <h4>Site Owner</h4>
      <div className="full-width flex-column align-start padding-1" style={{background: `rgba(249, 250, 251, 0.7)`}}>
        <Field name="Name" value={siteOwnerName} />
        <Field name="Site Address" value={siteAddress} />
        {lat && lng && (
          <div className="flex-row full-width" style={{padding: `2rem 0 1rem 0`}}>
            <Coordinate label="Lat" c={lat} />
            <div style={{flex: 1}}></div>
            <Coordinate label="Long" c={lng} />
          </div>
        )}
        <div className="flex-column padding-top-1 padding-bottom-1">
          <Checkbox className="padding-bottom-1 margin-0" disabled>
            Owner is different from Representative
          </Checkbox>
          <Checkbox className="padding-0 margin-0" disabled>
            Representative is located at a different site
          </Checkbox>
        </div>
      </div>
    </div>
  )
}
