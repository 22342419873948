import React from 'react'

export default function LabeledSection({label, children}) {
  return (
    <div className="flex-row">
      <div className="padding-right-1 bold">{label}</div>
      <div>{children}</div>
    </div>
  )
}
