import Create from './Create'
import SelectFailures from './SelectFailures'
import Update from './Update'

export {Create, Update, SelectFailures}

export default {
  create: Create,
  update: Update,
  'select-failures': SelectFailures,
}
