import React, {useState} from 'react'
import PDFReportGenerator from '../PDFReportGenerator'
import {Modal, Button} from 'antd'

// this may not need any updates;
// if the assumption is that only users who have access to projects can get them from the database
//  then only users who have a role on the project will be able to make this modal work
// there is an open question as to whether this is an appropriate permissions model; FUs might not get access to this
export default function CustomPDFReport({status, intake, site, id}) {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(false)

  async function generatePDFReport() {
    setShow(false)
    setDone(true)
  }

  return (
    <div className="full-width padding-1">
      {
        <Button
          className="full-width"
          disabled={['To Be Scheduled', 'Scheduled'].includes(status)}
          onClick={() => {
            setDone(false)
            setShow(!show)
          }}
        >
          Create Custom PDF Report
        </Button>
      }
      <Modal
        title="Create Custom PDF Report"
        visible={show}
        onCancel={() => setShow(!show)}
        onOk={generatePDFReport}
        okText="Create"
      >
        <PDFReportGenerator done={done} title={intake.title} site={site} formId={intake.id} id={id} />
      </Modal>
    </div>
  )
}
