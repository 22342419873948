import {firebase} from '../../utilities'

function convertDates(project) {
  project.created = new firebase.firestore.Timestamp(project.created?._seconds, project.created?._nanoseconds)
  project.updated = new firebase.firestore.Timestamp(project.updated?._seconds, project.updated?._nanoseconds)

  if (project.inspection && project.inspection.date) {
    project.inspection.date = new firebase.firestore.Timestamp(
      project.inspection.date._seconds,
      project.inspection.date._nanoseconds
    )
  }

  return project
}

export default function convertElastic(res) {
  const projects = res.map((h) => h._source).map(convertDates)

  return projects
}
