import React, {useEffect, useState, useContext, useRef} from 'react'
import {Button, List} from 'antd'
import {ReducerContext} from '../reducer'
import {loggerEffect} from '../../effects'
import DB from '../../DAL/DB'
import {auth} from '../../Auth'
import SiteNote from './SiteNote'

const projects = new DB.Projects()

export default function SiteNotes() {
  const {state, dispatch} = useContext(ReducerContext)
  const {project} = state
  const {siteNotes = []} = project
  const firstRun = useRef(true)

  const [logger, setLogger] = useState(null)
  useEffect(loggerEffect(setLogger, `/projects/${project.id}`), [project.id])

  useEffect(
    function () {
      if (firstRun.current) {
        firstRun.current = false
        return
      }

      (async function () {
        await save()
      })()
    },
    [(project.siteNotes || []).length]
  )

  async function save() {
    await projects.updatebatch(state.project.id, null, {updatedBy: auth?.sunkaizenUser?.id, updatedByName: auth?.sunkaizenUser?.name, siteNotes: state.project.siteNotes})
  }
  function add() {
    const {id, name} = auth.sunkaizenUser
    const newNote = {created: new Date(), note: '', authorId: id, author: name}

    dispatch({type: 'addNote', value: newNote})
  }

  return (
    <div className="flex-column full-width" style={{textAlign: 'left'}}>
      <div className="padding-1">
        <div className="full-width" style={{borderTop: '1px solid rgba(220, 220, 220, 1)'}}></div>
      </div>
      <div className="tiny-label">Notes</div>
      {siteNotes.length > 0 && (
        <List dataSource={siteNotes} renderItem={(note, noteKey) => <SiteNote {...{logger, noteKey, save}} />} />
      )}
      <div className="padding-top-1 flex-row justify-end">
        <Button onClick={add}>Add Note</Button>
      </div>
    </div>
  )
}
