import moment from 'moment'

export default function makeSpec(projects = []) {
  // count of projects completed by day
  const bins = {}
  const durationRange = []

  for (const project of projects) {
    const {completed_on} = project

    durationRange.push({completed: project.completed_on ? project.completed_on.toDate() : undefined})

    if (!completed_on || completed_on < moment().subtract('30', 'days')) continue

    const day = completed_on.startOf('day').toDate()

    bins[day] = bins[day] ? bins[day] + 1 : 1
  }

  // fill in blank dates
  for (let i = 0; i < 32; i++) {
    const m = moment().startOf('day').subtract(i, 'days')
    const d = m.toDate()
    const v = bins[d]
    if (!v) bins[d] = 0
  }

  const data = []

  // convert bins to an array of data
  for (let day in bins) {
    const incomplete = durationRange.filter((p) => {
      if (!p.completed) return true
      return p.completed >= new Date(day)
    }).length

    console.log(incomplete)

    data.push({date: day, type: 'Completed', count: bins[day], c: 0})
    data.push({date: day, type: 'Incomplete', count: incomplete})
  }

  return {
    data: {name: 'table', values: data},
    mark: {type: 'line', tooltip: true},
    width: 400,
    height: 300,
    padding: 5,
    encoding: {
      x: {field: 'date', type: 'temporal', title: null},
      y: {field: 'count', type: 'quantitative'},
      color: {field: 'type', type: 'nominal'},
    },
  }
}
