import BaseModel from './BaseModel';
import { db, firebase } from '../utilities';

interface Icon {
  id: string;
  name: string;
  description: string;
  image: string;
  formId: string;
  status: boolean;
  order: number;
}

class Icons extends BaseModel {
  constructor() {
    super(db.collection('icons'));
  }

  async getAllIcons(formId: string | undefined): Promise<Icon[]> {
    try {
      if (!formId) {
        console.warn('formId is undefined or null. Returning empty array.');
        return [];
      }
  
      const snapshot = await this.baseQuery
        .where('status', '==', true)
        .where('formId', '==', formId)
        .get(); // Remove orderBy to include records without an order field
  
      if (snapshot.empty) {
        return [];
      }
  
      // Map documents and ensure required fields with default values
      const icons = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          name: data.name || "",                // Provide a default if necessary
          description: data.description || "",
          image: data.image || "",              // Assuming `image` is required
          formId: data.formId || formId,
          status: data.status ?? true,
          order: data.order !== undefined ? data.order : Number.MAX_SAFE_INTEGER, // Default large order for missing
        };
      });
  
      // Sort by the `order` field after mapping
      return icons.sort((a, b) => a.order - b.order);
    } catch (error) {
      console.error("Error fetching icons:", error);
      throw error;
    }
  }
  

  async createIcon(icon: { name: string; description?: string; image?: string;  formId: string;  }) {
    try {
      const snapshot = await this.baseQuery.where('formId', '==', icon.formId).where('name', '==', icon.name).get();
      if (!snapshot.empty) {
        throw new Error('Icon name must be unique');
      }
      await this.baseQuery.add(icon);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateIcon(id: string, icon: { name: string; description?: string; image?: string; formId?: string }) {
    try {
      const snapshot = await this.baseQuery
        .where('name', '==', icon.name)
        .where('formId', '==', icon.formId)
        .where(firebase.firestore.FieldPath.documentId(), '!=', id)
        .get();
      if (!snapshot.empty) {
        throw new Error('Icon name must be unique');
      }
      await this.baseQuery.doc(id).update(icon);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteIcon(id: string) {
    try {
      await this.baseQuery.doc(id).update({ status: false });
    } catch (error) {
      console.error( error);
      throw error;
    }
  }

  async updateIconOrder(id: string, order: number): Promise<void> {
    try {
      if (!id || order === undefined) {
        throw new Error("ID and order are required.");
      }

      // Update only the order field for the specified document
      await this.baseQuery.doc(id).update({ order: order });
    } catch (error) {
      console.error("Error in updateIconOrder:", error);
      throw error;
    }
  }

}

export default Icons;
