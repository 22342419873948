import React, {useState} from 'react'
import {Input} from 'antd'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Editor({field}) {
  return <ReactQuill key={`que-${field.question}`}  defaultValue={field.question} />;
}

const MultiInput = ({field}) => <Editor placeholder={'Write something...'} field={field} />
export default MultiInput
