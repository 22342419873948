import React from 'react';
import { Provider } from 'react-redux';
import {store} from './utilities'
import DoIntake from './TopLevelState';

const EntryPoint = (props) => (
  <Provider store={store}>
    <DoIntake {...props} />
  </Provider>
);

export default EntryPoint;