import React from 'react'
import makeCalendarMap from './makeCalendarMap'

const dateCellRender = (calendar) => (value) => {
  const calendarMap = makeCalendarMap(calendar)
  const events = ((calendarMap[value.year()] || {})[value.month()] || {})[value.date()] || []

  return (
    <div className="flex-row justify-center">
      <div className="flex-row">
        {events
          .map((event, i) => {
            switch (i) {
              case 0:
              case 1:
              case 2:
                return <div key={i}>&bull;</div>
              case 3:
                return <div>+</div>
              default:
                return
            }
          })
          .filter((i) => i)}
      </div>
    </div>
  )
}

export default dateCellRender
