import React from 'react'
import Permissions from './Permissions'
import useSubscribePermissions from './useSubscribePermissions'

const PermissionsBlock = ({permissionsProps, user}) => {
  const [qualifications, clients] = useSubscribePermissions()

  return (
    <div>
      <div className="flex-column flex-align-start">
        <div style={{fontWeight: 'bold', padding: '2rem 0 1rem 0'}}>Qualifications</div>
        <Permissions
          {...{
            ...permissionsProps,
            permissions: qualifications,
            userPermissions: user.qualifications,
            userPermissionsAll: user.qualificationsAll,
            type: 'qualifications',
          }}
        />
      </div>
      <div className="flex-column flex-align-start">
        <div style={{fontWeight: 'bold', padding: '2rem 0 1rem 0'}}>Clients</div>
        <Permissions
          {...{
            ...permissionsProps,
            permissions: clients,
            userPermissions: user.clients,
            userPermissionsAll: user.clientsAll,
            type: 'clients',
          }}
        />
      </div>
    </div>
  )
}

export default PermissionsBlock
