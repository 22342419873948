import React from 'react'
import PropTypes from 'prop-types'
import {submitFiles} from './SubmitFiles'

// If you need to bypass all the machinery in this component except the storage
// ref assignment, supply a function to the 'submit' prop -- Chris

/**
 * This component creates a drag and drop box that lets a user upload files.
 * It handles file uploads by using the submitFiles function.
 * Requires the following props to be passed in
 * @param collection The collection of the associated document in firebase.
 * @param id The id of the document this file is expected to be part of. A reference to this file will be written to the corresponding document
 * @param type The type of file this is. Valid values are: "photo", "file", "report", "misc", "thumbnail"
 * The function will append file data to different parts of the document based on this,
 * and it will be stored in a folder with other files of the same type.
 *
 * The uploader handles multiple files by default, but if you'd like to restrict
 * it to only one file, use the `singleUpload` flag.
 */

export default class FileUploader extends React.Component {
  static propTypes = {
    helperMessage: PropTypes.func,
    id: PropTypes.string,
    type: PropTypes.oneOf([`photo`, `file`, `report`, `misc`, `thumbnail`, `avatar`, `logo`]).isRequired,
    collection: PropTypes.string,
    updateStatus: PropTypes.func,
    submit: PropTypes.func,
    sectionId: PropTypes.string,
    fieldIndex: PropTypes.string,
    done: PropTypes.func,
    disabled: PropTypes.bool,
  }

  state = {
    backgroundColor: `#E9ECEF`,
    color: `black`,
    uploaded: [],
    files: [],
    textarrays: [],
    names: [],
  }

  componentDidMount = () => {
    this.fileinput = React.createRef()
    // Without this the drop() function fails because .current is undefined
    if (!this.fileinput.current) this.fileinput.current = {}
  }

  componentDidUpdate() {
    if (this.state.files.length > 0) {
      this.upload()
    }
  }

  // fires when the user submits a list of CSV files
  upload = async () => {
    await submitFiles({files: this.state.files, ...this.props})
    this.setState({files: []})
  }

  // catches files the user drops and uses the most up-to-date file handler the browser suppports
  drop = async (e) => {
    this.setState({dragOver: false})
    e.preventDefault()
    e.persist()

    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.items
        ? Array.from(e.dataTransfer.items)
            .filter((i) => i.kind === `file`)
            .map((f) => f.getAsFile())
        : Array.from(e.dataTransfer.files)
    } else {
      files = Array.from(e.target.files)
      this.fileinput.current.value = null
    }

    this.setState({
      backgroundColor: `lightgrey`,
      color: `black`,
      files: [].concat(this.state.files, files).filter((f) => f),
      names: [].concat(
        this.state.names,
        files.map((f) => f.name).filter((i) => i)
      ),
    })

    // clean up the user-dropped files; copies are stored in state now
    if (e.dataTransfer) {
      if (e.dataTransfer.items) e.dataTransfer.items.clear()
      else e.dataTransfer.clearData()
    }

    this.upload()
  }

  // changes the backgroundColor of the file drop window when files dragged over it
  dragover = (e) => {
    e.preventDefault()
    this.setState({backgroundColor: `black`, color: `white`, dragOver: true})
  }

  // restores original backgroundColor on drag leave
  dragleave = (e) => {
    e.preventDefault()
    this.setState({backgroundColor: `lightgrey`, color: `black`, dragOver: false})
  }

  getAccept = () => {
    const accepts = {
      photo: 'image/png,image/jpeg',
      thumbnail: 'image/png,image/jpeg',
      avatar: 'image/png,image/jpeg',
      logo: 'image/png,image/jpeg',
      report: 'application/pdf"',
    }
    return accepts[this.props.type] || '*'
  }

  render = () => {
    let {drop, dragover, dragleave} = this //	upload,
    let {files} = this.state
    let {helperMessage: Message} = this.props

    return (
      <div
        className={`flex-column full-height full-width align-center justify-center file-uploader ${
          this.state.dragOver && `dragover`
        }`}
        style={this.props.disabled ? {pointerEvents: 'none', opacity: '0.4'} : {}}
      >
        <div
          className="files full-height full-width align-center justify-center flex-column margin-1"
          style={{
            flex: 1,
            backgroundColor: this.state.backgroundColor,
            color: this.state.color,
            padding: `5rem`,
          }}
          onDrop={drop}
          onDragOver={dragover}
          onDragLeave={dragleave}
        >
          {files.length === 0 && (
            <div style={{fontSize: `150%`, width: `100%`}}>
              <Message />
            </div>
          )}
          {files.length > 0 && (
            <div>
              <h3>Uploaded</h3>
              {files.map((f, i) => (
                <div className="bold" key={i}>
                  {f.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="upload-button">
          <p>Or...</p>
          <input type="file" onChange={(e) => drop(e)} multiple ref={this.fileinput} accept={this.getAccept()} />
        </div>
      </div>
    )
  }
}
