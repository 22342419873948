import React from 'react'
// import {CheckOutlined} from "@ant-design/icons"
import {connect} from 'react-redux'
import {modify} from './action_creators'
import FileUploader, {patterns} from '../NewFileUploader'

const stop = (state) => {
  const {id, logo} = state.form
  return {id, logo}
}

const formModify = modify('form_field')
const dtop = {formModify}

export function FormLogo({id, logo}) {
  return (
    <div>
      <FileUploader
        parentID={id}
        pattern={patterns.formLogo}
        helperMessage={() => {
          return (
            <div>
              <div>Drop an image here</div>
              <div style={{fontSize: 14}}>
                Your logo should be landscape-oriented, and will work best with a ratio of 3:2
              </div>
              <div style={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'center'}}>
                {logo && <img style={{ maxHeight: 50 }} src={logo.url} />}
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

const WrappedFormLogo = connect(stop, dtop)(FormLogo)
export default WrappedFormLogo
