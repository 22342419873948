import React, {useRef} from 'react'
import useMakeGraph from './useMakeGraph'
import makeSpec from './makePerWeekSpec'

export default function PerWeek({projects}) {
  const ref = useRef(null)
  const clicked = useMakeGraph(ref, projects, makeSpec)

  return (
    <div className="background-white margin-right-2 padding-1">
      <h4>Projects Completed Per Week</h4>
      <div className="full-width" ref={ref} />
    </div>
  )
}
