import React from 'react'
import {Radio} from 'antd'

export default function RadioButton({field}) {
  return (
    <Radio.Group style={{width: '20rem', pointerEvents: 'none'}}>
      <div className="flex-column">
        {field.options &&
          field.options.map((option, i) => (
            <Radio value={option.label.toLowerCase()} key={i}>
              {option.label}
            </Radio>
          ))}
      </div>
    </Radio.Group>
  )
}
