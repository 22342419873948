import React from 'react'
import NewTable from '../UnifiedList/Provider'

export default function ProjectsInspectionsList(props) {
  return (
    <div className="flex-column padding-2 align-start">
      <NewTable {...props} strategy="inspections" />
    </div>
  )
}
