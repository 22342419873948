import React from 'react'
import {Checkbox} from 'antd'

export default function Urgency({change}) {
  return (
    <div>
      <label>Urgency</label>
      <Checkbox onChange={(e) => change(e.target.checked)}>Check if Urgent</Checkbox>
    </div>
  )
}
