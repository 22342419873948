import React, {useState, useEffect, useRef} from 'react'

export default function StaticMap({site = {geocode_results: {geometry: {location: {}}}}}) {
  const mapRef = useRef(null)
  const [map, setMap] = useState(null)

  const {geocode_results: {geometry: {location}} = {geometry: {location: {}}}} = site
  const link = `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`

  useEffect(
    function () {
      if (map) map.setCenter(location)
    },
    [location]
  )
  useEffect(
    function () {
      if (window.google.maps && map) {
        window.removeEventListener(`storage`, setLoaded)
      } else if (window.google.maps) {
        setLoaded({key: 'googleMapsLoaded', newValue: 'true'})
      } else {
        window.addEventListener('storage', setLoaded)
      }
    },
    [window.google && window.google.maps, map]
  )

  function setLoaded(e) {
    if (e.key === `googleMapsLoaded` && e.newValue === `true`) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: location,
        zoom: 11,
      })

      if (location.lat && location.lng) setMap(map)
    }
  }

  return (
    <a target="_blank" rel="noopener noreferrer" href={link}>
      <div ref={mapRef} className="full-width" style={{height: `20rem`}}>
        Map
      </div>
    </a>
  )
}
