import React from 'react'

export default function RowLayout({left, right}) {
  return (
    <div className="flex-row full-width">
      <div style={{flex: 3}}>{left}</div>
      <div style={{flex: 1}} />
      <div style={{flex: 8, display: `flex`}}>{right}</div>
    </div>
  )
}
