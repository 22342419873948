import React, {useReducer} from 'react'
import CancelledIntakes from '../DetailedProjectView/InspectionInformation/CancelledIntakes'

function reducer(state, action) {
  switch (action.type) {
    case 'events':
      return {...state, events: action.value}
    case 'cancelledIntakes':
      return {...state, cancelledIntakes: action.value}  
    case 'availability':
      return {...state, availability: action.value}
    case 'filterType':
      return {...state, filterType: action.value}
    case 'filterText':
      return {...state, filterText: action.value}
    case 'selected':
      return {...state, selected: action.value}
    case 'event':
      return {...state, event: action.value}
    case 'eventField':
      return {...state, selected: {...state.selected, [action.key]: action.value}}
    default:
      return state
  }
}

const initialState = {
  events: {},
  availability: {},
  filterType: null,
  filterText: '',
  event: {},
  selected: null,
  cancelledIntakes: []
}

export const ReducerContext = React.createContext(null)

export const wrap = (C) => (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ReducerContext.Provider value={{state, dispatch}}>
      <C {...props} />
    </ReducerContext.Provider>
  )
}
