import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import { element } from 'prop-types'

export default function fullCalendarProps(args) {
  //console.log('all args',args)
  const {showIntakes, setShowIntakes} = args
  const {userType, displayedEvents, dispatch, eventClick, datesSet} = args

  return {
  initialView: "dayGridMonth",
      views: {listWeekMonth: {type: 'listWeek', duration: {months: 1}, buttonText: 'list'}},
      headerToolbar: {
        left: 'prev,next today label',
        center: 'title',
        right: 'showIntakes dayGridMonth,timeGridWeek,listWeekMonth',
      },
      customButtons: {
        showIntakes: {
          text: showIntakes ? 'Hide Intakes' : 'Show Intakes',
          click: () => setShowIntakes(!showIntakes),
        },
        label: {
          text: 'I: Intake, R: Rescheduled, C: Canceled, CR: Cancel Request, A: Availability',
          hint: 'custom-label',
          click: () => {}, // no action needed
          class: 'custom-label',
        },
      },
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      rerenderDelay: 10,
    unselectAuto: false,
    editable: userType === 'field-user',
    weekends: true,
    events: displayedEvents,
    selectable: userType === 'field-user',
    select: (value) => dispatch({type: 'selected', value}),
    eventClick: eventClick,
    datesSet
  }

  // return {
  //   defaultView: 'dayGridMonth',
  //   views: {listWeekMonth: {type: 'listWeek', duration: {months: 1}, buttonText: 'list'}},
  //   header: {
  //     left: 'prev,next today',
  //     center: 'title',
  //     right: 'showIntakes dayGridMonth,timeGridWeek,listWeekMonth',
  //   },
  //   customButtons: {
  //     showIntakes: {
  //       text: showIntakes ? 'Hide Intakes' : 'Show Intakes',
  //       click: () => setShowIntakes(!showIntakes),
  //     },
  //   },
  //   plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
  //   rerenderDelay: 10,
  //   unselectAuto: false,
  //   editable: userType === 'field-user',
  //   weekends: true,
  //   events: displayedEvents,
  //   selectable: userType === 'field-user',
  //   select: (value) => dispatch({type: 'selected', value}),
  //   eventClick: eventClick,
  //   datesSet
  // }
}
