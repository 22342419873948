import React, {useContext, useEffectk, useState} from 'react'
import {List, Progress, Checkbox, Space} from 'antd'
import {ReducerContext, wrap} from './reducer'
import {Header, DateItem, DropdownItem} from '.'
import {makeFilterManipulationFunctions, useGetResources, useGetProjects, useMakeFields} from './utilities'

const itemMap = (type) => (type === 'completed' || type === 'started' ? DateItem : DropdownItem)

export function CreateCSVReport({done, setDone}) {
  const {state, dispatch} = useContext(ReducerContext)

  const fields = useMakeFields()
  const [loading, percent] = useGetProjects(done, setDone)
  useGetResources()
  const {setFilter, deleteInstance, addInstance} = makeFilterManipulationFunctions(state, dispatch)

  return (
    <div>
      <List
        loading={loading}
        itemLayout="vertical"
        size="small"
        dataSource={fields}
        header={<Header />}
        renderItem={(item) => {
          const C = itemMap(item.type)
          return <C {...{item, setFilter, deleteInstance, addInstance}} />
        }}
      />

      <div className="padding-top-1 padding-bottom-2">
        <Checkbox
          defaultChecked={false}
          value={state.includeIntakeComments}
          onChange={(e) => {
            dispatch({type: 'includeIntakeComments', value: e.target.checked})
          }}
        >
          Include Intake Comments{' '}
        </Checkbox>
      </div>

      <button
        onClick={() => {
          dispatch({type: 'clearState'})
        }}
      >
        Reset Filter
      </button>
      {done && <Progress percent={percent} size="small" />}
    </div>
  )
}

const WrappedCreateCSVReport = wrap(CreateCSVReport)
export default WrappedCreateCSVReport
