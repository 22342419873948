import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Form, Select} from 'antd'
import {fieldNameConverter} from '../../validators'
import {Required} from '../utilities'
import {setField} from '../action_creators'
import {connect} from 'react-redux'
import {auth} from '../../Auth'
import naturalCompare from 'string-natural-compare'

const mapStateToProps = (state) => ({
  fields: state.fields,
  clients: Object.values(state.dropdowns.organizations || {}),
  project: state.projectBase,
})
const mapDispatchToProps = (dispatch) => ({
  setField: (field) => (value) => dispatch(setField(field, value)),
})

export function ClientInformation({project, fields, setField, allowCreateProject,clients = []}) {
  if (!fields) fields = {}
  const [clientsMap, setClientsMap] = useState({})

  useEffect(
    function () {
      const cm = []
      for (let client of clients) {
        cm[client.id] = client
        setClientsMap(cm)
      }
    },
    [clients]
  )

  const comparator = (a, b) => naturalCompare(a.name || '', b.name || '', {caseInsensitive: true})

  return (
    <div>
      {(fields.organization || {}).id === auth.sunkaizenUser.organization.id && (
        <div className="flex-column align-start padding-2 margin-bottom-1" style={{background: `#F4F6F8`}}>
          <h3>Client Information</h3>
          <Required />
          <Form.Item className="full-width margin-bottom-1 padding-right-1">
            <Select
              disabled={allowCreateProject || project.status === 'Requested'}
              initialValue={fields.client ? fields.client.name : null}
              onChange={(e) => {
                const {id, name, qualifications} = clientsMap[e]
                setField(`client`)({id, name, qualifications: qualifications || {}})
              }}
              size="large"
              value={fields.client ? fields.client.name : null}
              className="full-width"
              placeholder={fieldNameConverter[`client`]}
              data-cy="clientInfo"
            >
              {clients.sort(comparator).map((client, i) => (
                <Select.Option key={i} value={client.id}>
                  {client.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      )}
    </div>
  )
}

ClientInformation.propTypes = {
  fields: PropTypes.object,
  setFields: PropTypes.func,
  clients: PropTypes.array,
}

const WrappedClientInformation = connect(mapStateToProps, mapDispatchToProps)(ClientInformation)
export default WrappedClientInformation
