import columns from './columns'
import onRow from './onRow'
import permissions from './permissions'
import sorter from './sorter'
import rowSelection from './rowSelection'
import {requestFilter} from '../utilities' // organizationFilter,
import or from './or'

const config = {
  columns,
  onRow,
  permissions,
  sorter,
  or,
  rowSelection,
  type: 'requests',
  searchbar: true,
  savedFilters: true,
  tabs: 'status',
  // bulk: BulkProjectImport,
  // create: ProjectCreator,
  // postProcess: [hideCanceled]
  preProcess: [requestFilter], // organizationFilter,
}

export default config
