import {useState, useEffect, useContext} from 'react'
import {saveAs} from 'file-saver'
import {ReducerContext} from '../reducer'
import {getProjects, createCSV} from '../utilities'

export default function useGetProjects(done, setDone) {
  const {state, dispatch} = useContext(ReducerContext)

  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(0)

  window.stop_csv_report = !done

  useEffect( 
    function () {
      ;(async function () {
        if (!done) {
          setLoading(false)
          return
        }
        const projects = await getProjects({...state.filterTypes}, setPercent)
        if (window.stop_csv_report) {
          return
        }
        const csvFile = createCSV(projects, state.includeIntakeComments)
        const blob = new Blob([csvFile], {type: 'text/csv'})
        saveAs(blob, 'report.csv')
        setPercent(0)
        dispatch({type: 'clearState'})
        setDone(false)
      })()
    },
    [done]
  )

  return [loading, percent]
}
