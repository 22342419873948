import React, {useState} from 'react'
import {Button, Select, Input, message} from 'antd'
import DB from '../../DAL/DB'
import {connect} from 'react-redux'

function stop(state) {
  return {state}
}
function dtop(dispatch) {
  return {dispatch}
}

const rubrics = new DB.Rubrics()
export async function addRubric(formId, name, scoringSystemId, dispatch, existingRubrics) {
  if (!name) {
    message.error(`Rubric must have a name`)
    return false
  }

  if (!scoringSystemId) {
    message.error(`Rubric must have a scoring system`)
    return false
  }

  if (
    Object.values(existingRubrics)
      .map((rubric) => rubric.name)
      .includes(name)
  ) {
    message.error(`Rubric must have unique name`)
    return false
  }


  let autoRubricData = {
    label: '',
    autoRubricQuestion: '',
    tipText: '',
    options: [{option: '', selectedRubric: ''}],
    enableAutoRubric: false,
  }
  if (existingRubrics && Object.keys(existingRubrics).length > 0) {
    if(existingRubrics[Object.keys(existingRubrics)[0]].autoRubricData) {
      autoRubricData = existingRubrics[Object.keys(existingRubrics)[0]].autoRubricData
    }    
  }

  const id = rubrics.makeId()
  const r = {id, formId, name, questions: {}, scoringSystemId, autoRubricData, isSelected: false}
  dispatch({
    type: 'rubric',
    id: r.id,
    value: r,
  })

  return true
}

export function AddRubric({state, dispatch, formId, scoringSystems, existingRubrics}) {
  const [name, setName] = useState(null)
  const [scoringSystem, setScoringSystem] = useState(null)

  async function add() {
    try {
      await addRubric(formId, name, scoringSystem, dispatch, existingRubrics)
      setName(null)
      setScoringSystem(null)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="flex-row align-center margin-top-1">
      <div style={{flex: 4}}>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="New rubric name"
          data-cy="rubricNameInput"
        />
        <div className="margin-top-1" />
        <Select
          className="full-width"
          value={scoringSystem || undefined}
          placeholder="Select a Scoring System"
          onChange={(ssId) => setScoringSystem(ssId)}
          data-cy="scoringSystemSelect"
        >
          {Object.values(scoringSystems).map((scoringSystem) => (
            <Select.Option key={scoringSystem.id} value={scoringSystem.id}>
              {scoringSystem.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      {Object.keys(existingRubrics).length >= 4 ? (
        <Button style={{flex: 1, marginLeft: `1rem`, alignSelf: `flex-end`}} disabled={true}>
          No Rubrics Remaining
        </Button>
      ) : (
        <Button style={{flex: 1, marginLeft: `1rem`, alignSelf: `flex-end`}} onClick={add} data-cy="addRubricBtn">
          Add Rubric
        </Button>
      )}
    </div>
  )
}

const WrappedAddRubric = connect(stop, dtop)(AddRubric)
export default WrappedAddRubric
