import {store} from '../utilities'
import {setLock} from '../action_creators'

export function getLock() {
  const lock = store.getState().tableControls.lock + 1
  store.dispatch(setLock(lock))
  return lock
}

export function unlock(lock) {
  return store.getState().tableControls.lock === lock
}
