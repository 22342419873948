import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import save from '../save/save';

const ReportTemplateText = () => {
  const dispatch = useDispatch();
  const { coverPageText, keysPageText, categoryDescriptions, summaryOfActionItems } = useSelector((state) => state.form);

  const [coverText, setCoverText] = useState(coverPageText || '');
  const [keysText, setKeysText] = useState(keysPageText || '');
  const [categoryText, setCategoryText] = useState(categoryDescriptions || '');
  const [summaryText, setSummaryText] = useState(summaryOfActionItems || '');

  const [enableSave, setEnableSave] = useState(false);

  useEffect(() => {
    dispatch({ type: 'SET_COVER_PAGE_TEXT', payload: coverText });
  }, [coverText, dispatch]);

  useEffect(() => {
    dispatch({ type: 'SET_KEYS_PAGE_TEXT', payload: keysText });
  }, [keysText, dispatch]);

  useEffect(() => {
    dispatch({ type: 'SET_CATEGORY_DESCRIPTIONS_TEXT', payload: categoryText });
  }, [categoryText, dispatch]);

  useEffect(() => {
    dispatch({ type: 'SET_SUMMARY_OF_ACTION_ITEMS_TEXT', payload: summaryText });
  }, [summaryText, dispatch]);

  const handleSave = () => {
    dispatch({ type: 'SET_COVER_PAGE_TEXT', payload: coverText });
    dispatch({ type: 'SET_KEYS_PAGE_TEXT', payload: keysText });
    dispatch({ type: 'SET_CATEGORY_DESCRIPTIONS_TEXT', payload: categoryText });
    dispatch({ type: 'SET_SUMMARY_OF_ACTION_ITEMS_TEXT', payload: summaryText });
    save(); // Trigger the save function
    setEnableSave(false);
  };

  const handleChange = (setter) => (value) => {
    setter(value);
    setEnableSave(true);
  };

  return (
    <div>

      <div style={{ marginBottom: '8rem' }}>
        <h4>Cover Page Text</h4>
        <ReactQuill value={coverText} onChange={handleChange(setCoverText)} style={{ height: '200px' }} />
      </div>

      <div style={{ marginBottom: '8rem' }}>
        <h4>Keys Page Text</h4>
        <ReactQuill value={keysText} onChange={handleChange(setKeysText)} style={{ height: '200px' }} />
      </div>

      <div style={{ marginBottom: '8rem' }}>
        <h4>Category Descriptions</h4>
        <ReactQuill value={categoryText} onChange={handleChange(setCategoryText)} style={{ height: '200px' }} />
      </div>

      <div style={{ marginBottom: '8rem' }}>
        <h4>Summary of Action Items</h4>
        <ReactQuill value={summaryText} onChange={handleChange(setSummaryText)} style={{ height: '200px' }} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5rem' }}>
        <Button type="primary" onClick={handleSave} disabled={!enableSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ReportTemplateText;
