import React, {useEffect, useState} from 'react'
import {Button} from 'antd'
import moment from 'moment'
import ProjectFlowGraphs from './ProjectFlowGraphs'

function makeTestProjects(num) {
  const testProjects = []
  const today = moment()
  for (let i = 0; i <= num; i++) {
    testProjects.push(makeTestProject(today))
  }

  return testProjects
}

function randomDay() {
  return moment().subtract(Math.round(Math.random() * 30), 'days')
}

const statuses = [
  'To Be Scheduled',
  'Scheduled',
  'In Progress',
  'Review',
  'Needs Corrections',
  'Completed',
  'Cancelled',
]
function makeTestProject(today) {
  const status = statuses[Math.round(Math.random() * (statuses.length - 1))]

  const project = {
    updated: randomDay(),
    status,
  }

  project.updated_readable = project.updated.format()
  if (status === 'Completed') {
    project.completed_on = randomDay()
    project.recent = 'N/A'
  } else project.recent = Math.abs(project.updated.diff(today, 'days')) < 7 ? 'Yes' : 'No'

  return project
}

export default function ProjectFlowGraphsTestHarness() {
  const [toggle, setToggle] = useState(false)
  const [projects, setProjects] = useState([])

  useEffect(
    function () {
      setProjects(makeTestProjects(1000))
    },
    [toggle]
  )

  return (
    <div className="thin-lightgrey-border radius-1 padding-2 full-width">
      <div className="flex-row">
        <h3 className="padding-right-1">TEST HARNESS</h3>
        <Button onClick={() => setToggle(!toggle)}>Get New Data</Button>
      </div>
      <ProjectFlowGraphs projects={projects} />
    </div>
  )
}
