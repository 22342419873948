import React from 'react';
import { Checkbox, Icon, Tooltip } from 'antd';
import PhotoRequired from './PhotoRequired';
import Followup from './Followup';
import { connect } from 'react-redux';
import { setField, updateProgress } from '../../action_creators';
import { fieldHasErrors } from '../../utilities';

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  value: state.fields[ownProps.id].value,
});
const dtop = (dispatch) => ({
  setField: ({ id, value }) => {
    dispatch(setField(id, value));
    dispatch(updateProgress());
  }
});

export function Checkboxes({ id, setField, field }) {
  function set(v) {
    const vv = {};
    v.forEach((value) => (vv[value] = true));
    field.value = { values: { ...vv }, followup };
    setField({ id, value: field });
  }

  let { value, options = [], required, question, label, photoRequired, tipText } = field;
  let { values = {}, followup = {} } = value || {};

  const currentValues = Object.keys(values);

  return (
    <div className="question-container">
      <div className="bold padding-bottom-1 linebreak question-title">
        {question}
        {required && (
          <Tooltip title="Required">
            <Icon type="exclamation-circle" style={{ fontSize: '1.5rem', padding: '0 0 0 0.5rem', color:'red' }} /> <span style={{ fontSize: '1.25rem', color:'red' }}> Required</span>
            </Tooltip>
        )}
      </div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{ fontWeight: 600 }}>
          {(label || '').toUpperCase()}
        </div>
        <div className="padding-1 full-width" style={{ border: fieldHasErrors(field) ? '1px solid red' : 'initial' }}>
          <Checkbox.Group options={options.map((option) => option.label)} value={currentValues} onChange={set} />
          {options.map((option) => values[option.label] && <Followup id={id} option={option} />)}
        </div>
        <div className="linebreak">
          <small>{tipText && `${tipText}`}</small>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  );
}

const WrappedCheckboxes = connect(stop, dtop)(Checkboxes);
export default WrappedCheckboxes;
