import React from 'react'
import {auth} from '../Auth'
import {history} from '../history'
import {Button} from 'antd'

export default function Header({name}) {
  return (
    <div className="header-container flex-row flex-align-center">
      <h1 className="page-header">{name}</h1>
      {['client', 'admin', `super-admin`].includes(auth.sunkaizenUser.type) && (
        <Button size="large" onClick={() => history.push(`/users`)} type="primary">
          Done
        </Button>
      )}
    </div>
  )
}
