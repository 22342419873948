import React from 'react'
import {List} from 'antd'
import {history} from '../../history'



export default function Tickets({ tickets }) {
  

  console.log('tickets',tickets)

  return (
    <div className="misc-info">
        <div className='bold'>Tickets</div>
      <List
        itemLayout="horizontal"
        dataSource={tickets}
        renderItem={(item) => (
          <List.Item className="misc-info-item">
            
                <div className="flex-row full-width justify-space-between">
                <div> 
                <a
                onClick={() => history.push(`/tickets/${item.id}`)}
                >
                <div style={{ 
                    width: '150px', 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    flexDirection: 'column'
                }}>{String(item.title)}</div>
                </a>
                <div style={{ fontSize: '65%'  }}>{item.updatedByName} </div>
                </div>      
                <div style={{  }}>{item.updated.toDate().toDateString()} </div>
                </div>
                  
           
              
             
            
          </List.Item>
        )}
      />
    </div>
  )
}
