import React, {useState, useContext} from 'react'
import {Button, Select, Input, Icon, Tooltip, Dropdown} from 'antd'
import {ReducerContext} from './reducer'
import {HeaderMenu, loadSavedFilter, savePresetFilter} from './utilities'

export default function Header() {
  const [name, setName] = useState(``)
  const {state, dispatch} = useContext(ReducerContext)
  const {presetFilters = {}, filterTypes, includeIntakeData, filterSubscriptions} = state

  return (
    <div className="flex-column align-start">
      <div className="flex-column align-start full-width">
        <div className="padding-bottom-1">
          Set criteria to generate new report or select from your saved reports below
        </div>
        <div className="bold">View Saved Report</div>
        <div className="flex-row justify-space-between padding-top-1 full-width">
          <Select placeholder="Choose..." onChange={loadSavedFilter(state, dispatch)} style={{width: `30rem`}}>
            {Object.values(presetFilters)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((fil) => (
                <Select.Option
                  style={{display: `flex`, alignItems: `center`, width: `100%`, flexFlow: `row nowrap`}}
                  value={fil.id}
                >
                  {fil.name}
                  <Dropdown overlay={<HeaderMenu filter={fil} filterSubscriptions={filterSubscriptions} />}>
                    <Tooltip title="Subscribe to email delivery on...">
                      <Icon type="inbox" />
                    </Tooltip>
                  </Dropdown>
                </Select.Option>
              ))}
          </Select>
          <div className="flex-row">
            <Input
              placeholder="Name your filter"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{margin: `0 2rem 0 0`}}
            />
            <Button onClick={() => savePresetFilter(name, includeIntakeData, filterTypes)}>
              Save Criteria as New Report
            </Button>
          </div>
        </div>
        <h4 className="padding-top-2">Project Data</h4>
      </div>
      <div className="flex-row justify-start full-width" style={{textAlign: `left`}}>
        <div style={{flex: 2, fontWeight: `bold`, padding: `0 0 0 2rem`}}>Data</div>
        <div style={{flex: 5, fontWeight: `bold`}}>Filter</div>
        <div style={{flex: 1, fontWeight: `bold`}}>Edit</div>
        <div style={{flex: 1}}></div>
      </div>
    </div>
  )
}
