import {auth} from '../../Auth'

const filterConverter = {
  clients: (clientsArray) => (clientsArray ? {terms: {[`client.id`]: clientsArray}} : {}),
  intakes: (status) => (status ? {term: {'status.sortable': {value: status}}} : {}),
  timespan: (timespan) => (timespan ? {range: {'created._seconds': {gte: timespan.unix()}}} : {}),
  'inspection.inspector.id': (id) => [['inspection.inspector.id', '==', id]],
  'client.id': (id) => [['client.id', '==', id]],
}

export default function makeQuery(query) {
  const {size = 10000, from = 0, query_strings, barFilters} = query
  let ff = Object.entries(barFilters).map(([name, value]) => filterConverter[name](value))

  if (auth && auth.sunkaizenUser) {
    let {type = 'field-user'} = auth.sunkaizenUser
    // the user_index uses inspector instead of field-user because IBTS kept renaming the user type
    if (type === `field-user`) type = `inspector`

    if (type === 'quality-manager') {
      if (!auth.sunkaizenUser.qualificationsAll) {
        ff.push({terms: {[`intake.id`]: Object.keys(auth.sunkaizenUser.qualifications || {})}})
      }

      if (!auth.sunkaizenUser.clientsAll) {
        if (!barFilters.clients) ff.push({terms: {[`client.id`]: Object.keys(auth.sunkaizenUser.clients || {})}})
      }
    } else if (type === 'inspector') {
      ff = [...ff, {match: {[`user_index.${auth.sunkaizenUser.id}`]: type}}]
    }
  }

  ff.push({match: {'organization.id': auth.sunkaizenUser.organization.id}})

  const or = [
    {match: {'organization.id': auth.sunkaizenUser.organization.id}},
    {match: {[`user_index.${auth.sunkaizenUser.organization.id}`]: 'client'}},
    {match: {[`user_index.${auth.sunkaizenUser.organization.id}`]: 'inspector'}},
    {match: {[`user_index.${auth.sunkaizenUser.organization.id}`]: 'installer'}},
  ]

  return {
    type: `projects`,
    size,
    from,
    query_strings,
    filters: ff,
    or,
  }
}
