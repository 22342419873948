import React from 'react'
import {connect} from 'react-redux'
import {setFailure, deleteFailure} from '../../action_creators'
import IntakeFailure from '../../IntakeFailure'
import {Checkbox} from 'antd'

function stop(state) {
  return {selected: state.failures}
}

const dtop = (dispatch) => ({
  setFailure: (failure) => dispatch(setFailure(failure.id, failure)),
  deleteFailure: (id) => dispatch(deleteFailure(id)),
})

export function FailuresBySection({sectionTitle, failures, setFailure, deleteFailure, selected}) {
  function onChange(failure, value) {
    if (!value) {
      deleteFailure(failure.id)
      return
    }

    setFailure(failure)
  }

  return (
    <div className="failures-by-section" data-test="failures-by-section-component">
      <div className="section-name" style={{fontWeight: `600`}}>
        {sectionTitle}
      </div>
      {Object.values(failures).map((failure) => (
        <div
          className="padding-left-1 flex-row align-center selectable-intake-failure"
          key={failure.id}
          data-test="intake-failure-component"
        >
          <Checkbox
            className="checkbox"
            checked={selected[failure.id]}
            onChange={() => onChange(failure, !selected[failure.id])}
          ></Checkbox>
          <IntakeFailure sectionTitle={sectionTitle} failure={failure} />
        </div>
      ))}
    </div>
  )
}

const WrappedFailuresBySection = connect(stop, dtop)(FailuresBySection)
export default WrappedFailuresBySection
