import DB from '../../DAL/DB'
import {store} from '../utilities'

export default function saveFields(section = {}, formId, batch) {
  const state = store.getState()
  const {fields = {}} = state
  const formField = new DB.FormField(formId, section.id)

  section.order.forEach((id) => {
    let field = fields[id]
    if (field && field.type) {
      if (field.options) field = {...field, options: field.options.filter((o) => !!o.label)}

      batch.update(formField, id, field)
    }
  })
}
