import React from 'react'
import {Icon} from 'antd'
import {del} from './utilities'
import DeleteButton from '../../../DeleteButton'
import AuthorizationRequest from './AuthorizationRequest'
import {textFilter} from '../../tableFilters'
import {auth} from '../../../Auth'

export default function columnsMap(activeFilters = {sorter: {order: null, field: null}}) {
  return [
    {
      title: `Company`,
      dataIndex: `name`,
      key: 'name',
      render: (text) => <div>{text}</div>,
      sorter: true,
      ...textFilter(activeFilters)(`title`),
    },
    {
      title: `Phone`,
      dataIndex: `phone`,
      key: `phone`,
      render: (t) => <div>{t}</div>,
    },
    {
      title: `Email`,
      dataIndex: `email`,
      key: 'email',
      render: (t) => <div>{t}</div>,
      sorter: true,
    },
    {
      title: `Them`,
      render: (text, record) => <AuthorizationRequest {...{requestor: record.oID, record: record}} />,
    },
    {
      title: `You`,
      render: (text, record) => <AuthorizationRequest {...{requestor: auth.sunkaizenUser.id, record: record}} />,
    },
    {
      title: 'Delete',
      render: (text, record) => (
        <div onClick={(e) => e.stopPropagation()}>
          <DeleteButton
            customRender
            onConfirm={() => del(record.id)}
            documentId={record.id}
            collection="authorizations"
            okText="Delete"
            cancelText="Cancel"
            confirmationDisplay="Really delete this relationship?"
          >
            <Icon style={{padding: `0.2rem`, paddingLeft: `0.5rem`, fontSize: 24}} type="delete" />
          </DeleteButton>
        </div>
      ),
    },
  ]
}
