import Create from '../Create/Create'
import onOk from '../Create/onOk'
import Button from './ModalOpenButton'

const configuration = {
  title: 'Update Ticket',
  ok: onOk,
  okText: 'Update',
  okButtonDisabled: () => false,
  view: Create,
  type: 'update',
  button: Button,
}

export default configuration
