import React from 'react'
import {connect} from 'react-redux'
import {Row, Divider} from 'antd'
import {uuid} from '../../utilities'
import TitleRow from './TitleRow'
import OptionRow from './OptionRow'
import QuestionWrapper from './QuestionWrapper'

function stop(state, ownProps) {
  return {
    rubrics: state.rubrics,
    scoringSystems: state.scoringSystems,
    field: state.sections[ownProps.sectionId].fields[ownProps.id],
  }
}

export function RubricQuestion({rubrics, scoringSystems, style, field: question, selectedRubric, section}) {
  return (
    <QuestionWrapper style={style} question={question} rubrics={rubrics}>
       <div id="scroll">
      <Row gutter={16}>
        <TitleRow rubrics={rubrics} scoringSystems={scoringSystems} question={question} selectedRubric={selectedRubric} />
      </Row>
      <Divider />
      {question.options &&
        question.options.map((option, i) => (
          <Row gutter={16} key={uuid()}>
            <OptionRow key={i} option={option} question={question} 
            selectedRubric={selectedRubric} field={question} 
            section={section} />
          </Row>
        ))}
        </div>
       
    </QuestionWrapper>
  )
}

const WrappedRubricQuestion = connect(stop)(RubricQuestion)
export default WrappedRubricQuestion
