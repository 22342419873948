import Papa from 'papaparse'
import formatProjects from './formatProjects'

export default function convert(projects, strategies) {
  const rows = formatProjects(projects, strategies)

  if (!rows) throw new Error(`could not generate CSV rows`)

  const csv = Papa.unparse(rows)
  const blob = new Blob([csv], {type: 'text/csv'})
  return blob
}

export function convertActions(actions) {
  const headers = [
    'Action',
    'Description',
    'Reference Information',
    'Icon Text',
    'Cost',
    'Difficulty',
    'Criticality',
    'Tag 1 (Maintenance)',
  ]

  const rows = actions
    .sort((a, b) => a.actionIndex - b.actionIndex)
    .map(({actionIndex, strategy, actionDescription, referenceInformation, icon, cost, difficulty, criticality, tags}) => {
      return [
        `${actionIndex} - ${strategy}`,
        actionDescription,
        referenceInformation,
        icon,
        cost,
        difficulty,
        criticality,
        tags[0]?.maintaince || '',
      ]
    })
  const csv = Papa.unparse([headers, ...rows])
  const blob = new Blob([csv], {type: 'text/csv'})
  return blob
}
