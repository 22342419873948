export default function makeFilterManipulationFunctions(state, dispatch) {
  return {
    setFilter: (type, index, value) => {
      const ft = {...state.filterTypes}
      if (ft[type] && ft[type].instances) ft[type].instances[index] = value
      dispatch({type: 'filterTypes', value: ft})
    },
    addInstance: (type) => {
      const ft = {...state.filterTypes}
      ft[type].instances.push('all')
      dispatch({type: 'filterTypes', value: ft})
    },
    deleteInstance: (type, index) => {
      const ft = {...state.filterTypes}
      ft[type].instances.splice(index, 1)
      if (ft[type].instances.length < 1) ft[type].instances.push('all')
      dispatch({type: 'filterTypes', value: ft})
    },
  }
}
