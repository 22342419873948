import {store} from './utilities'
import {resourceType, validateResource} from '../validators'

const validators = {...resourceType[`project`], ...resourceType[`site`]}

export function setField(field, value) {
  if (validators[field]) {
    const [errors] = validateResource({[field]: value})({[field]: validators[field]})
    store.dispatch({type: `project`, value: {errors}})
  }

  return {type: `field`, field, value}
}

export const setInitialFields = (fields) => ({type: `fields`, fields})
export const blankFields = () => {
  return {type: `blankFields`}
}

export const populatePermissions = (type, permissions) => ({type: `${type}Populate`, [type]: permissions})
export const setPermissionGroup = (type, permissions) => ({type, [type]: permissions})
export const setDropdowns = (type, permissions) => ({type, [type]: permissions})

export const setProject = (project) => ({type: `initialProject`, project})
export const setInstallers = (value) => ({type: `installers`, value})

export const setTemplate = (template, order) => ({type: 'csvtemplate', template, order})
export const setSites = (value) => ({type: 'sites', value})
