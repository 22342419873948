import {store, mapDBToFields} from '../../utilities'
import {useEffect} from 'react'
import {setField, setProject} from '../../action_creators'

export default function useProjectEffect(subscribe, id, subscribeResources) {
  useEffect(
    function () {
      if (id) return subscribe({paths: [{collection: 'projects', doc: id}]}, 'project')
    },
    [id]
  )

  useEffect(
    function () {
      if (subscribeResources.project) {
        const [project] = subscribeResources.project
        const fields = mapDBToFields(project)
        for (let k in fields) {
          store.dispatch(setField(k, fields[k]))
        }

        store.dispatch(setProject(project))
      }
    },
    [subscribeResources.project]
  )
}
