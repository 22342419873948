import React from 'react'

export const styles = {
  fieldset: {
    borderRadius: `0.3em`,
    boxShadow: `1px 1px lightgrey`,
  },
  container: {
    alignContent: `flex-start`,
    borderRadius: `0.2em`,
    fontSize: `75%`,
  },
  header: {
    padding: `0.3em`,
    alignSelf: `flex-start`,
  },
}

function Settings() {
  return (
    <form className="flex-column padding-2">
      <div className="padding-1">
        <label className="padding-right-1">Setting 1</label>
        <input type="text" />
      </div>
      <div className="flex-column align-center justify-center">
        <legend>Setting 2</legend>
        <div>
          <label className="padding-right-1">option 1</label>
          <input id="option1" type="radio" name="setting2" value="one" />
        </div>
        <div>
          <label className="padding-right-1">option 2</label>
          <input id="option2" type="radio" name="setting2" value="two" />
        </div>
        <div>
          <label className="padding-right-1">option 3</label>
          <input id="option3" type="radio" name="setting2" value="three" />
        </div>
        <div>
          <label className="padding-right-1">option 4</label>
          <input id="option4" type="radio" name="setting2" value="four" />
        </div>
      </div>
      <hr />
      <div className="padding-1">
        <label className="padding-right-1">Setting 3 </label>
        <input type="checkbox" />
      </div>
      <div className="padding-1">
        <label className="padding-right-1">Setting 4 </label>
        <input type="date" />
      </div>
      <div className="padding-1">
        <label className="padding-right-1">Setting 5 </label>
        <input type="color" />
      </div>
    </form>
  )
}

export default function SystemSettings() {
  return (
    <div className="flex-column justify-start padding-1" style={styles.container}>
      <div className="full-width flex-row padding-1 padding-left-2">
        <h3 className="margin-0 flex-row justify-center align-center" style={{...styles.header, flex: `4 1 auto`}}>
          System Settings
        </h3>
      </div>

      <div
        className="flex-column justify-start background-white padding-1"
        style={{...styles.fieldset, padding: `3em`}}
      >
        <Settings />
      </div>
    </div>
  )
}
