import React from 'react'
import {Checkbox} from 'antd'
import {auth} from '../../../Auth'
import Multiselect from './Multiselect'

export default function Permissions({type, edit, permissions = {}, userPermissions, userPermissionsAll, change}) {
  return (
    <div className="flex-row flex-align-center full-width">
      {auth.sunkaizenUser && ['admin', 'super-admin', 'client'].includes(auth.sunkaizenUser.type) && edit ? (
        <Multiselect
          options={Object.values(permissions)}
          selected={Object.keys(userPermissions || {}).filter((p) => permissions[p])}
          select={(v = []) => {
            const pp = {}
            v.forEach((p) => (pp[p] = permissions[p].name))
            change(type)(pp)
          }}
        />
      ) : (
        <div className="flex-row" style={{flexFlow: 'row wrap'}}>
          {userPermissions &&
            Object.values(userPermissions).map((name, i) => (
              <div
                key={i}
                style={{
                  background: '#DFE3E8',
                  padding: '1rem 1.5rem 1rem 1.5rem',
                  margin: '0 0.5rem 0 0',
                }}
              >
                {name}
              </div>
            ))}
        </div>
      )}
      <Checkbox
        disabled={!edit || !['admin', 'super-admin', 'client'].includes(auth.sunkaizenUser.type)}
        onChange={(e) => change(`${type}All`)(e.target.checked)}
        checked={userPermissionsAll}
      >
        Grant All Access
      </Checkbox>
    </div>
  )
}
