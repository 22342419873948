import React from 'react'
import {List} from 'antd'
import SiteNotes from './SiteNotes'

// className='project-brief-container flex-row align-center justify-space-between'
export default function ProjectBrief({project, id, push}) {
  return (
    <List.Item
      style={{
        fontSize: '75%',
        justifyItems: 'flex-start',
        background: id === project.id ? `rgba(255, 255, 0, 0.3)` : `initial`,
      }}
      className="flex-column padding-1 project-brief-container full-width"
    >
      <div onClick={() => push({project})} className="flex-row" style={{textAlign: 'left', width: '100%'}}>
        <div className="flex-column padding-left-right-1" style={{width: '20%'}}>
          <div className="tiny-label">Site Owner</div>
          <div>{(project.site || {}).siteOwnerName}</div>
        </div>
        <div className="flex-column padding-left-right-1" style={{width: '35%'}}>
          <div className="tiny-label">Address</div>
          <div>{(project.site || {geocode_results: {}}).geocode_results.formatted_address}</div>
        </div>
        <div className="flex-column padding-left-right-1" style={{width: '20%'}}>
          <div className="tiny-label">Status</div>
          <div>{project.status}</div>
        </div>
        <div className="flex-column padding-left-right-1" style={{width: '20%'}}>
          <div className="tiny-label">Type</div>
          <div>{(project.intake || {}).title}</div>
        </div>
        {project.status !== 'To Be Scheduled' && (
          <div className="flex-column padding-left-right-1" style={{width: '20%'}}>
            <div className="tiny-label">Inspection Date</div>
            <div>
              {project.inspection.date && project.inspection.date.format && project.inspection.date.format('ll')}
            </div>
          </div>
        )}
      </div>
      {id === project.id && <SiteNotes />}
    </List.Item>
  )
}
