//////////////////////////////////////////////////////////////////////////
// HowTo:
// 	Wrapped = (elastic(FormBuilder)
//
// create a new query object using one of the constructors in queryTypes
// (see the validator on the ElasticSearchQuery class for the required format for fields, esp. sort, query_strings and filters):
// const query = new this.props.queryTypes.form({from, query_strings, filters, query, sort, type, size})
//
// call search(query, options) (mapReturn puts the results in {id: value} format instead of [value]):
// this.props.search(query, {mapReturn: true})
//
// 1. watch newElasticResources: bool in your componentDidUpdate lifecycle method/hook;
// 2. run clearElastic() to reset newElasticResources to false; and
// 3. get query results from resultsElastic:
// if (this.props.newElasticResources) {
//   this.props.clearElastic()
//   stateUpdate.resources = this.props.resultsElastic
//////////////////////////////////////////////////////////////////////////

import React, {useReducer, useEffect, useState} from 'react'

import {FULL_TEXT_SEARCH_URL} from '../../config'
import {firebase} from '../../utilities'

import {
  ElasticSearchQuery,
  FormElasticSearchQuery,
  ProjectElasticSearchQuery,
  UserElasticSearchQuery,
} from './ElasticSearchQuery'

async function makeQuery(esQuery) {
  if (Object.keys(esQuery).length < 1) return
  if (!(esQuery instanceof ElasticSearchQuery)) {
    console.log('an invalid query was submitted')
    return
  }

  try {
    const body = esQuery.toJSON()

    console.log(body)

    const res = await (
      await fetch(`${FULL_TEXT_SEARCH_URL}/full_search`, {
        method: `POST`,
        mode: `cors`,
        headers: {'Content-Type': `application/json`},
        body,
      })
    ).json()

    const rr = res.hits
      ? res.hits.hits.map((r) => {
          const resource = r._source
          if (resource.created) {
            resource.created = new firebase.firestore.Timestamp(
              resource.created._seconds,
              resource.created._nanoseconds
            )
          }
          if (resource.updated) {
            resource.updated = new firebase.firestore.Timestamp(
              resource.updated._seconds,
              resource.updated._nanoseconds
            )
          }
          return resource
        })
      : []

    return [rr, res.hits ? res.hits.total.value : 0]
  } catch (e) {
    // gracefully fail to query and return an Error object; the message needs work
    console.log(e)
    return new Error('The query failed')
  }
}

const reducer = function (state, action) {
  switch (action.type) {
    case 'results':
      return {...state, results: action.results, newResources: true, total: action.total}
    case 'clear':
      return {...state, newResources: false}
    case 'query':
      return {...state, query: action.query}
    case 'batchQuery':
      return {...state, batchQuery: action.batchQuery}
    case 'options':
      return {...state, options: action.options}
    case 'batchOptions':
      return {...state, batchOptions: action.options}
    case 'batchFrom':
      return {...state, batchFrom: action.from}
    default:
      return state
  }
}

// decorated components should check whether 'results' is an Error;
// 	if it is, they should show a notification before continuing operation
export const elastic = (Component) => {
  function ElasticHOC({childRef, posthooks: ph = [(a) => a], ...props}) {
    // these are the objects that you call the search function with
    // 	they allow the HOC to properly validate queries
    // 	some of the specific validation for them has not been built out yet -- Chris
    const queryTypes = {
      form: FormElasticSearchQuery,
      project: ProjectElasticSearchQuery,
      batchProject: (props) => props,
      user: UserElasticSearchQuery,
    }

    const [posthooks, setPosthooks] = useState((a) => a)
    const [state, dispatch] = useReducer(reducer, {query: {}, results: [], newResources: false, options: null})

    useEffect(function () {
      if (!ph.every((f) => typeof f === 'function')) throw new Error(`provided a non-function prop to posthooks`)
      const composed = () =>
        ph.reduceRight(
          (prevFn, nextFn) => (arg) => nextFn(prevFn(arg)),
          (value) => value
        )

      setPosthooks(composed)
    }, [])
    function makeResultsMap(rr) {
      console.log('rr', rr)

      const results = {}
      for (let i in rr) {
        results[rr[i].id] = posthooks(rr[i])
      }

      return results
    }

    useEffect(
      function () {
        if (!state.query) return
        ;(async () => {
          const [rr, total] = (await makeQuery(state.query)) || []
          let results
          if (state.options && state.options.mapReturn) {
            results = makeResultsMap(rr)
          } else {
            if (rr) results = rr.map((r) => posthooks(r))
          }

          dispatch({type: 'results', results, total})
        })()
      },
      [JSON.stringify(state.query)]
    )

    useEffect(
      function () {
        if (!state.batchQuery) return
        ;(async function () {
          let from = 0
          let size = 20
          let q = new queryTypes.project({...state.batchQuery, from, size})

          const [rr, total] = (await makeQuery(q)) || []
          dispatch({type: 'results', results: rr, total})
          from += size

          if (from > total) return

          const pp = []
          let results = rr

          while (from < total) {
            q.query.from = from
            const p = makeQuery(q).then(([res]) => {
              results = [...results, ...res]
              dispatch({type: 'results', results, total})
            })

            pp.push(p)
            from += size
          }

          await pp.reduce((a, c) => a.then(c), Promise.resolve(null))
        })()
      },
      [state.batchQuery]
    )

    function search(esSearch, options) {
      dispatch({type: 'options', options})
      dispatch({type: 'query', query: esSearch})
    }

    function batchSearch(esSearch) {
      console.log(esSearch)
      dispatch({type: 'batchQuery', batchQuery: esSearch})
    }

    function clearResults() {
      dispatch({type: 'clear'})
    }

    return (
      <div>
        <Component
          ref={childRef}
          {...props}
          resultsElastic={state.results}
          query={state.query}
          queryTypes={queryTypes}
          newElasticResources={state.newResources}
          search={search}
          batchSearch={batchSearch}
          clearElastic={clearResults}
          totalElasticResults={state.total}
        />
      </div>
    )
  }

  return React.forwardRef((props, ref) => <ElasticHOC {...props} childRef={ref} />)
}
