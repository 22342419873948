const conditionGroupValidation = (cgList, fields, operand) => {
    let isDisplay = false
    if(cgList && cgList.length > 0) {
      isDisplay = conditionListValidation(cgList[0], fields)
      if(cgList.length > 1 && (operand === "or" && !isDisplay) || (operand === "and" && isDisplay)){
        isDisplay = conditionListValidation(cgList[1], fields)
      }
    }
    return isDisplay;
  }


  const conditionListValidation = (list, fields) => {
    let isDisplay = false
    if(list){
      const {conditionList, mustBeTrueValue} = list
      if(mustBeTrueValue === "all") {
        let isAllTrue = true
        conditionList.forEach(clRec => {
            if(isAllTrue){
                if(conditionValidation(clRec, fields)){
                    isAllTrue = true
                } else {
                    isAllTrue = false 
                }
          }
        })
        isDisplay = isAllTrue
      } else {
        let isAnyTrue = false
        conditionList.forEach(clRec => {
            if(!isAnyTrue){
                if(conditionValidation(clRec, fields)){
                    isAnyTrue = true
                }
          }
        })
        isDisplay = isAnyTrue
      }
      if(conditionList && conditionList.length === 0) {
        isDisplay = true
      }
     }
     return isDisplay
  }

  const conditionValidation = (recQuestion, fields) => {

    if(recQuestion){
      const { selectedQuestion, selectedOption, selectedOperand} = recQuestion;

      const dependentQuestionValue = fields && fields[selectedQuestion] && fields[selectedQuestion].value;
      // Chris wanted to hide any conditional question during inital load, during first time question never
      // be touched and value always be undefined, that case it will return false and hide the field
      if (!dependentQuestionValue) {
        return false;
      }
      if( selectedQuestion && selectedOption && dependentQuestionValue && selectedOperand) {
        if(selectedOperand === "equal"){
            return (selectedOption || "").toString().replaceAll(",","") === dependentQuestionValue.toString().replaceAll(",","")
        } else if(selectedOperand === "lessThan") {
            return parseFloat(selectedOption) > dependentQuestionValue
        }
        else if(selectedOperand === "greaterThan") {
            return parseFloat(selectedOption) < dependentQuestionValue
        } else if(selectedOperand === "is") {
            const values = dependentQuestionValue && dependentQuestionValue.values
            if(values && Object.keys(values) && Object.keys(values).length > 1) {
              const multiValues = Object.keys(values)
              return multiValues.find(rec => rec === selectedOption)
           }
           if(values && Object.keys(values) && Object.keys(values).length === 1) {
              const compareValue =  Object.keys(values)[0]
              return selectedOption === compareValue
          }
        }
        else if(selectedOperand === "isnot") {
            let values1 = dependentQuestionValue && dependentQuestionValue.values
            if(values1 && Object.keys(values1) && Object.keys(values1).length > 1) {
              const multiValues = Object.keys(values1)
              return multiValues.find(rec => rec !== selectedOption)
           }
            if(values1 && Object.keys(values1) && Object.keys(values1).length === 1) {
              const compareValue1 = Object.keys(values1)[0]
              return selectedOption !== compareValue1
            }
        }
      } else {
        return true
      }
      return false;
    }
  }


  export default conditionGroupValidation 
