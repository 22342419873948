import makeQuery from './makeQuery'
import {firebase} from '../../utilities'
import {FULL_TEXT_SEARCH_URL} from '../../config'

export default async function get(q) {
  const body = makeQuery(q)
  console.log(body)

  const res = await (
    await fetch(`${FULL_TEXT_SEARCH_URL}/full_search`, {
      method: `POST`,
      mode: `cors`,
      headers: {'Content-Type': `application/json`},
      body: JSON.stringify(body),
    })
  ).json()

  console.log(res)

  const resources = {}
  const rr = res.hits ? res.hits.hits.map((r) => r._source) : []
  rr.forEach((resource) => {
    if (resource.created) {
      resource.created = new firebase.firestore.Timestamp(resource.created._seconds, resource.created._nanoseconds)
    }
    resources[resource.id] = resource
  })

  const query = {from: body.size + body.from, total: res.hits ? res.hits.total.value : 0}
  const projects = resources

  return [projects, query]
}
