import React from 'react'
import {connect} from 'react-redux'
import {Select, Col} from 'antd'
import RowLayout from './RowLayout'

function stop(state) {
  return {state}
}
function dtop(dispatch) {
  return {dispatch}
}

export function TitleRow({question, selectedRubric, state, dispatch}) {
  const {rubrics, scoringSystems, actionData} = state
  const {actions} = actionData;
  const actionOptions = actions.map((rec, index) => ({option: `${index + 1} - ${rec.strategy}`}))


  const ssMap = {}
  for (let s of scoringSystems) {
    ssMap[s.id] = s
  }

  function setCriticality(rubric, question, value) {
    const r = {...rubric}
    r.questions[question.id] = {options: {}, ...r.questions[question.id], criticalityCategory: value}
    dispatch({type: 'rubric', id: r.id, value: r})
  }
  const selectedRubricObj = rubrics[selectedRubric] 
  const scoringSystem = selectedRubricObj && ssMap[selectedRubricObj?.scoringSystemId] || {}

  return Object.keys(rubrics) ? (
    <div style={{textAlign: 'center', display: 'flex' }}>
      <div style={{fontStyle: 'italic', marginTop: '1rem', minWidth: '150px'}}>
        Select Criticality
      </div>
          <div  key={selectedRubricObj?.id} style={{marginTop: '1rem',  minWidth: '200px'}}>
            <Select
              style={{width: '100%'}}
              placeholder="Select Criticality"
              value={(selectedRubricObj?.questions[question.id] || {}).criticalityCategory || null}
              onChange={(e) => setCriticality(selectedRubricObj, question, e)}
              data-cy="rubricCriticality"
            >
              <Select.Option key={`ignore`} value={null}>
                <span>Ignore this question</span>
              </Select.Option>
              {Object.keys(scoringSystem.criticality_map || {}).map((criticalityCategory) => (
                <Select.Option key={criticalityCategory} value={criticalityCategory}>
                  {criticalityCategory}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="rubric-label-info">
            <div className="bold justify-left rubric-selection">{selectedRubricObj?.name}</div>
            { actionOptions && actionOptions.length > 0 &&
                <div  className="bold rubric-associatedAction-label" >
                Associated Actions
              </div>
            }
          </div>
    </div>
  ) : (
    <RowLayout
      left={<div style={{fontStyle: `italic`, marginTop: `0.5rem`}}>Select Criticality</div>}
      right={<div style={{fontWeight: `bold`, marginTop: `0.5rem`}}>Need to add at least one Rubric</div>}
    />
  )
}

const WrappedTitleRow = connect(stop, dtop)(TitleRow)
export default WrappedTitleRow
