import {store} from './store'
import {setField, drag} from '../action_creators'

export function dropHandle(result) {
  const state = store.getState()
  // drop happened outside a dropzone
  if (!result.destination) {
    console.log(`You dropped an item outside the droppable area!`)
  } else {
    const {fields} = state
    const {droppableId: dest} = result.destination
    const {droppableId: src} = result.source

    const dId = result.draggableId.split(`prefix`)[1]
    const destImages = fields[dest].images
      ? {...fields[dest].images, [dId]: fields[src].images[dId]}
      : {[dId]: fields[src].images[dId]}

    fields[dest].images = destImages

    // uncomment the next line if we only want a given image used in one location on an intake
    // delete droppablesMap[src][result.draggableId]
    store.dispatch(setField(dest, fields[dest]))
  }

  store.dispatch(drag(false))
}
