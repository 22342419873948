import {auth} from '../../../Auth'

export default function permissions() {
  if (auth && auth.sunkaizenUser) {
    return []
    // let permissions
    // if (['client', 'field-user'].includes(auth.sunkaizenUser.type)) {
    //   if (auth.sunkaizenUser.type === `field-user`) auth.sunkaizenUser.type = `inspector`
    //   permissions = [{match: {[`user_index.${auth.sunkaizenUser.id}`]: auth.sunkaizenUser.type || 'client'}}]
    // }

    // if (auth.sunkaizenUser.type === 'quality-manager') {
    //   permissions = []
    //   if (!auth.sunkaizenUser.qualificationsAll) {
    //     permissions = [...permissions, {terms: {[`intake.id.keyword`]: Object.keys(auth.sunkaizenUser.qualifications)}}]
    //   }

    //   if (!auth.sunkaizenUser.clientsAll) {
    //     permissions = [...permissions, {terms: {[`client.id.keyword`]: Object.keys(auth.sunkaizenUser.clients)}}]
    //   }
    // }

    // return permissions
  } else return
}
