import React from 'react'
import {Checkbox} from 'antd'

export default function Followup({option, index, set}) {
  return (
    <div className="flex-row followupContainer">
      <Checkbox
        onChange={(e) => set({id: index, value: {...option, requireFollowupComments: e.target.checked}})}
        checked={option.requireFollowupComments}
        style={{marginRight: '1rem'}}
        data-cy="requireComments"
      >
        Require Comments
      </Checkbox>
      <Checkbox
        onChange={(e) => set({id: index, value: {...option, requireFollowupPhoto: e.target.checked}})}
        checked={option.requireFollowupPhoto}
        data-cy="requireFollowupPhoto"
      >
        Require Photo
      </Checkbox>
    </div>
  )
}
