import React, {useState} from 'react'
import {fieldNameConverter} from '../../validators'
import {Select, Radio, Checkbox} from 'antd'
import {auth} from '../../Auth'
import {connect} from 'react-redux'
import useSetInspector from './useSetInspector'
import {setField} from '../action_creators'
import {store} from '../utilities'

const mapStateToProps = (state) => ({fields: state.fields })

const InspectionInformation = ({fields, allowCreateProject}) => {
  if (!fields) fields = {}
  const {inspector} = fields
  const [category, setCategory] = useState(null)
  const [fus, set] = useSetInspector(category) // this  will update 

  return (
    <div className="flex-column align-start padding-2 margin-bottom-1" style={{background: `#F4F6F8`}}>
      <h3>Inspector Information</h3>
      <div className="full-width flex-column align-start">
        {fields.projectType && fields.projectType.noSchedule && (
          <h3 className="bold padding-1">Do not schedule an intake for the the selected project type</h3>
        )}
        {(fields.organization || {}).name === auth.sunkaizenUser.organization.name && (
          <div className="full-width">
            <Radio.Group
              disabled={allowCreateProject || (fields.organization || {}).name !== auth.sunkaizenUser.organization.name}
              className="padding-1"
              onChange={(e) => setCategory(e.target.value)}
              style={{paddingLeft: 0, paddingTop: 0}}
            >
              <Radio value="internal">Internal</Radio>
              <Radio value="third-party">Third Party</Radio>
              <Radio value="client">Self-Submit</Radio>
            </Radio.Group>
            <Select
              disabled={allowCreateProject||(fields.projectType && fields.projectType.noSchedule) || !category}
              onChange={(e) => set(e)}
              value={inspector ? inspector.name : null}
              size="large"
              className="full-width"
              placeholder={fieldNameConverter[`inspector`]}
              data-cy="inspector"
            >
              {fus
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
            </Select>
          </div>
        )}
        <hr />
        <Checkbox
          checked={fields.restrict_schedule}
          onChange={(e) => {
            //console.info('checkbox changed', e)
            store.dispatch(setField('restrict_schedule', e.target.checked))
          }}
          disabled={allowCreateProject}
        >
          Restrict Scheduling on Inspector Availability
        </Checkbox>
      </div>
    </div>
  )
}

const WrappedInspectionInformation = connect(mapStateToProps)(InspectionInformation)
export default WrappedInspectionInformation
