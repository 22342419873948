import React, {useEffect, useState} from 'react'
import {Spin} from 'antd'
import TopLevelState from './TopLevelState'
import {subscribe} from '../HOC/Firebase/SubscribeHOC'

function ResourceGetter({match, location, history, makeSubscription, subscribeResources, readDB, readResults}) {
  const {id: doc} = match.params
  const [resource, setResource] = useState(null)
  const [organization, setOrganization] = useState(null)

  useEffect(
    function () {
      for (let k in subscribeResources) {
        switch (k) {
          case 'users':
            const {
              users: [r],
            } = subscribeResources
            setResource(r)
            continue
          case 'organization':
            const {
              organization: [o],
            } = subscribeResources
            setOrganization(o)
            continue
        }
      }
    },
    [subscribeResources]
  )

  useEffect(
    function () {
      return makeSubscription({paths: [{collection: 'users', doc}]}, 'users')
    },
    [doc]
  )
  useEffect(
    function () {
      if (!resource || !resource.organization) return
      makeSubscription({paths: [{collection: 'organizations', doc: resource.organization.id}]}, 'organization')
    },
    [resource]
  )

  return resource ? (
    <TopLevelState {...{resource, organization, match, location, history}} />
  ) : (
    <div className="padding-3">
      <Spin size="large" />
    </div>
  )
}

const WrappedResourceGetter = subscribe()(ResourceGetter)
export default WrappedResourceGetter
