import React, {useContext, useState, useEffect} from 'react'
import {ReducerContext, wrap} from '../reducer'
import CalendarAvailabilityModal from '../CalendarAvailabilityModal'
import CalendarEvent from '../CalendarEvent'
import CalendarFilter from '../CalendarFilter' 
// https://fullcalendar.io/docs/react
import FullCalendar from '@fullcalendar/react'
// import '@fullcalendar/core/main.css'
// import '@fullcalendar/daygrid/main.css'
// import '@fullcalendar/timegrid/main.css'
import '../Calendar.css'
import {filterEvents} from '../utilities'
import {auth} from '../../Auth'
import {subscribeAvailability, subscribeEvents, subscribeCancelledIntake, prefixShorts } from './effects'
import fullCalendarProps from './fullCalendarProps'
//import { mockFilterData } from './mockFilterData'
import moment from 'moment'





export function Calendar() {
  const {state, dispatch} = useContext(ReducerContext)

  const eventsMap = {...state.events, ...state.availability}
  const allEvents = Object.values(eventsMap)

  console.log('normal + available',allEvents)


  const cancelledIntakes = Object.values(state.cancelledIntakes)

  const [showIntakes, setShowIntakes] = useState(false)
  const [show, setShow] = useState(false)
  const [displayedEvents, setDisplayedEvents] = useState([])
  
  const [unsubcribeAvailability, setUnsubcribeAvailability] = useState(() => () => {})
  const [unsubcribeEvents, setUnsubcribeEvents] = useState(() => () => {})

  const [unsubcribeCancelledIntakes, setUnsubcribeCancelledIntakes] = useState(() => () => {})

  const redColor = `#cc0000`
  const lightRedColor = `#f39595`

  const getHHMM = (datetimeString) => {
    try {
    const date = new Date(datetimeString);
    return moment(date).format('hh:mm A')
    } catch (error) {
      //console.log('error',error)
      return ''
    }
  }

  const formatTime = (inputTime) => {
    return moment(inputTime, ['h:mma', 'hh:mma']).format('hh:mm A');
  }

  //console.log('state',cancelledIntakes , allEvents)


  const removeDuplicates = (array) => {
    return array.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);
  }

  useEffect(() => {
      /* old part */
      //console.log('whoiami',auth.sunkaizenUser.type, showIntakes)
      let events = updateDisplayedEvents()
      console.log('normal / available  ',events)
      console.log('cancelled events  ',cancelledIntakes)
      if(events && events.length > 0) {
        if(auth.sunkaizenUser.type === 'scheduler'){
          const schedulerOrgId = auth.sunkaizenUser.organization.id
          events = events.filter(each  => each.data.organization?.id  === schedulerOrgId || each.data?.inspection?.inspector?.organization?.id === schedulerOrgId )
        }
        else if(auth.sunkaizenUser.type === 'client') { 
          events = events.filter(event => event?.data?.client?.id === auth.sunkaizenUser?.id || event?.data?.organization?.id === auth.sunkaizenUser?.id ) 
        }  


        let uniqueArray = [...events]

        if(showIntakes) {
          //console.log('whats going on')
          const idsInFirstArray = new Set(events.map(obj => obj.id));
          //console.log('idsInFirstArray',idsInFirstArray)
          const filteredSecondArray = cancelledIntakes.filter(obj => !idsInFirstArray.has(obj.id));
          //console.log('filteredSecondArray',filteredSecondArray)
          //uniqueArray = removeDuplicates([...events, ...filteredSecondArray])

          uniqueArray = [...events, ...filteredSecondArray]
          //console.log('allEvents',events.length)
        }
        
        //console.log('right now triggers are here')
        setDisplayedEvents(uniqueArray)

      }
      else {
        if (showIntakes) {
          //console.log ('you ???')
          setDisplayedEvents([...events, ...cancelledIntakes]) // however events is empty here , yet i keep it as it 
        }
        else {
          setDisplayedEvents(events) //events can have availablity too , which display without showintakes 
        }
      }
       /* old part */

       /* new part */
       
       

       
      /* old part */
      // events = events.map(each => { 
      //   return {...each, title: ` ${getHHMM(each.start)} : ${each.title}` }
      // })

      //setDisplayedEvents(events)
    },
    [state.filterText, cancelledIntakes.length, state.filterType, showIntakes, allEvents.length]
  )
  //state.cancelledIntakes,

  useEffect(() => {
      if (state.filterType === 'Client' && state.filterText) setShowIntakes(true)
    },
    [state.filterType === 'Client' && state.filterText]
  )

  function eventClick(e) {
    e.jsEvent.preventDefault()
    //console.log('custom prefix',e, e.event.extendedProps?.customPrefix)
    //const titlePrefix = (e.event.extendedProps.data.status === 'Cancel Requested' || e.event.extendedProps.data.status === 'Canceled' || e.event.extendedProps.data.status === 'Rescheduled') ? prefixShorts[e.event.extendedProps.data.status] : ''
    const titlePrefix = e.event.extendedProps?.customPrefix ? `${e.event.extendedProps.customPrefix == 'R' ? '(Reassigned)': ''}` : ''
    dispatch({type: 'selected', value: {value: e.event.id, titlePrefix } })
    setShow(true)
  }

  function updateDisplayedEvents() {
    return filterEvents({
      showIntakes,
      events: allEvents,
      filter: state.filterType,
      searchTerm: state.filterText,
    })
  }

  async function datesSet(dateInfo) {
    console.log('this is init',dateInfo)
    const { activeStart: start, activeEnd: end} = dateInfo.view

    await unsubcribeAvailability()
    await unsubcribeEvents()
    await unsubcribeCancelledIntakes()

  
    setUnsubcribeAvailability(subscribeAvailability(dispatch, { start, end}))
    setUnsubcribeEvents(subscribeEvents(dispatch, { start, end}))
    setUnsubcribeCancelledIntakes(subscribeCancelledIntake(dispatch, { start, end}) )

  }

  function eventContent(eventInfo) {
    //console.log('eventInfo',eventInfo)
    const { start, end, title,  type , id, extendedProps, backgroundColor } = eventInfo.event
    const bg = backgroundColor !== 'undefined' && backgroundColor !== '' ? backgroundColor : 'blue' 

     // Check if extendedProps has a non-null parent field
     const hasParent = extendedProps?.parent !== undefined && extendedProps?.parent !== null;

     // Determine the background color and status based on the conditions
    let bgColor = bg;
    let status = '';
    let time = '';

    if (!hasParent) {
        if (extendedProps.data?.status === 'Cancel Requested') {
            bgColor = lightRedColor;
            status = `${prefixShorts['Cancel Requested']}: `;
        } else if (extendedProps.data?.status === 'Canceled') {
            bgColor = redColor;
            status = `${prefixShorts['Canceled']}: `;
        }
        time = eventInfo.timeText ? `${formatTime(eventInfo.timeText)}: `: '';

    } else {
      status = extendedProps?.customPrefix + ': '
      time = eventInfo.timeText ? extendedProps?.customCancelDate+ ': ' : '';

    } 

     return (
      <div style={{ padding: 1, backgroundColor: bgColor, color: 'white' }}>
          {status} {time} {title}
      </div>
    );

    //return <div style={{ padding: 1, backgroundColor: extendedProps.data?.status === 'Cancel Requested' ? lightRedColor: bg, color: 'white' }}>{extendedProps.data.status === 'Cancel Requested' ? extendedProps.data.status : ''} {title}</div>
  }

  //console.log('displayedEvents',displayedEvents)

  return (
    <div className="calendar calendar-fu">
      <CalendarAvailabilityModal />
      <CalendarFilter />
      <div className="demo-app-calendar padding-2" id="mycalendartest">
        <CalendarEvent event={eventsMap[state.selected?.value]} titlePrefix={state.selected?.titlePrefix} visible={show} toggle={() => setShow(!show)} />
        <div id="calendar-modal"></div>
        <FullCalendar
          {...fullCalendarProps({
            showIntakes,
            setShowIntakes,
            userType: auth.sunkaizenUser.type,
            displayedEvents,
            dispatch,
            eventClick,
            datesSet
          })}
          eventContent={eventContent}
        />
      </div>
    </div>
  )
}

const WrappedCalendar = wrap(Calendar)
export default WrappedCalendar
