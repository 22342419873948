import React, {useState, useEffect} from 'react'
import {Icon, Modal} from 'antd'
import FileUploader, {patterns} from '../NewFileUploader'
import {storage} from '../utilities'
import {helperMessage, logoMessage} from './utilities'
import DB from '../DAL/DB'
import {auth} from '../Auth'
import {correctedURL} from './../utilities'

const users = new DB.Users()

export default function AvatarPane({resource, user, organization}) {
  const [show, setShow] = useState(false)
  const [avatar, setAvatar] = useState(null)
  const [logo, setLogo] = useState(null)
  const [percentComplete, setPercentComplete] = useState(null)

  useEffect(
    function () {
      let avatarUrl
      const {avatar: aa} = resource
      if (!aa || !aa.fullPath) {
        const defaultUrl = `meta/images/default_avatar.png`
        avatarUrl = correctedURL(defaultUrl)
        setAvatar(avatarUrl)
      }
      if (aa && aa.fullPath) {
        avatarUrl = correctedURL(aa.fullPath)
        setAvatar(avatarUrl)
      }
    },
    [resource.avatar]
  )

  useEffect(
    function () {
      (function () {
        const {logo: ll} = organization || {}
        let logoURL
        if (ll && ll.fullPath) {
          logoURL = correctedURL(ll.fullPath)
          setLogo(logoURL)
        }
      })
    },
    [organization && organization.logo]
  )

  async function deleteAvatar() {
    const fixedUser = {...user}
    fixedUser.avatar = {}
    users.update(user.id, null, fixedUser)
  }

  return (
    <div className="avatar-pane-container flex-row flex-align-center">
      <div className="flex-column align-center justify-center">
        <div className="margin-bottom-2" style={{width: '20rem', height: '20rem'}}>
          <img className="full-width full-height avatar" src={avatar} alt="profile avatar" />
        </div>
        <div className="flex-row">
          <button className="avatar-action-button flex-row flex-align-center" onClick={() => setShow(true)}>
            <Icon type="edit" />
            <div className="padding-left-1">Change Photo</div>
          </button>
          <button className="avatar-action-button flex-row flex-align-center" onClick={deleteAvatar}>
            <Icon type="delete" />
            <div className="padding-left-1">Delete Photo</div>
          </button>
        </div>
        {['client', 'super-admin', 'admin'].includes(auth.sunkaizenUser.type) && (
          <FileUploader
            parentID={(organization || {}).id}
            pattern={patterns.logo}
            helperMessage={logoMessage(organization && organization.logo ? organization.logo : undefined)}
          />
        )}
      </div>
      <Modal
        title="Upload a new Profile Photo"
        visible={show}
        onOk={() => setShow(false)}
        onCancel={() => setShow(false)}
        footer={null}
      >
        <div>
          <FileUploader
            post={[() => setShow(false)]}
            parentID={user.id}
            helperMessage={helperMessage}
            pattern={patterns.avatar}
          />
        </div>
      </Modal>
    </div>
  )
}
