import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Draggable } from 'react-beautiful-dnd'
import { Button, Divider } from 'antd'
import { FormItem } from '.'
import ConditionGroups from './Condition/ConditionGroups'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: `none`,
  padding: `0.2rem`,
  margin: `0 0 2rem 0`,

  // styles needed for draggables
  ...draggableStyle,
})

const stop = (state) => ({
  editing: state.editing,
  fields: state.fields,
  section: state.sections[state.active.section] || null,
  sections: state.sections,
})

function readCgList(field) {
  if (field && field.conditionGroup && field.conditionGroup.cgList) {
    return field.conditionGroup.cgList;
  }
  return [];
}

export function Field(props) {
  const [draggable, setDraggable] = useState(false) // eslint-disable-line
  const { editing, id, i, loading, setLoading, f, section, fields, fieldDependency } = props
  const { conditionGroup, dependentQue } = fields[f.id];
  const [cgList, setCgList] = useState(conditionGroup && conditionGroup.cgList || []);
  const [conditionQuestions, setConditionQuestions] = useState(dependentQue ? [dependentQue] : [])
  const { order } = section


  const handleConditionQuestionSelection = (id, e) => {
    const isExist = isMatchingCircularRef(id, e)
    if (isExist) {
      alert('Please select another question as the selected question will create a circular reference.')
    }
    const conditionQuestionsObj = {
      questionId: id,
      selectedQuestion: !isExist ? e : '--Select Question --',
      selectedOption: '',
    }
    const conditionQuestionsUpdated = [...conditionQuestions]

    if (conditionQuestionsUpdated.find((x) => x && x.questionId === id)) {
      let updateRec = conditionQuestionsUpdated.find((x) => x && x.questionId === id)
      if (updateRec && updateRec.selectedQuestion) {
        updateRec.selectedQuestion = !isExist ? e : '--Select Question --'
        updateRec.selectedOption = ''
      } else {
        updateRec = conditionQuestionsObj
      }

      fieldDependency();
    } else {
      conditionQuestionsUpdated.push(conditionQuestionsObj)
      fieldDependency();
    }

    setConditionQuestions([...conditionQuestionsUpdated])
  }
  const handleSelectedQuestionOption = (id, e) => {
    if (id == '09awVjMZYwRdGJ39A54e') {
      console.info('Field detected: ', id)
    }
    const rec = fields[id] && fields[id].dependentQue
    if (rec && rec.questionId && rec.selectedQuestion) {
      rec.selectedOption = e

      fieldDependency(rec)
    }
  }
  let isSame = false
  const isMatchingCircularRef = (id, targetQuestion) => {
    let isDependentQuestion = ''

    Object.keys(fields).forEach((rec) => {
      if (!isSame && fields[rec] && fields[rec].dependentQue && fields[rec].dependentQue.selectedQuestion) {
        isDependentQuestion = fields[rec].dependentQue.selectedQuestion
        if (isDependentQuestion === id) {
          const childId = fields[rec].id
          if (childId && targetQuestion && childId === targetQuestion) {
            isSame = true
          } else {
            isMatchingCircularRef(childId, targetQuestion)
          }
        }
      }
    })
    return isSame
  }
  const findDefaultSelectedQuestion = (id) => {
    isSame = false
    const field_ = fields[id]
    const rec = field_ && field_.dependentQue

    if (rec && rec.questionId) {
      const isExist = isMatchingCircularRef(id, rec.selectedQuestion) //isMatchingCircularRef(id, rec.selectedQuestion)
      if (!isExist) {
        return rec.selectedQuestion
      } else {
        return 'matching found'
      }
    }
    return ''
  }
  const findDefaultSelectedQuestionOption = (id) => {
    const field_ = fields[id]
    const rec = field_ && field_.dependentQue
    return rec &&
      rec.questionId &&
      order.find((x) => x === rec.selectedQuestion) &&
      fields[rec.selectedQuestion] &&
      fields[rec.selectedQuestion].options &&
      fields[rec.selectedQuestion].options.find((x) => x.label === rec.selectedOption)
      ? rec.selectedOption
      : ''
  }
  const findEligibleCrossRefFilter = (sectionEligibleQuestions) => {
    const foundRecId = Object.keys(fields).find(
      (x) => fields[x] && fields[x].dependentQue && fields[x].dependentQue.selectedQuestion === f.id
    )
    if (foundRecId) {
      return sectionEligibleQuestions.filter((x) => x !== foundRecId)
    }
    return sectionEligibleQuestions
  }
  const handleAddConditionGroup = () => {
    const cgListUpdated = [...cgList]
    const obj = { conditionList: [], mustBeTrueValue: 'all' }
    cgListUpdated.push(obj)
    setCgList(cgListUpdated)
    let conditionGroup = {
      cgList: cgListUpdated,
      operand: ''
    };
    if (cgListUpdated.length > 1) {
      conditionGroup.operand = 'and';
    }

    fields[f.id].conditionGroup = conditionGroup;
    fieldDependency();
  }
  const updateFieldConditionDetails = (cgListUpdated) => {
    if (fields[f.id] && fields[f.id].conditionGroup) {
      fields[f.id].conditionGroup.cgList = cgListUpdated;
    } else {
      let conditionGroup = {
        cgList: cgListUpdated,
        operand: ''
      };
      fields[f.id].conditionGroup = conditionGroup;
    }
    fieldDependency();
  }
  const updateConditionList = (conditionListUpdated, index) => {
    let cgListUpdated = [...cgList]
    let cgListUpdatedRec = cgListUpdated.filter(x => x.id === index)
    cgListUpdatedRec = conditionListUpdated
    setCgList(cgListUpdated)
    updateFieldConditionDetails(cgListUpdated);
  }
  const handleDeleteConditionGroup = (cgDeleteIndex) => {
    let cgListUpdated = [...cgList]
    cgListUpdated = cgListUpdated.filter((x, index) => index !== cgDeleteIndex - 1)
    setCgList(cgListUpdated)
    updateFieldConditionDetails(cgListUpdated);
  }
  const handleMustBeTrueCondition = (index, e) => {
    if (fields[f.id] && fields[f.id].conditionGroup) {
      const conditionGroup = fields[f.id].conditionGroup;
      const cgList = conditionGroup.cgList;
      cgList[index - 1].mustBeTrueValue = e;
      fieldDependency();
    }
  }
  const handleGroupCondtionValue = (e) => {
    if (fields[f.id] && fields[f.id].conditionGroup) {
      const conditionGroup = fields[f.id].conditionGroup;
      conditionGroup.operand = e;
      fieldDependency();
    }
  }
  const renderConditionGroupRender = () => {
    return (
      <ConditionGroups
        cgList={cgList}
        fields={fields}
        id={id}
        order={order}
        sections={props.sections}
        field={f}
        conditionQuestions={conditionQuestions}
        selectedOperand={conditionGroup && conditionGroup.operand}
        handleConditionQuestionSelection={handleConditionQuestionSelection}
        handleDeleteConditionGroup={handleDeleteConditionGroup}
        updateConditionList={updateConditionList}
        handleMustBeTrueCondition={handleMustBeTrueCondition}
        handleGroupCondtionValue={handleGroupCondtionValue}
      />
    )
  }


  return (
    <Draggable isDragDisabled={!!editing} key={id} draggableId={id} index={i}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <FormItem setDraggable={setDraggable} loading={loading} setLoading={setLoading} id={f.id} type={f.type} fields={fields} />
          {cgList && cgList.length > 0 &&

            <React.Fragment key={`action-${cgList.length}`}>
              {renderConditionGroupRender()}
            </React.Fragment>
          }
          {cgList && cgList.length < 2 &&
            <div style={{ marginLeft: 20, marginTop: 10 }}>
              <Button type="primary" onClick={handleAddConditionGroup}>+ Condition Group</ Button>
            </div>
          }
          <Divider />
        </div>
         
      )}
       
    </Draggable>
  )
}

const WrappedField = connect(stop)(Field)
export default WrappedField
