import React from 'react'

export function UglyField({className, style, children}) {
  //console.log('children.props.field',children, children.props?.field)
  return (
    <div className={`ugly-field${className ? `` + className : ``}`} style={style}>
      {children.props?.field && (
        <div style={{color: `grey`, fontSize: `65%`, textAlign: `left`, padding: `0 0 0 0.2rem`}}>
          {children.props.placeholder}
        </div>
      )}
      {children}
    </div>
  )
}
