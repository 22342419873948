import {firebase} from '../../utilities'
import GeocodeWrapper from '../../GeocodeWrapper'
const wrappedGeocode = new GeocodeWrapper()

const types = {street_number: true, route: true, locality: true, administrative_area_level_1: true, postal_code: true}

export default async function makeUser(user) {
  const {type, name, email, geocode_results} = user

  let dummyAddress = null,
    latitude = null,
    longitude = null
  const filteredAddress = {}

  if (!geocode_results.address_components) {
    if (type === `field-user`) {
      throw new Error(`${name} does not have an address; field users must have addresses`)
    }
  } else {
    geocode_results.address_components.forEach((c) => {
      if (c.types.some((t) => types[t])) {
        filteredAddress[c.types[0]] = c.short_name
      }
    })
  }

  if (['admin', 'super-admin', 'client'].includes(type)) {
    throw new Error(`invalid user type: ${type} on user: ${name} with email: ${email}`)
  }

  const timestamp = firebase.firestore.Timestamp.now()
  user = {...user, ...filteredAddress, created: timestamp, updated: timestamp}

  if (dummyAddress) user.dummyAddress = dummyAddress
  if (latitude && longitude) {
    user.geocode_results = JSON.parse(
      JSON.stringify(await wrappedGeocode.geocode({lat: latitude, lng: longitude}, `location`))
    )
    user.formatted_address = user.geocode_results.formatted_address
  }

  return user
}
