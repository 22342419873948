import React, {useState, useEffect} from 'react'
import {Select} from 'antd'
import DB from '../../DAL/DB'
import {fieldNameConverter} from '../../validators'
import {Required} from '../utilities'
import {setField} from '../action_creators'
import {connect} from 'react-redux'
import {subscribe} from '../../HOC/Firebase/SubscribeHOC'
import {auth} from '../../Auth'

const mapStateToProps = (state) => ({
  fields: state.fields,
  id: state.projectId,
  forms: state.dropdowns.forms,
})
const mapDispatchToProps = (dispatch) => ({setField: (field) => (value) => dispatch(setField(field, value))})

const meta = new DB.Meta()

export function ProjectType({fields, setField, forms, id, makeSubscription, subscribeResources, clearUpdate, allowCreateProject}) {
  if (!fields) fields = {}
  const [rubrics, setRubrics] = useState({})
  const {projectType = {}, rubric} = fields

  useEffect(
    function () {
      // the placeholder option for the project type is the defaultForm static
      if (!projectType.id) {
        ;(async function () {
          const {
            ok,
            data: [{defaultForm}],
          } = await meta.get('statics')
          if (ok && forms[defaultForm]) {
            setField('projectType')(forms[defaultForm])
          }
        })()
      } else {
        return makeSubscription(
          {
            paths: [{collection: 'rubrics'}],
            options: {
              where: [
                // {field: 'organization.id', test: '==', value: auth.sunkaizenUser.organization.id},
                {field: 'formId', test: '==', value: projectType.id},
              ],
            },
          },
          'rubrics'
        )
      }
    },
    [projectType.id, forms]
  )

  useEffect(
    function () {
      clearUpdate()

      const {rubrics: rr = []} = subscribeResources
      const rubricMap = {}
      rr.forEach((r) => (rubricMap[r.id] = r))

      setRubrics(rubricMap)
    },
    [subscribeResources.rubrics]
  )

  useEffect(
    function () {
      if ((fields.organization || {}).id === auth.sunkaizenUser.organization.id) return

      setField('projectType')(undefined)
      setField('rubric')(undefined)
      setField('formRubrics')({})
    },
    [fields.organization]
  )

  const isAutoRubricDataEnabled =
    rubrics &&
    Object.keys(rubrics).length > 0 &&
    rubrics[Object.keys(rubrics)[0]].autoRubricData &&
    rubrics[Object.keys(rubrics)[0]].autoRubricData.enableAutoRubric

  if (isAutoRubricDataEnabled && fields && fields.formRubrics && Object.keys(fields.formRubrics).length === 0) {
    console.log('setting values')
    setField('formRubrics')(rubrics)
  } 
  const handleRubricSelection = (e) => {
    if(e !== 'Auto-Rubric') {
      e.isAutoRubricSelected = false
      setField('rubric')(e)
    } else {
      setField('rubric')(e)
    }
  }
  return (
    <div className="flex-column align-start padding-2 margin-bottom-1" style={{background: '#F4F6F8'}}>
      <h3>Project Type</h3>
      <Required />
      {id && (
        <div className="bold padding-0-5">
          Warning: Changing the Project Type will reset the Intake; progress cannot be recovered
        </div>
      )}
      <div className="full-width margin-bottom-1 padding-right-1">
        <Select
          placeholder={fieldNameConverter['type']}
          value={projectType.title}
          onChange={(e) => setField('projectType')(forms[e])}
          size="large"
          className="full-width"
          disabled={allowCreateProject || (fields.organization || {}).id !== auth.sunkaizenUser.organization.id}
          data-cy="projectType"
        >
          {Object.values(forms || {})
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((f, i) => (
              <Select.Option key={i} value={f.id}>
                {f.title}
              </Select.Option>
            ))}
        </Select>
      </div>

      <h3>Project Scoring Rubric</h3>
      <Required />
      <div className="full-width margin-bottom-1 padding-right-1">
        <Select
          key={`projectTitle-${projectType.title}`}
          disabled={allowCreateProject || (fields.organization || {}).id !== auth.sunkaizenUser.organization.id}
          placeholder="Rubrics for this form"
          value={rubric === 'Auto-Rubric' ? rubric : (rubric || {}).name}
          onChange={(e) => handleRubricSelection(e)}
          size="large"
          className="full-width"
          data-cy="projectRubric"
        >
          {isAutoRubricDataEnabled && (
            <Select.Option key={'Auto Rubric'} value={'Auto-Rubric'}>
              {`Auto-Rubric`}
            </Select.Option>
          )}
          {Object.values(rubrics).map((r, i) => (
            <Select.Option key={i} value={r}>
              {r.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

const WrappedProjectType = subscribe()(connect(mapStateToProps, mapDispatchToProps)(ProjectType))
export default WrappedProjectType
