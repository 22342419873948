import React from 'react'
import {Input} from 'antd'

export function TextField({type = `text`, set, field, placeholder, isdisabled= false}) {
  return (
    <Input
      type={type}
      value={field}
      onChange={(e) => set(e.target.value)}
      size="large"
      placeholder={placeholder}
      autoComplete="new-password"
      disabled ={isdisabled}
    />
  )
}
