import React from 'react'
import {Input} from 'antd'
import '../DetailedUserView.css'

export default function TextField(props) {
  const {label, placeholder, disabled, value, change} = props
  const {style = {}, inputStyle = {}, className = '', inputClassName = ''} = props
  return (
    <div className={`flex-column field-wrapper ${className}`} style={style}>
      <div className="info-text">{label}</div>
      <Input
        disabled={disabled}
        className={inputClassName}
        style={{border: 'none', ...inputStyle}}
        size="large"
        placeholder={placeholder ? placeholder : label}
        value={value}
        onChange={(e) => change(e.target.value)}
      />
    </div>
  )
}
