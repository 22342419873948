import thumbnail from './thumbnail'
import logo from './logo'
import photo from './photo'
import ticketPhoto from './ticketPhoto'
import avatar from './avatar'
import file from './file'
import formLogo from './formLogo'

export default {
  thumbnail,
  logo,
  photo,
  file,
  ticketPhoto,
  avatar,
  formLogo
}
