import {combineReducers} from 'redux'

const fields = (state = null, action) => {
  switch (action.type) {
    case `field`:
      return {...state, [action.field]: action.value}
    case `fields`:
      return {...state, ...action.fields}
    case `blankFields`:
      return {}
    default:
      return state
  }
}

const installers = (state = {}, action) => {
  if (action.type === `installers`) return action.value
  else return state
}

const projectBase = (state = {errors: {}}, action) => {
  switch (action.type) {
    case `initialProject`:
      return {...state, ...action.project}
    case 'blankFields':
      return {errors: {}}
    default:
      return state
  }
}

const dropdowns = (state = {fus: {}, clients: {}, forms: {}, organizations: {}, users: {}}, action) => {
  switch (action.type) {
    case `forms`:
      return {...state, forms: action.forms}
    case 'users':
      return {...state, users: action.users}
    case 'organizations':
      return {...state, organizations: action.organizations}
    default:
      return state
  }
}

const projectId = (state = null, action) => {
  switch (action.type) {
    case `projectId`:
      return action.projectId
    default:
      return state
  }
}

function bulk(state = {}, action) {
  switch (action.type) {
    case 'csvtemplate':
      return {...state, csvtemplate: action.template, order: action.order}
    case 'sites':
      return {...state, sites: action.value}
    default:
      return state
  }
}

const rootReducer = combineReducers({
  fields,
  installers,
  projectBase,
  dropdowns,
  projectId,
  bulk,
})

export default rootReducer
