import React from 'react';
import { connect } from 'react-redux';
import SingleInput from './SingleInput';
import NumericInput from './NumericInput';
import TextAreaField from './TextAreaField';
import RadioButton from './RadioButton';
import Dropdown from './Dropdown';
import Checkboxes from './Checkboxes';
import Multiselect from './Multiselect';
import DateInput from './DateInput';
import TimeInput from './TimeInput';
import MultiInput from './MultiInput';
import { fieldHasErrors } from '../../utilities';

const components = [
  MultiInput,
  SingleInput,
  NumericInput,
  TextAreaField,
  RadioButton,
  Dropdown,
  Checkboxes,
  Multiselect,
  DateInput,
  TimeInput,
];

const componentNameMapping = {
  'info-box': MultiInput,
  'single-input': SingleInput,
  'numeric-input': NumericInput,
  'text-area-field': TextAreaField,
  'radio-button': RadioButton,
  dropdown: Dropdown,
  checkboxes: Checkboxes,
  multiselect: Multiselect,
  'date-input': DateInput,
  'time-input': TimeInput,
  // "file-upload": FileUpload,
  templated: RadioButton,
}

const mapStateToProps = (state) => ({
  errors: state.project.intake.errors,
  fields: state.fields,
});

const NakedFieldType = ({ component, onChange }) => {
  const C = componentNameMapping[component.type] || SingleInput;

  return (
    <div>
      <C {...component} onChange={onChange} />
    </div>
  );
};

const FieldType = connect(mapStateToProps)(NakedFieldType);

export { components, FieldType };
