import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IntakeFailure from '../../IntakeFailure';
import { setTicketField, setCreateValid, setSelectedFailure } from '../../action_creators';
import { Title, Description, AssignedTo, Other, Urgency, Status, RejectionComment } from './Fields';
import { auth } from '../../../Auth';

export function CreateTicket() {
  const dispatch = useDispatch();

  const completestate = useSelector((state) => state);


  // Accessing the redux state with useSelector
  const ticket = useSelector((state) => state.ticket);
  const failures = useSelector((state) => Object.values(state.failures));
  const selected = useSelector((state) => state.selectedFailure);
  const project = useSelector((state) => state.project);

  const [otherValid, setOtherValid] = useState(false);
  const [titleValid, setTitleValid] = useState(false);
  const [descriptionValid, setDescriptionValid] = useState(false);
  const [rejectionCommentValid, setRejectionCommentValid] = useState(false);

  useEffect(() => {
    dispatch(setSelectedFailure(failures[0]));
  }, [failures.length, dispatch]);

  useEffect(() => {
    if ([otherValid, titleValid, descriptionValid].every((i) => i)) {
      dispatch(setCreateValid(true));
    } else {
      dispatch(setCreateValid(false));
    }
  }, [otherValid, titleValid, descriptionValid, dispatch]);

  const handleTicketFieldChange = (field, value) => {
    dispatch(setTicketField(field, value));
  };


  return (
    <div className="create-ticket">
      <div className="intake-failure-container">
        <IntakeFailure failure={selected} enhanced={true} />
      </div>
      <Title title={ticket.title} change={handleTicketFieldChange} setValid={setTitleValid} />
      <Description description={ticket.description} change={handleTicketFieldChange} setValid={setDescriptionValid} />
      <AssignedTo assignedTo={ticket.assignee} change={handleTicketFieldChange} ticket={ticket} project={project} />
      {ticket.assignee === 'Other' && (
        <Other other={ticket.otherName} change={handleTicketFieldChange} setValid={setOtherValid} />
      )}
      <Urgency change={(urgent) => handleTicketFieldChange('priority', urgent ? 'Urgent' : 'Normal')} />
      {ticket.status && auth.sunkaizenUser?.id === ticket?.creatorMap?.id && (
        <Status status={ticket.status} change={handleTicketFieldChange} ticket={ticket} project={project} />
      )}
      {ticket?.status === 'Rejected' && (
        <RejectionComment title={ticket?.rejectionComment || ''} change={handleTicketFieldChange} setValid={setRejectionCommentValid} />
      )}
    </div>
  );
}

export default CreateTicket;
