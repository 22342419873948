import moment from 'moment'

export default function disabledDate(inspector, date) {
  const includedDays = {}
  if (!date.isValid()) return (date) => includedDays[date.date()]

  if (inspector && inspector.availability) {
    for (let i in inspector.availability) {
      const {id, date: d, endDate: ed} = inspector.availability[i]
      if (!d || !ed) throw new Error(`availability event ${id} is missing a start or end`)
      const [start, end] = [moment(d.toDate()).startOf('day'), moment(ed.toDate()).startOf('day')]

      // if the availability is in a year that can't be currently displaying, don't bother parsing it
      if (start.year() > date.year()) continue

      do {
        includedDays[start.format('L')] = true
        start.date(start.date() + 1)
      } while (start <= end)
    }
  }

  return (currentDate) => {
    if (currentDate < moment().subtract(1, 'day')) return true
    return !includedDays[currentDate.startOf('day').format('L')]
  }
}
