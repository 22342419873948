import React from 'react'
import {ProjectType, ClientInformation} from '../Shared'
import {ProjectOwner, InstallerInformation, InspectionInformation, SiteOwnerInformation, SiteRepresentative} from '.'
import {fieldNameConverter} from '../../validators'
import useFinalize from './useFinalize'
import {useUserEffect, useProjectEffect, useFormEffect, useOrganizationEffect} from '../Shared/subscriptionEffects'
import {subscribe} from '../../HOC/Firebase/SubscribeHOC'
import '../ProjectBuilder.css'
function ProjectCreator({done, setDone, success, id, makeSubscription, subscribeResources, allowCreateProject=false}) {
  const {errors = {}} = subscribeResources.project || {errors: {}}

  useFinalize(id, done, setDone, success, allowCreateProject)

  useUserEffect(makeSubscription, subscribeResources)
  useProjectEffect(makeSubscription, id, subscribeResources)
  useFormEffect(makeSubscription, subscribeResources)
  useOrganizationEffect(makeSubscription, subscribeResources)

  return (
    <div className="padding-2 background-white" data-cy="projectModal">
      {Object.values(errors).filter((error) => error.length > 0).length > 0 && (
        <div className="flex-column background-red notifier-item">
          {Object.entries(errors || {})
            .filter(([field, errors]) => errors.length > 0)
            .map(([field, errors]) => (
              <div className="flex-row padding-1 align-start color-white">
                <div className="padding-right-1">{fieldNameConverter[field]}</div>
                <div>
                  {errors.map((error) => (
                    <div>{error}</div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      )}
      <ProjectOwner allowCreateProject={allowCreateProject} />
      <ProjectType allowCreateProject={allowCreateProject}/>
      <SiteOwnerInformation />
      <SiteRepresentative />
      <InstallerInformation allowCreateProject={allowCreateProject} />
      <ClientInformation allowCreateProject={allowCreateProject}/>
      <InspectionInformation allowCreateProject={allowCreateProject} />
    </div>
  )
}

const WrappedProjectCreator = subscribe()(ProjectCreator)
export default WrappedProjectCreator
