export default function getAddress(gg, full) {
  //Default to display the formatted address in the field
  let [{formatted_address: showingAddress}] = gg

  //If full is not set or false, then only show the street and route
  if (!full) {
    const types = {
      street_number: true,
      route: true,
    }

    //Filter down just to the address components we want from the lookup
    const addressComponents = gg[0].address_components.filter((c) => types[c.types[0]])

    let streetNumber = addressComponents.filter((c) => c.types.includes(`street_number`))[0].short_name
    let route = addressComponents.filter((c) => c.types.includes(`route`))[0].short_name

    showingAddress = `${streetNumber} ${route}`
  }

  return [showingAddress, gg]
}
