import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Select, Col, Row, Button} from 'antd'
import Option from './Option'
import RubricActionList from './RubricActionList'
import { handleChangeRubricActions } from './change'
import change from './change'

function stop(state) {
  return {rubrics: state.rubrics, actionData: state.actionData, sections: state.sections}
}

function disabled(rubric, question) {
  return Boolean(rubric.questions && rubric.questions[question.id] && rubric.questions[question.id].criticalityCategory)
}

export function OptionRow({ rubrics, option = {}, question = {}, actionData, 
  selectedRubric, sections, section, field }) {

  let {actions} = actionData;
  // removing delete actions
  actions = actions.filter(x => !x.isDelete)
  const actionOptions = actions.sort((a, b) => {
    return a.actionIndex - b.actionIndex
  }).map((rec) => ({option: `${rec.actionIndex} - ${rec.strategy}`}))

  let fields = section && section.fields || {};
  const [selectedRubricObj, setSelectedRubricObj] = useState(selectedRubric && rubrics[selectedRubric])

  useEffect(() => {
    if(Object.keys(rubrics).find(rec => rubrics[rec].isSelected === true)) {
      const rec = Object.keys(rubrics).find(rec => rubrics[rec].isSelected === true)
      setSelectedRubricObj(rubrics[rec])
    }
  }, [Object.keys(rubrics)])

  const updateActions = (selectedActionList) => {
    console.log('rubric', selectedRubricObj)
  }
 
  const handleRubricQuestionActions = (e, rubric, option) => {
       handleChangeRubricActions(rubric.id, question, option, e)
  }
  const onSelectionChange = (rubric, question, option) => {
    if (rubric.questions && question && question.id && rubric.questions[question.id] && rubric.questions[question.id].selectedActions) {
      return rubric.questions[question.id].selectedActions[option.label]
    }
    return "-- Select an Action --";
  }

 
  if(!selectedRubricObj){
    return ''
  }
  const optionActions =  selectedRubricObj && 
              selectedRubricObj.questions[question.id] &&
              selectedRubricObj.questions[question.id].OptionActions

  let selectedOptions = option && option.label && optionActions && optionActions[option.label]
  let isActionsDeleted = false
  if (selectedOptions && selectedOptions.length > 0) {
    selectedOptions.forEach(rec => {
      const foundRec = actions.find(x => x.id === rec.id)
      if (!foundRec) {
        selectedOptions = selectedOptions.filter(x => x.id !== rec.id)
        isActionsDeleted = true
      }
    })
  }
  if(isActionsDeleted) {
    const cArgs=[selectedRubricObj.id, question, option.label]
    change(...cArgs, selectedOptions, "isOptionActions")
  }

 const [openAction, setOpenAction] = useState(false)
 const handleCloseAction = () => {
  setOpenAction(false)
 }

 const isEditActions = selectedOptions && selectedOptions.length > 0;

  return (
    <div style={{textAlign: 'center', display: 'flex', paddingBottom: '10px' }}>
      
      <div style={{ width: '750px',marginLeft:'20px',textAlign:'justify'}}>{option && option.label ? <p> {option.label} </p> : <i>Unlabeld Option</i>}</div>
     
        { ! selectedRubricObj.questions[question.id] ?
            <div style={{ display: 'flex'}}>
              <div style={{ minWidth: '200px'}}>
                <Select style={{width: '12rem'}} disabled={true}></Select>
              </div>
              <div className='rubric-select-action'>
              <Button key={selectedRubricObj.questions[question.id]} disabled={true}> 
               Select Action </Button>
            </div>
            </div>
         :
        <React.Fragment>
            <div style={{ minWidth: '200px'}}>
        <Option disabled={!disabled(selectedRubricObj, question)} 
            cArgs={[selectedRubricObj.id, question, option.label]} />
        </div>
        <div className='rubric-select-action-button'>
              <Button key={selectedRubricObj.questions[question.id]} 
                  className='rubric-select-action-edit'
                  disabled={!disabled(selectedRubricObj, question)} 
                  type="primary" onClick={() => setOpenAction(!openAction)}> 
                  { isEditActions  ? 'Edit Action' : 'Select Action'} 
               </Button>
       </div>
         </React.Fragment>
      }
     
      {openAction &&
        <RubricActionList 
          actions={actions} 
          handleCloseAction={handleCloseAction} 
          fields={fields} 
          field={field} 
          sections={sections}
          cArgs={[selectedRubricObj.id, question, option.label]}
          updateActions={updateActions}
          optionActions={selectedOptions || []}
          selectedRubricObj={selectedRubricObj}
          section={section} /> 
      }

    </div>
  )
}

const WrappedOptionRow = connect(stop)(OptionRow)
export default WrappedOptionRow
