import {useState, useEffect} from 'react'
import {prepareProject, store} from './utilities'
import {setProject, setFields, setLoading, setActive} from './action_creators'

export default function useProcessProject(logger, subscribeResources) {
  const state = store.getState()

  const [first, setFirst] = useState(true)
  const [readonly, setReadonly] = useState(false)

  useEffect(
    function () {
      (async function () {
        store.dispatch(setLoading(true))
        const {project: [project] = []} = subscribeResources
        if (!project) return

        try {
          // if (project.status === 'Completed') throw new Error('this project is completed and cannot be modified')

          const {fields, userIsFieldUser} = await prepareProject(project, first, logger)
          if (!fields) return

          setFirst(false)
          store.dispatch(setProject(project))
          store.dispatch(setFields(fields))

          if (!state.active.section) store.dispatch(setActive({name: 'section', value: project.intake.order[0]}))
          if (userIsFieldUser) setReadonly(true)
        } catch (e) {
          setReadonly(true)
          console.log('error transforming project', e)
        } finally {
          store.dispatch(setLoading(false))
        }
      })()
    },
    [subscribeResources]
  )

  return readonly
}
