import React from 'react'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import {modify, activate} from './action_creators'
import {connect} from 'react-redux'
import {Button} from 'antd'
import addSection from './addSection'
import switchSection from './switchSection'

const stop = (state) => ({sections: state.sections, form: state.form, selected: state.active.section})
const dtop = (dispatch) => ({
  modify: (key, value) => dispatch(modify('form_field')(key, value)),
  activate: (type, value) => dispatch(activate(type, value)),
})

export function DraggableTabs({form, sections, modify, activate, selected}) {
  function onDragEnd(result) {
    if (!result.destination) return

    const {order} = form
    const [element] = order.splice(result.source.index, 1)
    order.splice(result.destination.index, 0, element)

    modify('order', order)
  }
  function onDragStart(target) {
    activate('section', target.draggableId)
  }

  return (
    <div className="flex-row align-center background-white padding-right-1">
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId="tabs" direction="horizontal">
          {(provided) => (
            <div className="flex-row full-width background-white" style={{overflowX: 'scroll'}} ref={provided.innerRef}>
              {(form.order || []).map((id, i) => (
                <Draggable key={id} draggableId={id} index={i}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`section-tab ${id === selected ? 'selected-section-tab' : ''}`}
                      onClick={() => switchSection(id)}
                    >
                      {(sections[id] || {}).title}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        style={{fontSize: '2rem', border: 'none'}}
        title="add-section"
        icon="plus"
        onClick={addSection}
        data-cy="addSectionBtn"
      />
    </div>
  )
}

const WrappedDraggableTabs = connect(stop, dtop)(DraggableTabs)
export default WrappedDraggableTabs
