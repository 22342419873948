import {store} from '../utilities'

export default async function preProcess(query) {
  const state = store.getState()
  const {preProcess = []} = state.tableVars.tableStrategy || {}
  // console.log('preprocessor lets you change parameters of the query', preProcess)

  if (preProcess.length) {
    return preProcess.reduce((a, f) => f(a), query)
  }
}
