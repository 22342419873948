import React, {useReducer} from 'react'

const initialState = {created: {}, updated: {}, forms: [], clients: [], rubrics: [], form: null, rubric: null}

function reducer(state, action) {
  const {type, value} = action
  switch (type) {
    case 'clear':
      return {...initialState}
    case 'forms':
      return {...state, forms: value}
    case 'rubrics':
      return {...state, rubrics: value}
    case 'form':
      return {...state, form: value}
    case 'rubric':
      return {...state, rubric: value}
    case 'clients':
      return {...state, clients: value}
    case 'client':
      return {...state, client: value}
    case 'created':
      const {before: createdBefore, after: createdAfter} = action
      return {...state, created: {before: createdBefore, after: createdAfter}}
    case 'updated':
      const {before: updatedBefore, after: updatedAfter} = action
      return {...state, updated: {before: updatedBefore, after: updatedAfter}}
    case 'completed':
      const {before, after} = action
      return {...state, completed: {before, after}}
    default:
      return state
  }
}

export const ReducerContext = React.createContext(null)
export const wrap = (C) => (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ReducerContext.Provider value={{state, dispatch}}>
      <C {...props} />
    </ReducerContext.Provider>
  )
}
