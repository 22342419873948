import React, {useState, useEffect, useContext} from 'react'
import {ReducerContext} from '../reducer'
import addInspectors from './addInspectors'
import addProjects from './addProjects'
import {pushData} from '../utilities'

// this component is the second half of a single map; it does nothing without a Google Maps map in component state
//  the SchedulingMapWrapper creates this map
//  it has no DOM elements, it just manipulates the map;
//      it could be a bunch of useEffects on the SchedulingMapWrapper,
//      but I decided to split it up

// the projects and inspectors that this component receives are already filtered;
//      once this component receives them, it just drops pins on the map
export default function SchedulingMap() {
  const {state, dispatch} = useContext(ReducerContext)
  const push = pushData({state, dispatch})

  const [projectPins, setProjectPins] = useState([])
  const [inspectorPins, setInspectorPins] = useState([])
  const [boundsChanged, setBoundsChanged] = useState(false)

  const {
    projects,
    inspectors,
    project,
    inspector,
    mapping: {map},
  } = state

  useEffect(
    function () {
      if (!map) return
      const pp = addProjects(state, projectPins, (project) => push({project}))
      setProjectPins(pp)
    },
    [boundsChanged, projects, project.id]
  )

  useEffect(
    function () {
      if (!map) return

      const [filteredInspectors, pp] = addInspectors(state, inspectorPins, (inspector) => push({inspector}))
      dispatch({type: 'setFilteredInspectors', value: filteredInspectors})
      setInspectorPins(pp)
      setBoundsChanged(false)
    },
    [boundsChanged, inspectors, inspector.id]
  )

  useEffect(
    function () {
      if (!map) return

      map.addListener('bounds_changed', function () {
        setBoundsChanged(true)
      })
      return () => window.google.maps.event.clearListeners(map, 'bounds_changed')
    },
    [map]
  )

  return <div></div>
}
