import DB from '../../DAL/DB'
import {firebase} from '../../utilities'
import moment from 'moment'
import clearData from '../utilities/clearData'
import SystemLogger from '../../SystemLogger'
import {auth} from '../../Auth'

const projects = new DB.Projects()
const calendars = new DB.Calendars()

export default async function finalize({project, inspector, time, date, duration}, dispatch, state) {
  // that means date was already there , that means its not schedules first time , and it was not cancelled before, we simply replacing date 
  // which I need to register
  //if(project.inspection?.date && project?.intakeCancellations) {


  // console.log('old',  project.inspection.inspector) // old inspector
  // console.log('new', inspector) // new 
  // console.log('other data',time,date,duration)

  let newInspector = null
  if (project?.inspection?.inspector && inspector.id && project?.inspection?.inspector?.id !== inspector.id) {
    newInspector = { id: inspector.id , name: inspector.name, email: inspector.email, organization: inspector.organization}
  }  
  
  date.hours(time.hours()) // because time is in separate dropdown so i have to inject it here
  date.minutes(time.minutes()) // because time is in separate dropdown so i have to inject it here

  if(project.inspection?.date){ 
    const {
      inspector: {name, id},
      date: canceledDate,
    } = project.inspection
    const dateObj = canceledDate.toDate()
    //console.log('canceledDate', canceledDate, dateObj) 
    const intakeCancellation = {canceledAt: firebase.firestore.Timestamp.now(), newScheduledDate:  firebase.firestore.Timestamp.fromDate(date.toDate()), canceledDate: firebase.firestore.Timestamp.fromDate(dateObj), inspector: {name, id}, reason: '', rescheduled: true, newInspector}
    project.intakeCancellations = [...(project.intakeCancellations || []), intakeCancellation].filter((i) => i)
  } 
  project.inspection = {}
  project.inspection.inspector = {
    name: inspector.name,
    id: inspector.id,
    email: inspector.email,
    organization: inspector.organization,
  }

  project.inspection.date = date.toDate()
  project.inspection.duration = duration.toDate()
  project.inspection.endDate = moment(date)
    .set({
      hour: date.hours() + duration.hours(),
      minute: date.minutes() + duration.minutes(),
    })
    .toDate()

  try {
    project.status = 'Scheduled'
    project.updatedBy = auth?.sunkaizenUser?.id
    project.updatedByName = auth?.sunkaizenUser?.name
    await projects.updatebatch(project.id, null, project)

    delete project.intake
    await calendars.update(project.id, null, {
      data: project,
      date: project.inspection.date,
      duration: project.inspection.duration,
      endDate: project.inspection.endDate,
      start: project.inspection.date,
      end: project.inspection.endDate,
      title: `Intake - ${project.client.name}`,
      id: project.id,
      type: 'Intake',
    })

    clearData(dispatch)
    const pp = {...state.projects}
    delete pp[project.id]
    dispatch({type: 'projects', value: pp})

    const logger = new SystemLogger({uid: auth.sunkaizenUser.id})
    logger.log(
      `Scheduled ${project.site.siteOwnerName} ` +
        `${project.site.geocode_results.formatted_address} ` +
        `for inspection on ${project.inspection.date.toISOString()}`
    )
  } catch (e) {
    console.log('error scheduling project')
    throw e
  }
}
