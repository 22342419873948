import {store} from '../../utilities'
import {useEffect} from 'react'
import {setDropdowns} from '../../action_creators'
import {auth} from '../../../Auth'

export default function useFormEffect(subscribe, subscribeResources) {
  useEffect(function () {
    return subscribe(
      {
        paths: [{collection: 'forms'}],
        options: {
          where: [
            {field: 'active', test: '==', value: true},
            {field: 'organization.id', test: '==', value: auth.sunkaizenUser.organization.id},
          ],
        },
      },
      'forms'
    )
  }, [])

  useEffect(
    function () {
      const formsMap = {}
      for (let form of subscribeResources.forms || []) {
        formsMap[form.id] = form
      }
      store.dispatch(setDropdowns('forms', formsMap))
    },
    [subscribeResources.forms]
  )
}
