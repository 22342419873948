import React, {useState} from 'react'
import {saveAs} from 'file-saver'
import {Icon, Button} from 'antd'
import {firebaseConfig, FUNCTIONS_URL} from '../../config'
import {auth} from '../../Auth'
import {correctedURL, firebase} from './../../utilities'
import DB from '../../DAL/DB'
import moment from 'moment'

const projects = new DB.Projects()

export default function SaveReport({id, status, report, site, intake}) {
  const className = `project-report ${report && report.fullPath ? 'color-primary' : 'color-quaternary'}`
  const [disabled, setDisabled] = useState(false)

  const {fullPath} = report
  let url
  if (fullPath) {
    url = correctedURL(fullPath)
  }

  async function generateReport() {
    setDisabled(true)

    const r = await (await fetch(`${FUNCTIONS_URL}/utilities/reportmaker/${id}`)).text()
    if (r !== 'success') {
      console.log(r)
      return
    }

    setTimeout(() => setDisabled(false), 10000)
  }

  async function regenerateReport() {
    await projects.updatebatch(
      id,
      {noMerge: false},
      {
        regenerate_report: {
          by: auth.sunkaizenUser.id,
          at: firebase.firestore.FieldValue.serverTimestamp(),
        },
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: auth?.sunkaizenUser?.id, 
        updatedByName: auth?.sunkaizenUser?.name
      }
    )
    alert('The report is being generated, the completion email will not be sent!')
  }

  function downloadFile(uri,name) {
    var link = document.createElement('a')
    link.href = uri
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
 

  const coverntFiletoBlobAndDownload = async (file, name) => {
     downloadFile(file,name);
  }

  return (
    <div>
      {status === 'Completed' &&
        ['admin', 'super-admin'].includes(auth.sunkaizenUser.type) &&
        !Object.keys(report || {}).length && (
          <Button disabled={disabled} onClick={generateReport}>
            Generate a Missing Report
          </Button> 
        )}
      <>
        <a
          className={className}
          style={{pointerEvents: report && report.fullPath ? `auto` : `none`}}
          onClick={() => {
            const fileName = `${site.siteAddress}_${moment().format('MM-DD-YYYY')}_${site.siteOwnerName}_${id}_report.pdf`
            console.log('fileName',fileName,'url',url)
            coverntFiletoBlobAndDownload(url, fileName)
          }}
        >
          <Icon className="padding-right-1" type="project" />
          Project Report
        </a>
        {status === 'Completed' && (
          <a
            onClick={() => {
              const yes = window.confirm('Do you confirm to rengerate the report?')
              if (yes) {
                regenerateReport()
              }
            }}
          >
            Regenerate
          </a>
        )}
      </>
    </div>
  )
}
