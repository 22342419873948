import React, {useContext, useEffect} from 'react'
import {Input, InputNumber, Radio, Button} from 'antd'
import {ReducerContext} from './Provider'

export default function CriticalityRow({i, disabled, name, limit, value}) {
  const {dispatch} = useContext(ReducerContext)

  console.log(limit)

  useEffect(
    function () {
      if (!limit) {
        change('value', null)
        return
      }
      if (value) return
      if (limit) change('value', 0)
    },
    [limit]
  )

  function change(k, v) {
    const row = {name, limit, value}
    row[k] = v
    dispatch({type: 'changeRow', index: i, value: row})
  }

  return (
    <div className="criticality-row">
      <div className="criticality-row-name">
        <Input
          disabled={disabled}
          value={name}
          size="large"
          placeholder="Name"
          onChange={(e) => change('name', e.target.value)}
          data-cy="criticalityName"
        />
      </div>
      <div className="criticality-row-limit">
        <Radio.Group disabled={disabled} onChange={(e) => change('limit', e.target.value)} value={limit}>
          <Radio value={false} data-cy="unlimitedFailuresOpt">
            Any Number
          </Radio>
          <Radio value={true} data-cy="limitedFailuresOpt">
            No More Than
          </Radio>
        </Radio.Group>
      </div>
      <div className="criticality-row-value">
        <InputNumber
          disabled={disabled || !limit}
          style={{width: '6rem'}}
          size="large"
          value={value}
          min={0}
          onChange={(e) => change('value', e)}
          data-cy="criticalityValue"
        />
      </div>
      <div className="criticality-row-delete">
        <Button
          disabled={disabled}
          title="Remove"
          icon="minus-circle"
          size="large"
          onClick={() => dispatch({type: 'deleteRow', value: i})}
        />
      </div>
    </div>
  )
}
