import React, {useEffect, useState} from 'react'
import {Card} from 'antd'
import Header from './Header'
import {ApiAccess, OrganizationConfiguration, ReportConfiguration, UserInformation} from './tabs'
import AvatarPane from './AvatarPane'
import './DetailedUserView.css'

export default function DetailedUserView({resource, organization}) {
  const [cardKey, setCardKey] = useState('userinformation')
  const [user, setUser] = useState({organization: {}})

  useEffect(
    function () {
      setUser(resource)
    },
    [resource.id]
  )

  const change = (type) => (v) => {
    setUser({...user, [type]: v})
  }

  const tabList = [
    {key: 'userinformation', tab: 'User Information'},
    {key: 'apiaccess', tab: 'API Access'},
    {key: 'reportconfiguration', tab: 'Report Configuration'},
    {key: 'organizationconfiguration', tab: 'Organization Configuration'},
  ]

  const contentList = {
    userinformation: <UserInformation {...{user, setUser, change}} />,
    apiaccess: <ApiAccess user={user} />,
    reportconfiguration: <ReportConfiguration user={user} />,
    organizationconfiguration: <OrganizationConfiguration />,
  }

  return (
    <div>
      <Header name={user.name} />
      <div className="flex-row">
        <div className="user-information-pane">
          <Card tabList={tabList} activeTabKey={cardKey} onTabChange={(k) => setCardKey(k)}>
            {contentList[cardKey]}
          </Card>
        </div>
        <AvatarPane {...{resource, organization, user}} />
      </div>
    </div>
  )
}
