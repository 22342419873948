import React from 'react';
import { Required } from '../utilities';
import AutocompleteField from '../../AutocompleteField';
import { types } from '../../UserBuilder/UserInformation/addressFieldTypes';
import { TextField } from '../../UserBuilder/UserInformation/TextField';

export default function CreateProjectClient({
  fields = {}, 
  setField = () => {}, 
  style = {}, 
  noheader = false, 
  autocomplete = false
}) {
  async function changeAddress(address) {
    const filteredAddress = {};
    address[0].address_components.forEach((c) => {
      if (c.types.some((t) => types[t])) {
        filteredAddress[c.types[0]] = c.short_name;
      }
    });
    for (let k in filteredAddress) setField(k)(filteredAddress[k]);
    setField('siteAddress')(address[0].formatted_address);
    setField('geocode_results')(address[0]);
  }

  return (
    <div style={{ ...style, margin: '0 auto' }}>
      {!noheader && <h3 style={{ textAlign: 'left' }}>Property Information</h3>}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label 
            htmlFor="site_owner" 
            style={{ fontWeight: 'bold', flex: 1, textAlign: 'left' }}
          >
            Site Owner Name
          </label>
        </div>
        <TextField 
          id="site_owner"
          field={fields.siteOwnerName}  
          type="text" 
          set={setField('siteOwnerName')} 
          placeholder="Site Owner" 
          style={{ flex: 2, marginLeft: '1rem' }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label 
            htmlFor="site_address" 
            style={{ fontWeight: 'bold', flex: 1, textAlign: 'left' }}
          >
            Site Address
          </label>
        </div>
        <AutocompleteField
          id="projectAddress"
          full
          edit
          autoComplete={autocomplete}
          style={{ flex: 2 }}
          submit={changeAddress}
          onChange={(e) => setField('dummyAddress')(e)}
          old_address={fields.siteAddress}  
          placeholder="Address"
          data-cy="address"
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label 
            htmlFor="site_email" 
            style={{ fontWeight: 'bold', flex: 1, textAlign: 'left' }}
          >
            Site Contact Email Address
          </label>
        </div>
        <TextField 
          id="site_email"
          field={fields.siteRepEmail}  
          type="text" 
          set={setField('siteRepEmail')} 
          placeholder="Site Contact Email" 
          style={{ flex: 2, marginLeft: '1rem' }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label 
            htmlFor="site_phone" 
            style={{ fontWeight: 'bold', flex: 1, textAlign: 'left' }}
          >
            Site Contact Phone Number
          </label>
        </div>
        <TextField 
          id="site_phone"
          field={fields.siteRepPhone}  
          type="text" 
          set={setField('siteRepPhone')} 
          placeholder="Site Contact Phone" 
          style={{ flex: 2, marginLeft: '1rem' }}
        />
      </div>
    </div>
  );
}
