import DB from '../../DAL/DB'

const users = new DB.Users()

export default function makeBatches(dbOps) {
  const batchJobs = []
  for (let i = 0; i < dbOps.length; i = i + 400) {
    const b = dbOps.slice(i, i + 400)
    const batch = new DB.FirebaseBatch()

    b.forEach((data) => batch.update(users, data.id, data))
    batchJobs.push(batch.commit())
  }
  return batchJobs
}
