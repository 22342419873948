import React from 'react'
import PropTypes from 'prop-types'

const CancellationPhoto = ({style, photo}) => (
  <div style={{...style}}>
    <a href={photo.url} className="full-height full-width" target="_blank" rel="noopener noreferrer">
      <img className="full-width" alt="cancellation reason" src={photo.url} />
    </a>
  </div>
)

CancellationPhoto.propTypes = {photo: PropTypes.object, style: PropTypes.object}
export default CancellationPhoto
