import React from 'react'
import {connect} from 'react-redux'
import change from './change'
import {Select, Col} from 'antd'

function getValue(rubrics, rId, question, oId) {
  if (!rubrics[rId]) return
  return rubrics[rId].questions[question.id].options[oId]
}

function stop(state, ownProps) {
  return {value: getValue(state.rubrics, ...ownProps.cArgs)}
}

export function Option({disabled, cArgs, value}) {
  return (
      <Select
        style={{width: '12rem'}}
        disabled={disabled}
        onChange={(v) => change(...cArgs, v)}
        value={value}
        data-cy="rubricQuestion"
      >
        {['Pass', 'Fail', 'Ignore'].map((o) => (
          <Select.Option key={o} value={o} data-cy="passFailIgnore">
            {o}
          </Select.Option>
        ))}
      </Select>
  )
}

const WrappedOption = connect(stop)(Option)
export default WrappedOption
