import React, {useState} from 'react'
import {Button, Modal} from 'antd'
import ScheduleInspections from '.'

export default function ScheduleInspectionModal({id, icon, style, children}) {
  const [visible, setVisible] = useState(null)

  return (
    <div>
      <Button icon={icon} style={style} size="default" onClick={() => setVisible(true)}>
        {children}
      </Button>
      <Modal
        width="95%"
        visible={visible}
        style={{top: 10}}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        title="Schedule an Inspection"
      >
        <ScheduleInspections setVisible={setVisible} match={{params: {id}}} />
      </Modal>
    </div>
  )
}
