import React from 'react';
import { TimePicker, Icon, Tooltip } from 'antd';
import PhotoRequired from './PhotoRequired';
import { firebase } from '../../../utilities';
import { setField, updateProgress } from '../../action_creators';
import { connect } from 'react-redux';
import moment from 'moment';
import { fieldHasErrors } from '../../utilities';

let now = moment();

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  value: state.fields[ownProps.id].value,
});
const dtop = (dispatch) => ({
  setField: ({ id, value }) => {
    dispatch(setField(id, value));
    dispatch(updateProgress());
  }
});

export function TimeInput({ setField, field, id }) {
  const { value, question, label, required, photoRequired, tipText } = field;
  let val = value && Object.keys(value).length > 0 && value.toDate ? moment(value.toDate()) : null;
  const set = (value) => {
    field.value = firebase.firestore.Timestamp.fromDate(value.toDate());
    setField({ id, value: field });
  };

  return (
    <div className="question-container">
      <div className="bold padding-bottom-1 linebreak question-title">
        {question}
        {required && (
          <Tooltip title="Required">
            <Icon type="exclamation-circle" style={{ fontSize: '1.5rem', padding: '0 0 0 0.5rem', color:'red' }} /> <span style={{ fontSize: '1rem', color:'red' }}> Required</span>
            </Tooltip>
        )}
      </div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{ fontWeight: 600 }}>
          {(label || '').toUpperCase()}
        </div>
        <div className="full-width" style={{ border: fieldHasErrors(field) ? '1px solid red' : 'initial' }}>
          <TimePicker style={{ width: '100%' }} value={val || now} onChange={set} />
        </div>
        <div className="linebreak">
          <small>{tipText && `${tipText}`}</small>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  );
}

const WrappedTimeInput = connect(stop, dtop)(TimeInput);
export default WrappedTimeInput;
