import React from 'react'
import {PermissionsBlock} from './PermissionsBlock'
import {makeQualifications, makeClients} from './permissionsUtilities'
import {useDBSubscribe} from '../effects'
import DB from '../DAL/DB'

const forms = new DB.Forms()
const users = new DB.Users()

export default function Permissions({setField, fields}) {
  const clients = useDBSubscribe(users, null, makeClients)
  const qualifications = useDBSubscribe(forms, null, makeQualifications)

  const setPermissions = (type) => (permissions) => {
    const resources = type === 'qualifications' ? qualifications : clients
    const p = {}
    let name = 'name'
    permissions.forEach((pp) => (p[pp] = resources[pp][name]))
    setField(type)(p)
  }

  const {clientsAll, qualificationsAll, clients: cc = {}, qualifications: qq = {}} = fields || {}

  return (
    <div className="flex-column align-start padding-2 margin-bottom-1" style={{background: '#F4F6F8'}}>
      <h3>Permissions</h3>
      <PermissionsBlock
        className="full-width"
        {...{
          type: 'Clients',
          fieldAll: clientsAll,
          check: setField('clientsAll'),
          fieldsObject: cc,
          options: clients,
          set: setPermissions('clients'),
          placeholder: 'Select Clients',
        }}
        data-cy="userPermissions"
      />
      <PermissionsBlock
        className="full-width"
        {...{
          type: 'Qualifications',
          fieldAll: qualificationsAll,
          check: setField('qualificationsAll'),
          fieldsObject: qq,
          options: qualifications,
          set: setPermissions('qualifications'),
          placeholder: 'Select Qualifications',
        }}
      />
    </div>
  )
}
