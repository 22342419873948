import React from 'react'
import NewTable from '../UnifiedList/Provider'

export default function FormsList() {
  return (
    <div className="flex-column padding-2" style={{borderRadius: '0.2rem', alignContent: 'flex-start'}}>
      <NewTable strategy="users" />
    </div>
  )
}
