import React from 'react'
import {store} from './utilities'
import {Provider} from 'react-redux'
import TopLevelState from './TopLevelState'

export default function EntryPoint(props) {
  return (
    <Provider store={store}>
      <TopLevelState {...props} />
    </Provider>
  )
}
