import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Input, Icon} from 'antd'
import Duplicate from './Duplicate'
import Followup from './Followup'
import DeleteButton from '../../../DeleteButton'
import {modify} from '../../action_creators'
import {useFocus} from '../../utilities'

const stop = (state, ownProps) => ({
  option: state.fields[state.active.field].options[ownProps.index],
  field: state.fields[state.active.field] || null,
  fields : state.fields,
  rubrics: state.rubrics
})

const dtop = (dispatch) => ({modify: (type) => (id, value) => dispatch(modify(type)(id, value))})


export function Option({fields,field, option, index, errors, modify, rubrics}) {
  // if (!option) return <div />

  function set({id, value}) {
    const o = [...field.options]
    o[id] = value

    modify('field')(field.id, {...field, options: o})
    updateQuestionSelectedOption(field,fields,value)
  }

  function del({index,value}) {
    const {options} = field
    const canDelete = deleteQuestionSelectedOption(field,fields,value)
    if (canDelete){
      options.splice(index, 1)
      modify('field')(field.id, {...field, options})
    } else
    {
      alert('Please do not delete option, if this option associated with another conditional question.')
    }
  }

  function deleteQuestionSelectedOption(field,fields,value) {
    //when option deleted , check if any condition group question should be deleted.
    let id = field.id;
    let recordFound = false;
    Object.keys(fields).forEach(rec => {
      if(!recordFound && rec !== id && fields[rec] && fields[rec].conditionGroup && fields[rec].conditionGroup.cgList){
        const cgList = fields[rec].conditionGroup.cgList
        cgList.forEach(x => {
          if(x.conditionList && x.conditionList.length > 0 && 
            x.conditionList.find(cc => cc.selectedQuestion === id)) {
            let  conditionList = x.conditionList.find(cc => cc.selectedQuestion === id);
            if (conditionList.selectedOption === value) {
              recordFound = true;
            }
          }
        })
      }

      if(!recordFound) {
        const {rubrics: formRubrics} = rubrics

        if(formRubrics && Object.keys(formRubrics).length > 0) {
          // Rubrics present for this form
          Object.keys(formRubrics).forEach(frRec => {
            const {questions: formRubricQuestions } = formRubrics[frRec]
            if(formRubricQuestions && Object.keys(formRubricQuestions).length > 0) {
              Object.keys(formRubricQuestions).forEach(frqRec => {
                const {OptionActions: formRubricOptionActions} = formRubricQuestions[frqRec]
                if(formRubricOptionActions && Object.keys(formRubricOptionActions).length > 0) {
                  Object.keys(formRubricOptionActions).forEach(froARec => {
                    const frOAData = formRubricOptionActions[froARec]
                    if(frOAData && frOAData.length > 0) {
                      frOAData.forEach(froADRec => {
                        const {conditionGroup} = froADRec
                        if(conditionGroup && conditionGroup.cgList){
                          const cgList = conditionGroup.cgList
                          cgList.forEach(x => {
                            if(x.conditionList && x.conditionList.length > 0 && 
                              x.conditionList.find(cc => cc.selectedQuestion === id)) {
                              let  conditionList = x.conditionList.find(cc => cc.selectedQuestion === id);
                              if (conditionList.selectedOption === value) {
                                recordFound = true;
                              }
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }
     }
    })
    return !recordFound;
  }

  function updateQuestionSelectedOption(field,fields,updatedValue) {
    //when option value change , update condition group question selectedoption
    let id = field.id;
    let recordFound = false;
    Object.keys(fields).forEach(rec => {
      if(!recordFound && rec !== id && fields[rec] && fields[rec].conditionGroup && fields[rec].conditionGroup.cgList){
        const cgList = fields[rec].conditionGroup.cgList
        cgList.forEach(x => {
          if(x.conditionList && x.conditionList.length > 0 && 
            x.conditionList.find(cc => cc.selectedQuestion === id)) {
            let  conditionList = x.conditionList.find(cc => cc.selectedQuestion === id);
            conditionList.selectedOption = updatedValue.label;
          }
        })
      }
    })
  }

  const [inputRef, setInputFocus] = useFocus()

  //Autofocus on the input field
  useEffect(() => setInputFocus(), [])

  return (
    <div>
      <Duplicate errors={errors} />
      <div className="flex-row">
        <Input
          ref={inputRef}
          addonAfter={<Icon type="drag" />}
          placeholder="Please provide a name for this option"
          onChange={(e) => {
            set({id: index, value: {...option, label: e.target.value}})
          }}
          value={option.label}
          data-cy="optionName"
        />
        <DeleteButton
          confirmationDisplay="Remove this option?"
          type="secondary"
          onConfirm={(e) => {
            del({index: index, value: option.label})
          }}          
          icon="minus-circle"
        />
      </div>
      <Followup {...{option, index, set}} />
    </div>
  )
}

const WrappedOption = connect(stop, dtop)(Option)
export default WrappedOption
