import React, {useState, useEffect} from 'react'
// import PropTypes from 'prop-types'

import {history} from './utilities'
import DB from './DAL/DB'

import {Table} from 'antd'

// name, email, phone, formatted_address
const columns = [
  {title: `ID`, dataIndex: `id`, key: `id`},
  {title: `Name`, dataIndex: `name`, key: `name`},
  {title: `Email`, dataIndex: `email`, key: `email`},
  {title: `Phone`, dataIndex: `phone`, key: `phone`},
  {title: `Address`, dataIndex: `geocode_results.formatted_address`, key: `geocode_results.formatted_address`},
]

const users = new DB.Users()

const InstallerDirectory = () => {
  const [installers, setInstallers] = useState([])

  useEffect(function () {
    return users.subscribe(null, {filters: [['type', '==', 'installer']]}, ({ok, data}) => {
      if (!ok) return
      setInstallers(data)
    })
  }, [])

  return (
    <Table
      {...{
        columns,
        dataSource: installers,
        onRow: (record) => ({onClick: () => history.push(`/users/${record.id}/manage`)}),
        style: {padding: `1rem`, backgroundColor: `white`, margin: `3rem`},
      }}
    />
  )
}

export default InstallerDirectory
