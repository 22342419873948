import {fieldHasErrors} from './fieldHasErrors'

export function checkRequired(intake) {
  let errors = {}

  Object.values(intake.sections).forEach((section) => {
    for (let i in section.fields) {
      const field = section.fields[i]
      if (hasDependentQuestion(section, field) && fieldHasErrors(field)) {
        // console.log(field, section.id, section.title)
        errors[field.id] = true
      }
    }
  })

  // console.log(`errors found for project: `, errors)
  return errors
}

// this function checking field had dependent question and field displayed or not
function hasDependentQuestion(section, field) {
  if (field.dependentQue && field.dependentQue.selectedQuestion) {
    const selectedQUestion = field.dependentQue.selectedQuestion
    const selectedOption = field.dependentQue.selectedOption
    const dependentField = section.fields.find((x) => x.id === selectedQUestion)
    if (
      dependentField &&
      dependentField.value &&
      dependentField.value.values &&
      dependentField.value.values[selectedOption]
    ) {
      return true
    } else {
      return false
    }
  }
  return true
}

