import React from 'react'
import {Router, Route, Switch, Redirect, useRouteMatch} from 'react-router-dom'
import CheckRegistration from './CheckRegistration'

import GetProject from './UtilityDashboard/GetProject'

function Authorized({component: Component, ...rest}) {
  return <Route {...rest} render={(props) => <CheckRegistration {...{Component, ...rest, ...props}} />} />
}

const admins = ['admin', 'super-admin']

export default function Utilities() {
  const {path} = useRouteMatch()

  return (
    <div>
      <Authorized exact path={`${path}/get_project/:id`} component={GetProject} type={admins} />
    </div>
  )
}
