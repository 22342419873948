import React from 'react'
import {Icon} from 'antd'

export default function PhotoRequired() {
  return (
    <div className="photo-icon-container">
      <Icon type="picture" style={{fontSize: '4rem'}} />
      <div className="bold">Add</div>
    </div>
  )
}
