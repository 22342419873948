export default function loadSavedFilter(state, dispatch) {
  return async function (e) {
    const {filterTypes, presetFilters} = state

    const ft = {...filterTypes}
    const savedFilter = presetFilters[e]
    Object.entries(savedFilter.filter).forEach(([k, v]) => (ft[k].instances = v.instances))
    dispatch({type: 'filterTypes', value: ft})
    dispatch({type: 'includeIntakeData', value: savedFilter.includeIntakeData})
  }
}
