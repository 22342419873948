import {useContext, useEffect} from 'react'
import {ReducerContext} from './reducer'
import {auth} from '../Auth'
import DB from '../DAL/DB'

const forms = new DB.Forms()
const rubrics = new DB.Rubrics()
const users = new DB.Users()

export default function useResources() {
  const {state, dispatch} = useContext(ReducerContext)

  useEffect(function () {
    forms.subscribe(
      undefined,
      {filters: [['organization.id', '==', auth.sunkaizenUser.organization.id]]},
      ({data: value}) => dispatch({type: 'forms', value})
    )

    users.subscribe(undefined, {filters: [['type', '==', 'client']]}, ({data}) =>
      dispatch({type: 'clients', value: data})
    )
  }, [])

  useEffect(
    function () {
      if (!state.form) return

      const unsubscribe = rubrics.subscribe(undefined, {filters: [['formId', '==', state.form]]}, ({data: value}) => {
        dispatch({type: 'rubrics', value})
      })

      return unsubscribe
    },
    [state.form]
  )
}
