import React, {useEffect, useState} from 'react'
import DB from './DAL/DB'

const projectLogs = new DB.ProjectLogs()

export default function ProjectLogs({match}) {
  const [logs, setLogs] = useState([])

  useEffect(function () {
    (async function () {
      const {id} = match.params
      const {ok, data} = await projectLogs.get(null, {filters: [['categoryId', '==', id]]})

      data.sort((a, b) => (a.created > b.created ? 1 : -1))

      if (ok) setLogs(data)
    })()
  }, [])

  return (
    <div>
      <h1 className="padding-2">Project Logs for {match.params.id}</h1>
      <div>
        {logs.map((r) => (
          <div style={{display: 'grid', gridTemplateColumns: '25% 75%'}}>
            <div>{r.created.toDate().toString()}</div>
            <div>{r.message}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
