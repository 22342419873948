import React, {useEffect, useState, useReducer} from 'react'
import UserInformation from './UserBuilder/UserInformation'
import {usersOne} from './UserBuilder/utilities'
import {loggerEffect, checkLogin} from './effects'
import {auth} from './Auth'
import SystemLogger from './SystemLogger'


const styles = {
  button: {padding: '0.3em', width: '10rem', margin: '0.3em', border: 'none'},
}

function reducer(state, action) {
  switch (action.type) {
    case 'change':
      return {...state, [action.key]: action.value}
    default:
      return state
  }
}



export default function Register({history, location }) {
  //const [state, dispatch] = useReducer(reducer, {type: 'client'})
  const [state, dispatch] = useReducer(reducer, {type: 'client', name: '', email: '',  phone:'', company: '', address: '' })

  const [logger, setLogger] = useState(null)
  console.log('location.state',location)

  useEffect(
    checkLogin(() => history.push('/')),
    [auth.sunkaizenUser]
  )
  useEffect(loggerEffect(setLogger), [])
  useEffect(() => { 
     if(location?.state?.email) {
      console.log('payload is there')
      dispatch({type: 'change', key: 'email', value: location.state.email})
      dispatch({type: 'change', key: 'emailDisabled', value: true })
     } else {
      console.log('payload is NOT there')
      dispatch({type: 'change', key: 'emailDisabled', value: false })
      history.push('/login')
     }
   },[location])

   function validateClientData (client) {
    const requiredFields = ['name', 'email', 'phone', 'company', 'address'];
    for (const field of requiredFields) {
      if (!client[field]) {
        const logger = new SystemLogger({})
        logger.error('All fields are required');
        return false;
      }
    }
    return true;
  };

  async function submit() {

    const isValid = validateClientData(state)
    //console.log('isValid',isValid)
    if (!isValid) return 
    try {
      await usersOne({...state, id: auth.currentUser.uid}, logger)
      logger.log('Registration Complete; logging in...')
    } catch (e) {
      console.log(e)
    }
  }

  //console.log('state',state)

  return (
    <>
    {state.emailDisabled ? 
      (
    <div className="full-width">
      <div style={{padding: '3rem'}}>
        <h1>Register</h1>
        <h3>This login is not associated with an account on FIT-QM; please create one</h3>
        <div className="flex-row justify-center padding-top-2">
          <UserInformation
            noheader
            notype
            style={{width: '80%'}}
            fields={state}
            setField={(key) => (value) => dispatch({type: 'change', key, value})}
            autocomplete="new-password"
          />
        </div>

        <div className='flex-row'>
          <div
            style={styles.button}
            className="background-primary color-white flex-row justify-center bold pointer"
            onClick={submit}
          >
            Submit
          </div>

          <div
            style={styles.button}
            className="background-primary color-white flex-row justify-center bold pointer"
            onClick={()=> history.push('/login')}
            >
            Cancel
          </div>
        </div>

      </div>
    </div>
      ) 
      : 
      (<div></div>)
    }
   </> 
  )
}
