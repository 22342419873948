import React from 'react'
import {Select} from 'antd'
import '../DetailedUserView.css'

export default function MultiselectField(props) {
  const {options, disabled, label, value, onChange} = props
  const {style = {}, selectStyle = {}, className = '', selectClassName = ''} = props

  return (
    <div className={`flex-column field-wrapper ${className}`} style={style}>
      <div className="info-text">{label}</div>
      <Select
        className={`full-width ${selectClassName}`}
        style={selectStyle}
        defaultValue={label}
        onChange={onChange}
        disabled={disabled}
        value={value}
        mode="multiple"
        size="large"
      >
        {options.map((option) => (
          <Select.Option key={option}>{option}</Select.Option>
        ))}
      </Select>
    </div>
  )
}
