import React from 'react'

export default function IntakeStatus({status}) {
  return (
    <div
      className="flex-row align-center justify-space-between"
      style={{borderBottom: '0.1rem solid #DFE3E8', padding: '1.5rem'}}
    >
      <h3 style={{padding: 0, margin: 0}}>Intake Details</h3>
      <div
        className="background-tertiary color-white"
        style={{borderRadius: '1rem', padding: '0.5rem 1rem 0.5rem 1rem'}}
        data-cy="intakeProjectStatus"
      >
        {status}
      </div>
    </div>
  )
}
