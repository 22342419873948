import React, {useEffect, useContext} from 'react'
import FieldUserBrief from './FieldUserBrief'
import SelectedFieldUser from './SelectedFieldUser'
import {pushData} from '../utilities'
import {useSubscribeResources} from '../DateTimePicker/effects'
import {List} from 'antd'
import {ReducerContext} from '../reducer'
import {subscribe} from '../../HOC/Firebase/SubscribeHOC'
import {auth} from '../../Auth'

export function FieldUsers({makeSubscription, subscribeResources}) {
  const {state, dispatch} = useContext(ReducerContext)
  const {
    mapping: {filteredInspectors = {}},
    inspector,
  } = state
  const push = pushData({state, dispatch})

  useEffect(function () {
    auth.sunkaizenUserSubscribe((user) => {
      if (!user) return

      if (user.type === `field-user`) {
        dispatch({type: 'inspectors', value: {[user.id]: user}})
        return
      }

      return makeSubscription(
        {
          paths: [{collection: `users`}],
          options: {
            where: [
              {field: `type`, test: `==`, value: `field-user`},
              {field: 'organization.id', test: '==', value: auth.sunkaizenUser.organization.id},
            ],
            order: `name`,
          },
        },
        `inspectors`
      )
    })
  }, [])

  useSubscribeResources(subscribeResources)

  let fieldUsers = Object.values(filteredInspectors)
  if (auth.sunkaizenUser.type == 'client') {
    fieldUsers.push(auth.sunkaizenUser)
  }
  fieldUsers = fieldUsers.filter((user) => user.id !== inspector?.id && !user.disabled)

  return (
    <div className="padding-top-bottom-2">
      <div className="flex-row justify-space-between align-center">
        <div className="bold padding-0-5">Inspector</div>
        {inspector.id && (
          <a className="padding-right-2" href="#" onClick={() => push({inspector: {}})}>
            Clear Selected Inspector
          </a>
        )}
      </div>
      {inspector.id && <SelectedFieldUser inspector={inspector} />}
      <div className="bold padding-top-1">Field Users</div>
      <small>Please select an inspector</small>
      <List
        dataSource={fieldUsers}
        loading={Object.keys(filteredInspectors).length === 0}
        renderItem={(user) => <FieldUserBrief inspector={user} />}
        style={{maxHeight: '10em', overflow: 'scroll'}}
      />
    </div>
  )
}

const WrappedFieldUsers = subscribe()(FieldUsers)
export default WrappedFieldUsers
