export default function updateDirections(selectedProject, state, directionsLibraries) {
  return function () {
    if (!selectedProject.id) return
    if (!state.project.id) {
      console.log('no project selected to compare against')
      return
    }
    const {directionsService, directionsRenderer} = directionsLibraries
    if (!directionsService || !directionsRenderer) {
      console.log('google libraries not loaded yet')
      return
    }

    directionsService.route(
      {
        origin: state.project.site.geocode_results.geometry.location,
        destination: selectedProject.site.geocode_results.geometry.location,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === 'OK') directionsRenderer.setDirections(response)
        else console.log('directions request failed: ', status)
      }
    )
  }
}
