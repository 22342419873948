import {store} from '../utilities'
import {activate, category} from '../action_creators'

export default function resourcesReturned(readResources) {
  return function () {
    for (let k in readResources) {
      switch (k) {
        case 'form':
          store.dispatch(category('form')({order: [], ...readResources[k][0]}))
        case 'action':
          const actionsData = readResources['actions']
          store.dispatch(category('saveAction')(actionsData))
        case 'rubrics':
            const rubrics = readResources['rubrics']
            store.dispatch(category('rubrics')(rubrics))
        case 'sections':
          const ss = readResources[k]
          const sections = {}
          for (let section of ss) {
            sections[section.id] = section
          }
          store.dispatch(category('sections')(sections))

          const state = store.getState()
          const {active, form} = state
          if (!active.section && form.order) store.dispatch(activate('section', form.order[0]))
      }
    }
  }
}
