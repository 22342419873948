import React, {useRef} from 'react'
import {Input} from 'antd'
import {useAddress} from '.'

/*
@param {boolean} full If true then write out the entire formatted address into the autocomplete line on a match, if false just write the street address
@param {boolean} edit If true then you can edit the field, disabled otherwise 
*/
function AutocompleteField(args) {
  const {edit, style = {}, placeholder = null} = args

  const [address, setAddress, autocompleteRef] = useAddress(args)

  return (
    <Input
      disabled={!edit}
      style={style}
      type="text"
      ref={autocompleteRef}
      size="large"
      placeholder={placeholder || `Address`}
      value={address}
      onChange={setAddress}
      autoComplete="new-password"
    />
  )
}

export default AutocompleteField
