import React from 'react'
import {Button} from 'antd'
import {colors} from '../../../config'

export default function ModalOpenButton({setShow}) {
  return (
    <Button
      className="button"
      style={{fontSize: `1.5rem`, width: '100%', background: colors.tertiary, color: 'white'}}
      onClick={() => setShow(true)}
    >
      Create Tickets
    </Button>
  )
}
