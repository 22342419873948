import DB from '../../DAL/DB'
const users = new DB.Users()
import makeUser from './makeUser'
import makeBatches from './makeBatches'

export async function finalize(finalize = true, uu, organization, permissions) {
  if (finalize) {
    let dbOps = uu
      .filter((u) => !u.errors || !u.errors.type)
      .map((u) => {
        try {
          u.id = users.makeId()
          u.organization = {id: organization.id, name: organization.name, email: organization.email, ...permissions}
          return makeUser(u)
        } catch (e) {
          console.log(e.message)
        }
      })
    dbOps = await Promise.all(dbOps)

    const batchJobs = makeBatches(dbOps)
    const results = await Promise.all(batchJobs)
    return [results, dbOps.length]
  }
}
