import moment from 'moment'
import {firebase} from '../../utilities'

export function dateToMoment(dd) {
  if (!dd) return

  let momentDate
  if (Number.isInteger(dd)) momentDate = moment({hour: dd})
  else if (dd.hours) momentDate = moment(dd)
  else if (dd.toDate) momentDate = moment(dd.toDate())
  else if (dd._seconds) {
    momentDate = moment(new firebase.firestore.Timestamp(dd._seconds, dd._nanoseconds).toDate())
  } else {
    console.log(dd)
    throw new Error(`could not convert a date`)
  }

  return momentDate
}

export default function projectDatesToMoment(project) {
  if (!project || !project.inspection) return

  let {date, duration} = project.inspection
  for (let d in {date, duration}) {
    project.inspection[d] = dateToMoment(project.inspection[d])
  }

  return project
}
