const userMap = {
  'super-admin': [
    `home`,
    `projects`,
    `users`,
    `reports`,
    `calendar`,
    `data`,
    `logs`,
    `forms`,
    `schedule`,
    `scoring`,
    `tickets`,
  ],
  admin: [
    `home`,
    `projects`,
    `users`,
    `reports`,
    `calendar`,
    `data`,
    `logs`,
    `forms`,
    `schedule`,
    `scoring`,
    `tickets`,
  ],
  // client: [`home`, `projects`, `reports`, `data`, `tickets`, 'users'],
  client: [
    `home`,
    `projects`,
    `users`,
    `reports`,
    `calendar`,
    `data`,
    `logs`,
    `forms`,
    `schedule`,
    `scoring`,
    `tickets`,
  ],
  'field-user': [`home`, `projects`, `reports`, `calendar`, `schedule`, `tickets`],
  installer: [`home`, `projects`, `reports`, `calendar`, `data`, `tickets`],
  'quality-manager': [`home`, `projects`, `reports`, `schedule`, `tickets`],
  scheduler: [`home`, `projects`, `reports`, `calendar`, `schedule`, `tickets`],
}

export default userMap
