// https://stackoverflow.com/questions/8248077/google-maps-v3-standard-icon-shadow-names-equiv-of-g-default-icon-in-v2

export const image = () => ({
  // url: `http://maps.google.com/mapfiles/ms/icons/blue.png`,
  url: 'https://firebasestorage.googleapis.com/v0/b/sunkaizen-9f140.appspot.com/o/meta%2Fimages%2Fpin_project_unselected.png?alt=media',
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(0, 20),
  site: new window.google.maps.Size(20, 20),
})

export const selectedImage = () => ({
  // url: `http://maps.google.com/mapfiles/ms/icons/yellow.png`,
  url: 'https://firebasestorage.googleapis.com/v0/b/sunkaizen-9f140.appspot.com/o/meta%2Fimages%2Fpin_project_selected.png?alt=media',
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(0, 20),
  site: new window.google.maps.Size(20, 20),
})

export const inspectorImage = () => ({
  // url: `http://maps.google.com/mapfiles/ms/icons/picnic.png`,
  url: 'https://firebasestorage.googleapis.com/v0/b/sunkaizen-9f140.appspot.com/o/meta%2Fimages%2Fpin_inspector_unselected.png?alt=media',
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(0, 20),
  site: new window.google.maps.Size(20, 20),
})

export const inspectorSelected = () => ({
  url: 'https://firebasestorage.googleapis.com/v0/b/sunkaizen-9f140.appspot.com/o/meta%2Fimages%2Fpin_inspector_selected.png?alt=media',
  // url: `http://maps.google.com/mapfiles/ms/icons/truck.png`,
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(0, 20),
  site: new window.google.maps.Size(20, 20),
})

export const inspectorOther = () => ({
  url: `https://firebasestorage.googleapis.com/v0/b/sunkaizen-9f140.appspot.com/o/meta%2Fimages%2Fpin_project_scheduled.png?alt=media`,
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(0, 20),
  site: new window.google.maps.Size(20, 20),
})

export const otherIntakes = () => ({
  url: `https://maps.gstatic.com/mapfiles/ms2/micons/mechanic.png`,
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(0, 20),
  site: new window.google.maps.Size(20, 20),
})

export const shape = {
  coords: [1, 1, 40, 70, 40, 1, 1, 1],
  type: 'poly',
}
