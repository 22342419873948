import React from 'react'
import NewTable from './UnifiedList/Provider'

export default function Authorizations() {
  return (
    <div style={{padding: '2rem'}}>
      <NewTable strategy="authorizations" />
    </div>
  )
}
