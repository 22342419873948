import React, {useState} from 'react'
import FileUploader from '../../../FileHandling/FileUploader'
import CancellationPhoto from '../../CancellationPhoto'
import {storage, firebase, correctedURL} from '../../../utilities'

export function CommentBox({change, value, type}) {
  //save,
  return (
    <div className="flex-column">
      <h4 className="padding-bottom-1">You must enter a comment explaining why you canceled</h4>
      <textarea cols="50" rows="4" onChange={(e) => change(type, e.target.value)} value={value} />
    </div>
  )
}

export const helperMessage = () => <div>You must upload a photo to explain why you canceled this intake</div>

export function PhotoTaker({id, change}) {
  const [photo, setPhoto] = useState(null)

  async function submit(files, filePath) {
    const photoRef = storage.ref().child(filePath)
    await photoRef.put(files[0])

    const {fullPath, name, bucket} = photoRef
    const created = firebase.firestore.Timestamp.now()
    const url = correctedURL(fullPath)
    const p = {fullPath, name, bucket, created, url}

    setPhoto(p)
    change('photo', p, id)
  }

  return (
    <div>
      <div>Upload a photo</div>
      <FileUploader id={id} type="photo" collection="projects" helperMessage={helperMessage} submit={submit} />
      {photo && <CancellationPhoto className="full-width margin-1" style={{height: '10rem'}} photo={photo} />}
    </div>
  )
}

export const requirementsMap = {
  comment: (props) => <CommentBox {...props} />,
  photo: (props) => <PhotoTaker {...props} />,
  reschedule: () => (
    <div className="flex-row align-center justify-center">
      <div style={{flex: 10}}>The Scheduler will be notified to reschedule this intake</div>
    </div>
  ),
}
