import React from 'react'
import {db} from '../../../utilities'
import {Icon, Button} from 'antd'
import DeleteButton from '../../../DeleteButton'
import {textSorter, numberSorter} from '../../tableFilters'
import SystemLogger from '../../../SystemLogger'
import {auth} from '../../../Auth'
import {onChange} from '../../onChange'
import { merge } from 'lodash'
import { store } from '../../utilities'
import { setQueryParams } from '../../action_creators' 

const del = async (id, name, type, disabledVal) => {
  const logger = new SystemLogger({path: `/users/${auth.currentUser.uid}`, uid: auth.currentUser.uid})
  if (['admin', 'super-admin'].includes(type) && auth.sunkaizenUser.type !== 'super-admin') {
    logger.error(
      `User ${auth.sunkaizenUser.name} (${auth.sunkaizenUser.id}) does not have permission to delete user ${name} (${auth.sunkaizenUser.id})`
    )
    return
  }

  try {
    const batch = db.batch()
    const calDates = await db.collection(`calendars`).where(`owner`, `==`, id).get()
    calDates.forEach((date) => batch.delete(date.ref))
    await batch.commit()
    //await db.collection(`users`).doc(id).delete()
    //trying to work here 
    await db.collection(`users`).doc(id).set({ disabled: disabledVal },{ merge: true })
    store.dispatch(setQueryParams({fullTextQuery: ''}))

    logger.event(`Disabled user ${name} (${id}); it may take a few seconds to complete this operation`)
  } catch (e) {
    logger.error(`Failed to Disable/Enable user ${name} (${id}), contact your system administrator`)
    console.log(e)
  }
}

export default function columnsMap() {
  return [
    {
      title: `Name`,
      dataIndex: `name`,
      key: `name`,
      sorter: (a, b) => textSorter(a.name, b.name),
    },
    {
      title: `Email`,
      dataIndex: `email`,
      key: `email`,
      sorter: (a, b) => textSorter(a.email, b.email),
    },
    {
      title: `Type`,
      dataIndex: `type`,
      key: `type`,
    },
    {
      title: `Phone`,
      dataIndex: `phone`,
      key: `phone`,
    },
    {
      title: `Zip`,
      dataIndex: `postal_code`,
      key: `zip`,
      sorter: (a, b) => numberSorter(a.zip, b.zip),
    },
    {
      title: `State`,
      dataIndex: `administrative_area_level_1`,
      key: `administrative_area_1`,
      sorter: (a, b) => textSorter(a.administrative_area_level_1, b.administrative_area_level_1),
    },
    {
      title: `Actions`,
      key: `action`,
      render: (text, record) => {
        return (
        <DeleteButton
          customRender
          confirmationDisplay={`${record?.disabled ? 'Enable?' : 'Disable?'}`}
          onConfirm={async (e) => {
            e.stopPropagation()
            await del(record.id, record.name, record.type, record?.disabled ? !record.disabled: true)
            setTimeout(() => { console.log('now'); onChange(); }, 1000) 
          }}
          onCancel={(e) => e.stopPropagation()}
          collection="users"
          documentId={record.id}
          okText="Yes"
          cancelText="No"
        >
          
          <Button style={{ backgroundColor: record?.disabled ? '#cc0000': '#eee', color: record?.disabled ? 'white' :'#444' , border: 1, borderWidth: 1, borderRadius: 5, padding: `0.5rem`, fontSize: 11 }} onClick={(e) => e.stopPropagation()} >
            {`${record?.disabled ? 'Enable' : 'Disable'}`}  
            {/* <Icon type="delete" style={{padding: `0.5rem`, fontSize: 24}} onClick={(e) => e.stopPropagation()} /> */}
          </Button>
        </DeleteButton>
      )},
    },
  ]
}
