import React, {useEffect} from 'react'
import {Input} from 'antd'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useFocus, store} from '../utilities'

export default function LabelQuestionTip({field, modify}) {
  const [inputRef, setInputFocus] = useFocus()

  //Autofocus on the first input when rendered
  useEffect(() => {
    setInputFocus()
  }, [])


  return (
    <div>
      <div className="flex-row align-center label-question">
        <label className="margin-right-1 bold label-question-label">
          Label
        </label>
        <Input.TextArea
          ref={inputRef}
          onChange={(e) => {
            modify({key: field.id, value: {...field, label: e.target.value}})
          }}
          value={field.label}
          placeholder="A short string to represent the question"
          data-cy="inputLabel"
          rows={1}
        />
      </div>
      {field.type === 'info-box' ?
      <div className="flex-row align-center label-question">
        <label className="margin-right-1 bold label-question-label-infoBox">
          InfoBox
        </label>
        <ReactQuill
         value={field.question}
         placeholder="The text of the Info itself"
         onChange={(e) => modify({key: field.id, value: {...field, question: e }})}
         className="react-quill"/>
      </div>
      :
      <div className="flex-row align-center label-question" >
        <label className="margin-right-1 bold label-question-label">
          Question
        </label>
        <Input.TextArea
          onChange={(e) => modify({key: field.id, value: {...field, question: e.target.value}})}
          value={field.question}
          placeholder="The text of the question itself"
          data-cy="inputQuestion"
          rows={1}
        />
      </div>
} 
      <div className="flex-row align-center label-question">
        <label className="margin-right-1 bold label-question-label">
          Tip Text
        </label>
        <Input.TextArea
          size="large"
          onChange={(e) => modify({key: field.id, value: {...field, tipText: e.target.value}})}
          value={field.tipText}
          placeholder="Any additional text that will help the field user answer the question"
          data-cy="inputTipText"
          rows={1}
        />
      </div>
    </div>
  )
}
