import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { FieldType } from './Fields';
import PhotoContainer from './Fields/PhotoContainer';
import Corrections from './Corrections';
import { setCorrection, setField, updateProgress } from '../action_creators';

const sToP = (state, ownProps) => ({
  field: state.fields[ownProps.fieldId],
  corrections: state.project.intake.corrections,
  status: state.project.status,
  state: state,
});

const dToP = (dispatch) => ({
  setCorrection: (id) => (correction) => dispatch(setCorrection(id, correction)),
  setField: (id, value) => dispatch(setField(id, value)),
  updateProgress: () => dispatch(updateProgress())
});

export function Field({ state, fieldId, field = {}, corrections = {}, status, setCorrection, setField, updateProgress, provided }) {
  const set = setCorrection(fieldId);

  const handleChange = (newValue) => {
    setField(fieldId, { ...field, value: newValue });
    updateProgress(); // Trigger progress update to re-evaluate conditions
  };

  return (
    <div className="full-width">
      <div
        className="full-width"
        style={{ border: corrections[fieldId] !== undefined ? '5px solid rgba(255, 0, 0, 0.5)' : 'none' }}
        data-cy="field"
      >
        <FieldType component={field} onChange={handleChange} />
        <PhotoContainer {...{ provided, fieldId }} />
        {field.comment && (
          <div className="flex-column">
            <div className="bold" style={{ padding: '0.5rem, 0, 0.5rem, 0' }}>
              Field Notes
            </div>
            <div>{field.comment}</div>
          </div>
        )}
        {corrections[fieldId] !== undefined && <Corrections fieldId={fieldId} />}
      </div>
      <div className="flex-row align-center justify-center">
        {status === 'Review' && (
          <Icon
            className="margin-1"
            style={{
              fontSize: 24,
              ...(corrections[field.id] !== undefined
                ? { color: 'red', padding: '0.5rem' }
                : { color: 'grey', padding: '0.5rem' }),
            }}
            type="flag"
            onClick={corrections[fieldId] === undefined ? () => set('') : () => set()}
          />
        )}
      </div>
    </div>
  );
}

const WrappedField = connect(sToP, dToP)(Field);
export default WrappedField;
