import {useContext, useEffect} from 'react'
import {useDBSubscribe} from '../../effects'
import DB from '../../DAL/DB'
import {auth} from '../../Auth'
import {ReducerContext} from '../reducer'

const forms = new DB.Forms()

export default function useGetForms() {
  const {state, dispatch} = useContext(ReducerContext)

  const fo = useDBSubscribe(forms, {filters: [['organization.id', '==', auth.sunkaizenUser.organization.id]]})

  useEffect(
    function () {
      const ft = {...state.filterTypes}
      ft.type.options = fo.map((form) => form.title).filter((i) => i)
      dispatch({type: 'filterTypes', value: ft})
    },
    [fo]
  )
}
