import {cancelIntakeReasons} from './cancelIntakeReasons'

export function cancelIntake(project, cancellation, selected, noIncrement) {
  if (!project.inspection) return

  if (!noIncrement) {
    const {
      inspector: {name, id},
      date: canceledDate,
    } = project.inspection
    const intakeCancellation = {...cancellation, canceledDate, inspector: {name, id}}
    project.intakeCancellations = [...(project.intakeCancellations || []), intakeCancellation].filter((i) => i)
  }

  project.inspection = {inspector: project.inspection.inspector}
  project.status = 'To Be Scheduled'

  project.needsRescheduling = cancelIntakeReasons[selected].options.required.includes('reschedule')
}
