import React, {useState} from 'react'
import DB from '../../../DAL/DB'
import emailReport from './emailReport'
import {Icon, Modal, Input} from 'antd'
import {saveAs} from 'file-saver'
import DeleteButton from '../../../DeleteButton'
import {store} from '../../../Reports/ReportsList'

const reports = new DB.Reports()
async function deleteReport(id) {
  await reports.delete(id)
}

function Actions({url, record}) {
  const [recipientEmails, setRecipientEmails] = useState([])
  const [modal, setModal] = useState(null)
  const [eReport, setEReport] = useState(null)
  const [loading, setLoading] = useState(false)

  function wrapLoading(asyncFunction) {
    setLoading(true)
    return asyncFunction()
      .then((res) => res)
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="actions flex-row">
      <Icon type="download" onClick={() => saveAs(url)} />
      <div style={{padding: '0.5rem'}}></div>
      <DeleteButton
        customRender
        collection="reports"
        documentId={record.id}
        onConfirm={() => wrapLoading(async () => await deleteReport(record.id))}
      >
        <Icon type="delete" style={{cursor: 'pointer'}} />
      </DeleteButton>
      <div style={{padding: '0.5rem'}}></div>
      <Icon
        type="mail"
        onClick={() => {
          setEReport(record)
          setModal('email')
        }}
      />
      <Modal
        title="Email Report"
        visible={modal === 'email'}
        onOk={() => {
          return wrapLoading(() => emailReport(eReport, recipientEmails, () => setModal(null)))
        }}
        onCancel={() => setModal(null)}
        confirmLoading={loading}
      >
        <p>Send a copy of this report to up to 10 addresses separated by commas or semicolons</p>
        <Input placeholder="Email" onChange={(event) => setRecipientEmails(event.target.value)}></Input>
      </Modal>
    </div>
  )
}

export default function columnsMap() {
  return [
    {
      dataIndex: `name`,
      title: `Title`,
      sorter: (a, b) => (a.title || ``).localeCompare(b.title || ``),
    },
    {
      dataIndex: `created`,
      title: `Created`,
      render: (text) => {
        return text ? text.toDate().toDateString() : null
      },
      sorter: (a, b) => a.created.toDate().getTime() - b.created.toDate().getTime(),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: 'Actions',
      key: 'url',
      dataIndex: 'url',
      render: (url, record) => <Actions {...{url, record}} />,
    },
  ]
}
