import Papa from 'papaparse'
import formatProjects from './formatProjects'

export default function convert(projects) {
  const rows = formatProjects(projects)

  if (!rows) throw new Error(`could not generate CSV rows`)

  const csv = Papa.unparse(rows)
  const blob = new Blob([csv], {type: 'text/csv'})
  return blob
}
