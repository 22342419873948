import React, {useEffect, useState} from 'react'
import {db} from './utilities'
import {Spin} from 'antd'
import {Redirect} from 'react-router-dom'

import {auth} from './Auth'

const NewLogin = ({match}) => {
  const [params, setParams] = useState({}) // eslint-disable-line
  const [valid, setValid] = useState(null)

  useEffect(() => {
    const {email, code} = match.params

    db.collection(`email_links`)
      .doc(code)
      .onSnapshot((snap) => {
        const validation = snap.data()
        setValid(email === validation.email)

        if (email === validation.email) {
          auth.sendPasswordResetEmail(email)
          setTimeout(() => setValid(`redirect`), 5000)
        }
      })

    setParams({email, code})
  }, [])

  switch (valid) {
    case `redirect`:
      return <Redirect to="/login" />
    case true:
      return (
        <div style={{display: `flex`, flexFlow: `column`, width: `100%`}}>
          <h1 style={{padding: `3rem 3rem 1rem 3rem`}}>
            Please check your email inbox. We have sent an email that will allow you to set your password
          </h1>
          <h4>(Redirecting you to the login page momentarily)</h4>
        </div>
      )
    case null:
      return (
        <h1 style={{padding: `3rem`}}>
          Validating your code...
          <Spin style={{paddingLeft: `2rem`}} size="large" />
        </h1>
      )
    case false:
      return <h1 style={{padding: `3rem`}}>You have submitted an invalid code</h1>
    default:
      return <h1 style={{padding: `3rem`}}>Something went wrong; try refreshing your browser</h1>
  }
}

export default NewLogin
