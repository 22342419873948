
import {useDBSubscribe} from '../../effects'
import DB from '../../DAL/DB'
import {store} from '../utilities'

const rubrics = new DB.Rubrics()

export default function readRubrics(id) {
  if (!id) return
  
  const rr = useDBSubscribe(rubrics, {filters: [['formId', '==', id]]})
  const rMap = {}
  for (let r of rr) {
    rMap[r.id] = r
  }
  if (rr) store.dispatch({type: 'rubrics', value: rMap})
  
}
