import React from 'react'
import {Droppable} from 'react-beautiful-dnd'
import {connect} from 'react-redux'
import Field from './Field'
import {updateFieldsState} from '../action_creators'
import conditionGroupValidation from './utils'

const sToP = (state, op) => ({
  dragging: state.active.dragging,
  field: state.fields[op.fieldId],
  state: state,
  // field: state.fields[op.fieldId]
})

const getStyle = (snapshot) => {
  return snapshot.isDraggingOver
    ? {
        border: '0.2rem dashed lightblue',
        borderRadius: '0.3rem',
      }
    : {}
}

const dToP = (dispatch) => ({
  updateFieldsState: (value) => dispatch(updateFieldsState(value)),
})

const baseStyle = {border: '0.1rem solid lightgrey', zIndex: 10, marginBottom: '5px', minHeight: '150px',borderRadius:'8px'}

export function DnDWrapper({fieldId, dragging, field, state, updateFieldsState}) {
  // field, key,
  const style = (snapshot) => (dragging ? {...baseStyle, ...getStyle(snapshot)} : baseStyle)

  const isParentDisplay = (questionID) => {
    const {fields} = state
    if (fields[questionID] && fields[questionID].dependentQue && fields[questionID].dependentQue.selectedQuestion) {
      const {selectedQuestion, selectedOption} = fields[questionID].dependentQue
      if (selectedQuestion && selectedOption) {
        const values =
          fields[selectedQuestion] && fields[selectedQuestion].value && fields[selectedQuestion].value.values
        if (values && values[selectedOption]) {
          return true
        } else {
          return false
        }
      }
    } else {
      return true
    }
  }

  const { cgList, operand } = field && field.conditionGroup && field.conditionGroup || {cgList: [],operand: "" }

  let isFieldDisplay = true

  if(cgList && cgList.length > 0) {
    isFieldDisplay = false
    const data = cgList[0].conditionList && cgList[0].conditionList[0]
    //included additional condition when associated actions were deleted
    const {selectedQuestion, selectedOption} = data || {selectedQuestion : '', selectedOption: ''}
    if (selectedQuestion) {
      const {fields} = state
      
     // const values = fields[selectedQuestion] && fields[selectedQuestion].value
      if (selectedOption) {
        if (fields && conditionGroupValidation(cgList, fields, operand)) {
          if (isParentDisplay(selectedQuestion) === undefined || isParentDisplay(selectedQuestion)) {
            isFieldDisplay = true
          } else {
            isFieldDisplay = false
            delete fields[selectedQuestion].value
            updateFieldsState(fields)
          }
        } else {
          isFieldDisplay = false
           delete fields[fieldId].value
          updateFieldsState(fields)
        }
      }
      if (!isFieldDisplay) {
        return ''
      }
    }
  }


  return (
    <div>
      {fieldId && (
        <Droppable droppableId={fieldId}>
          {(provided, snapshot) => (
            <div className="flex-row padding-2" style={style(snapshot)} ref={provided.innerRef}>
              <Field {...{fieldId, provided, snapshot}} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  )
}

const WrappedDnDWrapper = connect(sToP, dToP)(DnDWrapper)
export default WrappedDnDWrapper
