import {staticMapKey} from '../../../config'
import {storage} from '../../../utilities'

export default async function getStaticMapThumbnail(site) {
  const {lat, lng} = site.geocode_results.geometry.location

  const mapUrl =
    `https://maps.googleapis.com/maps/api/staticmap` +
    `?center=${lat},${lng}` +
    `&zoom=13&size=500x200` +
    `&maptype=roadmap` +
    `&markers=color:green%7C${lat},${lng}` +
    `&key=${staticMapKey}`

  const res = await fetch(mapUrl)
  const b = await res.blob()

  const imageRef = storage.ref().child(`siteThumbnails/${site.id}`)
  await imageRef.put(b)

  const {bucket, fullPath, name} = imageRef
  site.thumbnail = {bucket, fullPath, name}
  return site
}
