const validUserTypes = {client: true, 'field-user': true, 'quality-manager': true, scheduler: true} // 'installer': true,

export function validateType(userArray) {
  for (let i in userArray) {
    userArray[i].type = userArray[i].type.toLowerCase()
    if (!validUserTypes[userArray[i].type])
      userArray[i].errors = {
        ...(userArray[i].errors || {}),
        type: `Invalid type supplied (${userArray[i].type})`,
      }
  }
}
