import React from 'react'
import {Card, Button} from 'antd'
import {connect} from 'react-redux'
import {reversedComponentDisplayNames} from '../FieldTypes'
import {edit} from '../action_creators'
import {PropertyBox} from '../PropertyBoxes'

const stop = (state) => ({
  field: state.fields[state.active.field] || {},
  active: state.active.field,
})

const dtop = {edit}

export function FieldDetail({active, field, edit}) {
  return (
    <div className="full-width">
      {active && (
        <Card
          style={{width: '100%'}}
          title={
            <div className="flex-row justify-space-between">
              {/* make the field type an invisible dropdown or a modal to select a different field type */}
              <div>Edit {reversedComponentDisplayNames[field.type]} Properties</div>
              <Button title="Edit" onClick={() => edit(false)} icon="edit" size="large" />
            </div>
          }
        >
          <PropertyBox type={field.type} id={field.id} />
        </Card>
      )}
    </div>
  )
}

const WrappedFieldDetail = connect(stop, dtop)(FieldDetail)
export default WrappedFieldDetail
