export default function validateQuestions(rubrics, sections) {
  let invalidQuestions = {}

  const questions = Object.values(sections || {})
    .map((section) =>
      Object.values(section.fields || {}).filter((field) =>
        [`radio-button`, `dropdown`, `templated`, 'multiselect','checkboxes'].includes(field.type)
      )
    )
    .flat()

  const questionsMap = {}
  for (let q of questions) {
    questionsMap[q.id] = q
  }

  for (let i in rubrics) {
    const rubric = rubrics[i]
    const {questions: qq} = rubric

    console.log(qq)

    for (let j in qq) {
      const rQuestion = qq[j]
      if (!rQuestion.criticalityCategory) continue

      const sQuestion = questionsMap[j]

      console.log(sQuestion, j)

      let {error: bFError, ok} = _blankFields(sQuestion, rQuestion, rubric.name)
      if (!ok) invalidQuestions[j] = {error: bFError, rubric, question: rQuestion}

      let {error: pFError, ok: ok2} = _passFail(rQuestion, sQuestion, rubric.name)
      if (!ok2) invalidQuestions[j] = {error: pFError, rubric, question: rQuestion}
    }
  }

  return Object.keys(invalidQuestions).length ? invalidQuestions : null
}

function _blankFields(sQuestion, rQuestion, rubric) {
  let [error, ok] = [null, true]
  console.info(
    '_blankFields',
    sQuestion,
    rQuestion,
    (sQuestion && sQuestion.options || []).length,
    (Object.values(rQuestion.options) || []).length
  )

  let isBlankFields = false
  if(sQuestion && sQuestion.options && sQuestion.options.length > 0){
    sQuestion.options.forEach(rec => {
      if(!isBlankFields && !rQuestion.options[rec.label]) {
        isBlankFields = true
        console.info('The below field had blank Fields', rec.label)
      }
    })
  }
  if (isBlankFields) {
    console.info('_blankFields')
    ok = false
    error = sQuestion && sQuestion.label || ""
      ? `${sQuestion && sQuestion.label || ""} for rubric ${rubric} has blank fields`
      : `rubric ${rubric} is missing a label`

  }


  return {error, ok}
}

function _passFail(rQuestion, sQuestion, rName) {
  let [error, ok] = [null, true]

  let [pass, fail] = [0, 0]
  for (let o in rQuestion.options) {
    switch (rQuestion.options[o]) {
      case 'Pass':
        pass++
        break
      case 'Fail':
        fail++
        break
      case 'Ignore':
        break
      default:
        throw new Error(`option key is not Pass, Fail, or Ignore: ${rQuestion.options[o]}`)
    }
  }

  if (pass < 1 || fail < 1) {
    error = `
            a question (${sQuestion.question || 'missing question text'}) for ${rName} 
            doesn't have at least one pass ${pass} or one fail ${fail}
        `
    ok = false
  }

  return {error, ok}
}