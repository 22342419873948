import React from 'react'
import { Select, Input } from 'antd'
import DeleteButton from '../../../DeleteButton'

// User can create actions. New Tab called Actions in form creation
const Condition = ({
  currentIndex,
  condition,
  fields,
  id,
  order,
  sections,
  field,
  conditionQuestions,
  handleConditionSelection,
  handleConditionSelectionV1,
  handleDeleteCondition,
  handleAddGroupCondition,
  conditionIndex
}) => {

  const displaySelectedQuestionOptions = () => {
    const rec = condition.selectedQuestion;
    let selectedQuesitonOptions = []
    if (condition  && condition.selectedQuestion) {
      selectedQuesitonOptions =
          allSectionFields[rec] &&  allSectionFields[rec].options && allSectionFields[rec].options.map((x) => x.label) || []
    }

    return selectedQuesitonOptions.map((rec,index) => (
      <Select.Option index={index} value={rec} key={`selectedQuesOption-${rec}`}>
        {rec}
      </Select.Option>
    ))
  }

  const findEligibleCrossRefFilter = (sectionEligibleQuestions) => {
    const foundRecId = Object.keys(fields).find(
      (x) => fields[x] && fields[x].dependentQue && fields[x].dependentQue.selectedQuestion === field.id
    )
    if (foundRecId) {
      return sectionEligibleQuestions.filter((x) => x !== foundRecId)
    }
    return sectionEligibleQuestions
  }

  const findAllFields = () => {
    let allSectionFields = [];
    Object.keys(sections).forEach(rec => {
      if(sections[rec].fields) {
        Object.keys(sections[rec].fields).forEach(xx => {
          allSectionFields[xx] = sections[rec].fields[xx]
        })
       
      }
    })
  // condition component loads in form building and rubric settings
  // section props did not have fields in the form building time, 
   return (Object.keys(allSectionFields).length > 0) ? allSectionFields : fields
  }

  const allSectionFields = findAllFields()

  
  const handleEligilbleControls = (order, fields) => {
    const sectionEligibleQuestions = []
    const eligibleControlTypes = ['dropdown', 'radio-button', 'checkboxes', 'templated', 'multiselect', 'numeric-input']
    // in below filter we are excluding current question in the dropdown

    const orders = Object.values(sections).map((s) => {
      return s.order
    })

    const ids = [].concat.apply([], orders)

    ids
      .filter((x) => x !== field.id)
      .forEach((rec) => {
        if (eligibleControlTypes.find((x) => allSectionFields[rec] && x === allSectionFields[rec].type)) {
          sectionEligibleQuestions.push(rec)
        }
      })
     return findEligibleCrossRefFilter(sectionEligibleQuestions)
   }

  function deleter() {
    handleDeleteCondition(conditionIndex)
  }

  const deleteProps = {
    confirmationDisplay: 'Are you sure you want to delete this condition?',
    icon: 'delete',
    size: 'large',
    title: 'Delete',
    type: 'secondary',
    onConfirm: deleter,
  }
  
  const type = condition && condition.selectedQuestion && 
               fields[condition.selectedQuestion] && fields[condition.selectedQuestion].type ? fields[condition.selectedQuestion].type : ''
  const isQuestionSelection = condition.selectedQuestion ? true: false

  let questionList = []
  //resetting selected question value if respective question is deleted
  if(condition && condition.selectedQuestion) {
    questionList = handleEligilbleControls(order, fields)
    const isFound = questionList.find(x => x === condition.selectedQuestion) 
    if(!isFound){
      handleConditionSelection("selectedQuestion", conditionIndex, '')
    }
  }
  //resetting option value if respective value is deleted
  if(type !== "numeric-input") {
    if(condition && condition.selectedOption) {
      const rec = condition.selectedQuestion;
      let selectedQuesitonOptions = []
      if (condition  && condition.selectedQuestion) {
        selectedQuesitonOptions =
            allSectionFields[rec] &&  allSectionFields[rec].options && allSectionFields[rec].options.map((x) => x.label) || []
      }
      const isFound = selectedQuesitonOptions.find(x => x === condition.selectedOption) 
      if(!isFound){
        condition.selectedOption = ''
        condition.selectedOptionIndex = ''
      }
    }
  }
  
  return (
    <React.Fragment>
      <div className='condition'>
        <span>Display when</span>
        <Select
          className='condition-question'
           key={`select-${condition.selectedQuestion}`}
           defaultValue={condition.selectedQuestion}
           value={condition.selectedQuestion}
           placeholder={`Please select question`}
           onChange={(e) => handleConditionSelection("selectedQuestion", conditionIndex, e)}
        >
        <Select.Option value="" key={`--Select Question--`}>{`-- Select Question--`}</Select.Option>
          {handleEligilbleControls(order, fields).map((rec) => (
            <Select.Option value={allSectionFields[rec] ? allSectionFields[rec].id : '-no id found-'} key={rec}>
              {allSectionFields[rec] ? allSectionFields[rec].question : ''}
            </Select.Option>
          ))}
        </Select>
        {type !== "numeric-input" ?
          <Select
            className='condition-operand'
            title={`Select Operand`}
            placeholder={`Select Operand`}
            disabled={!isQuestionSelection}
            key={`operand-${condition.selectedOperand}`}
            defaultValue={condition.selectedOperand}
            onChange={(e) => handleConditionSelection("selectedOperand", conditionIndex, e)}
          >
             <Select.Option key={`--select--`} value="">
              {`--Select Operand--`}
            </Select.Option>
            <Select.Option key={`is`} value={`is`}>
              {`is`}
            </Select.Option>
            <Select.Option key={`isnot`} value={`isnot`}>
              {`is not`}
            </Select.Option>
          </Select>
          :
          <Select
            className='condition-operand'
            disabled={!isQuestionSelection}
            title={`Select Operand`}
            placeholder={`Select Operand`}
            defaultValue={condition.selectedOperand}
            key={`operand-${condition.selectedOperand} `}
            onChange={(e) => handleConditionSelection("selectedOperand", conditionIndex, e)}
          >
            <Select.Option key={`--select--`} value="">
              {`--Select Operand--`}
            </Select.Option>
            <Select.Option key={`lessThan`} value={`lessThan`}>
              {`<`}
            </Select.Option>
            <Select.Option key={`greaterThan`} value={`greaterThan`}>
              {`>`}
            </Select.Option>
            <Select.Option key={`equal`} value={`equal`}>
              {`=`}
            </Select.Option>
          </Select>
        }

        {type !== "numeric-input" ?
          <Select
            className='condition-option'
            title={`Select Option`}
            placeholder={`Select Option`}
            disabled={!isQuestionSelection}
            key={`option-${condition.selectedOption}`}
            onChange={(value, options)=>handleConditionSelectionV1("selectedOption", options, conditionIndex,value)}
            // onChange={(e) => handleConditionSelection("selectedOption", conditionIndex, e)}
            defaultValue={condition.selectedOption}
           value={condition.selectedOption}
          >
            <Select.Option value="" key={'--select--'}>
              {`-- Select Option-- `}
            </Select.Option>
            {displaySelectedQuestionOptions()}
          </Select>
          :
          <Input type="text"  
            className='condition-option'
            key={`option-${condition.selectedOption}`}
            disabled={!isQuestionSelection}
            title='Please enter Numeric values'
            placeholder='Please enter Numeric values'
            defaultValue={condition.selectedOption}
            onBlur={e => {
                const value = e.target.value.replace(/[^\d,.]/g, '');
                if (value.indexOf('.') > -1 && value.indexOf('.') !== value.length - 2){
                     alert('Please enter numbers with single decimal only.')
                }
                if(!value && e.target.value){
                  alert('Please enter numbers only.')
                } else {
                  handleConditionSelection("selectedOption", conditionIndex, e.target.value)
                }
              }
            }
          />
        }
        <DeleteButton {...deleteProps} data-cy="deleteBtn" />
      </div>
    </React.Fragment>
  )
}

export default Condition
