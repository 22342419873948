import BaseModel from './BaseModel'
import {db, firebase} from '../utilities'

export default class Projects extends BaseModel {
  constructor() {
    super(db.collection('projects'))
  }

  // normalize receives an array of resources, even if the array only has one object
  normalize(snapshotArray: firebase.firestore.DocumentSnapshot[]): (firebase.firestore.DocumentData | undefined)[] {
    const da: (firebase.firestore.DocumentData | undefined)[] = snapshotArray.map((r) => this.fromDatabase(r))

    return da
  }

  // denormalize expects to receive only one project at a time
  //  because it's run with the update method
  denormalize(project: firebase.firestore.DocumentData): firebase.firestore.DocumentData {
    return this.toDatabase(project)
  }

  fromDatabase(snapshot: firebase.firestore.DocumentSnapshot): firebase.firestore.DocumentData | undefined {
    if (snapshot.exists) {
      const project: firebase.firestore.DocumentData = snapshot.data() || {}

      // convert a single project
      const {
        intake: {sections},
      } = project

      for (let i in sections) {
        const section = sections[i]
        let fields: firebase.firestore.DocumentData = {}
        if (Array.isArray(section.fields)) {
          for (let field of section.fields) {
            fields[field.id] = field
          }
          section.fields = fields
        }
      }

      return project
    }
  }

  // this is only here to balance fromDatabase
  toDatabase(project: firebase.firestore.DocumentData): firebase.firestore.DocumentData {
    // convert a project back to a database-ready format
    const {sections} = project.intake || {}
    // this is a dangerous hack that allows projects through that hae no intake
    //      it's here to allow merge updates that don't contain a full project
    if (!sections) return project

    for (let i in sections) {
      const section = sections[i]
      section.fields = Object.values(section.fields || {})
    }
    return project
  }
}
