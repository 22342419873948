import {store} from '../../utilities'

export default function addTipText(project, row) {
  const state = store.getState()
  const {order} = state.bulk

  if (!order) throw new Error('template has no order')

  console.log(row)

  let {tipText} = row
  tipText = tipText.slice(7)

  const fieldMaps = {}
  Object.values(project.intake.sections).forEach(
    (section) => (fieldMaps[section.id] = (section.fields || []).map((f) => f.id))
  )

  order.forEach(({sectionId, fieldId}, i) => {
    const value = tipText[i]

    if (value) {
      const fIndex = fieldMaps[sectionId].indexOf(fieldId)
      if (fIndex > -1) {
        project.intake.sections[sectionId].fields[fIndex].tipText = value
      }
    }
  })

  return project
}
