import {useContext, useEffect, useState} from 'react'
import {ReducerContext} from '../reducer'

function matchData(dropdown, datum) {
  if (dropdown.orgRole === datum.role && dropdown.rubric === datum.rubric && dropdown.form === datum.form) return true
}

const defaultData = [{date: null, score: null}]

export default function useGraphData() {
  const [data, setData] = useState([])
  const {state, dispatch} = useContext(ReducerContext)

  const {orgRole, rubric, form} = state.dropdown

  // Converts projectAverages to the form that the graph wants
  useEffect(
    function () {
      if (!orgRole || !rubric || !form) {
        console.log('resetting plot data:')
        dispatch({type: 'graphData', value: defaultData})
        return
      }

      console.log('Updating plot data... ')
      const data = state.projectAvgs
        // .filter(d => matchData(state.dropdown, d))
        .map((d) => ({
          score: parseFloat(d['30_day'].toFixed(3)),
          date: d.date,
          projects_included: d['30_day_count'],
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date))

      dispatch({type: `graphData`, value: data})
    },
    [orgRole, rubric, form, state.projectAvgs]
  )

  console.log('state:', state)
  return data
}
