import DB from '../../../DAL/DB'

const projects = new DB.Projects()

export default function makeBatches(dbOps) {
  const batchJobs = []
  for (let i = 0; i < dbOps.length; i = i + 400) {
    const b = dbOps.slice(i, i + 400)
    const batcher = new DB.FirebaseBatch()

    b.forEach(({id, data}) => {
      batcher.update(projects, id, data)
    })
    batchJobs.push(batcher)
  }
  return batchJobs
}
