import rootReducer from './reducer'
import {createStore, applyMiddleware, compose} from 'redux'
// import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'


const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// const loggerMiddleware = createLogger()
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware))) // , loggerMiddleware
