import columns from './columns'
import onRow from './onRow'
import permissions from './permissions'
import sorter from './sorter'
import rowSelection from './rowSelection'
import or from './or'

import AddProjectButton from '../../../ProjectBuilder/ProjectCreator/AddProjectButton'
import AddBulkButton from '../../../ProjectBuilder/BulkProvider/AddBulkButton'

// import {organizationFilter} from '../utilities'

const config = {
  columns,
  onRow,
  permissions,
  or,
  sorter,
  rowSelection,
  type: 'projects',
  searchbar: true,
  savedFilters: true,
  tabs: 'status',
  bulk: AddBulkButton,
  create: AddProjectButton,
  // postProcess: [hideCanceled],
  // preProcess: [organizationFilter]
}

export default config
