import _ from 'lodash'

import {db} from '../../utilities'

/**
 * ## Paths
 *
 * The structure for `paths` should resemble the following:
 *
 *      [{collection: `projects`}] // Same as db.collection(`projects`)
 *
 *      [{collection: `projects`, doc: `2hf78e742hdy4i7es4y`}]
 *      // Same as db.collection(`projects`).doc(`2hf78e742hdy4i7es4y`)
 *
 *      [{collection: `projects`, doc: `2hf78e742hdy4i7es4y`}, {collection: `admins`}]
 *      // Same as db.collection(`projects`).doc(`2hf78e742hdy4i7es4y`).collection(`admins`)
 *
 * ## Options
 *
 * The structure for `options` depends on the operation you want to perform.
 * Unless otherwise indicated, all option fields are expected to be arrays, as
 * many operations can be performed multiple times in firebase. The following
 * operations are supported:
 *
 * ### Where
 *
 * Filters documents:
 *
 *      {where: [{field: `name`, test: `==`, value: `John`}]}
 *
 * ### Order
 *
 * Returns documents in sorted order, either `asc` or `desc`:
 *
 *    {order: [{field: `name`, direction: `desc`}]}
 *
 *    {order: `name`} // Sorts in ascending order on the given field, and only that field.
 *
 * You must use the array of objects structure for multiple sorts or descending
 * sorts.
 */
export function buildQuery({paths, options}) {
  let query = buildQueryFromPaths(paths)

  if (options) {
    query = addOptionsToQuery(query, options)
  }

  return query
}

function buildQueryFromPaths(paths) {
  return paths.reduce((acc, pathObject) => {
    const {collection, doc} = pathObject

    let query = acc.collection(collection)
    if (doc) {
      query = query.doc(doc)
    }
    return query
  }, db)
}

function addOptionsToQuery(query, options) {
  const {where, order} = options

  if (where) {
    query = addWhereFilteringToQuery(query, where)
  }
  if (order) {
    query = addResultOrderingToQuery(query, order)
  }

  return query
}

function addWhereFilteringToQuery(query, where) {
  return where.reduce((acc, whereObject) => {
    const {field, test, value} = whereObject
    return acc.where(field, test, value)
  }, query)
}

// Supports an order query as either a single string for the field to sort
// ascending on, or an array of objects containing a `field` and the `direction`
// to order it. See `buildQuery` docs.
function addResultOrderingToQuery(query, order) {
  if (_.isString(order)) {
    return query.orderBy(order)
  } else {
    return order.reduce((acc, orderObject) => {
      const {field, direction} = orderObject
      return acc.orderBy(field, direction)
    }, query)
  }
}

/**
 * `makeFunction(paths)` should return another function that performs some
 * database operation on the path specified. See implementation code for
 * inspiration.
 *
 * Returns a function for every functionName->path defined in
 * `mapFunctionsToPaths`, by calling `makeFunction` on each `paths` value.
 */
export function createFunctionsFromPathsMapping({mapFunctionsToPaths, makeFunction}) {
  const functions = {}

  for (let functionName in mapFunctionsToPaths) {
    const paths = mapFunctionsToPaths[functionName]
    const func = makeFunction(paths)
    functions[functionName] = func
  }

  return functions
}

// Example `operationName`: "delete". Example `operationFunctionName`: "deletion".
export function pathMustNotEndInADocumentMsg(operationName, operationFunctionName, lastPath) {
  return `You cannot ${operationName} a document through your paths definition. Instead, provide the document (id) to the ${operationName} function itself, and leave your terminal path an object with only a 'collection' property. Here's the failing path object: ${JSON.stringify(
    lastPath
  )}`
}

/**
 * Takes a database `paths` array (from the syntax defined in this module) and
 * sets the document on the terminal path. This can help facilitate reads,
 * deletions, and updates.
 */
export function setTerminalDocumentForPaths(paths, id) {
  const lastPathObject = _.last(paths)
  const leadingPathObjects = _.dropRight(paths, 1)
  return [...leadingPathObjects, {...lastPathObject, doc: id}]
}
