import {useEffect, useContext} from 'react'
import {ReducerContext} from '../reducer'
import {useDBSubscribe} from '../../effects'
import DB from '../../DAL/DB'

const analytics = new DB.Analytics()

// get analytics metadata
export default function useGetAnalayticsMetadata() {
  const {state, dispatch} = useContext(ReducerContext)

  const data = useDBSubscribe(analytics, {
    filters: [['form_id', '==', state.dropdown.form || 'non-existant form']],
  })

  let transformedData = {}

  data.forEach((record) => {
    transformedData[record.id] = record
  })

  useEffect(
    function () {
      dispatch({type: `analytics`, value: transformedData})
    },
    [data]
  )
}
