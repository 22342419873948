import {store} from '../../utilities'
import {useEffect} from 'react'
import {setDropdowns} from '../../action_creators'

export default function useOrganizationEffect(subscribe, subscribeResources) {
  useEffect(function () {
    return subscribe({paths: [{collection: 'organizations'}]}, 'organizations')
  }, [])

  useEffect(
    () => {
      if (subscribeResources.organizations) {
        const t = subscribeResources.organizations.filter(each => !each?.disabled && each?.name !== null)
        store.dispatch(setDropdowns('organizations', t))
      }
    },
    [subscribeResources.organizations]
  )
}
