import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {read} from '../HOC/Firebase/ReadHOC'
import Header from './Header'
import EditWall from './EditWall'
import FormEdit from './FormEdit'
import {edit} from './action_creators'
import {activateSection, activateField, readActions, readForm, 
  readSections, 
  readRubrics,
  resourcesReturned} from './effects'
import './Forms.css'

const stop = (state) => ({form: state.form, active: state.active})
const dtop = {edit}

export function TopLevelState({form, edit, active, readDB, readResults, match}) {
  useEffect(activateSection, [active.section])
  useEffect(activateField, [active.field])

  useEffect(readForm(readDB, match.params.id), [])
  useEffect(readSections(readDB, match.params.id, form.id), [form.id])
  useEffect(readActions(readDB, match.params.id, form.id), [form.id])

  useEffect(resourcesReturned(readResults), [readResults])
  readRubrics(match.params.id)

  return (
    <div
      // this closes a field-editing window when the user clicks outside it
      onClick={() => {
        edit(null)
      }}
      className="pageContainer"
    >
      <Header />
      <div>{form.active ? <EditWall /> : <FormEdit />}</div>
    </div>
  )
}

const WrappedFormEdit = read()(connect(stop, dtop)(TopLevelState))
export default WrappedFormEdit
