import React, {useContext} from 'react'
import {ReducerContext} from '../reducer'
import {List} from 'antd'
import {pushData} from '../utilities'
import ProjectBrief from './ProjectBrief'

export default function Projects() {
  const {state, dispatch} = useContext(ReducerContext)
  const push = pushData({state, dispatch})

  const {id} = state.project

  return (
    <div className="full-height full-width">
      <List
        style={{height: '70rem', overflow: 'scroll'}}
        dataSource={Object.values(state.projects)}
        loading={!Object.keys(state.projects)}
        renderItem={(project) => <ProjectBrief id={id} push={push} project={project} />}
      />
    </div>
  )
}
