import DB from '../../DAL/DB'

const subscriptions = new DB.Subscriptions()

export default async function deleteSubscription(id) {
  try {
    await subscriptions.delete(id)
  } catch (e) {
    console.log(`error deleting subscriptions: `, e)
  }
}
