import {useState, useEffect} from 'react'

export default function useAutocomplete(autocompleteRef) {
  const [autocomplete, setAutocomplete] = useState(null)

  function doAutocomplete(e) {
    if (autocomplete && autocomplete.getBounds) autocomplete.getBounds()
  }

  useEffect(function () {
    (async function () {
      window.addEventListener(`bounds_changed`, doAutocomplete)
      const autocomplete = await new window.google.maps.places.Autocomplete(autocompleteRef.current.input, {
        types: [`geocode`],
      })

      setAutocomplete(autocomplete)
    })()

    return () => window.removeEventListener('bounds_changed', doAutocomplete)
  }, [])

  return autocomplete
}
