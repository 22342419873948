import React from 'react'

const logoMessage =
  (logo = {}) =>
  () =>
    (
      <div style={{width: '100%'}}>
        {console.log(logo)}
        <div>Drop a PNG image here to replace your current company logo</div>
        <div style={{fontSize: 14}}>Your logo should be landscape-oriented, and will work best with a ratio of 3:2</div>
        <div style={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'center'}}>
          <img style={{width: '100%'}} src={logo.url} />
        </div>
      </div>
    )

export default logoMessage
