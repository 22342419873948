import React from 'react'

export default function CardTitle({question}) {
  return (
    <div>
      {question.label ? <strong>{question.label}</strong> : <i>No label</i>}
      <div style={{fontSize: `1.35rem`}}>{question.question}</div>
    </div>
  )
}
