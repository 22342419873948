import React from 'react'
import Multiple from './OptionPropertyBoxBase'
import Single from './SingleInputPropertyBoxBase'
import Templated from './TemplatedBase'

const componentNameMapping = {
  'single-input': Single,
  'numeric-input': Single,
  'text-area-field': Single,
  'radio-button': Multiple,
  dropdown: Multiple,
  checkboxes: Multiple,
  multiselect: Multiple,
  'date-input': Single,
  'time-input': Single,
  // "file-upload": Single,
  templated: Templated,
}

function PropertyBox({type, id}) {
  const C = componentNameMapping[type] || Single
  return <C id={id} />
}

export {PropertyBox}
