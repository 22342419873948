import React, {useEffect, useState} from 'react'
import {Table} from 'antd'
import {correctedURL} from '../../utilities'
import FileUploader from '../../FileHandling/FileUploader'
import {helperMessage} from './helperMessage'
import {submit} from './submit'
import {makeColumns} from './columns'
import StatusDisplay from './StatusDisplay'

export default function TopLevelState({project}) {
  const [files, setFiles] = useState({})
  const [statuses, setStatuses] = useState({})

  useEffect(
    function () {
      const pp = (project.intake.files || []).map((file) => {
        if (!file.fullPath) return null
        const url = correctedURL(file.fullPath)
        file.url = url
        return file
      })
      setFiles(pp)
    },
    [project.intake.files]
  )

  function updateStatus(newStatus) {
    setStatuses({...statuses, ...newStatus})
  }

  let k = 0
  const dataSource = Object.entries(files).map(([id, file]) => ({...file, key: ++k, id}))
  const columns = makeColumns(project.id)

  return (
    <div className="background-white margin-top-2">
      <StatusDisplay statuses={statuses.statuses} />
      <h3 className="padding-2 margin-0" style={{borderBottom: '0.1rem solid #DFE3E8'}}>
        Uploaded Documents
      </h3>
      <div className="padding-1">
        <Table {...{dataSource, columns}} />
      </div>
      <div className="padding-2">
        <FileUploader
          collection="projects"
          id={project.id}
          type="file"
          updateStatus={updateStatus}
          helperMessage={helperMessage}
          submit={submit(updateStatus, project.id)}
        />
      </div>
    </div>
  )
}
