import {auth} from '../Auth'
import {store} from './reducer'
import DB from '../DAL/DB'
import {useDBSubscribe, useDBSubscribeOne} from '../effects'

const forms = new DB.Forms()
const scoringSystems = new DB.ScoringSystems()
const rubrics = new DB.Rubrics()

const useSubscriptions = async (id) => {
  if (!id) return () => null

  const form = useDBSubscribeOne(forms, id)

  if (form) {
    store.dispatch({type: 'form', value: form})
  }

  const ss = useDBSubscribe(scoringSystems, {filters: [['organization.id', '==', auth.sunkaizenUser.organization.id]]})
  if (ss) store.dispatch({type: 'scoringSystems', value: ss})

  const rr = useDBSubscribe(rubrics, {filters: [['formId', '==', id]]})
  const rMap = {}
  for (let r of rr) {
    rMap[r.id] = r
  }
  if (rr) store.dispatch({type: 'rubrics', value: rMap})
}

export {useSubscriptions}
