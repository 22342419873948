import React, {useState, useEffect} from 'react'
import {Modal} from 'antd'
import {connect} from 'react-redux'
import {history} from '../utilities'
import {setFailures, setProject, setActive, setTicket, reset} from './action_creators'
import states from './states'

const stop = (state) => ({state: state.meta.active})
const dtop = {setActive, setProject, setFailures, setTicket, reset}

export function TicketModal({mode, ticket, project, state, setActive, setProject, setFailures, setTicket, reset}) {
  const [show, setShow] = useState(false)
  const {title, ok, okText, view: V, button: B, okButtonDisabled} = states[state]

  useEffect(
    function () {
      if (!show) {
        reset(mode)
      }
    },
    [show]
  )
  useEffect(
    function () {
      if (mode) setActive(mode)
    },
    [mode]
  )
  useEffect(
    function () {
      if (ticket) setTicket(ticket)
      return () => setTicket({})
    },
    [ticket]
  )
  useEffect(
    function () {
      if (mode && !project) return
      if (!project) {
        history.push('/tickets')
        return
      }

      if (!show) return
      setProject(project)
      if (ticket && ticket.failure) {
        setFailures([ticket.failure])
      }
    },
    [project, show]
  )

  return (
    <div>
      <B setShow={setShow} />
      <Modal
        className="ticketing modal"
        width="70%"
        title={title}
        visible={show}
        onOk={() => {
          ok()
          if (state === 'update') setShow(false)
        }}
        onCancel={() => setShow(false)}
        okText={okText}
        okButtonProps={{disabled: okButtonDisabled()}}
      >
        <V />
      </Modal>
    </div>
  )
}

const WrappedModal = connect(stop, dtop)(TicketModal)
export default WrappedModal
