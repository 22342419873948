import React from 'react'
import NewTable from '../UnifiedList/Provider'

export default function FormsList() {
  return (
    <div className="page-container padding-2">
      <NewTable strategy="forms" />
    </div>
  )
}
