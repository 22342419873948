import useGetForms from './useGetForms'
import useGetCSVFilters from './useGetCSVFilters'
import useGetUsers from './useGetUsers'
import useGetSubscriptions from './useGetSubscriptions'
import useGetProjectStatuses from './useGetProjectStatuses'
import useGetClients from './useGetClients'

export default function useGetResources() {
  useGetForms()
  useGetCSVFilters()
  useGetUsers()
  useGetSubscriptions()
  useGetProjectStatuses()
  useGetClients()
}
