import {auth} from '../../Auth'

const brandingMap = {
  'IBTS Logo': {ibts: true},
  'IBTS + Client Logo': {ibts: true, client: true},
  'Client Logo': {client: true},
}

export function makeBody(options) {
  const {branding, intakePhotos, projectHistoryLog, otherProjectAttachments, includeBlankFields} = options

  const oo = {
    branding: brandingMap[branding],
    type: 'report',
    contents: {
      coverPage: true,
      intakeReport: true,
      intakePhotos,
      projectHistoryLog,
      otherProjectAttachments,
      options: {includeBlankFields: !includeBlankFields},
      permissions: {[auth.sunkaizenUser.id]: 'Unread'},
    },
  }

  return oo
}
