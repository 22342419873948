import iterateQuestions from './iterateQuestions'
export default function iterateIntake(project, form, cb) {
  const {intake} = project

  const couplets = []
  iterateQuestions(form, (section, {id}) => {
    let answer = ''
    let value = ''
    let label = ''
    let question = ''
    let score = ''
    if (intake.sections[section.id]?.fields[id]) {
      const field = intake.sections[section.id].fields[id]
      value = field.value
      if (typeof value == 'object') {
        answer = Object.keys(value.values)[0]
      } else {
        if (value != undefined) {
          answer = `${value}`
        }
      }

      label = field.label
      question = field.question
      score = field.score
    }
    couplets.push([section, {id, label, question, answer, score}])
  })

  let result = []
  for (let [section, field] of couplets) {
    result.push(cb(section, field))
  }

  return result
}
