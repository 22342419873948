import {Button, Tooltip} from 'antd'
import React, {useState, useEffect} from 'react'
import {store} from '../../../Reports/ReportsList'
import CreateCSV from '../../../CreateCSVReports'
import CreateScoringReport from '../../../CreateScoringReport'
import CreateStrategyReport from '../../../CreateStrategyReport'
import CreateQuestionsReport from '../../../CreateQuestionsReport'
import CreateInvoicingReport from '../../../CreateInvoicingReport'
import createZip from './createZip'
import {auth} from '../../../Auth'
import {FULL_TEXT_SEARCH_URL} from '../../../config'

/*
    this component uses one of the two reducers that're available above it;
    the ReportList reducer only holds data about which rows are selected
    the UnifiedList reducer holds information that's related but unhelpful to the purpose of this component:
        download all the selected/unread reports
    the UnifiedList reducer only holds the current page of reports, but the selector allows you to select reports from multiple pages
    the ReportList reducer has all the selected reports
*/

export default function ButtonBar() {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)

  useEffect(function () {
    return store.subscribe(() => {
      setDisabled(Object.keys(store.getState()).length > 0 ? false : true)
    })
  }, [])

  async function loadingWrapper(asyncFunction) {
    setLoading(true)
    try {
      await asyncFunction()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  function clear() {
    store.dispatch({type: 'clear'})
  }

  async function downloadSelected(e) {
    const state = store.getState()

    if (Object.keys(state).length) {
      loadingWrapper(async () => await createZip(state))
    } else {
      console.log('systemLogger: you have not selected any reports')
    }
  }

  async function downloadUnread(e) {
    const query = {
      size: 1500,
      from: 0,
      sort: [{'created._seconds': 'desc'}],
      query: {
        bool: {
          // filter: [{ match: { indexType: 'reports' } }],
          should: [
            {term: {'organization.id.sortable': auth.sunkaizenUser.organization.id}},
            {match: {permissions: auth.sunkaizenUser.organization.id}},
          ],
          must_not: {
            match: {[`permissions.${auth.sunkaizenUser.id}`]: 'Read'},
          },
          minimum_should_match: 1,
        },
      },
    }

    const body = {query, type: 'reports'}

    try {
      const res = await (
        await fetch(`${FULL_TEXT_SEARCH_URL}/free_query`, {
          method: `POST`,
          mode: `cors`,
          headers: {'Content-Type': `application/json`},
          body: JSON.stringify(body),
        })
      ).json()

      const items = res.hits.hits.map((h) => h._source)

      if (items.length > 0) {
        loadingWrapper(async () => await createZip(items))
      } else {
        console.log('systemNotifier: you have no unread reports')
      }
    } catch (e) {
      console.log('failed to save unread reports')
      console.log(e)
    }
  }

  const fieldUserReviewerCond = auth.sunkaizenUser.type !== 'field-user' && auth.sunkaizenUser.type !== 'quality-manager'

  if (auth.sunkaizenUser.type === 'scheduler') {
    return (<div className="actions-div">
         <div className="padding-0-5">
          <CreateCSV />
        </div>
     </div> )
  }

  return (
    <div className="actions-div">
      {fieldUserReviewerCond && (
        <div className="flex-row padding-0-5">
        <Button disabled={disabled} onClick={downloadSelected} className="margin-right-1">
          Download Selected
        </Button>
        <Button className="margin-right-1" onClick={clear}>
          Clear Selected
        </Button>
        <Tooltip title="Download up to 1,500 unread reports" placement="top">
          <Button
            className="margin-right-1"
            disabled={loading}
            onClick={downloadUnread}
            style={{backgroundColor: '#234091', color: 'white'}}
          >
            Download All Unread
          </Button>
        </Tooltip>
        <div></div>
      </div>
      )}
      
      <div className="flex-row">
        <div className="padding-0-5">
          <CreateCSV />
        </div>
        {fieldUserReviewerCond && (
        <>
        <div className="padding-0-5">
          <CreateScoringReport />
        </div>
        <div className="padding-0-5">
          <CreateStrategyReport />
        </div>
        <div className="padding-0-5">
          <CreateQuestionsReport />
        </div>
        </>
        )}
      </div>
      <div className="flex-row">
        {fieldUserReviewerCond && (
        <div className="padding-0-5">
          <CreateInvoicingReport />
        </div>
        )}
      </div> 
    </div>
  )
}
