import React, {useEffect, useState, useContext} from 'react'
import {ReducerContext} from '../../reducer'
import {List} from 'antd'
import moment from 'moment'

export default function Availability() {
  const {state} = useContext(ReducerContext)
  const {date} = state

  const [selectedAvailability, setSelectedAvailability] = useState({})
  const current = date ? date : new Date()

  useEffect(
    function () {
      if (state.inspectorAvailability) setSelectedAvailability(state.inspectorAvailability)
    },
    [state.inspectorAvailability]
  )

  return (
    <div>
      {selectedAvailability.availability && selectedAvailability.availability.length > 0 && (
        <List
          dataSource={selectedAvailability.availability}
          renderItem={(availability) => {
            const {date: start, end} = availability
            if (start.toDate() > current || current > end.toDate()) return <div></div>

            const [b, e] = [moment(start.toDate()), moment(end.toDate())]
            return (
              <div>
                Available {b.format('hh:mm A')} - {e.format('hh:mm A')}
              </div>
            )
          }}
        />
      )}
    </div>
  )
}
