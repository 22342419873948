import columns from './columns'
import onRow from './onRow'
import permissions from './permissions'
import sorter from './sorter'
import rowSelection from './rowSelection'

import AddUserButton from '../../../UserBuilder/AddUserButton'
import AddBulkUserButton from '../../../UserBuilder/BulkUserBuilder/AddBulkUserButton'

import {organizationFilter} from '../utilities'

const config = {
  columns,
  onRow,
  permissions,
  sorter,
  rowSelection,
  type: 'users',
  searchbar: true,
  savedFilters: false,
  tabs: 'type',
  bulk: AddBulkUserButton,
  create: AddUserButton,
  // preProcess: [organizationFilter]
}

export default config
