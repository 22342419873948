import React from 'react'
import LabelQuestionTip from './LabelQuestionTip'
import Required from './Required'
import OptionsWrapper from './OptionsWrapper'
import {connect} from 'react-redux'
import {modify} from '../action_creators'

const stop = (state, ownProps) => ({field: state.fields[ownProps.id] || {}})
const dtop = (dispatch) => ({modify: ({key, value}) => dispatch(modify('field')(key, value))})

const OptionPropertyBoxBase = ({id, modify, field}) => (
  <div style={{overflow: `scroll`}}>
    <LabelQuestionTip {...{modify, field}} />
    <Required {...{id, modify, field}} />
    <div className="bold">Options</div>
    <OptionsWrapper />
  </div>
)

const WrappedOptionPropertyBoxBase = connect(stop, dtop)(OptionPropertyBoxBase)
export default WrappedOptionPropertyBoxBase
