import {useState, useEffect, useContext} from 'react'
import {ReducerContext} from '../reducer'
import DB from '../../DAL/DB'
import {orgRole2aggType} from '../orgRolesEnum'
import {auth} from '../../Auth'
import {useDBSubscribe} from '../../effects'
// import additionalFilters from './additionalFilters'
import useDispatchResults from './useDispatchResults'

const questionScores = new DB.QuestionScores()

export default function useGenerateResults() {
  const {state} = useContext(ReducerContext)
  const [aggType, setAggType] = useState()
  const [filters, setFilters] = useState([])

  useEffect(
    function () {
      if (!state.dropdown.orgRole) return

      const at = orgRole2aggType(state.dropdown.orgRole)
      setAggType(at)
    },
    [state.dropdown.orgRole]
  )

  useEffect(
    function () {
      if (!aggType) return

      const ff = [
        [`aggregation_type`, `==`, aggType],
        [`${aggType}_org_id`, `==`, auth.sunkaizenUser.organization.id],
        ['filters', '!=', null], //Filters property must be written to the db object for it to be valid
        // ...additionalFilters(aggType)
      ]

      setFilters(ff)
    },
    [aggType]
  )

  // Don't look up question scores until a rubric has been selected
  let qScores = useDBSubscribe(questionScores, {filters})

  useDispatchResults(qScores, aggType)
}
