import React, {useState, useEffect} from 'react'
import {Tag} from 'antd'
import DB from '../../../DAL/DB'
import {auth} from '../../../Auth'
import SystemLogger from '../../../SystemLogger'

const authorizations = new DB.Authorizations()

export default function AuthorizationRequest({requestor, record}) {
  const [logger, setLogger] = useState(null)

  useEffect(function () {
    auth.sunkaizenUserSubscribe((user) => {
      if (user) setLogger(new SystemLogger({uid: user.id, path: `/authorizations`}))
    })
  }, [])

  function cycleStatus(type) {
    record.permissions[requestor][type] = !record.permissions[requestor][type]
    authorizations.update(record.id, null, record)
    logger.log('Updating permissions, this may take a moment...')
  }

  return (
    <div className="flex-column">
      <Tag
        onClick={() => cycleStatus('client')}
        className="transition-padding-0-5 margin-0-5"
        style={{padding: record.permissions[requestor].client ? 0 : '0.5rem'}}
      >
        <span className="pointer">Client {requestor && record.permissions[requestor].client}</span>
      </Tag>
      <Tag
        onClick={() => cycleStatus('field-user')}
        className="transition-padding-1 margin-0-5"
        style={{padding: record.permissions[requestor]['field-user'] ? 0 : '0.5rem'}}
      >
        <span className="pointer">Field User {requestor && record.permissions[requestor]['field-user']}</span>
      </Tag>
    </div>
  )
}
