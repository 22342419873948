// src/components/Badge.js
import React from 'react';

const Badge = () => {
  if (process.env.REACT_APP_ENV !== 'doe2') {
    return null;
  }

  const badgeStyle = {
    position: 'fixed',
    top: 0,
    right: 0,
    backgroundColor: 'red',
    color: 'white',
    padding: '5px 20px',
    fontSize: '14px',
    fontWeight: 'bold',
    zIndex: 1000,
    borderBottomLeftRadius: '5px',
  };

  return (
    <div style={badgeStyle}>
      Test
    </div>
  );
};

export default Badge;
