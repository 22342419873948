import React, {useEffect, useState} from 'react'
import validationEffect from './validationEffect'
import {FUNCTIONS_URL} from '../../../../config'
import {Input} from 'antd'

async function checkEmail(value) {
  if (!value || !value.toLowerCase) return
  const {data} = await fetch(`${FUNCTIONS_URL}/utilities/getAuthUserByEmail?email=${value.toLowerCase()}`)
  return data !== 'None'
}

export default function Other({other, change, setValid}) {
  const [error, setError] = useState(null)
  const [validationTimeout, setValidationTimeout] = useState(0)

  useEffect(
    validationEffect({
      validate: async () => await checkEmail(other),
      setErrorMessage: () => setError('This field must contain a valid email address'),
      clearErrorMessage: () => setError(null),
      validationTimeout,
      setValidationTimeout,
      setValid,
    }),
    [other]
  )

  return (
    <div className="margin-bottom-1 flex-column">
      {error && (
        <div className="background-white radius-1" style={{color: 'red'}}>
          {error}
        </div>
      )}
      <Input
        size="large"
        placeholder="Enter email"
        onChange={(e) => change('otherName', e.target.value)}
        value={other}
      />
    </div>
  )
}
