import React, {useEffect, useState} from 'react'
import {Icon, Table} from 'antd'
import './styles.css'
import AddScoringSystem from './AddScoringSystem'
import {subscribe} from '../HOC/Firebase/SubscribeHOC'
import {auth} from '../Auth'

export function ScoringSystemsList({makeSubscription, subscribeResources}) {
  const [data, setData] = useState(null)
  const [modalType, setModalType] = useState(null)
  const [selected, setSelected] = useState(null)

  useEffect(
    () =>
      makeSubscription(
        {
          paths: [{collection: 'scoring_systems'}],
          options: {
            where: [{field: 'organization.id', test: '==', value: auth.sunkaizenUser.organization.id}],
          },
        },
        'scoringSystems'
      ),
    []
  )
  useEffect(() => {
    setData(subscribeResources.scoringSystems)
  }, [subscribeResources.scoringSystems])

  const tableProps = {
    dataSource: data,
    rowKey: (x) => x.id,
    columns: [
      {title: `Name`, dataIndex: `name`},
      {
        title: `View`,
        dataIndex: `id`,
        render: (text, record) => (
          <Icon
            style={{fontSize: `2.5rem`}}
            type="eye"
            onClick={() => {
              console.info('View Scoring System:', record)
              setSelected(record)
              setModalType('view')
            }}
          />
        ),
      },
    ],
  }

  return (
    <div className="scoring-systems-grid">
      <div className="add-scoring-system">
        <AddScoringSystem style={{width: '100%'}} selected={selected} type={modalType} setType={setModalType} />
      </div>
      <div className="scoring-system-table">
        <Table {...tableProps} />
      </div>
    </div>
  )
}

const WrappedScoringSystemList = subscribe()(ScoringSystemsList)
export default WrappedScoringSystemList
