import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import NoContent from '../NoContent'
// import {fieldsRead, fieldsReturn} from '../effects'
// import {read} from '../../HOC/Firebase/ReadHOC'
import FieldWrapper from './FieldWrapper'
import useGetFields from '../effects/useGetFields'

const stop = (state) => ({
  fields: state.fields,
  section: state.sections[state.active.section] || null,
  sections: state.sections,
})

export function Fields({section, fields, provided, sections}) {
  const [loading, setLoading] = useState(null)

  useGetFields()

  return (
    <div ref={provided.innerRef} {...provided.droppableProps}>
      {section.order.length > 0 ? (
        <div>
          {(section.order || []).map((id, i) => {
            const f = fields[id]
            return f ? (
              <FieldWrapper {...{id, i, f, loading, setLoading, section, fields, sections}} key={`field:${id}`} />
            ) : (
              <div></div>
            )
          })}
        </div>
      ) : (
        <NoContent parent="section" child="field" childs="fields" />
      )}
      {provided.placeholder}
    </div>
  )
}

const WrappedFields = connect(stop)(Fields)
export default WrappedFields
