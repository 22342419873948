import {baseStore} from './utilities'
import DB from '../../DAL/DB'
import SystemLogger from '../../SystemLogger'

const logger = new SystemLogger({})
const projects = new DB.Projects()

export default function photo(parentID, state, dispatch) {
  async function saveToDB(photo) {
    const {
      data: [p],
      ok,
    } = await projects.get(parentID)

    if (!ok) throw new Error(`project provided to file uploader ${parentID} doesn't exist in DB`)
    if (!p.photos) p.photos = {}
    p.photos[photo.name] = photo

    dispatch({type: 'photos', value: {[photo.name]: photo}})
  }
  function store(file) {
    if (file.type.match(/image/)) return baseStore(`projects/${parentID}/photos/`)(file)

    logger.event(`file ${file.name} is not an image; you can only upload images`)
    return
  }

  return {store, saveToDB}
}
