import React, {useState, useEffect} from 'react'
import Dropdown from './Dropdown'
import Field from './Field'
import AutocompleteField from '../../../AutocompleteField'
import {auth} from '../../../Auth'
import {capitalizationMap, changeAddress} from '../../utilities'

export default function InnerBody({user, setUser, change, edit, userTypes}) {
  const [a, sa] = useState(null)
  useEffect(
    function () {
      if (!a) return

      console.log(user)
      setUser(changeAddress(user, a))
    },
    [a]
  )

  async function submit(address) {
    sa(address)
  }

  const editable =
    auth &&
    auth.sunkaizenUser &&
    !['super-admin', 'client'].includes(user.type) &&
    ['client', 'admin', 'super-admin'].includes(auth.sunkaizenUser.type) &&
    edit

  return (
    <div className="flex-column">
      <div className="field-wrapper flex-column align-start">
        <div className="info-text">Organization </div>
        <div className="padding-left-1" data-test="organization">
          {(user.organization || {}).name}
        </div>
      </div>
      <div className="flex-row flex-align-center">
        <Field edit={edit} user={user} style={{flex: 12}} type="name" change={change(`name`)} />
        <div style={{flex: 1}}></div>
        <Dropdown
          options={userTypes}
          edit={editable}
          user={user}
          style={{flex: 12}}
          type="type"
          select={change(`type`)}
        />
      </div>
      <div className="flex-row flex-align-center">
        <Field edit={edit} user={user} style={{flex: 12}} type="email" change={change(`email`)} />
        <div style={{flex: 1}}></div>
        <Field edit={edit} user={user} style={{flex: 12}} type="phone" change={change(`phone`)} />
      </div>
      <div className="flex-row flex-align-center full-width">
        <div className="flex-column address-wrapper">
          <div className="address-title">{capitalizationMap[`address`]}</div>
          <AutocompleteField
            full
            edit={edit}
            style={{flex: 1, border: `none`}}
            submit={submit}
            old_address={user.address}
          />
        </div>
      </div>
      {user.type === `installer` && (
        <Field edit={edit} user={user} style={{flex: 12}} type="nabcep_ids" change={change(`nabcep_ids`)} />
      )}
    </div>
  )
}
