import React, {useState, useEffect} from 'react'
import {correctedURL} from '../../utilities'
import {subscribe} from '../../HOC/Firebase/SubscribeHOC'
import {StaticMap, Header, TypeRubric, MiscInfo, IntakeAndSnapshots, SaveReport, Tickets } from '.'
import {
  DownloadImages,
  CancelProjectButton,
  ApproveCancelButton,
  CustomPDFReport,
  InstallerViewable,
  AddTicket,
} from '.'
import '../DetailedProjectView.css'
import useSnapshots from './useSnapshots'
import DownloadStrategyReport from './StrategyReport'

function ProjectDetails({history, project, makeSubscription, subscribeResources}) {
  const [reportURL, setReportURL] = useState(null)

  const {
    created,
    updated,
    status,
    completed_on,
    id,
    rubric = {name: ''},
    client = {name: '', id: null},
    report = {},
  } = project
  const {intake = {title: ''}, site = {geocode_results: {geometry: {location: {}}}}} = project

  const snapshots = useSnapshots(id, makeSubscription, subscribeResources)

  useEffect(
    function () {
      ;(async function () {
        if (!report.fullPath) return
        const url = correctedURL(report.fullPath)
        setReportURL(url)
      })()
    },
    [report.fullPath]
  )

  return (
    <div className="flex-column background-white margin-left-2" data-cy="projectDetails">
      <Header {...{status, completed_on}} />
      <StaticMap site={site} />
      <TypeRubric {...{id, intake, status, rubric}} />
      <MiscInfo {...{client, created, updated}} />
      <IntakeAndSnapshots {...{project, snapshots}} />
      <SaveReport {...{id, report, reportURL, site, intake, status}} />
      <DownloadImages id={id} />
      {/* <DownloadStrategyReport /> */}
      <InstallerViewable {...{project}} />
      <CustomPDFReport {...{status, intake, site, id}} />
      {project?.tickets && <Tickets tickets={project.tickets} />}
      <AddTicket {...{project, history, status, rubric}} />

      {project.status !== 'Canceled' && project.status !== 'Cancel Requested' && <CancelProjectButton id={id} />}
      {project.status === 'Cancel Requested' && <ApproveCancelButton project={project} />}
    </div>
  )
}

const WrappedProjectDetails = subscribe()(ProjectDetails)
export default WrappedProjectDetails
