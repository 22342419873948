import columns from './columns'
import onRow from './onRow'
import permissions from './permissions'
import sorter from './sorter'
import rowSelection from './rowSelection'
import {inspectionsFilter} from '../utilities'
import or from './or'

const config = {
  columns,
  onRow,
  permissions,
  sorter,
  rowSelection,
  type: 'inspections',
  searchbar: true,
  savedFilters: true,
  tabs: 'status',
  or,
  // bulk: BulkProjectImport,
  // create: ProjectCreator,
  // postProcess: [hideCanceled]
  preProcess: [inspectionsFilter],
}

export default config
