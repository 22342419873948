import GeocodeWrapper from '../../GeocodeWrapper'
import retry from 'async-retry'

const wrappedGeocode = new GeocodeWrapper()

export function geocodeAddress(csvArray) {
  return csvArray
    .map(async ([name, email, address, city, state, zip, type, nabcep_ids]) => {
      console.log(address)

      const user = {
        name,
        email,
        address,
        city,
        state,
        zip,
        type,
        nabcep_ids,
      }

      if (!address) return user

      const a = `${address} ${city}, ${state} ${zip}`
      const geocode_results = await retry(
        async () => {
          return await wrappedGeocode.geocode(address)
        },
        {retries: 10}
      )
      user.geocode_results = JSON.parse(JSON.stringify(geocode_results))

      return user
    })
    .filter((i) => i)
}
