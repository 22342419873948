import React, {useState, useEffect} from 'react'
import Autocomplete from './Autocomplete'
import {dbResources} from './DAL/DB'

function LF(item) {
  return (
    <div>
      <span className="bold padding-right-1">{item.name}</span>
      {item.email || 'no email'}
    </div>
  )
}

export default function AutocompleteDatabase({type, sort, setValue}) {
  const [collection, setCollection] = useState(null)
  const [resources, setResources] = useState([])

  useEffect(
    function () {
      setCollection(new dbResources[type]())
    },
    [type]
  )

  async function updateSearch(search) {
    const {ok, data} = await collection.get(null, {sort, startAt: search, endAt: `${search}\uf8ff`})
    if (ok) setResources(data)
  }

  return (
    <div style={{position: 'relative'}}>
      <Autocomplete setValue={setValue} onAutocomplete={updateSearch} resources={resources} listField={LF} />
    </div>
  )
}
