// https://plot.ly/javascript/

import React, {useEffect, useRef} from 'react'
import Plotly from 'plotly.js-basic-dist'

export default function ({options, layout}) {
  const chartRef = useRef(null)
  useEffect(function () {
    Plotly.newPlot(chartRef.current, options, layout || {height: 300, width: 300})
  })

  return (
    <div style={{padding: '1rem', width: '100%', height: '100%'}}>
      <div ref={chartRef}></div>
    </div>
  )
}

// export default class Chart extends React.Component {
//   static propTypes = {
//     options: PropTypes.array,
//     layout: PropTypes.object
//   }

//   chartRef = React.createRef()

//   componentDidUpdate = pprops => {
//     console.log(this.chartRef)
//     Plotly.newPlot(this.chartRef.current, this.props.options, this.props.layout || {height: 300, width: 300})
//   }

//   render = () => (
//     <div style={{padding: "1rem", width: "100%", height: "100%"}}>
//       <div ref={this.chartRef}></div>
//     </div>
//   )
// }
