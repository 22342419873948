import {useContext, useEffect} from 'react'
import {useDBSubscribe} from '../../effects'
import DB from '../../DAL/DB'
import {ReducerContext} from '../reducer'
import {auth} from '../../Auth'

const clients = new DB.Organizations()

export default function useGetClients() {
  const {state, dispatch} = useContext(ReducerContext)

  const filters = []
  if (auth.sunkaizenUser.type === 'client') {
    filters.push(['id', '==', auth.sunkaizenUser.id])    
  }

  const cc = useDBSubscribe(clients, {filters})

  useEffect(
    function () {
      const fff = {...state.filterTypes}
      for (let u of cc) {
        fff.client.options.push({
          id: u.id,
          name: u.name,
        })
      }
      fff.client.options = Array.from(new Set(fff.client.options)).sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
      dispatch({type: 'filterTypes', value: fff})
    },
    [cc]
  )
}
