export default function mapDBToFields(project) {
  console.log(project)

  const fields = {
    client: project.client,
    projectType: project.intake,
    rubric: project.rubric,
    installer: project.installer,
    inspector: project.inspection && project.inspection.inspector ? project.inspection.inspector : {},
    nabcep_ids: project.nabcep_ids,
    siteOwnerName: project.site.siteOwnerName,
    siteAddress: project.site.siteAddress,
    sameRep: project.site.sameRep,
    sameAddress: project.site.sameAddress,
    siteRepName: project.site.siteRepName,
    siteRepEmail: project.site.siteRepEmail,
    siteRepPhone: project.site.siteRepPhone,
    organization: project.organization,
    restrict_schedule: !!project.restrict_schedule,
  }

  return fields
}
