import React from 'react'

import {Input, Select, Icon, Button} from 'antd'
import DeleteButton from '../DeleteButton'
import './RubricPage.scss'

// this component  specifies that can create regions and region based rubric
export function AutoRubricOption({rec, index, handleUpdateRubricOptions, handleRubricOptions, createdRubrics}) {
  return (
    <div style={{display: 'flex', marginTop: 15, marginLeft: 50}}>
      <div style={{width: '30%', marginRight: 5}}>
        <Input
          key={`labelValue-${index}-${rec.option}`}
          addonAfter={<Icon type="plus" onClick={() => handleRubricOptions(index, 'add')} />}
          defaultValue={rec.option}
          onBlur={(e) => handleUpdateRubricOptions(e.target.value, index, 'option')}
          placeholder={'Please enter option'}
          data-cy="rubricOptionRegion"
        />
      </div>
      <div className="delete-wrapper padding-right-1">
        <DeleteButton
          style={{height: `3rem`}}
          icon="minus-circle"
          size="large"
          type="secondary"
          confirmationDisplay={
            <div>
              <p>Are you sure you want to remove this Option?</p>
            </div>
          }
          onConfirm={() => handleRubricOptions(index, 'remove')}
        />
      </div>
      <div style={{width: '30%'}}>
        <Select
          size="medium"
          key={`selectControl-${index}-${rec.selectedRubric}`}
          defaultValue={rec.selectedRubric}
          onChange={(e) => handleUpdateRubricOptions(e, index, 'selectedRubric')}
          placeholder={`Please select Rubric`}
          className="full-width"
        >
          <Select.Option value={''}>{'Rubrics for this form'}</Select.Option>
          {createdRubrics.map((opt) => (
            <Select.Option value={opt}>{opt}</Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
}
