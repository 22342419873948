import {useEffect, useState} from 'react'
import embed from 'vega-embed'
import transform from './transform'
import {useLoggerEffect} from '../../../effects'

// const opts = {
//   patch: spec => {
//       spec.signals.push({
//           "name": "barClick",
//           "value": 0,
//           "on": [{"events": "rect:mousedown", "update": "datum"}]
//       })
//       return spec
//   }
// }

const opts = {}

export default function useMakeGraph(graph, projects = [], makeSpec) {
  const [clicked, setClicked] = useState(null)
  const [spec, setSpec] = useState(undefined)
  const logger = useLoggerEffect('')

  useEffect(
    function () {
      projects = transform(projects)
      const spec = makeSpec(projects)
      setSpec(spec)
    },
    [projects.length > 0]
  )

  useEffect(
    function () {
      (async function () {
        if (!graph || !graph.current) return
        if (!spec) return

        const result = await embed(graph.current, spec, opts)

        if (logger) await logger.log(`updated project flow graphs`)
      })()
    },
    [graph.current, projects]
  )

  return clicked
}
