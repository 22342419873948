import React, {useState, useEffect} from 'react'
import {Icon} from 'antd'
import PermissionsBlock from './PermissionsBlock'
import InnerBody from './InnerBody'
import {toggleEdit} from '../../utilities'
import {read} from '../../../HOC/Firebase/ReadHOC'
import {loggerEffect} from '../../../effects'

export function UserInformation({user, setUser, change, readDB, readResults}) {
  const [edit, setEdit] = useState(false)
  const [logger, setLogger] = useState(null)
  useEffect(loggerEffect(setLogger, `/users/${user.id}`), [user.id])

  const [userTypes, setUserTypes] = useState([])

  useEffect(function () {
    console.info('Display User:', user)
    readDB({paths: [{collection: 'meta', doc: 'roles'}]}, 'userTypes')
  }, [])

  useEffect(
    function () {
      if (!readResults.userTypes) return
      const {
        userTypes: [ut],
      } = readResults
      delete ut.client
      setUserTypes(Object.keys(ut))
    },
    [readResults.userTypes]
  )

  const te = toggleEdit(user, edit, logger, setEdit)
  return (
    <div>
      <div className="flex-row align-center justify-end">
        <div className="user-information-pane-edit-button flex-row align-center" onClick={te}>
          <Icon type={edit ? `save` : `edit`} />
          <div className="padding-left-1">{edit ? `Save` : `Edit`}</div>
        </div>
      </div>
      <InnerBody {...{user, setUser, change, edit, userTypes}} />
      <div className="padding-1"></div>
      {['quality-manager', 'scheduler'].includes(user.type) && (
        <PermissionsBlock {...{permissionsProps: {edit, change}, user}} />
      )}
    </div>
  )
}

const WrappedUserInformation = read()(UserInformation)
export default WrappedUserInformation
