import React, {useContext, useEffect} from 'react'
import {GenericSelector} from './GenericSelector'
import {ReducerContext} from './reducer'
import DB from '../DAL/DB'

export default function SOFilters() {
  // get aggregations
  const {state, dispatch} = useContext(ReducerContext)

  // Get aggregation types that match the selected rubric and organization Role
  useEffect(
    function () {
      // state.rubrics must exist for state.dropdown.rubric to exist
      if (state.dropdown.rubric) {
        const aggregations = state.analytics[state.dropdown.rubric].aggregation_types
        dispatch({type: `aggregations`, value: aggregations})
      }
    },
    [state.dropdown.rubric]
  )

  // get question analysis data
  useEffect(
    function () {
      (async function () {
        if (!state.dropdown.rubric || !state.dropdown.aggregation) return
        const aggregations = new DB.Aggregations()
        const {
          ok,
          data: [aggregation],
        } = await aggregations.get(null, {
          filters: [
            [`rubric`, `==`, state.dropdown.rubric],
            [`aggregation_type`, `==`, state.dropdown.aggregation],
          ],
        })

        if (ok) dispatch({type: `SO_data`, value: aggregation})
      })()
    },
    [state.dropdown.aggregation]
  )

  return (
    <div style={{flex: 1}}>
      <GenericSelector
        placeholder={state.aggregations ? `Select Aggregation Type` : `Please Select a Rubric First`}
        label="Aggregation Type"
        values={(state.aggregations || []).map((x) => ({id: x}))}
        valueTitle="id"
        selectedValue={state.dropdown.aggregation}
        onChange={(value) => dispatch({type: 'aggregation', value})}
        data-cy="soAggType"
      />
    </div>
  )
}
