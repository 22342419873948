import {auth} from '../../Auth'
import {setField} from '../action_creators'
import {store} from '../utilities'
import {useEffect, useState} from 'react'

// manages what FUs should display in the inspection information dropdown based on the selected category of inspection
// useSetInspector (category: string) => [fus: UserBrief[], set: (id: string) => void]
// set: sets the inspector,
// fus: users displayed in the dropdown
export default function useSetInspector(category) {

  

  const [fus, setFus] = useState([])
  const [fuMap, setFuMap] = useState({})

  const state = store.getState()
  const fields = state.fields || {}

  // this block just makes it cleaner to set the inspector on the inspection information dropdown
  useEffect(
    function () {
      const fum = {}
      for (let fu of fus) {
        fum[fu.id] = fu
      }

      setFuMap(fum)
    },
    [fus]
  )
  function set(id) {
    store.dispatch(setField('inspector', fuMap[id]))
  }

  // when the inspection category is changed, the FUs displayed change; also, if the client is changed
  //  and the inspection type is 'client'/self-submit, the one name displayed in the dropdown must update
  useEffect(
    function () {
      const state = store.getState()
      const {fields = {}} = state
      const {users = [], organizations = []} = state.dropdowns || {}

      switch (category) {
        case 'internal':
          const fuSubset = users.filter(
            (user) => user.type === 'field-user' && user.organization.id === auth.sunkaizenUser.organization.id && !user.disabled
          )
          setFus(fuSubset)
          break
        case 'client':
          store.dispatch(setField('inspector', fields.client))
          setFus([])
          break
        case 'third-party':
          setFus(
            organizations.filter((organization) => {
              if (organization.id === auth.sunkaizenUser.organization.id) return false
              //return (organization.roles || []).includes('Inspector') && organization.third_party_inspector
              return organization.third_party_inspector
            })
          )
          break
        default:
          console.log(`category (${category}) is unrecognized`)
      }
    },
    [category, fields.client]
  )

  return [fus, set]
}
