import React, {useState, useEffect} from 'react'
import DB from '../DAL/DB'
import ReactJson from 'react-json-view'
import {Spin} from 'antd'

const projects = new DB.Projects()

export default function GetProject({match}) {
  const [project, setProject] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(
    function () {
      (async function () {
        await fetchProject()
      })()
    },
    [match.params.id]
  )

  async function fetchProject() {
    const {id} = match.params
    console.log('fetching: ', id)

    const {
      data: [p],
      ok,
    } = await projects.get(id)
    if (ok) {
      console.log('success')
      setProject(p)
      setLoading(false)
      return
    }

    setProject({error: 'could not get project'})
    setLoading(false)
  }

  return (
    <div className="padding-2" style={{textAlign: 'left'}}>
      {loading && <Spin />}
      <ReactJson name={project.site ? project.site.siteOwnerName : 'none'} src={project} />
    </div>
  )
}
