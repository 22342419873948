import {useEffect, useContext} from 'react'
import {ReducerContext} from '../reducer'

export default function useOrgRoles() {
  const {state, dispatch} = useContext(ReducerContext)

  useEffect(
    function () {
      if (state.organization) {
        const value = [{id: `Client`}, ...(state.organization.roles || []).map((role) => ({id: role}))]
        dispatch({type: `orgRoles`, value})
      }
    },
    [state.organization]
  )
}
