import React, {useContext} from 'react'
import {Button, Popconfirm} from 'antd'
import {ReducerContext, finalize} from '.'
import {useLoggerEffect} from '../../effects'

export default function ModalFooter({setType, type}) {
  const {state, dispatch} = useContext(ReducerContext)
  const logger = useLoggerEffect(null)

  function cancel() {
    dispatch({type: 'clear'})
    dispatch({type: 'show', value: false})
    setType(null)
  }

  async function ok() {
    await finalize({state, dispatch, logger, type})()
    setType(null)
  }

  return (
    <div>
      <Popconfirm
        title={`Scoring systems cannot be changed or deleted; please confirm that you wish to create this scoring system`}
        onConfirm={ok}
        onCancel={cancel}
        okText="Create"
        cancelText="Cancel"
      >
        <Button>Ok</Button>
      </Popconfirm>
      <Button onClick={cancel}>Cancel</Button>
    </div>
  )
}
