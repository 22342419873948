import React, {useState, useEffect} from 'react'
import DB from './DAL/DB'

const projectLogs = new DB.ProjectLogs()
const users = new DB.Users()

function useGetNotification(id) {
  const [notification, setNotification] = useState(null)

  useEffect(
    function () {
      (async function () {
        const {
          data: [not],
          ok,
        } = await projectLogs.get(id)
        setNotification(not)
      })()
    },
    [id]
  )

  return notification
}

function useGetRecipient(notification) {
  const [user, setUser] = useState(null)

  useEffect(
    function () {
      (async function () {
        if (!notification) return
        const {
          data: [user],
          ok,
        } = await users.get(notification.recipient)
        setUser(user)
      })()
    },
    [notification]
  )

  return user
}

export default function ProjectNotification({match}) {
  const {id} = match.params

  const notification = useGetNotification(id)
  const user = useGetRecipient(notification)

  return (
    <div className="flex-column" style={{padding: `3rem`}}>
      <div className="flex-row padding-right-2 padding-bottom-1 justify-space-between align-center">
        <h1 className="page-header">Project Notification</h1>
      </div>
      {notification && (
        <div className="flex-column padding-1 background-white">
          <div>
            <span className="bold">Project Id:</span> {notification.categoryId}
          </div>
          <div>
            <span className="bold">Created:</span> {notification.created.toDate().toDateString()}
          </div>
          <div>
            <span className="bold">Message:</span> {notification.message}
          </div>
          <div>
            <span className="bold">Recipient:</span> {user ? user.name : `(getting recipient)`}
          </div>
        </div>
      )}
    </div>
  )
}
