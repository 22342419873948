import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Loading from './Loading'
import RubricSettings from './RubricSettings'
import RubricSection from './RubricSection'
import TitleBar from './TitleBar'
import {useSubscriptions} from './effects'
import {getFormFields} from './utilities'
import {Collapse, Tabs} from 'antd'
import  AutoRubric  from './AutoRubric'
import './RubricPage.scss'

function stop(state) {
  return {
    sections: state.sections, 
    form: state.form, 
    scoringSystems: state.scoringSystems, 
  }
}

export function TopLevelState({sections, form, scoringSystems, history, match}) {
  const [sectionList, setSectionList] = useState([])

  useSubscriptions(match.params.formId)

  useEffect(
    function () {
      setSectionList(Object.keys(sections))
    },
    [sections]
  )
  useEffect(
    function () {
      getFormFields(form)
    },
    [form.id]
  )

  if (!Object.keys(scoringSystems).length)
    return (
      <div className="padding-2">
        You have not created any scoring systems; you must have at least one scoring system to create a rubric
      </div>
    )

  return (
    <div className="rubric-page full-width flex-column">
      <TitleBar history={history} />
      <Collapse defaultActiveKey={1}>
        <Collapse.Panel key="1" header={<h3 style={{marginTop: '1rem'}}>Rubric Settings</h3>}>
          <RubricSettings formId={match.params.formId} />
        </Collapse.Panel>
      </Collapse>
      <AutoRubric form={form} formId={match.params.formId}/>
      {!sectionList && <Loading />}
      <Tabs defaultActiveKey={1}>
        {sectionList.map((s, i) => (
          <Tabs.TabPane tab={sections[s].title} key={i}>
            <RubricSection key={s} id={s} selectedRubric={''}/>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  )
}

const WrappedTopLevelState = connect(stop)(withRouter(TopLevelState))
export default WrappedTopLevelState
