import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'

import FailuresBySection from './FailuresBySection'
import {makeFailures, makeFailuresBySection} from '../../utilities'

function stop(state) {
  return {project: state.project}
}

export function SelectFailures({project}) {
  const [failuresBySection, setFBS] = useState({})

  useEffect(
    function () {
      if (!project) return

      const f = makeFailures(project)
      const fbs = makeFailuresBySection(f)

      setFBS(fbs)
    },
    [project]
  )

  return (
    <div>
      {Object.entries(failuresBySection).map(([sectionTitle, failures]) => (
        <FailuresBySection key={sectionTitle} {...{sectionTitle, failures}} />
      ))}
    </div>
  )
}

const WrappedSelectFailures = connect(stop)(SelectFailures)
export default WrappedSelectFailures
