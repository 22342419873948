import React, { useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { SelectControlAction, InputCtrlAction, MultiInputControlAction } from './components/index';
import { actionData, dropdownDataFunc } from './actionsDataJSON'; 
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddAction = React.memo(({
  action,
  handleDeleteActionCancel,
  handleDeleteActionConfirmation,
  handleDeleteAction,
  handleInputChange, // Use this to handle field changes
  currentIndex,
  ratingsData,
  iconsData,
  setRatingsData,
  setIconsData,
}) => {
  const [tags, setTags] = useState(action.tags || []); // Initialize with empty array if undefined

  // Add debug statements to track action state
  console.log("Rendering AddAction for Action ID:", action.id);
  console.log("Current strategy value:", action.strategy);
  console.log("Current referenceInformation value:", action.referenceInformation);

  // Handle adding tags
  const handleAddTags = () => {
    const tagsUpdated = [...tags];
    const tagInfoObjUpdated = { id: tagsUpdated.length + 1, text: '', isEdit: true, isDelete: false };
    tagsUpdated.push(tagInfoObjUpdated);
    setTags(tagsUpdated);

    const actionUpdated = { ...action, tags: tagsUpdated };
    handleInputChange(action.id, 'tags', tagsUpdated); // Using handleInputChange to update the action's tags
  };

  // Handle updating tags
  const handleTagsUpdate = (e, obj) => {
    const editTags = [...tags];
    const editedRec = editTags.find(x => x.id === obj.id);
    editedRec.text = e.target.value;
    editedRec.isEdit = false;
    setTags(editTags);

    handleInputChange(action.id, 'tags', editTags); // Using handleInputChange to update tags
  };

    // Handle editing tags
  const handleEditTags = (tagObj) => {
      const editTags = [...tags];
      const editedRec = editTags.find((x) => x.id === tagObj.id);
      editedRec.isEdit = true;  // Set the tag to edit mode
      setTags(editTags);
      handleInputChange(action.id, 'tags', editTags); // Using handleInputChange to update tags
    };
  

  // Handle strategy input (this should display existing values and update when changed)
  const handleStrategyUpdate = (e) => {
    console.log("Updating strategy for Action ID:", action.id, "New value:", e.target.value);
    handleInputChange(action.id, 'strategy', e.target.value);
  };

  // Handle reference information input (this should display existing values and update when changed)
  const handleReferenceInformationUpdate = (e) => {
    console.log("Updating referenceInformation for Action ID:", action.id, "New value:", e.target.value);
    handleInputChange(action.id, 'referenceInformation', e.target.value);
  };

  // Handle action description update using ReactQuill
  const handleActionDescriptionUpdate = (value) => {
    console.log("Updating actionDescription for Action ID:", action.id, "New value:", value);
    handleInputChange(action.id, 'actionDescription', value);
  };

  // Handle dropdown selection changes
  const handleOnChangeRating = (value, opt) => {
    console.log("Updating dropdown value for Action ID:", action.id, "Field:", opt.dbProp, "New value:", value);
    handleInputChange(action.id, opt.dbProp, value);
  };

  // Handle deletion actions
  const handleDeleteCancelTags = (obj) => {
    const editTags = [...tags];
    const editedRec = editTags.find((x) => x.isDelete === 'confirmation');
    editedRec.isDelete = false;
    setTags(editTags);
    handleInputChange(action.id, 'tags', editTags);
  };

  const handleDeleteConfirmation = (obj) => {
    const editTags = [...tags];
    const editedRec = editTags.find((x) => x.isDelete === 'confirmation');
    editedRec.isDelete = true;
    setTags(editTags);
    handleInputChange(action.id, 'tags', editTags);
  };

  const handleDeleteTags = (obj) => {
    const editTags = [...tags];
    const editedRec = editTags.find((x) => x.id === obj.id);
    editedRec.isDelete = 'confirmation';
    setTags(editTags);
    handleInputChange(action.id, 'tags', editTags);
  };

  const handleMaintainceUpdate = (e, obj) => {
    const editTags = [...tags];
    const editedRec = editTags.find((x) => x.id === obj.id);
    editedRec.maintaince = e.target.value;
    setTags(editTags);
    handleInputChange(action.id, 'tags', editTags);
  };

  const actionDataValues = actionData(ratingsData, iconsData);
  const dropdownData = dropdownDataFunc(actionDataValues);

  return (
    <React.Fragment>
      <div className="addAction">
        <h4>Action #{currentIndex}</h4>
        <div className="addActionBg">
        <div className="actionStrategy">
            <InputCtrlAction
              name={'Strategy'}
              uniqueValue={action.id}
              defaultValue={action.strategy}
              handleChange={(e) => handleStrategyUpdate(e, 'strategy')}
            />
          </div>
        </div>

        <div className="actionDescription" style={{ marginBottom: '8rem' }}>
          <label className="question-title">
            Action Description <span style={{ color: 'red' }}>*</span>
          </label>
          <ReactQuill
            value={action.actionDescription || ''} // Controlled input with value prop
            onChange={handleActionDescriptionUpdate} // Update actionDescription field
            style={{ height: '100px' }}
          />
        </div>

        <MultiInputControlAction
          name={'Reference Information'}
          uniqueValue={action.id}
          defaultValue={action.referenceInformation}
          shortNotes={'[Google](www.google.com) | [Yahoo](http://yahoo.com)'}
          handleChange={(e) => handleReferenceInformationUpdate(e)}
        />

        <div className="actionDropdown">
          <div className="actionDropdownDisplay">
            {dropdownData.map((ddD, index) => (
              <SelectControlAction
                key={`SelectAction-${ddD.name}-${index}-${ddD.unquieProp}`}
                name={ddD.name}
                options={ddD.options}
                uniqueValue={action[ddD.unquieProp] || ''} // Controlled input with value prop
                defaultValue={action[ddD.dbProp]}
                handleChange={(value) => handleOnChangeRating(value, ddD)} // Update dropdown selection field
              />
            ))}
          </div>

          <div className="actionTags">
            <div>
              <label className="padding-right-30 actionTagsLabel" htmlFor="tags">
                Tags{' '}
              </label>
              <Button className="actionTagsButton" title="add-tag" icon="plus" onClick={handleAddTags} />
            </div>

            {(tags || [])
              .filter(x => x.isDelete !== true)
              .map(x => (
                <div key={`tags-${x.id}`} className="actionTagsAdd">
                  <Button
                    className="actionTagEdit"
                    title="edit"
                    icon={'edit'}
                    onClick={() => handleEditTags(x)}
                    data-cy="toggleEditFormBtn"
                  />
                  {x.isEdit ? (
                    <input
                      className="ant-input ant-input-lg actionTagName"
                      key={`tags-${tags.length}-${x.id}-${x.text}`}
                      onBlur={e => handleTagsUpdate(e, x)}
                      placeholder="Please enter tag name"
                      type="text"
                      id="actionName"
                      name="actionName"
                      defaultValue={x.text}
                    />
                  ) : (
                    <label className="actionTagName">{x.text}</label>
                  )}

                  <Popconfirm
                    onCancel={() => handleDeleteCancelTags(x)}
                    onConfirm={() => handleDeleteConfirmation(x)}
                    title="Are you sure you want to delete this tag?"
                  >
                    <Button
                      className="actionTagNameDelete"
                      title="delete"
                      icon={'delete'}
                      onClick={() => handleDeleteTags(x)}
                      data-cy="toggleDeleteFormBtn"
                    />
                  </Popconfirm>

                  <div className="actionCommonClass">
                    <textarea
                      cols="39"
                      key={`tags-${tags.length}-${x.id}-${x.maintaince}`}
                      className="actionTagDesc"
                      placeholder="Please enter tag description"
                      onBlur={e => handleMaintainceUpdate(e, x)}
                      defaultValue={x.maintaince}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="actionDelete">
          <Popconfirm
            onCancel={() => handleDeleteActionCancel(action)}
            onConfirm={() => handleDeleteActionConfirmation(action)}
            title="Are you sure you want to delete this action?"
          >
            <Button
              className="actionDeleteConf"
              title="deleteAction"
              icon={'delete'}
              onClick={() => handleDeleteAction(action)}
              data-cy="toggleDeleteActionFormBtn"
            />
          </Popconfirm>
        </div>
      </div>
    </React.Fragment>
  );
}, (prevProps, nextProps) => {
  // Prevent unnecessary re-renders by comparing props
  return (
    prevProps.ratingsData === nextProps.ratingsData &&
    prevProps.iconsData === nextProps.iconsData &&
    prevProps.action === nextProps.action
  );
});

export default AddAction;
