import {store} from '../utilities'
import {modify} from '../action_creators'
import getFormFieldId from './getFormFieldId'

// Hooked up to the copy button for fields on forms
export default function duplicateField(id) {
  if (!id) throw new Error('failed to provide a field ID to duplicate')

  const state = store.getState()
  const {fields, sections, active} = state
  const section = sections[active.section]

  if (!section.order.includes(id)) throw new Error(`section does not contain expected ID`, id)

  const field = {...fields[id]}
  field.id = getFormFieldId()

  //Add the newly created field immediately after the one just made
  let fieldIndex = section.order.indexOf(id)

  const order = [...section.order]
  order.splice(fieldIndex + 1, 0, field.id)

  store.dispatch(modify('section')(section.id, {...section, order}))
  store.dispatch(modify('field')(field.id, field))

  return field.id
}
