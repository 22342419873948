import {history} from '../../../history'
import {store} from '../../utilities'
import {setPopup} from '../../action_creators'
import DB from '../../../DAL/DB'
import {message} from 'antd'

const notifications = new DB.ProjectLogs()

export default function onRow(record) {
  return {
    style: {cursor: `pointer`},
    onClick: async (e) => {
      e.stopPropagation()
      e.preventDefault()

      try {
        await notifications.update(record.id, null, {...record, seen: true})
        if (e.metaKey) return window.open(`/notifications/${record.id}`)
        history.push(`/notifications/${record.id}`)
      } catch (e) {
        console.log(e)
        message.error(`could not navigate to notification for some reason, see the console for the error`)
      }
    },
    onContextMenu: (e) => {
      e.preventDefault()
      const state = store.getState()

      if (!state.tableVars.popup.x) store.dispatch(setPopup(record, e.clientX, e.clientY))
    },
  }
}
