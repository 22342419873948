import React from 'react'
import {Icon} from 'antd'

export default function helperMessage() {
  return (
    <div style={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'center'}}>
      <Icon type="arrow-up" />
      <div style={{padding: '0 0 0 1rem'}}>Drop an image here to make it your profile pic</div>
    </div>
  )
}
