import * as Fuse from 'fuse.js'
import moment from 'moment'
import {firebase} from '../utilities'

export const formatTime = (time) => moment(time).format(`M/D/YYYY, h:mm A`)

export const toFirebaseTimestamp = (start, end) => {
  const startTimestamp = firebase.firestore.Timestamp.fromDate(new Date(start))
  const endTimestamp = firebase.firestore.Timestamp.fromDate(new Date(end))
  const duration = (endTimestamp.seconds - startTimestamp.seconds) / 60

  return {startTimestamp, endTimestamp, duration}
}

export function filterEvents({filter, events, searchTerm, showIntakes}) {
  // Determine whether or not intakes are shown.
  let newEvents = []
  if (showIntakes) {
    if (filter === 'ProjectType') {
      newEvents = events.filter((e) => e.type === 'Intake')
    } else newEvents = events
  } else {
    newEvents = events.filter((e) => e.type === 'Availability')
  }

  let keys = []

  // Check if events should be filtered, exiting the function if not.
  if (!filter || searchTerm === ``) return newEvents

  // Set the object keys that the searcher will look through (which are
  // different for each filter type).
  if (filter === `User`) {
    keys.push(`data.name`)
    keys.push(`data.inspection.inspector.name`)
  } else if (filter === `Client`) keys.push(`data.client.name`)
  else if (filter === `ProjectType`) keys.push(`title`)

  newEvents = fuzzySearchEvents({
    events: newEvents,
    keys,
    searchTerm,
  })

  return newEvents
}

// https://fusejs.io/
/**
 * The object instance that retains search state and used in
 * `fuzzySearchEvents`.
 */
// export function createEventSearcher() {
//   return new Fuse([], {
//     shouldSort: true,
//     tokenize: true,
//     findAllMatches: true,
//     threshold: 0.5,
//     location: 0,
//     distance: 60,
//     maxPatternLength: 32,
//     minMatchCharLength: 1
//   })
// }

function fuzzySearchEvents({events, keys, searchTerm}) {
  const eventSearcher = new Fuse(events, {
    shouldSort: true,
    tokenize: true,
    findAllMatches: true,
    threshold: 0.2,
    location: 0,
    distance: 60,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys,
  })

  return eventSearcher.search(searchTerm)
}
