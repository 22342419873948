import React from 'react'
import {withRouter} from 'react-router'
import {onChange} from './onChange'
import {setQueryParams} from './action_creators'
import {connect} from 'react-redux'
import {colors} from '../config'

const sToP = (state) => ({
  createButton: state.tableVars.tableStrategy.create,
  bulk: state.tableVars.tableStrategy.bulk,
  tableControls: state.tableControls,
})

const dToP = (dispatch) => ({
  setQueryParams: () => dispatch(setQueryParams({from: 0})),
})

function ResourceCreate({createButton: Create, bulk: Bulk, setQueryParams, tableControls}) {
  function postClose() {
    setQueryParams()
    onChange(tableControls)
  }

  function buttonStyle(background) {
    return {flex: 1, height: '4rem', background, color: 'white', margin: '0 0.3rem 0 0.3rem'}
  }

  return Create ? (
    <div className="flex-row align-center" style={{height: '5rem'}}>
      <Create success={[postClose]} style={buttonStyle(colors.primary)} />
      {Bulk ? <Bulk success={[postClose]} style={buttonStyle(colors.tertiary)} /> : <div style={{flex: 1}}></div>}
    </div>
  ) : (
    <div></div>
  )
}

const WrappedResourceCreate = withRouter(connect(sToP, dToP)(ResourceCreate))
export default WrappedResourceCreate
