import React, {useState} from 'react'
import {Button} from 'antd'
import {FUNCTIONS_URL} from '../../config'
import {FileImageOutlined, LoadingOutlined} from '@ant-design/icons'

function downloadFile(uri) {
  var link = document.createElement('a')
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', 'images.zip')
  link.href = uri
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export default function DownloadImages({id}) {
  const [loading, setLoading] = useState(false)

  async function imageDownloader() {
    setLoading(true)

    try {
      const result = await fetch(`${FUNCTIONS_URL}/utilities/downloadAllImages`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        mode: 'cors',
        body: JSON.stringify({id}),
      })

      const url = await result.text()
      downloadFile(url)
    } catch (e) {
      console.error(e)
      alert('Something went wrong!\nSome files are missing.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button className="margin-1" onClick={imageDownloader}>
      <FileImageOutlined className="padding-right-1" />
      Download Project Information{loading && <LoadingOutlined />}
    </Button>
  )
}
