import {store} from '../../utilities'
import {readUploadedText, validateHeaders, geocodeSites} from '.'
import {setSites} from '../../action_creators'

export default async function upload(files) {
  if (files.length < 1) return

  const state = store.getState()

  console.log(files)

  try {

  const [fi] = await Promise.all(files.map((f) => readUploadedText(f)))

  console.log(fi)

  const headers = fi.shift()
  const file = fi.filter((f) => f.some((l) => l.length > 0))

  if (!validateHeaders(headers, state.bulk.csvtemplate)) {
    console.log(`there's something wrong with the header; canceling`, headers)
    alert(`there's something wrong with the header; canceling..`)
    return
  }

  try {
    const sites = await geocodeSites(file)
    console.info('Geocode finished:', sites)
    const l = sites.length
    for (let i = 0; i < l; i++) {
      sites[i].tipText = file[i]
    }

    store.dispatch(setSites(sites))
  } catch (e) {
    alert('could not geocode all sites', e)
    console.log('could not geocode all sites', e)
  } 
} 
catch {
  alert('There is problem while uploading. Please try after some time.')
}
}
