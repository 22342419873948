import {firebase, storage, db, correctedURL} from '../../utilities'

export const submit = (updateStatus, id) => async (files, filePath) => {
  const docSnap = await db.collection('projects').doc(id).get()

  const statuses = files.map((file) => {
    const fileRef = storage.ref().child(`${filePath}/${file.name}`)
    return fileRef.put(file)
  })

  const addToDoc = {intake: {files: []}}
  const totalCount = statuses.length
  let currentCount = 0
  statuses.forEach((status) => {
    status.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        const statuses = {
          [status.snapshot.ref.name]: {
            progress,
            size: snapshot.totalBytes,
          },
        }
        // Update the status to the callback provided if they gave us one.
        updateStatus && updateStatus({statuses})
      },
      (error) => {
        alert('Something went wrong uploading files to this project')
        console.log(`Something went wrong uploading files to this project`, error)
      },
      async () => {
        let fileMetadata = []

        const {bucket, fullPath, name} = status.snapshot.ref
        const {totalBytes: size} = status.snapshot
        const url = correctedURL(fullPath)

        //Add object containing file metadata to "files" array
        fileMetadata.push({
          bucket,
          fullPath,
          url,
          name,
          size,
          created: firebase.firestore.Timestamp.now(),
          file_type: 'file',
        })

        //Update status again now that we know we're fully uploaded.
        let currentStatus = {}
        currentStatus[name] = {
          size: size,
          progress: 100,
        }
        if (updateStatus) {
          updateStatus({statuses: {...currentStatus}})
        }

        addToDoc.intake.files = [...addToDoc.intake.files, ...fileMetadata]

        currentCount++
        if (totalCount === currentCount) {
          addToDoc.intake.files = [...(docSnap.data().intake.files || []), ...addToDoc.intake.files]

          console.log(addToDoc)
          await db.collection('projects').doc(id).set(addToDoc, {merge: true})
        }
      }
    )
  })
}
