import React from 'react'
import {Select} from 'antd'

export default function Dropdown({field}) {
  return (
    <Select style={{width: '20rem'}}>
      {field.options &&
        field.options.map((option, i) => (
          <Select.Option value={option.label.toLowerCase()} key={i}>
            {option.label}
          </Select.Option>
        ))}
    </Select>
  )
}
