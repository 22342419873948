import React from 'react'
import {Icon} from 'antd'
import PhotoRequired from './PhotoRequired'
import Required from './Required'
import MultiInput from './MultiInput'
import SingleInput from './SingleInput'
import TextAreaField from './TextAreaField'
import RadioButton from './RadioButton'
import Dropdown from './Dropdown'
import Checkboxes from './Checkboxes'
import Multiselect from './Multiselect'
import DateInput from './DateInput'
import TimeInput from './TimeInput'
import NumericInput from './NumericInput'
// import FileUpload from "./FileUpload"
import Templated from './Templated'

const componentNameMapping = {
  'info-box': MultiInput,
  'single-input': SingleInput,
  'numeric-input': NumericInput,
  'text-area-field': TextAreaField,
  'radio-button': RadioButton,
  dropdown: Dropdown,
  checkboxes: Checkboxes,
  multiselect: Multiselect,
  'date-input': DateInput,
  'time-input': TimeInput,
  // "file-upload": FileUpload,
  templated: Templated,
}

export default function Base({id, field}) {
  if (!field.type) return <div></div>
  const C = componentNameMapping[field.type]

  return (
    <div>
      <div>
        {field.required && <Required />}
        {field.label || field.question || field.tipText ? (
          <div className="bold linebreak">
            {field.label}
          </div>
        ) : (
          <div style={{fontStyle: 'italic', color: 'gray'}}>{`Empty ${field.type}`}</div>
        )}
      </div>
      {/* Displaying question is not required for info-box */}
      {field.type !== 'info-box' &&
      <div className="linebreak">{field.question}</div>
      }
      <div style={{pointerEvents: `none`}} className="linebreak">
        <C field={field} id={id} />
      </div>

      <small className="linebreak">{field.tipText}</small>
      {field.photoRequired && <PhotoRequired />}
      {field.requiredForFieldIntake && (
        <div className="padding-top-1 flex-row align-center">
          <Icon type="exclamation" style={{fontSize: '2.5rem'}} /> Required for Field Intake
        </div>
      )}
    </div>
  )
}
