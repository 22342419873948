import Papa from 'papaparse'

export default function makeCSV(textfile) {
  return new Promise((resolve, reject) => {
    Papa.parse(textfile, {
      error: (e) => reject(e),
      complete: (f) => resolve(f.data),
    })
  })
}
