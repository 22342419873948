import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {Card, Divider} from 'antd'
import {activate} from '../action_creators'
import {Fields, FieldTypes, SectionTitle} from '.'
import {dropCreateField, fieldDrop, optionDrop} from '.'
import NoContent from '../NoContent'
import {useLoggerEffect} from '../../effects'
import {store} from '../utilities'

const stop = (state) => ({
  section: state.sections[state.active.section],
  active: state.active.field,
  editing: state.editing,
})
const dtop = {activate}

export function FieldsWrapper({section, active, activate, editing, fields}) {
  const logger = useLoggerEffect()

  async function onDragEnd(result) {
    if (!result.destination) {
      logger.log('You dropped an item outside the droppable area', 'please try again', 5)
      return
    }

    if (result.destination.droppableId.includes('option')) {
      return optionDrop(result)
    }
    if (result.source.droppableId === 'fieldTypeIcons') {
      return dropCreateField(result)
    }
    fieldDrop(result)
  }
  function onDragStart(target) {
    if (target.draggableId.includes('fieldTypeIcons') && editing) {
      logger.log('You must close the field you are editing to drop more fields into the section')
      return
    }

    if (target.draggableId.includes('option')) {
      return
    }
  }

  function beforeDragStart(target) {
    // console.log("target:", target)

    //If you start to drag a field in the section stop editing and activate it
    if (target.source.droppableId === 'sectionsdroppable' && !(active === target.draggableId)) {
      // store.dispatch(edit(null))
      store.dispatch(activate('field', target.draggableId))
    }
  }

  

  if (section) {
    return (
      <Card>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} onBeforeDragStart={beforeDragStart}>
          <Droppable droppableId="fieldTypeIcons" isDropDisabled={true}>
            {(provided) => (
              <div ref={provided.innerRef}>
                <FieldTypes />
              </div>
            )}
          </Droppable>
          <SectionTitle />
          <Divider />
          <Droppable isDropDisabled={!!editing} droppableId="sectionsdroppable">
            {(provided, snapshot) =>
            <Fields fields={fields} section={section}  {...{provided, snapshot}} />}
          </Droppable>
        </DragDropContext>
      </Card>
    )
  } else {
    return <NoContent parent="Form" child="Section" childs="Sections" />
  }
}

const WrappedFieldsWrapper = connect(stop, dtop)(FieldsWrapper)
export default WrappedFieldsWrapper
