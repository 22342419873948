import {makeCSV} from '.'

export default function readUploadedText(file) {
  let fr = new FileReader()

  return new Promise((resolve, reject) => {
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.onerror = (e) => reject(console.log(`some kinda error converting file to text: `, e))
    fr.readAsText(file)
  }).then((file) => makeCSV(file))
}
