import React from 'react'
import {Input} from 'antd'
import './RubricPage.scss'

export function AutoRubricFormField({ labelText, value, placeholder, handleUpdate }) {
  return (
    <div style={{display: 'flex'}}>
      <div style={{width: '10%', margin: 10}}>
        <span><b>{labelText}</b></span>
      </div>
      <div style={{width: '80%'}}>
        <Input
          key={`labelValue-${labelText}-${value}`}
          defaultValue={value}
          onBlur={(e) => handleUpdate(e.target.value)}
          placeholder={placeholder}
          data-cy="rubricNameInput"
        />
      </div>
    </div>
  )
}
