// import DB from '../../../../DAL/DB'
// import convertSectionsAndFields from './convertSectionsAndFields'
// import convertRubrics from './convertRubrics'

// export default async function duplicate (id) {
//     try {
//         const batch = new DB.FirebaseBatch()
//         const fieldIdDict = await convertSectionsAndFields(id, batch)
//         await convertRubrics(id, fieldIdDict, batch)
//         await batch.commit()

//     }
//     catch (e) { console.log("failed to commit duplicate form", e) }
// }

import convertSectionsAndFields from './convertSectionsAndFields'
import convertRubrics from './convertRubrics'

export default async function duplicate(id) {
  try {
    const [newId, fieldIdDict] = await convertSectionsAndFields(id)
    await convertRubrics(id, newId, fieldIdDict)
  } catch (e) {
    console.log('failed to commit duplicate form', e)
  }
}
