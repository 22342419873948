import React from 'react'

const UserInformation = ({users = []}) => (
  <div className="padding-2 margin-top-2" style={{borderRadius: `0.5rem`, backgroundColor: `rgb(244, 246, 248)`}}>
    <h3>Uploaded Users</h3>
    <div
      className="background-white"
      style={{border: `0.1rem solid lightgrey`, padding: `1.5rem`, borderRadius: `0.5rem`}}
      data-cy="bulkUsers"
    >
      {users.map(({errors, geocode_results, name, email, address, city, state, zip, type, nabcep_ids}, i) => (
        <div className="flex-row align-center justify-center" key={i}>
          {errors && errors.type && (
            <div
              className="padding-2"
              style={{fontSize: `3rem`, position: `absolute`, background: `rgba(0, 0, 0, 0.3)`}}
            >
              {errors.type}
            </div>
          )}
          <div style={{flex: 1}}>
            <div className="padding-bottom-1">#{i + 1}</div>
            <div className="padding-right-1 bold">Geocoded Address</div>
            <div>{(geocode_results && geocode_results.formatted_address) || `(Failed to Geocode this address)`}</div>
            <div className="padding-right-1 bold">Your Address</div>
            <div>{`${address} ${city}, ${state} ${zip}`}</div>
            <div className="flex-row justify-space-between padding-top-2 padding-bottom-2" data-cy="bulkUserRow">
              <div className="flex-row">
                <div className="padding-right-1 bold">Name</div>
                <div>{name}</div>
              </div>
              <div className="flex-row">
                <div className="padding-right-1 bold">Email</div>
                <div>{email}</div>
              </div>
              <div>
                <div className="padding-right-1 bold">Type</div>
                <div>{type}</div>
              </div>
              {nabcep_ids && nabcep_ids.length > 0 && (
                <div className="flex-row">
                  <div className="padding-right-1 bold">NABCEP IDs</div>
                  <div>{nabcep_ids}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default UserInformation
