import React from 'react'
import {dateFilter} from '../../tableFilters'

export default function columnsMap(activeFilters = {sorter: {order: null, field: null}}, setModalVisible, setCancelId) {
  return [
    {
      title: `Message`,
      dataIndex: `message`,
      key: `message`,
    },
    {
      title: `Severity`,
      dataIndex: `severity`,
      key: `severity`,
    },
    {
      title: `Site`,
      dataIndex: `site.siteAddress`,
      key: `site`,
    },
    {
      title: `Site Owner`,
      dataIndex: `site.siteOwnerName`,
      key: `siteOwner`,
      sorter: true,
    },
    {
      title: `Created`,
      dataIndex: `created`,
      key: `created`,
      sorter: true,
      render: (t) => <div>{t ? t.toDate().toDateString() : `N/A`}</div>,
      ...dateFilter(activeFilters)(`created`),
    },
    {
      title: `Seen`,
      dataIndex: `seen`,
      key: `seen`,
    },
  ]
}
