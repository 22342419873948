export default function iterateQuestions(form, cb) {
  const {sections, order} = form

  console.info('iterateQuestions - ', form)
  const couplets = []
  for (let sId of order) {
    const section = sections[sId]
    for (let fId of section.order) {
      const {id, label, question} = section.fields[fId]
      if (question) {
        couplets.push([section, {id, label, question}])
      }
    }
  }

  const result = []
  for (let [section, field] of couplets) {
    result.push(cb(section, field))
  }

  return result
}
