export function setActive(state) {
  if (!['select-failures', 'update', 'create'].includes(state)) throw new Error('tried to set an invalid state')
  return {type: 'type', state}
}
export function setProject(project) {
  return {type: 'project', project}
}
export function setFailures(failures) {
  return {type: 'failures', failures}
}
export function setFailure(id, value) {
  return {type: 'failure', id, value}
}
export function deleteFailure(id) {
  return {type: 'deleteFailure', id}
}
export function setTicket(ticket) {
  return {type: 'setTicket', ticket}
}
export function setTicketField(id, value) {
  return {type: 'ticketField', id, value}
}
export function setCreateValid(value) {
  return {type: 'createValid', value}
}
export function setSelectedFailure(value) {
  return {type: 'selectedFailure', value}
}
export function reset(mode) {
  return {type: 'resetTicket', mode}
}
