import React, {useState} from 'react'
import {elastic} from '../../HOC/Elastic/ElasticHOC'
import {SearchOutlined} from '@ant-design/icons'
import SchedulingMap from '../SchedulingMap'
import Projects from '../Projects'
import {Button, Input} from 'antd'
import useGenerateQuery from './useGenerateQuery'
import useLoadSchedule from './useLoadSchedule'

export function RightPanel(props) {
  const {single} = props
  const [mapToggle, setMapToggle] = useState(true)

  const setTerm = useGenerateQuery(props)
  useLoadSchedule(single) // I think this is Api getting data from projects 

  return (
    <div>
      <div className="flex-row padding-1">
        <Button onClick={() => setMapToggle(!mapToggle)}> {mapToggle ? 'Project List' : 'Map'}</Button>
        <div className="padding-1"></div>
        {!single && (
          <Input onPressEnter={(e) => setTerm(e.target.value)} prefix={<SearchOutlined />} placeholder="Search" />
        )}
      </div>
      {/* the map has to stay in the DOM or it resets the center point to whatever the default is */}
      <div style={mapToggle ? {visibility: 'visible'} : {visibility: 'hidden', height: 0}}>
        <SchedulingMap />
      </div>
      {!mapToggle && <Projects single={single} />}
    </div>
  )
}

const WrappedRightPanel = elastic(RightPanel)
export default WrappedRightPanel
