import React, {useEffect, useState} from 'react'
import './intakeFailure.css'

function Field({text, missing}) {
  return <div className={text ? `` : `value-missing`}>{text ? text : missing}</div>
}

export default function IntakeFailure({failure = {}, enhanced}) {
  const [answer, setAnswer] = useState(null)
  useEffect(
    function () {
      if (!failure.value) return

      if (failure.value.values) {
        setAnswer(Object.keys(failure.value.values)[0])
        return
      }

      setAnswer(failure.value)
    },
    [failure]
  )

  console.log(answer)

  return (
    <div className={`intake-failure ${enhanced ? `enhanced` : ``}`}>
      {failure.images && (
        <div>
          <div className="subsection-title">Intake Images:</div>
          <div className="image-row">
            {Object.values(failure.images || {}).map((image) => (
              <img className="full-width" key={image.url} src={image.url}></img>
            ))}
          </div>
        </div>
      )}
      {enhanced && <span className="section-title">{`Section Title: ${failure.sectionTitle}`}</span>}
      {<Field text={`Label: ${failure.label}`} missing="missing label" />}
      {<Field text={`Question: ${failure.question}`} missing="missing question" />}
      <div className="answer-row flex-row align-center">
        <span className="answer-word">Answer:</span>
        {<Field text={answer} missing="missing" />}
      </div>
    </div>
  )
}
