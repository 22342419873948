import DB from '../../DAL/DB'
import {baseStore} from './utilities'
import {auth} from '../../Auth'
import SystemLogger from '../../SystemLogger'

const organizations = new DB.Organizations()

export default function logos(parentID, state, dispatch) {
  // Only allow PNGs for logos
  for (let file of state.files) {
    if (file.type !== `image/png`) {
      const logger = new SystemLogger({uid: parentID, path: `/users/${parentID}`})
      logger.event(`tried to upload a non-PNG logo, logo type is ${file.type}; canceling`, null, 5, {
        [auth.sunkaizenUser.id]: auth.sunkaizenUser.type,
      })
      return {store: {}, saveToDB: () => null}
    }
  }

  async function saveToDB(metadata) {
    await organizations.update(parentID, null, {testing: 'testing', logo: metadata})
  }
  const store = baseStore(`users/${parentID}/logo/`)

  return {store, saveToDB}
}
