import React from 'react'
import {Icon} from 'antd'

export default function Duplicate({errors}) {
  return (
    <div>
      {errors && (
        <div
          className="flex-row align-center justify-center color-white padding-0-5"
          style={{background: 'red', margin: '0 0 0.5rem 0', borderRadius: '0.5rem'}}
        >
          <Icon type="exclamation-circle" />
          <div style={{padding: '0 0 0 0.5rem'}}>Duplicate</div>
        </div>
      )}
    </div>
  )
}
