const capitalizationMap = {
  name: 'Full Name',
  type: 'User Type',
  email: 'Email',
  phone: 'Phone',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  nabcep_ids: 'NABCEP Ids',
}

export default capitalizationMap
