import DB from '../../DAL/DB'
import {store} from '../utilities'

export default function getFormFieldId() {
  const state = store.getState()
  const fs = [state.form.id, state.sections[state.active.section].id]

  // console.log(fs)

  const formField = new DB.FormField(...fs)
  return formField.makeId()
}
