import BaseModel from './BaseModel'
import {db} from '../utilities'
import {Site} from '../models'
import {JSONObject} from './Types'
import {staticMapKey} from '../config'
import {storage} from '../utilities'

async function getStaticThumbnail(site: JSONObject): Promise<void> {
  if (!site.geocode_results) throw new Error(`site has not been geocoded, cannot create a thumbnail map image for it`)

  const {lat, lng} = site.geocode_results.geometry.location

  const mapUrl =
    `https://maps.googleapis.com/maps/api/staticmap` +
    `?center=${lat},${lng}` +
    `&zoom=13&size=500x200` +
    `&maptype=roadmap` +
    `&markers=color:green%7C${lat},${lng}` +
    `&key=${staticMapKey}`

  const res = await fetch(mapUrl)
  const b = await res.blob()

  const imageRef = storage.ref().child(`siteThumbnails/${site.id}`)
  await imageRef.put(b)

  const {bucket, fullPath, name} = imageRef
  site = {...site, thumbnail: {bucket, fullPath, name}}
}

export default class Sites extends BaseModel {
  constructor() {
    super(db.collection('sites'))
  }

  async createSite(obj: Site): Promise<string | void> {
    try {
      const id = await super.create({}, obj, getStaticThumbnail)

      return id
    } catch (e) {
      console.log(e)
      return
    }
  }
}
