import React, {useEffect, useState, useReducer} from 'react'
import UserInformation from './UserInformation'
import Permissions from './Permissions'
import {auth} from '../Auth'
import {validateField, fieldNameConverter} from '../validators'
import {validators, usersOne} from './utilities'
import {loggerEffect, simpleGet} from '../effects'
import DB from '../DAL/DB'

const users = new DB.Users()

function reducer(state, action) {
  switch (action.type) {
    case 'setField':
      return {...state, [action.field]: action.value}
    case 'clearFields':
      return {errors: {}}
    default:
      return state
  }
}

export default function SingleUserBuilder({done, setDone}) {
  const [state, dispatch] = useReducer(reducer, {errors: {}})
  const [logger, setLogger] = useState({error: console.log, event: console.log})
  const [organization, setOrganization] = useState({})

  useEffect(loggerEffect(setLogger), [])
  useEffect(simpleGet('organizations', (auth.sunkaizenUser.organization || {}).id, setOrganization), [
    auth.sunkaizenUser,
  ])
  useEffect(
    function () {
      switch (done) {
        case 'done':
          finalize(true)
          setDone(null)
        case 'clear':
          dispatch({type: 'clearFields'})
        default:
          return
      }
    },
    [done]
  )

  async function finalize(create) {
    if (!auth.sunkaizenUser || !['client', 'admin', 'super-admin'].includes(auth.sunkaizenUser.type)) {
      return
    }
    setField('organization')({id: organization.id, name: organization.name, email: organization.email})
    if (create) await usersOne(state, logger)

    const unsubscribe = users.subscribe(state.id, null, ({data: [user]}) => {
      if (user.verified === 'verified') {
        unsubscribe()
        logger.event(`Successfully created user ${user.name}`)
        dispatch({type: 'clearFields'})
      } else if (user.verified === 'error') {
        logger.event(`User creation for ${user.name} failed. There is already a user with this email address`)
      }
    })
  }

  function setField(field) {
    return function (value) {
      console.log(field, value)

      if (field !== 'geocode_results') {
        const errors = {[field]: validateField(value)(validators[field])}
        dispatch({type: 'setField', field: 'errors', value: {...state.errors, ...errors}})
      }

      dispatch({type: 'setField', field, value})
    }
  }

  return (
    <div className="padding-2 background-white">
      {Object.values(state.errors).filter((error) => error.length > 0).length > 0 && (
        <div className="background-red flex-column" style={{zIndex: 10, position: 'sticky', top: '1rem'}}>
          {Object.entries(state.errors)
            .filter(([field, errors]) => errors.length > 0)
            .map(([field, errors]) => (
              <div className="color-white padding-1 flex-row align-start">
                <div className="padding-right-1">{fieldNameConverter[field]}</div>
                <div>
                  {errors.map((error) => (
                    <div key={`${field}_${error}`}>{error}</div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      )}
      {state.type === 'installer' && (
        <h4>Please ensure that your installer does not already exist before creating it</h4>
      )}
      <UserInformation fields={state} setField={setField} />
      {['scheduler', 'quality-manager'].includes(state.type) && <Permissions fields={state} setField={setField} />}
    </div>
  )
}
