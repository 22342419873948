import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {AutoRubricOption} from './AutoRubricOption'
import {AutoRubricFormField} from './AutoRubricFormField'
import {withRouter} from 'react-router-dom'
import {Divider, Select} from 'antd'
import './RubricPage.scss'

function stop(state) {
  return {rubrics: state.rubrics}
}
function dtop(dispatch) {
  return {
    enableAutoRubricValues: (value) => dispatch({type: 'enableAutoRubricValues', value: value}),
    setRubrics: (r) => dispatch({type: 'rubrics', value: r}),
  }
}

//  this function specifies auto rubric functionality 
// user can select rubric in this form
export function AutoRubric({rubrics, enableAutoRubricValues, setRubrics}) {
  const enableAutoRubricValue =
    rubrics &&
    Object.keys(rubrics).length > 0 &&
    rubrics[Object.keys(rubrics)[0]].autoRubricData &&
    rubrics[Object.keys(rubrics)[0]].autoRubricData.enableAutoRubric
  const [enableAutoRubric, setEnableAutoRubric] = useState(enableAutoRubricValue)
  const [labelValue, setLabelValue] = useState('')
  const [questionValue, setQuestionValue] = useState('')
  const [tipTextValue, setTipTextValue] = useState('')
  const [rubricOptions, setRubricOptions] = useState([{option: '', selectedRubric: ''}])
  const [createdRubrics, setCreatedRubrics] = useState([])
  const [selectedRubric, setSelectedRubric] = useState('')

  useEffect(() => {
    if (rubrics && Object.keys(rubrics).length > 0) {
      let selectedRubric1 = '';
       Object.keys(rubrics).forEach(rec => {
          if(rubrics[rec].isSelected === true){
            selectedRubric1 = rubrics[rec].id
          }
       })
        
      if(selectedRubric1) {
        setSelectedRubric(selectedRubric1)
      } else {
        setSelectedRubric('')
      }
    } 
  },[JSON.stringify(rubrics)])

  useEffect(() => {
    if (rubrics && Object.keys(rubrics).length > 0) {
      const rubricObj = rubrics[Object.keys(rubrics)[0]]
      const autoRubricData = rubricObj && rubricObj.autoRubricData
      if (autoRubricData) {
        setEnableAutoRubric(autoRubricData.enableAutoRubric)
        setLabelValue(autoRubricData.label)
        setQuestionValue(autoRubricData.autoRubricQuestion)
        setTipTextValue(autoRubricData.tipText)
        setRubricOptions(autoRubricData.options)
      } else {
        setRubricOptions([{option: '', selectedRubric: ''}])
      }
      setCreatedRubrics(Object.keys(rubrics).map((rec) => rubrics[rec]['name']))
    }
  }, [JSON.stringify(rubrics)])

  

  const handleUpdateRubric = (e) => {
    const rubrics1 = {...rubrics}
    if(selectedRubric){
      rubrics1[selectedRubric].isSelected = false
    } 
    rubrics1[e].isSelected = true 
    setSelectedRubric(e)
    setRubrics(rubrics1)
 }


  const handleUpdate = (prop, value) => {
    let autoRubricObj = {
      label: labelValue,
      autoRubricQuestion: questionValue,
      tipText: tipTextValue,
      options: rubricOptions,
      enableAutoRubric: enableAutoRubric,
    }
    autoRubricObj[prop] = value
    Object.keys(rubrics).forEach((rec) => {
      rubrics[rec]['autoRubricData'] = {...autoRubricObj}
    })
    if (prop === 'label') {
      setLabelValue(value)
    } else if (prop === 'autoRubricQuestion') {
      setQuestionValue(value)
    } else if (prop === 'tipText') {
      setTipTextValue(value)
    }
    enableAutoRubricValues(rubrics)
  }
  const handleAutoRubricSelection = (e) => {
    setEnableAutoRubric(e.target.checked)
    handleUpdate('enableAutoRubric', e.target.checked)
  }
  const handleUpdateRubricOptions = (rec, index, prop) => {
    const rubricOptions_ = [...rubricOptions]
    rubricOptions_[index][prop] = rec
    setRubricOptions(rubricOptions_)
    handleUpdate('options', rubricOptions_)
  }
  const handleRubricOptions = (recIndex, action) => {
    let rubricOptions_ = [...rubricOptions]
    if (action === 'add') {
      const firstIndexOptions = rubricOptions_.slice(0, recIndex + 1)
      const secondIndexOptions = rubricOptions_.slice(recIndex + 1, rubricOptions_.length)
      firstIndexOptions.push({option: '', selectedRubric: ''})
      setRubricOptions([...firstIndexOptions, ...secondIndexOptions])
      handleUpdate('options', [...firstIndexOptions, ...secondIndexOptions])
    } else {
      rubricOptions_ = rubricOptions_.filter((rec, index) => index !== recIndex)
      setRubricOptions(rubricOptions_)
      handleUpdate('options', rubricOptions_)
    }
  }
  return (
    <div style={{minWidth: '200px', marginTop: 10, fontSize: 14, background: '#FFF', textAlign: 'left', padding: 10}}>
      <input
        type="checkbox"
        key={`chk-${enableAutoRubric}}`}
        defaultChecked={enableAutoRubric}
        onChange={handleAutoRubricSelection}
      />
      <b> Enable Auto-Rubric</b>
      <Divider className='rubric-line-divider' />
      {enableAutoRubric && (
        <>
          <div style={{marginTop: '20px'}}>
            <span style={{fontSize: 14}}>
              <b>Auto-Rubric Question</b>
            </span>
            <Divider style={{margin: '.5rem'}} />
            <div className="ant-card-head" style={{marginTop: 15}}>
              <AutoRubricFormField
                placeholder={'A short string to represent the question.'}
                labelText={'Label'}
                value={labelValue}
                handleUpdate={(e) => handleUpdate('label', e)}
              />
              <AutoRubricFormField
                placeholder={'Question asked to determine which rubric should be automatically applied.'}
                labelText={'Question'}
                value={questionValue}
                handleUpdate={(e) => handleUpdate('autoRubricQuestion', e)}
              />
              <AutoRubricFormField
                placeholder={'Any additional text that will help the field user answer the question.'}
                labelText={'Tip Text'}
                value={tipTextValue}
                handleUpdate={(e) => handleUpdate('tipText', e)}
              />
            </div>
          </div>
          <div style={{marginTop: '20px', marginBottom: '20px', borderRadius: '2px solid #ccc', padding: '10px'}}>
            <span style={{fontSize: 14, marginBottom: 20}}>
              <b>Options</b>
            </span>
            {(rubricOptions || []).map((rec, index) => (
              <AutoRubricOption
                rec={rec}
                index={index}
                handleRubricOptions={(index, action) => handleRubricOptions(index, action)}
                handleUpdateRubricOptions={handleUpdateRubricOptions}
                createdRubrics={createdRubrics}
              />
            ))}
          </div>
        </>
      )}
       <div>
      <div className='condition-group-rel ant-card-head' key={`rubrics-${Object.keys(rubrics).length}`}>
          <span>Please select the Rubric</span>
          <Select
            key={`selectedRubric-${selectedRubric}-${rubrics.length}`}
            defaultValue={selectedRubric}
            className='condition-group-select rubric-select-rubric'
            placeholder='Please select the created Rubrics'
            onChange={handleUpdateRubric}
          >
             {Object.keys(rubrics).map(rec => 
               <Select.Option key={`rubric-${rubrics[rec].id}`} value={rubrics[rec].id}>{rubrics[rec].name}</Select.Option>
             )}
          </Select>
        </div>
      </div>
    </div>
  )
}

const WrappedAutoRubric = connect(stop, dtop)(withRouter(AutoRubric))
export default WrappedAutoRubric
