import React from 'react'
import {Field} from './Field'
import {connect} from 'react-redux'
import {fieldDependency, modified} from '../action_creators'
//Source: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component

const stop = (state) => ({editing: state.editing, state1: state})

const dtop = {fieldDependency, modified}

function FieldWrapper(props) {
  const {editing, id, children, fieldDependency, state1, modified} = props
  const fieldDependency1 = () => {
    modified(true)
  }

  return editing === id ? (
    <div id="field-wrapper">
      <Field {...props} fieldDependency={fieldDependency1}>
        {children}
      </Field>
    </div>
  ) : (
    <Field {...props} fieldDependency={fieldDependency1}>
      {children}
    </Field>
  )
}

const WrappedFieldWrapper = connect(stop, dtop)(FieldWrapper) // , dtop
export default WrappedFieldWrapper
