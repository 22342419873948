export default function getCityState(geocode_results) {
  const {address_components} = geocode_results
  const cityState = {}
  for (let c of address_components) {
    if (c.types.includes('locality')) cityState['city'] = c.long_name
    if (c.types.includes('administrative_area_level_1')) cityState['state'] = c.long_name
  }

  const {city, state} = cityState
  return `${city}, ${state}`
}
