import React from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {fieldTypeIcons, FieldTypeIcon} from '.'
import {Button, Divider} from 'antd'
import {CloseOutlined, SaveOutlined} from '@ant-design/icons'
import {connect} from 'react-redux'
import {colors} from '../../../config'
import save from '../../save'
import {withRouter} from 'react-router'
import {modified} from '../../action_creators'

const stop = (state) => ({isModified: state.modified})
const dtop = {modified}

export function FieldTypes({isModified, modified, history}) {
  function s() {
    save()
    modified(false)
  }

  return (
    <div className="flex-column align-center padding-1 background-quaternary radius-1 field-chooser-bar z10">
      {fieldTypeIcons.map((o, i) => (
        <Draggable key={o.id} draggableId={o.id} index={i}>
          {(provided, snapshot) => <FieldTypeIcon {...{...o, provided, snapshot}} />}
        </Draggable>
      ))}
      <Divider />
      <Button
        style={{fontSize: '2rem', background: colors.quaternary}}
        title="done"
        onClick={() => history.push('/forms')}
      >
        <CloseOutlined />
        {/* { true ? <CloseOutlined /> : <CheckOutlined /> } */}
      </Button>
      <Button style={{background: isModified ? colors.primary : colors.quaternary}} title="save" onClick={s}>
        <SaveOutlined style={{fontSize: '2rem', color: isModified ? 'white' : 'black'}} />
      </Button>
    </div>
  )
}

const WrappedFieldTypes = withRouter(connect(stop, dtop)(FieldTypes))
export default WrappedFieldTypes
