import React from 'react';
import { DatePicker, Icon, Tooltip } from 'antd';
import PhotoRequired from './PhotoRequired';
import { firebase } from '../../../utilities';
import { connect } from 'react-redux';
import { setField, updateProgress } from '../../action_creators';
import moment from 'moment';
import { fieldHasErrors } from '../../utilities';

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  value: state.fields[ownProps.id].value,
});
const dtop = (dispatch) => ({
  setField: ({ id, value }) => {
    dispatch(setField(id, value));
    dispatch(updateProgress());
  }
});

export function DateInput({ setField, id, field }) {
  const { value, question, label, required, tipText, photoRequired } = field;
  let val = value ? moment(value.toDate()) : null;
  const set = (value) => {
    field.value = value ? firebase.firestore.Timestamp.fromDate(value.toDate()) : null;
    setField({ id, value: field });
  };

  return (
    <div className="question-container">
      <div className="bold padding-bottom-1 linebreak question-title">
        {question}
        {required && (
          <Tooltip title="Required">
            <Icon type="exclamation-circle" style={{ fontSize: '1.5rem', padding: '0 0 0 0.5rem', color:'red' }} /> <span style={{ fontSize: '1rem', color:'red' }}> Required</span>
            </Tooltip>
        )}
      </div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{ fontWeight: 600 }}>
          {(label || '').toUpperCase()}
        </div>
        <div className="full-width" style={{ border: fieldHasErrors(field) ? '1px solid red' : 'initial' }}>
          <DatePicker className="full-width" value={val || null} onChange={set} />
        </div>
        <div className="linebreak">
          <small>{tipText && `${tipText}`}</small>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  );
}

const WrappedDateInput = connect(stop, dtop)(DateInput);
export default WrappedDateInput;
