import {auth} from '../../Auth'
import {validateResource, siteValidators, projectValidators} from '../../validators'
import {projectModify, projectsOne} from '.'

export default async function finalizeSingleProject(id, project, fields, logger, allowCreateProject= false) {
  try {
    const validationTargets = {...siteValidators, ...projectValidators}

    if (auth.sunkaizenUser.organization.id !== fields.organization.id) {
      delete validationTargets.client
      delete validationTargets.projectType

      delete fields.projectType
      delete fields.client
    }

    const errors = validateResource(fields)(validationTargets)
    const fatalErrors = []

    for (let error of errors) {
      const [[key, value]] = Object.entries(error)
      if (value.length > 0) {
        fatalErrors.push([key, value])
      }
    }

    for (let [k, v] of fatalErrors) {
      logger.error(`Error validating ${k}: ${JSON.stringify(v)}`)
    }
    if (fatalErrors.length > 0) throw new Error(`Validation error: project not created`)

    if (allowCreateProject){
      await projectsOne(fields, auth.currentUser.uid, allowCreateProject)
      return 'success';
    }
    else if (id) {
      await projectModify(project, fields, auth.currentUser.uid)
      logger.categoryId = project.id
      logger.log(`Project with address ${project.site.siteAddress} was successfully updated`)
      return 'success'
    } else {
      await projectsOne(fields, auth.currentUser.uid)
      return 'success'
    }
  } catch (e) {
    logger.category = null
    logger.categoryId = null
    if (e.message === 'ADDRESS_FAILED_GEOCODING') {
      console.log(e)
      logger.error(
        'Could not geocode the address you provided; please provide a valid address or choose a point on the map'
      )
    } else logger.error(`Project creation failed; contact your system administrator if this issue persists`)
    throw e
  }
}
