import React, {useState} from 'react'
import {auth} from '../Auth'
import {Button} from 'antd'
import TicketControlsModal from './TicketControlsModal'

const styles = {accept: '#6dbe44', reject: '#912240'}
function makeButtonStyle(style) {
  return {backgroundColor: styles[style], color: 'white'}
}

function FancyButton({setShow, label, type}) {
  return (
    <Button
      size="large"
      className="margin-bottom-1"
      style={makeButtonStyle(type)}
      onClick={() => setShow(type)}
      data-cy={`${type}WorkBtn`}
    >
      {label}
    </Button>
  )
}

export default function TicketControls({ticket}) {
  const [show, setShow] = useState(null)

  return (
    <div>
      <div className="ticket-controls">
        {ticket.assignee === auth.sunkaizenUser.id && ['Incomplete', 'Rejected'].includes(ticket.status) && (
          <Button
            disabled={!Object.keys(ticket.photos || {}).length}
            className="incomplete-assignee"
            onClick={() => setShow('submit')}
            data-cy="submitTicketBtn"
          >
            Submit Ticket
          </Button>
        )}
        <hr className="margin-1" />
        {ticket.creator === auth.sunkaizenUser.id && ticket.status === 'Submitted' && (
          <div className="flex-column">
            <FancyButton setShow={setShow} label="Accept Work" type="accept" />
            <FancyButton setShow={setShow} label="Reject Work" type="reject" />
          </div>
        )}
      </div>
      <TicketControlsModal {...{ticket, setShow, type: show}} />
    </div>
  )
}
