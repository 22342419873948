import { combineReducers } from 'redux'

const SET_PROJECT = `SET_PROJECT`
const SET_FIELDS = `SET_FIELDS`
const SET_FIELD = `SET_FIELD`
const SET_SECTION = `SET_SECTION`
const DELETE_PHOTO = `DELETE_PHOTO`
const SET_CORRECTION = `SET_CORRECTION`
const SET_ACTIVE = `SET_ACTIVE`
const SET_STATUS = `SET_STATUS`
const SET_LOADING = `SET_LOADING`
const DRAGGING = `DRAGGING`
const RESET = `RESET`
const HIGHLIGHT_ERRORS = `HIGHLIGHT_ERRORS`
const PHOTO_VIEWER = `PHOTO_VIEWER`
const REVIEW_MODE = `REVIEW_MODE`
const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
const SAVE_ANSWERS = 'SAVE_ANSWERS'
const SET_FORM_LOGO = 'SET_FORM_LOGO';

const meta = (state = { photoViewer: false, reviewMode: true }, action) => {
  switch (action.type) {
    case PHOTO_VIEWER:
      return { ...state, photoViewer: action.value }
    case REVIEW_MODE:
      return { ...state, reviewMode: action.value }
    default:
      return state
  }
}

const active = (state = { section: `` }, action) => {
  switch (action.type) {
    case SET_ACTIVE:
      return { ...state, [action.name]: action.value }
    case DRAGGING:
      return { ...state, dragging: action.dragging }
    case RESET:
      return { section: `` }
    default:
      return state
  }
}

const status = (state = { loading: false }, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.loading }
    case RESET:
      return { loading: false }
    default:
      return state
  }
}

const project = (
  state = {
    photos: {},
    intake: { corrections: {}, order: [], errors: {}, progress: { totalQuestions: 0, answeredQuestions: 0, percentComplete: 0 } },
    startedAt: null,
    formLogo: null,
  },
  action
) => {
  switch (action.type) {
    case SET_STATUS:
      return { ...state, status: action.status }
    case SET_PROJECT:
      return { ...state, ...action.project }
    case HIGHLIGHT_ERRORS:
      return { ...state, intake: { ...state.intake, errors: action.errors } }
    case SET_CORRECTION:
      const { corrections = {} } = state.intake
      if (action.correction !== undefined) {
        corrections[action.id] = action.correction
      } else {
        delete corrections[action.id]
      }
      return { ...state, intake: { ...state.intake, corrections } }
    case RESET:
      return {
        photos: {},
        intake: { corrections: {}, order: [], errors: {}, progress: { totalQuestions: 0, answeredQuestions: 0, percentComplete: 0 } },
        startedAt: null,
        formLogo: null,
      }
    case UPDATE_PROGRESS:
      //console.log('Reducer - UPDATE_PROGRESS:', action.progress)
      return { ...state, intake: { ...state.intake, progress: action.progress } }
    case SET_FORM_LOGO:
      //console.log('Reducer - SET_FORM_LOGO:', action.payload);
      return { ...state, formLogo: action.payload }
    default:
      return state
  }
}

const sections = (state = {}, action) => {
  switch (action.type) {
    case SET_PROJECT:
      return { ...state, ...action.project.intake.sections }
    case SET_SECTION:
      return { ...state, ...action.section }
    case RESET:
      return {}
    case UPDATE_PROGRESS:
      return state // Explicitly return the current state for UPDATE_PROGRESS action
    default:
      return state
  }
}

const fields = (state = {}, action) => {
  switch (action.type) {
    case SET_FIELDS:
      return { ...state, ...action.fields }
    case SET_FIELD:
      return { ...state, [action.id]: action.value }
    case DELETE_PHOTO:
      return { ...state, [action.id]: action.field }
    case RESET:
      return {}
    case UPDATE_PROGRESS:
      return state // Explicitly return the current state for UPDATE_PROGRESS action
    default:
      return state
  }
}

const answers = (state = {}, action) => {
  switch (action.type) {
    case SAVE_ANSWERS:
      return {
        ...state,
        ...action.answers,
      }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  project,
  sections,
  fields,
  active,
  status,
  meta,
  answers, // Add answers reducer
})

export default rootReducer
