import {store, generateProjectShell} from '../../utilities'
import {setSites, blankFields} from '../../action_creators'
import {addTipText, makeBatches, makeResources} from '.'
import DB from '../../../DAL/DB'
import {resourceType, validateResource} from '../../../validators'

const [projects, sites] = [new DB.Projects(), new DB.Sites()]

export default async function finalize(logger) {
  const state = store.getState()

  const [ss, fields] = [state.bulk.sites, state.fields]
  const {client, projectType, rubric = {}} = fields

  const intake = await generateProjectShell(projectType)
  // const addAnswers = applyAnswers(answers)

  const dbOps = []
  for (let s of ss) {
    const [pId, sId] = [projects.makeId(), sites.makeId()]
    const t = makeResources(s)(sId, pId).then(([site, project]) => {
      console.log(site, project)

      const ii = {...intake, sections: JSON.parse(JSON.stringify(intake.sections))}
      project = {...project, intake: ii, client, rubric}
      project.errors = validateResource(project)(resourceType[`project`])
      addTipText(project, s)

      return [
        {id: pId, data: project},
        {id: sId, data: {...site}},
      ]
    })

    dbOps.push(t)
  }

  try {
    const res = await Promise.all(dbOps)
    const batchJobs = makeBatches([].concat(...res))

    const results = await Promise.all(batchJobs.map((batch) => batch.commit()))
    if (!results.every((r) => !r)) {
      throw new Error(`failed to commit all projects to the db`)
    }

    if(res && res.length) {
     logger.event(`inserted ${res.length} projects into the database`, null, 3)
    } else {
      logger.event(`No Projects inserted into the database. Please try again`, null, 3)
    }
    store.dispatch(setSites([]))
    store.dispatch(blankFields())
  } catch (e) {
    logger.error('failed to commit sites and/or projects to the database', null, 5)
    console.log(e)
  }
}
