//Takes in an org role and returns the aggregation_type field
// that corresponds to it as found on 'question_scores' and 'project_scores' records
export function orgRole2aggType(org_role) {
  var orgRole2AggType = {
    [`Project Reviewer`]: `reviewer`,
    [`Client`]: `client`,
    [`Installer`]: `installer`,
    [`Field User`]: `field_user`,
  }

  return orgRole2AggType[org_role]
}
