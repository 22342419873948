import {useState, useEffect} from 'react'
import {correctedURL} from '../../utilities'

export default function useSnapshots(id, makeSubscription, subscribeResources) {
  const [snapshots, setSnapshots] = useState([])

  useEffect(function () {
    return makeSubscription(
      {
        paths: [{collection: 'snapshots'}],
        options: {where: [{field: 'projectId', test: '==', value: id}]},
      },
      'snapshots'
    )
  }, [])

  useEffect(
    function () {
      (async function () {
        const {snapshots: ss = []} = subscribeResources
        const pp = snapshots.map((snapshot) => correctedURL(snapshot.fullPath))
        await Promise.all(pp)
        setSnapshots(ss)
      })()
    },
    [subscribeResources]
  )

  return snapshots
}
