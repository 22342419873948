import React from 'react'
import {useContext, useRef, useState, useEffect} from 'react'
import {useGraphData} from './effects'
import {ReducerContext} from './reducer'
import {auth} from '../Auth'
import embed from 'vega-embed'

function makeGraphConfig(data) {
  return {
    data: {name: 'table', values: data},
    background: null,
    width: window.innerWidth * 0.7,
    height: window.innerHeight * 0.5,
    padding: 3,
    autosize: 'pad',
    layer: [
      {
        mark: {type: 'line', interpolate: 'monotone', strokeWidth: 6, color: '#5C8EF0'},
        encoding: {
          x: {field: 'date', type: 'temporal'},
          y: {field: 'score', type: 'quantitative'},
        },
      },
      {
        mark: {
          type: 'point',
          color: '#913831',
          shape: 'triangle',
          filled: true,
          size: 150,
        },
        encoding: {
          x: {
            field: 'date',
            type: 'temporal',
            title: null,
            axis: {
              labelAngle: -60,
              labelFontSize: 20,
            },
          },
          y: {
            field: 'score',
            type: 'quantitative',
            title: '30-day Project Average',
            axis: {
              titleFontSize: 25,
              labelFontSize: 15,
              offset: 10,
            },
          },
          tooltip: [
            {field: 'score', type: 'quantitative', title: 'Average Score'},
            {field: 'projects_included', type: 'quantitative', title: 'Number of Projects Included'},
            {field: 'date', type: 'temporal', title: 'Date'},
          ],
        },
      },
    ],
    config: {
      legend: {
        disable: true,
      },
    },
    scales: [
      {
        name: 'x',
        type: 'point',
        range: 'width',
        domain: {data: 'table', field: 'date'},
        title: '30-day Average Project Score',
        titleAnchor: 'Middle',
      },
      {
        name: 'y',
        type: 'linear',
        range: 'height',
        nice: true,
        zero: true,
        domain: [0, 100],
      },
    ],
  }
}

export default function OSPlot() {
  const {state} = useContext(ReducerContext)
  const plotRef = useRef(null)
  const [config, setConfig] = useState(undefined)
  useGraphData()

  useEffect(
    function () {
      const newConfig = makeGraphConfig(state.graphData)
      setConfig(newConfig)
    },
    [state.graphData, window.innerHeight, window.innerWidth]
  )

  useEffect(() => {
    (async function () {
      if (!plotRef || !plotRef.current) return
      if (!config) return

      await embed(plotRef.current, config)
    })()
  }, [plotRef.current, state.graphData, config])

  // embed( plotRef, config )

  return (
    <div className="background-none margin-right-2 padding-1">
      <h4>
        Projects Score Rolling Average for {auth.sunkaizenUser.organization.name} as {state.dropdown.orgRole}
      </h4>
      <div className="full-width" ref={plotRef} />
    </div>
  )
}
