import React, { useState, useEffect } from 'react'
import {connect} from 'react-redux'
import {Card} from 'antd'
import RubricQuestion from './RubricQuestion'

function stop(state, ownProps) {
  return {
    section: state.sections[ownProps.id],
    rubrics: state.rubrics,
  }
}

export function displayCount({count, singular, plural, none}) {
  if (!count || count <= 0) return none || `None`
  else if (count === 1) return `1 ${singular}`
  else if (count > 1) return `${count} ${plural || singular + `s`}`
}


export function syncQuestion({question,section})
{
  question.OptionActions && Object.values(question.OptionActions).map((action) => {
      if (action && action.length && action[0].conditionGroup) {
             syncConditionGroup(action[0].conditionGroup,section)
         }
    });
}
export function syncConditionGroup(conditionGroup,section)
{
  conditionGroup.cgList && conditionGroup.cgList.map((cgList) => {
    cgList.conditionList && cgList.conditionList.map((condition) => {
      updateSelectedOptionValue(condition,section);
    })
  });
}
export function updateSelectedOptionValue(condition,section){
  try {
    Object.values(section.fields).map((field) => {
      if (field.id === condition.selectedQuestion)
       field.options && field.options.map((option, index) => {
          if (index == condition.selectedOptionIndex) {
            condition.selectedOption = option.label;
          }
        })
    });
  }
  catch(ex)
  {
    console.log(condition,section)
  }

}

export function syncUpOptionValues({ id, section, rubrics }) {
  Object.keys(rubrics) &&
    Object.values(rubrics).map((rubric) => {
      Object.values(rubric.questions).map((question) => {
        syncQuestion({ question, section })
        checkifQuestionAvailableinRuberic({question,section,rubrics})
      })
    });
}

export function checkifQuestionAvailableinRuberic({ question, section, rubrics }){
  Object.keys(section.fields).map((questionId) => {
    Object.keys(rubrics) &&
    Object.values(rubrics).map((rubric) => {
      Object.keys(rubric.questions).map((question) => {
        if (!section.fields[question])
        {
          console.log(question,questionId)
        }
      })
    });
  });
}

export function RubricSection({id, section, rubrics}) {
  const fields = section.order
    .map((id) => (section.fields || {})[id])
    .filter((field) => {
      if (!field || !field.options) return false
      if ([`radio-button`, `dropdown`, `templated`, 'multiselect','checkboxes'].includes(field.type)) return true
      return false
    })
  
  const [selectedRubric, setSelectedRubric] = useState('')

  if (fields.length > 0) {
    syncUpOptionValues({id,section, rubrics})
  }

  useEffect(() => {
      if (rubrics && Object.keys(rubrics).length > 0) {
        const selectedRubric1 = Object.keys(rubrics).find(rec => rubrics[rec].isSelected === true)
        if(selectedRubric1) {
          setSelectedRubric(selectedRubric1)
        }
      } 
  },[JSON.stringify(rubrics)])

  const rubricCount = Object.keys(rubrics).length >= 1

  return (
    <Card
      title={
        <div className="flex-row justify-space-between">
          <strong>{section.title}</strong>
          <span style={{color: `#8A8C8F`}}>
            {displayCount({count: fields.length, none: `0 questions`, singular: `question`})}
            {` `}available for scoring
          </span>
        </div>
      }
      style={{margin: `1.5rem`, textAlign: `left`}}
    >
      {rubricCount && (
        <div>
          {!fields && <div>No scorable questions for this section.</div>}
          {fields.map((field) => (
            <RubricQuestion key={`${field.id}-${selectedRubric}`} id={field.id} 
            sectionId={section.id} style={{marginBottom: `1rem`}} 
            section={section} selectedRubric={selectedRubric} />
          ))}
        </div>
      )}
      {!rubricCount && <h3>Add a rubric above to get started</h3>}
    </Card>
  )
}

const WrappedRubricSections = connect(stop)(RubricSection)
export default WrappedRubricSections
