import {store} from '../utilities'

export default async function get(url, body) {
  const state = store.getState()

  const {customQuery} = state.tableVars.tableStrategy || {}

  if (customQuery) {
    const res = await customQuery(body)
    console.log(res)
    return res
  }

  const res = await (
    await fetch(url, {
      method: `POST`,
      mode: `cors`,
      headers: {'Content-Type': `application/json`},
      body: JSON.stringify(body),
    })
  ).json()

  return res
}
