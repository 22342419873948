import React, {useState, useEffect} from 'react'

export const googleMaps = () => (Component) => {
  function GoogleMaps({childRef, ...props}) {
    const [loaded, setLoaded] = useState(null)

    useEffect(function () {
      if (window.google && window.google.maps) setLoaded(true)
      else {
        window.addEventListener('storage', function (e) {
          if (e.key === 'googleMapsLoaded') {
            console.log('loaded')
            setLoaded(true)
            window.removeEventListener('storage', this)
          }
        })
      }
    }, [])

    return <Component {...props} loaded={loaded} ref={childRef} />
  }

  return React.forwardRef((props, ref) => <GoogleMaps {...props} childRef={ref} />)
}
