import React from 'react'
import NewTable from '../UnifiedList/Provider'

export default function ProjectsRequestsList(props) {
  return (
    <div className="full-width flex-column padding-2 align-start">
      <NewTable {...props} strategy="requests" />
    </div>
  )
}
