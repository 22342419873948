import BaseModel from './BaseModel'
import {db, firebase} from '../utilities'
import Auth from '../Auth'
export default class Users extends BaseModel {
  constructor() {
    super(db.collection('users'))
  }

  async getByEmail(email: string) {
    const snap = await this.baseQuery.where('email', '==', email).get()
    const data = snap.docs.map((doc) => doc.data())

    return {
      data,
      ok: data.length > 0,
    }
  }

  async getAllClients() {
    const snap = await this.baseQuery.where('type', '==', 'client').get()
    const data = snap.docs.map((doc) => doc.data())

    return data
  }
}
