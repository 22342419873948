import React, {useContext, useEffect} from 'react'
import {Button, Modal} from 'antd'
import {ReducerContext, InputForm, reverseTransform} from '.'
import ModalFooter from './ModalFooter'

export default function AddScoringSystem({type, setType, selected, style, className}) {
  const {state, dispatch} = useContext(ReducerContext)

  useEffect(
    function () {
      if (!selected || !selected.id) return

      const {percentage_failure: percentOverall, name, criticality_map: criticalityMap, id} = selected
      const criticalities = reverseTransform(criticalityMap)
      const value = {percentOverall, name, criticalities, id}

      dispatch({type: 'merge', value})
    },
    [selected]
  )
  useEffect(
    function () {
      if (!type) return dispatch({type: 'show', value: false})
      dispatch({type: 'show', value: true})
    },
    [type]
  )

  return (
    <div>
      <Button
        style={style}
        type="primary"
        className={className}
        onClick={() => {
          dispatch({type: 'clear'})
          setType('create')
        }}
        data-cy="addBtn"
      >
        Add
      </Button>
      <Modal
        visible={state.show}
        width="80rem"
        footer={type === 'create' ? <ModalFooter setType={setType} type={type} /> : null}
        onCancel={() => setType(null)}
      >
        <InputForm type={type} />
      </Modal>
    </div>
  )
}
