import {createBrowserHistory} from 'history'
import {auth} from './Auth'

function addPrefix(history) {
  
  const {push} = history

  history.push = (path, state = false) => {
    

    const prefix = auth.sunkaizenUser ? `/organization/${auth.sunkaizenUser.organization.id}` : ''
    if(state) {
      push(`${prefix}${path}`, {...state})
    } else {
      push(`${prefix}${path}`)
    }
  }

  return history
}

export const history = addPrefix(createBrowserHistory())
