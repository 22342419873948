import React, {useState, useEffect} from 'react'
import Filters from './Filters'
import ProjectBar from './ProjectBar'
import {auth} from '../../Auth'
import {subscribe} from '../../HOC/Firebase/SubscribeHOC'
import {filterClients, makeFilterProps} from './utilities'

export function ProjectStatusBar({projects, filters, setFilters, clearFilters, makeSubscription, subscribeResources}) {
  const [clients, setClients] = useState([])
  const [statuses, setStatuses] = useState([])

  useEffect(function () {
    return makeSubscription(
      {
        paths: [{collection: 'users'}],
        options: {where: [{field: 'type', test: '==', value: 'client'}]},
      },
      'clients'
    )
  }, [])

  useEffect(
    function () {
      const {clients: cc} = subscribeResources
      if (!cc) return

      setClients(filterClients(cc))
    },
    [subscribeResources.clients]
  )

  useEffect(
    function () {
      const ss = {}
      for (let project of projects) {
        ss[project.status] = true
      }
      setStatuses(Object.keys(ss))
    },
    [projects]
  )

  const type = auth.sunkaizenUser && auth.sunkaizenUser.type ? auth.sunkaizenUser.type : undefined

  return (
    <div className="flex-column full-width">
      <Filters {...makeFilterProps({filters, clearFilters, setFilters, clients, statuses, type})} />
      <ProjectBar projects={projects} type={type} />
    </div>
  )
}

const WrappedProjectStatusBar = subscribe()(ProjectStatusBar)
export default WrappedProjectStatusBar
