import * as Utils from './utilities'

/*
I want to import the validator into the component that is creating a resource
	and check against the validator whenever state is updated
	then change the validator's default requirements dynamically
	and include all validation errors as part of the resource creation
*/

export const validators = {
  lengthMin: (f, v = 3) => {
    if (f && f.length < v) return `must be at least ${v} characters`
  },
  lengthMax: (f, v = 40) => {
    if (f && f.length > v) return `may be no longer than ${v} characters`
  },
  required: (f) => {
    if (!f) return `this field is required`
  },
  email: (f) => {
    if (f && !Utils.emailRx.test(f)) return `is not a valid email address`
  },
  lat: (f) => {
    if (f && (isNaN(f) || f > 90 || f < -90)) return `must be a number between -90 and 90`
  },
  lng: (f) => {
    if (f && (isNaN(f) || f > 180 || f < -180)) return `must be a number between -180 and 180`
  },
  phone: (f) => {
    if (f && !(/\d+/.test(f) || /\(\d{3}\)[- ]\d{3}[- ]\d{4}/.test(f) || /\d{3}[- ]\d{3}[- ]\d{4}/.test(f)))
      return `invalid phone number format`
  },
  zip: (f) => {
    if (isNaN(f) || !/^\d{5}-?(\d{4})?$/.test(f)) return `not a valid zip code`
  },
  exists: (f) => {
    if (!f) {
      return 'required'
    }
  }
}

const projectType = (f) => {
  if (!f.title) return 'is required'
}
const client = (f) => {
  if (!f.id) return 'is required'
}

export const projectValidators = {
  installer: [validators.lengthMin, validators.lengthMax],
  inspector: [validators.lengthMin, validators.lengthMax],
  client: [client],
  projectType: [projectType],
  rubric: [validators.exists],
}

export const fieldNameConverter = {
  siteAddress: `Site Address`,
  siteOwnerName: `Site Owner Name`,
  siteRepPhone: `Site Contact Phone Number`,
  latitude: `Latitude`,
  longitude: `Longitude`,
  siteRepName: `Site Represtative Name`,
  siteRepEmail: `Site Contact Email Address`,
  name: `User Name`,
  email: `Email Address`,
  address: `Address`,
  city: `City`,
  state: `State`,
  zip: `Zip Code`,
  type: `Project Type`,
  installer: `Installer`,
  inspector: `Field User`,
  client: `Client`,
}

export const siteValidators = {
  // siteAddress: [validators.required],
  siteOwnerName: [validators.required, validators.lengthMin, validators.lengthMax],
  latitude: [validators.lat],
  longitude: [validators.lng],
  siteRepName: [validators.lengthMin, validators.lengthMax],
  siteRepPhone: [validators.phone, validators.required],
  siteRepEmail: [validators.email, validators.required],
}

export const userValidators = {
  name: [validators.required, validators.lengthMin, validators.lengthMax],
  email: [validators.email],
  address: [validators.required, validators.lengthMin],
  city: [validators.required],
  state: [validators.required],
  zip: [validators.zip],
}

export const validateResource = (resource) => (validator) => {
  return (
    Object.entries(validator).map(([k, v]) => {
      // strip the field before performing validation
      let field = resource[k] || ''
      if (typeof field === 'string' || field instanceof String) {
        field.replace(/^\s+|\s+$/g, '')
      }
      // else field = ''
      return {[k]: v.map((f) => f(field)).filter((i) => i)}
    }) || []
  )
}

export const validateField =
  (field, ...rest) =>
  (validators = []) => {
    return validators.map((f) => f(field, ...rest)).filter((i) => i)
  }

export const resourceType = {
  project: projectValidators,
  site: siteValidators,
  user: userValidators,
  all: {...siteValidators, ...projectValidators, ...userValidators},
}
