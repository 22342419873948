import {useContext, useEffect} from 'react'
import {useDBSubscribe} from '../../effects'
import DB from '../../DAL/DB'
import {ReducerContext} from '../reducer'

const subscriptions = new DB.Subscriptions()

export default function useGetUsers() {
  const {dispatch} = useContext(ReducerContext)
  const ss = useDBSubscribe(subscriptions)

  useEffect(
    function () {
      dispatch({type: 'subscriptions', value: ss})
    },
    [ss]
  )
}
