import TopLevelState from './TopLevelState'

import CompletedWorkDetail from './CompletedWorkDetail'
import DetailsBox from './DetailsBox'
import TicketControls from './TicketControls'
import TicketComments from './TicketComments'

export {CompletedWorkDetail, DetailsBox, TicketControls, TicketComments}

export default TopLevelState
