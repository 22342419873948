import {history} from '../../../history'

export default function onRow(record) {
  return {
    style: {cursor: `pointer`},
    onClick: (e) => {
      if (e.metaKey) return window.open(`/tickets/${record.id}`)
      history.push(`/tickets/${record.id}`)
    },
  }
}
