import {useEffect, useContext} from 'react'
import DB from '../../DAL/DB'
import {useDBSubscribe} from '../../effects'
import {auth} from '../../Auth'
import {ReducerContext} from '../reducer'
import {orgRole2aggType} from '../orgRolesEnum'

const rolling_average_scores = new DB.RollingAverageScores()

export default function useRollingAverages() {
  const {dispatch, state} = useContext(ReducerContext)

  const agg_type = orgRole2aggType(state.dropdown.orgRole)

  const ra = useDBSubscribe(rolling_average_scores, {
    filters: [
      ['aggregated_by', '==', agg_type || 'none'],
      [`${agg_type}_org_id`, '==', auth.sunkaizenUser.organization.id],
      ['rubric', '==', state.dropdown.rubric || 'non-existant rubric'],
    ],
  })

  useEffect(
    function () {
      dispatch({type: `projectAvgs`, value: ra})
    },
    [ra]
  )
}
