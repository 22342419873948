import {useState, useEffect} from 'react'
import {db} from '../../../utilities'
import {auth} from '../../../Auth'

export default function useSubscribePermissions() {
  const [qualifications, setQualifications] = useState({})
  const [clients, setClients] = useState({})

  useEffect(() => {
    const subscriptions = []

    const unsubscribeQualifications = db
      .collection('forms')
      .where('organization.id', '==', auth.sunkaizenUser.organization.id)
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const qualifications = {}
          snapshot.forEach((form) => {
            qualifications[form.id] = {
              name: form.data().title || '',
              id: form.id,
            }
          })
          setQualifications(qualifications)
        }
      })
    subscriptions.push(unsubscribeQualifications)

    const unsubscribeClients = db
      .collection('users')
      .where('type', '==', 'client')
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const clients = {}
          snapshot.forEach((client) => {
            clients[client.id] = {
              name: client.data().name,
              id: client.id,
            }
          })
          setClients(clients)
        }
      })
    subscriptions.push(unsubscribeClients)

    return () => subscriptions.map((s) => s())
  }, [])

  return [qualifications, clients]
}
