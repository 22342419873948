import React from 'react'
import {Radio} from 'antd'

export default function Branding({setField, options}) {
  return (
    <div>
      <div className="bold padding-top-2">Branding</div>
      <Radio.Group
        onChange={(e) => setField('branding', e.target.value)}
        className="flex-column"
        value={options.branding}
      >
        {['IBTS Logo', 'IBTS + Client Logo', 'Client Logo'].map((name) => (
          <Radio className="margin-top-1" value={name}>
            {name}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  )
}
