import {useEffect} from 'react'
import {store} from '../utilities'
import {activate, category} from '../action_creators'
import DB from '../../DAL/DB'

export default function useGetFields() {
  useEffect(function () {
    const state = store.getState()
    if (!state.form.id || !state.active.section) return

    const {sections} = state
    const unsubscribes = []
    for (let sectionId in sections) {
      const fields = new DB.FormField(state.form.id, sectionId)
      unsubscribes.push(
        fields.subscribe(null, null, ({data, ok}) => {
          if (!ok) return

          const ff = {}
          for (let field of data) {
            ff[field.id] = field
          }
          store.dispatch(category('fields')(ff))

          const section = state.sections[state.active.section]
          if (sectionId === state.active.section && section.order && !state.active.field)
            store.dispatch(activate('field', section.order[0]))
        })
      )
    }
    return () => {
      unsubscribes.forEach((unsubscribe) => {
        unsubscribe()
      })
    }
  }, [])
}
