import React from 'react'

export function message() {
  return (
    <div className="full-width" style={{fontSize: `75%`}}>
      <p>Drag and drop a CSV of user information here.</p>
      <p>User information has the following format:</p>
      <table className="full-width">
        <tbody>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
            <th>Type</th>
            <th>NABCEP_IDs</th>
          </tr>
          <tr>
            <td>Bob John</td>
            <td>bob@john.com</td>
            <td>123 Street Lane Suite 3</td>
            <td>Pleasanton</td>
            <td>CA</td>
            <td>12345</td>
            <td>client</td>
            <td>4123x24uijsdlf1238</td>
          </tr>
        </tbody>
      </table>
      <p style={{padding: `2rem 0 0 0`}}>
        Please include all the headers on your CSV, even if you do not provide any values for them, or user creation
        will fail
      </p>
    </div>
  )
}
