import DB from '../../../DAL/DB'

const users = new DB.Users()

export default async function validateInstaller(si) {
  if (!si) {
    throw new Error(`No Installer was assigned to this project`)
  }

  try {
    const {ok, data} = await users.get(null, {
      filters: [
        ['type', '==', 'installer'],
        ['name', '==', si],
      ],
    })
    const [user] = data
    if (ok) return user
  } catch (e) {
    console.log(`error validating field user provided`, e)
    throw new Error(`The provided Installer does not exist ${si}`)
  }
}
