import {store} from '../utilities'
import {activate} from '../action_creators'
import addSection from '../addSection'

export default function activateSection() {
  return function () {
    const state = store.getState()
    const {active, form} = state

    if (active.section) return
    if (form && form.order && !form.order.length) {
      addSection()
      return
    }
    if (Array.isArray(form.order)) activate('section', form.order[0])
  }
}
