import React, {useState, useEffect} from 'react'
// import PropTypes from "prop-types"
import {Modal, Input, Button} from 'antd'
import {auth} from '../Auth'
import DB from '../DAL/DB'
import {makeQuery} from './onChange'
import SystemLogger from '../SystemLogger'

import QueryVisualFormatter from './QueryVisualFormatter'

export default function SavedFilters({show, setShow}) {
  const [name, setName] = useState(null)
  const [logger, setLogger] = useState(null)
  const [filter, setFilter] = useState(null)

  useEffect(function () {
    const logger = new SystemLogger(window.location.pathname, auth.sunkaizenUser.id)
    setLogger(logger)
  }, [])

  useEffect(
    function () {
      if (show === true) setFilter(makeQuery())
    },
    [show]
  )

  async function saveFilter() {
    const filter = makeQuery()
    filter.from = 0

    const users = new DB.Users()
    const data = {
      projectFilters: [...auth.sunkaizenUser.projectFilters, {filter, name}].filter((i) => i),
    }

    try {
      await users.update(auth.sunkaizenUser.id, null, data)

      setName(null)
      setShow(false)
    } catch (e) {
      console.log(e)
      logger.log(e.message)
    }
  }

  return (
    <Modal
      title="Save a Project Filter"
      visible={show}
      onOk={(args) => {
        console.log(args)
      }}
      onCancel={() => setShow(false)}
      footer={null}
      width="60%"
      maskStyle={{background: 'rgba(0, 0, 0, 0.3)'}}
    >
      <div>
        <div
          className="flex-column thin-lightgrey-border padding-1 padding-2-bottom"
          style={{margin: '1rem 1rem 1rem 0rem'}}
        >
          <QueryVisualFormatter filter={filter} />
        </div>
        <Input
          placeholder="Name your filter"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{marginBottom: '1rem'}}
        />
        <Button type="default" onClick={saveFilter}>
          Add
        </Button>
        <div className="flex-column padding-2-top">
          <div className="bold">These Are Your Current Saved Filters</div>
          <div className="padding-1">
            {auth.sunkaizenUser &&
              auth.sunkaizenUser.projectFilters &&
              auth.sunkaizenUser.projectFilters.map((filter, i) => <div key={i}>{filter.name}</div>)}
          </div>
        </div>
      </div>
    </Modal>
  )
}
