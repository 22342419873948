import React from 'react'
import {Menu, Icon} from 'antd'
import {auth} from '../Auth'
import {Link} from 'react-router-dom'

export default function Overlay() {
  return (
    <Menu style={{background: 'white', border: 'none', boxShadow: 'none'}}>
      {auth.sunkaizenUser && (
        <Menu.Item>
          <Link to="/settings/account">Account Info</Link>
        </Menu.Item>
      )}
      <Menu.Item>
        <div onClick={auth.logout} data-cy="logoutOnClick">
          <Icon type="logout" className="padding-right-1" />
          Logout
        </div>
      </Menu.Item>
    </Menu>
  )
}
