import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {modify} from '../action_creators'
import LabelQuestionTip from './LabelQuestionTip'
import Required from './Required'

const mapStateToProps = (state, ownProps) => ({
  field: state.fields[ownProps.id] || {},
})

const mapDispatchToProps = (dispatch) => ({
  modify: ({key, value}) => dispatch(modify('field')(key, value)),
  add: (field) => {
    const options = [
      {label: 'Pass', requireFollowup: false},
      {label: 'Fail', requireFollowup: false},
      {label: 'Missed', requireFollowup: false},
      {label: 'N/A', requireFollowup: false},
    ]
    dispatch(modify('field')(field.id, {...field, options}))
  },
  delete: () => {
    console.log('this should remove the options from the store')
  },
})

export function TemplatedBase({add, field, modify}) {
  useEffect(function () {
    if (!field.options) add(field)
  }, [])

  return (
    <div>
      <LabelQuestionTip {...{modify, field}} />
      {field && field.type !== 'info-box' &&
      <Required {...{id: field.id, field, modify}} />
      }
    </div>
  )
}

const WrappedTemplated = connect(mapStateToProps, mapDispatchToProps)(TemplatedBase)
export default WrappedTemplated
