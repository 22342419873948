import DB from '../../DAL/DB'
const ScoringSystems = new DB.ScoringSystems()

export async function scoreProject(project) {
  const {rubric, intake} = project
  if (!rubric) return Promise.resolve({})

  const {scoringSystemId} = rubric
  if (!scoringSystemId) return Promise.resolve({})

  try {
    const resp = await ScoringSystems.get(scoringSystemId)
    const scoringSystem = resp.data[0]
    if (!scoringSystem) return null

    const {criticality_map = {}, percentage_failure = null} = scoringSystem
    const criticality_values = {}
    for (let k in criticality_map) {
      criticality_values[k] = 0
    }

    let [total, pass, scored] = [0, 0, {}]

    // console.info('Rubric:', JSON.stringify(rubric, null, 2))

    for (let k in intake.sections) {
      const section = intake.sections[k]
      for (let field of section.fields) {
        if(field.type !== "multiselect"){
          const [sc, t, p, cv] = scoreField(field, rubric)

          if (sc) scored[field.id] = {...sc, sectionId: section.id}
          if (t) total++
          if (p) pass++
          if (cv) criticality_values[cv]++
        } else {
          console.info(`The field ${field.id} is multi-select and not required for scoring`, rubric.id)
        }
      }
    }

    const rawData = {pass, total, percentage_failure, scored}
    const score = `actual: ${total > 0 ? Math.round((100 * pass) / total) : 0}%; minimum: ${percentage_failure}%`
    const criticalities = {}
    for (let name in criticality_map) {
      criticalities[name] = {actual: criticality_values[name], max: criticality_map[name]}
    }

    return {score, criticalities, rawData}
  } catch (e) {
    console.log(`error scoring project`, e)
  }
}

function scoreField({id, value = {}, ...rest}, rubric) {
  let [scored, total, pass, criticality_value] = [false, false, false, false]

  // if the field is in the rubric, it gets checked but not all fields are in rubrics
  if (rubric.questions[id] && value && value.values) {
    const {options = {}, criticalityCategory} = rubric.questions[id]
    const v = Object.keys(value.values || {})

    if (v.length > 0) {
      const outcome = options[v[0]]
      // console.info(`field ${id} has outcome`, v, rubric.questions[id].options)

      switch (outcome) {
        case `Ignore`:
          console.info(`Ignoring field ${id}`)
          break
        case `Pass`:
          total = true
          pass = true
          scored = {id, value, ...rest, score: outcome}
          break
        case `Fail`:
          total = true
          criticality_value = criticalityCategory
          scored = {id, value, ...rest, score: outcome}
          break
        default:
          total = true
          criticality_value = criticalityCategory
      }
    } else {
      console.log(`the question is broken, field id:`, id)
    }
  } else {
    console.info(`field ${id} is not in the rubric`)
  }

  return [scored, total, pass, criticality_value]
}
