export default function formatProjects(projects, strategies) {
  if (!projects || !projects.length) return null

  const header = makeHeader(strategies)
  const rows = projects.map((project) => formatProject(project, strategies))

  return [header, ...rows]
}

function formatProject(project, strategies) {
  const row = [project.id, project.site.siteAddress, project.site.siteOwnerName]

  const answers = {} // { [question id]: { [answer]: true }}

  // extract answer map for each question
  if (project.intake?.sections) {
    const {
      intake: {sections},
    } = project
    const keys = Object.keys(sections)

    keys.forEach((s) => {
      for (let q in sections[s].fields) {
        if (sections[s].fields[q].value?.values)
          answers[q] = sections[s].fields[q].value?.values
      }
    })
  }
  console.info('Answers:', answers)

  const values = strategies.map((s) => {
    return isUsed(project, answers, s)
  })
  return [...row, ...values]
}

function isUsed(project, answers, strategy) {
  const {rubric} = project

  if (!rubric) {
    return ''
  }

  const {questions} = rubric

  for (let q in questions) {
    const {selectedActions} = questions[q]
    for (let a in selectedActions) {
      // strategy is matched
      if (selectedActions[a] == strategy) { // strategy is matched with the selected action
        if (answers[q] && answers[q][a]) { // answered
          return '1'
        }
      }
    }
  }
  return ''
}

function makeHeader(strategies) {
  return ['Project ID', 'Site Address', 'Owner', ...strategies]
}
