import React, {useState, useEffect} from 'react'
import {Select, Button} from 'antd'

const defaultConfiguration = ['Intake Report', 'Photos', 'History Log', 'Comments', 'Include Blank Fields']

function ReportConfiguration({config: {id, name, entries}, set}) {
  return (
    <div className="flex-column flex-align-start padding-top-bottom-1">
      <div className="padding-right-1 bold">{name}</div>
      <Select mode="multiple" value={entries} defaultValue={defaultConfiguration} onChange={(e) => set(id)(name)(e)}>
        {Object.entries(entries).map(([name, value]) => (
          <Select.Option value={name}>{value}</Select.Option>
        ))}
      </Select>
    </div>
  )
}

export default function ReportDefaultsConfiguration({user}) {
  const [reportConfiguration, setReportConfiguration] = useState(user.reportConfiguration || {})
  const {qualifications = {}} = user
  const set = (id) => (name) => (entries) => {
    if (!entries.length) delete reportConfiguration[id]
    else reportConfiguration[id] = {name, entries}
    setReportConfiguration(reportConfiguration)
    // change(reportConfiguration)
    console.log(reportConfiguration)
  }

  useEffect(
    function () {
      if (Object.keys(reportConfiguration).length) {
        for (let qualification in qualifications) {
          if (!reportConfiguration[qualification]) {
            set(qualification)(qualifications[qualification])(defaultConfiguration)
          }
        }
      }
    },
    [Object.keys(qualifications).length]
  )

  const initialize = () => {
    const rc = {def: {name: 'Default', entries: defaultConfiguration}}
    Object.entries(qualifications).map(([id, name]) => {
      rc[id] = {name, entries: defaultConfiguration}
    })
    setReportConfiguration(rc)
  }

  return (
    <div className="flex-column flex-align-start padding-top-bottom-1">
      <div>
        {Object.keys(reportConfiguration).length ? (
          <div>
            {Object.entries(reportConfiguration).map(([id, {name, entries}]) => (
              <ReportConfiguration set={set} config={{id, name, entries}} />
            ))}
          </div>
        ) : (
          <Button onClick={initialize}>Change System Defaults</Button>
        )}
      </div>
    </div>
  )
}
