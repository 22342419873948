import {headerMap} from './headerOrder'

const getComments = (project) => {
  const intakeFields = []
  const sections = Object.values(project.intake.sections)

  sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (Array.isArray(field.options)) {
        field.options.forEach((option) => {
          if (option.requireFollowupComments) {
            const {label} = option
            if (field.value?.followup[label]?.requireFollowUpComment) {
              intakeFields.push({
                section: section.title,
                question: field.question,
                answer: label,
                comment: field.value.followup[label].requireFollowUpComment,
              })
            }
          }
        })
      }
    })
  })

  // Question, Answer, Comment
  const cells = []
  intakeFields.forEach(({question, answer, comment}) => {
    cells.push(question)
    cells.push(answer)
    cells.push(comment)
  })
  return cells
}

export default function flattenProject(project, headers, includeIntakeComments) {
  let cells = []

  const hm = headerMap(project)
  for (let header of headers) {
    cells.push(hm[header])
  }

  if (includeIntakeComments) {
    cells = [...cells, ...getComments(project)]
  }

  return cells
}
