import React from 'react'
import {Tooltip} from 'antd'

export default function FieldTypeIcon({provided, id, type, icon: Icon, title}) {
  return (
    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
      <Tooltip placement="left" title={title}>
        <div className="margin-1" id={id} type={type}>
          <Icon style={{fontSize: '2rem'}} />
        </div>
      </Tooltip>
    </div>
  )
}
