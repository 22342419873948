import React from 'react'

export default function Header({status, completed_on}) {
  return (
    <div className="title-bar flex-row">
      <h4 className="padding-0 margin-0">Project Details</h4>
      <div>
        <div className="status-bubble">{status}</div>
        {completed_on && <div>{completed_on.toDate().toDateString()}</div>}
      </div>
    </div>
  )
}
