import React from 'react'
import {onChange} from './onChange'
import {filterBarSelect, setTableControls} from './action_creators'
import {connect} from 'react-redux'

import './Styles.css'

const sToP = (store) => ({
  selected: store.query.status,
  resourceKey: (store.tableVars.tableStrategy || {}).tabs,
  counts: store.tabs,
  loading: store.tableControls.loading,
  tableControls: store.tableControls,
})

const dToP = (dispatch) => ({
  select: (selected) => dispatch(filterBarSelect(selected)),
  setTableControls: (tableControls) => dispatch(setTableControls(tableControls)),
})

const localStyles = {
  filterField: (selected) => ({
    background: selected ? 'white' : 'lightgrey',
    borderTop: selected ? '0.3rem solid #234091' : '0.3rem solid lightgrey',
  }),
}

function TabFilters({selected, resourceKey, counts, loading, tableControls, select, setTableControls}) {
  function selectTab(selection) {
    const newTableControls = {
      ...tableControls,
      pagination: {...tableControls.pagination, current: 1},
    }

    setTableControls(newTableControls)
    select(selection)
    onChange(newTableControls)
  }

  return (
    <div className="full-width">
      {resourceKey && (
        <div className="flex-row align-stretch justify-center tab-filters-wrapper">
          {loading && <div className="tab-filters-blocking-overlay"></div>}
          {counts.map(({key, doc_count: count}) => (
            <div
              key={key}
              className="tab-filters-filter-field bold"
              style={localStyles.filterField(key === selected)}
              onClick={() => selectTab(key)}
              data-cy="projectStatus"
            >
              {`${key} (${count})`}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const WrappedTabFilters = connect(sToP, dToP)(TabFilters)
export default WrappedTabFilters
