// add organizations card
// if the user selects a non-self organization, display a note that explains that the FU column is useless

import React, {useState, useEffect} from 'react'
import {Spin} from 'antd'
import FileUploader from '../../FileHandling/FileUploader'
import ProjectOwner from '../ProjectCreator/ProjectOwner'
import ProjectType from '../Shared/ProjectType'
import ClientInformation from '../Shared/ClientInformation'
import {useUserEffect, useFormEffect, useOrganizationEffect} from '../Shared/subscriptionEffects'
import {useLoggerEffect} from '../../effects'
import {CSVTemplate, upload, finalize, SiteInformation, mapsLoaded, setCSVTemplate} from './bulkUtilities'
import {connect} from 'react-redux'
import {subscribe} from '../../HOC/Firebase/SubscribeHOC'
import {blankFields, setField} from '../action_creators'
import {auth} from '../../Auth'

const sToP = (store) => ({fields: store.fields || {}, template: store.bulk.csvtemplate, sites: store.bulk.sites})
const dToP = (dispatch) => ({
  blankFields: () => dispatch(blankFields()),
  setField: (field, value) => dispatch(setField(field, value)),
})

export function BulkProjectImport(props) {
  const {fields, template, sites = [], blankFields, setField} = props
  const {subscribeResources = {}, makeSubscription, done} = props

  const [loading, setLoading] = useState(false)

  const logger = useLoggerEffect(`/projects`)

  useUserEffect(makeSubscription, subscribeResources)
  useFormEffect(makeSubscription, subscribeResources)
  useOrganizationEffect(makeSubscription, subscribeResources)

  useEffect(mapsLoaded(setLoading), [])
  useEffect(setCSVTemplate(fields.projectType, setLoading), [fields.projectType])

  useEffect(
    function () {
      switch (done) {
        case 'done':
          finalize(logger)
        case 'clear':
          blankFields()
        default:
          return
      }
    },
    [done]
  )

  useEffect(() => {
    props.updateSiteLength(sites.length)
    setLoading(false)
  },[sites.length])

  useEffect(
    function () {
      if (!fields.organization) {
        setField('organization', auth.sunkaizenUser.organization)
      }
    },
    [fields.organization]
  )

  const fileUpload = (e) => {
    setLoading(true)
    upload(e)
  }

  return (
    <div style={{height: `100%`}}>
      {/* PROJECT OWNER; NOT EDITABLE */}
      <ProjectOwner disabled={true} />
      <ProjectType />
      <ClientInformation />
      <FileUploader
        disabled={loading}
        submit={fileUpload}
        collection="none"
        id="none"
        type="csvUpload"
        helperMessage={fields.projectType && template ? CSVTemplate : () => <div>Please Select a Project Type</div>}
      />
      {loading && 
      <div style={{ marginLeft: '50%', 
                    zIndex: 99999, position: 'absolute', top: '46%' }}>
         <Spin size="large" /> </div>}

      {props.loaderOkButton && 
      <div style={{ marginLeft: '50%',
                    zIndex: 99999, position: 'absolute', top: '95%' }}>
         <Spin size="large" /> </div>}
      {sites.length > 0 && <SiteInformation sites={sites} loaderOkButton={props.loaderOkButton} />}
      {/* <AnswerUploader setAnswers={ this.setAnswers } /> */}
    </div>
  )
}

const WrappedBulkProjectImport = subscribe()(connect(sToP, dToP, null, {forwardRef: true})(BulkProjectImport))
export default WrappedBulkProjectImport
