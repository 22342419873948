import React from 'react';
import AddAction from './AddAction';

const ActionsRender = React.memo(({
  actionList,
  handleInputChange, // Pass down to AddAction
  handleDeleteAction,
  handleDeleteActionCancel,
  handleDeleteActionConfirmation,
  ratingsData,
  iconsData,
  setRatingsData,
  setIconsData,
}) => {
  return actionList
    .sort((a, b) => a.actionIndex - b.actionIndex)
    .filter(x => x.isDelete !== true)
    .map(rec => (
      <AddAction
        key={`action-${rec.id}`}
        action={rec}
        currentIndex={rec.actionIndex}
        handleInputChange={handleInputChange} // Pass the function to AddAction
        handleDeleteAction={handleDeleteAction}
        handleDeleteActionCancel={handleDeleteActionCancel}
        handleDeleteActionConfirmation={handleDeleteActionConfirmation}
        ratingsData={ratingsData}
        iconsData={iconsData}
        setRatingsData={setRatingsData}
        setIconsData={setIconsData}
      />
    ));
}, (prevProps, nextProps) => {
  // Check if the important props have changed before re-rendering
  return (
    prevProps.actionList === nextProps.actionList &&
    prevProps.ratingsData === nextProps.ratingsData &&
    prevProps.iconsData === nextProps.iconsData
  );
});

export default ActionsRender;
