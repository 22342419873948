import React, {useState, useEffect} from 'react'
import {saveAs} from 'file-saver'
import Papa from 'papaparse'
import flat from 'flat'
import {Button} from 'antd'
import {db} from './utilities'

export default function GarbageProjectExporter() {
  function createCSV(projects) {
    const pp = []

    for (let project of projects) {
      const p = flat(project.intake)
      pp.push(p)
    }

    return Papa.unparse(pp)
  }

  async function goProjects() {
    const snap = await db
      .collection('projects')
      .where('intake.title', '==', '2017 Solar Inspection Form V1 PV Only')
      .get()
    const csv = createCSV(snap.docs.map((d) => d.data()))

    const blob = new Blob([csv], {type: 'text/csv'})
    saveAs(blob, 'report.csv')
  }

  return (
    <div>
      <Button onClick={goProjects}>DOWNLOAD IMPORTANT CSV</Button>
    </div>
  )
}
