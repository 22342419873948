import BaseModel from './BaseModel';
import { db } from '../utilities';

export default class Forms extends BaseModel {
  constructor() {
    super(db.collection('forms'));
  }

  static async get(id: string) {
    try {
      const doc = await db.collection('forms').doc(id).get();
      if (doc.exists) {
        return { data: doc.data() };
      } else {
        console.error("No such document!");
        return { data: null };
      }
    } catch (error) {
      console.error("Error getting document:", error);
      return { data: null };
    }
  }
}
