// These will be stripped out during the build step when deployed to production
// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables

let firebaseConfig
let FULL_TEXT_SEARCH_URL
let FUNCTIONS_URL
let BUCKET

let ENVIRONMENT = process.env.REACT_APP_ENV || 'development'
console.info('ENVIRONMENT - ', ENVIRONMENT)

if (ENVIRONMENT === `development`) {
  firebaseConfig = {
    apiKey: `AIzaSyA7Czt3RMjZ0qEFEVUbSzZMfAfvCFIXoKk`,
    authDomain: `sunkaizen-9f140.firebaseapp.com`,
    databaseURL: `https://sunkaizen-9f140.firebaseio.com`,
    projectId: `sunkaizen-9f140`,
    storageBucket: `sunkaizen-9f140.appspot.com`,
    messagingSenderId: `712328998852`,
  }

  FULL_TEXT_SEARCH_URL = `https://us-central1-sunkaizen-9f140.cloudfunctions.net/utilities`
  FUNCTIONS_URL = `https://us-central1-sunkaizen-9f140.cloudfunctions.net`
  BUCKET = 'sunkaizen-9f140.appspot.com'
}

if (ENVIRONMENT === `production`) {
  firebaseConfig = {
    apiKey: `AIzaSyALM_4ol-7Q_djWiTTn4Qx5HCUIGGY1MMQ`,
    authDomain: `solarfit-6d9b8.firebaseapp.com`,
    databaseURL: `https://solarfit-6d9b8.firebaseio.com`,
    projectId: `solarfit-6d9b8`,
    storageBucket: `solarfit-6d9b8.appspot.com`,
    messagingSenderId: `545874751425`,
  }

  FULL_TEXT_SEARCH_URL = `https://us-central1-solarfit-6d9b8.cloudfunctions.net/utilities`
  FUNCTIONS_URL = `https://us-central1-solarfit-6d9b8.cloudfunctions.net`
  BUCKET = `solarfit-6d9b8.appspot.com`
}

if (ENVIRONMENT === `doe2`) {
  firebaseConfig = {
    apiKey: 'AIzaSyCpr_gLYz1qOppXDsdI8N7ln14tbKZw548',
    authDomain: 'fitqm-doe2.firebaseapp.com',
    projectId: 'fitqm-doe2',
    storageBucket: 'fitqm-doe2.appspot.com',
    messagingSenderId: '935925769476',
    appId: '1:935925769476:web:1a67340db4cf0c65181c51',
    measurementId: 'G-C4XTBRWNRJ',
  }

  // TODO: set after functions deploy
  FULL_TEXT_SEARCH_URL = `https://us-central1-fitqm-doe2.cloudfunctions.net/utilities`
  FUNCTIONS_URL = `https://us-central1-fitqm-doe2.cloudfunctions.net`
  BUCKET = `fitqm-doe2.appspot.com`
}

const staticMapKey = `AIzaSyA7Czt3RMjZ0qEFEVUbSzZMfAfvCFIXoKk`

export {firebaseConfig, FULL_TEXT_SEARCH_URL, FUNCTIONS_URL, BUCKET, ENVIRONMENT, staticMapKey}

export const googleAPIKey = `AIzaSyA7Czt3RMjZ0qEFEVUbSzZMfAfvCFIXoKk`

// this needs to mirror the colors in styles.css; it's used to style antd components without having to change post-processor defaults
export const colors = {
  primary: '#234091',
  secondary: '#F4F6F8',
  tertiary: '#4F8335',
  quaternary: 'lightgrey',
}
