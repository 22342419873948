// instantiation of this base class fails validation unless you provide a type in the args
export class ElasticSearchQuery {
  constructor(args) {
    this.query = args
    this.query.from = args.from || 0
    this.query.query_strings = args.query_strings
    this.query.filters = args.filters
    this.query.query = args.query || ''
    this.query.sort = args.sort
    this.query.type = args.type
    this.query.size = args.size || 10

    // validateQuery should not be overridden in child classes
    if (!this.validateQuery()) {
      console.log(this.query)
      throw new Error('query failed to validate')
    }
  }

  isString(s) {
    return typeof s === 'string' || s instanceof String
  }

  sortDirections = {
    asc: true,
    desc: true,
  }

  // implement these to restrict the fields that can be used in each of these clauses
  // {string: true}, where string is the permitted field name option
  queryFieldOptions = null
  sortFields = null
  // this is hard to implement, because it requries detailed knowledge of all filters the query can use
  // 	filter context is used for strange things, like calculating range filters (used for date fields)
  // 	I recommend not implementing this at first -- Chris 4/23/2020
  // filterOptions needs to be in the form {string: (filter_body) -> bool {}} where the function
  // since the filter_body can be formatted in many ways
  filterOptions = null

  validateQuery() {
    try {
      const validates = []
      validates.push(this.isString(this.query.query))
      validates.push(Number.isInteger(this.query.from))
      validates.push(this.isString(this.query.type))

      // [{[field_name]: 'asc'}, {[field_name]: 'desc'}]
      for (let sorter in this.query.sort) {
        validates.push(Object.keys(this.query.sort[sorter]).length === 1)
        const [[field, direction]] = Object.entries(this.query.sort[sorter])
        if (this.sortFields) validates.push(this.sortFields[field])
        validates.push(this.sortDirections[direction])
      }

      // [{query_string: {default_field, query}}]
      for (let i in this.query.query_strings) {
        const {query_string} = this.query.query_strings[i]
        const [[key, {default_field, query}]] = Object.entries(query_string)
        validates.push(key === 'query_string')

        if (this.queryFieldOptions) {
          validates.push(this.queryFieldOptions[default_field])
        }
        validates.push(this.isString(query))
      }

      // the only filter used right now is a date filter that looks for values within
      // (greater than/equal and less than/equal) the provided range -- Chris
      // [range: {[field_name]: {gte, lte}}]
      for (let i in this.query.filters) {
        const [[filter_type, body]] = Object.entries(this.query.filters[i])
        validates.push(this.isString(filter_type))
        if (this.filterOptions) {
          validates.push(this.filterOptions[filter_type](body))
        }
      }

      // next line is for debugging which check fails -- Chris
      return validates.every((i) => i)
    } catch (e) {
      console.log(e)
      return false
    }
  }

  toJSON() {
    if (!this.validateQuery()) {
      throw new Error('query failed to validate')
    }

    return JSON.stringify(this.query)
  }
}

export class FormElasticSearchQuery extends ElasticSearchQuery {
  constructor(args) {
    // console.log(args)
    args.type = 'forms'
    super(args)

    console.log('implement queryFilterOptions and sortOptions')
  }
}

export class ProjectElasticSearchQuery extends ElasticSearchQuery {
  constructor(args) {
    args.type = 'projects'
    super(args)

    // console.log('implement queryFilterOptions and sortOptions')
  }
}

export class UserElasticSearchQuery extends ElasticSearchQuery {
  constructor(args) {
    args.type = 'users'
    super(args)

    console.log('implement queryFilterOptions and sortOptions')
  }
}
