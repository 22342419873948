import DB from '../../../DAL/DB'
import {store} from '../../utilities'
import {setTemplate} from '../../action_creators'

const formCSV = new DB.FormCSV()

export default function setCSVTemplate(form, setLoading) {
  return function () {
    if (!form || !form.id) return
    ;(async function () {
      try {
        setLoading(true)
        const {
          data: [{template, order}],
          ok,
        } = await formCSV.get(form.id)
        if (ok) store.dispatch(setTemplate(template, order))
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    })()
  }
}
