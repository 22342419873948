import React, {useState, useEffect} from 'react'
import {auth} from '../Auth'
import {storage} from '../utilities'
import Overlay from './Overlay'
import {Icon, Popover} from 'antd'
import {correctedURL} from './../utilities'

export default function UserInfo() {
  const [avatar, setAvatar] = useState(null)
  const {fullPath} = (auth.sunkaizenUser || {}).avatar || {}

  useEffect(
    function () {
      let avatarURL
      const defaultURL = `meta/images/default_avatar.png`
      avatarURL = correctedURL(defaultURL)
      if (fullPath) {
        avatarURL = correctedURL(fullPath)
      }
      setAvatar(avatarURL)
    },
    [fullPath]
  )

  return (
    <div>
      {auth.sunkaizenUser ? (
        <div className="flex-row full-width align-center justify-space-between">
          <div className="full-width flex-row" style={{width: '3rem', height: '3rem', marginRight: '0.5rem'}}>
            <img src={avatar} alt="user" style={{width: 'inherit'}} />
          </div>
          <div className="flex-column" style={{paddingRight: '0.5rem', lineHeight: 'initial', overflow: 'hidden'}}>
            <div style={{paddingBottom: '0.5rem'}} data-cy="sidebarName">
              {auth.sunkaizenUser ? auth.sunkaizenUser.name : ``}
            </div>
            <div style={{fontSize: '75%'}}>{auth.sunkaizenUser ? auth.sunkaizenUser.email : `no email on file`}</div>
          </div>
          <Popover placement="right" content={Overlay()} trigger="hover">
            <Icon className="flex-row" type="caret-right" data-cy="logoutIcon" />
          </Popover>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
