export default function transformCriticalities(criticalities) {
  const transformed = {}
  for (let c of criticalities) {
    transformed[c.name] = c.value
  }
  return transformed
}

export function reverseTransform(criticalityMap) {
  const criticalities = []
  for (let i in criticalityMap) {
    criticalities.push({name: i, limit: criticalityMap[i] != null, value: criticalityMap[i]})
  }

  return criticalities
}
