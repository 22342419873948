import React from 'react'
import {connect} from 'react-redux'
import {Button} from 'antd'
import {modify} from '../action_creators'

const stop = (state) => ({field: state.fields[state.active.field]})
const dtop = (dispatch) => ({
  addOption: (field) => {
    if (!field.options) {
      field.options = []
    }

    const options = [...field.options, {label: '', requireFollowup: false}].filter((i) => i)
    dispatch(modify('field')(field.id, {...field, options}))
  },
})

export function Add({addOption, field}) {
  return (
    <Button onClick={() => addOption(field)} className="thick margin-left-1" type="primary" data-cy="addOptionBtn">
      Add Option
    </Button>
  )
}

const WrappedAdd = connect(stop, dtop)(Add)
export default WrappedAdd
