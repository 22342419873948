import retry from 'async-retry'
import {firebase} from '../../../utilities'
import {auth} from '../../../Auth'
import DB from '../../../DAL/DB'
import {resourceType, validateResource} from '../../../validators'
import GeocodeWrapper from '../../../GeocodeWrapper'
import {getStaticMapThumbnail} from '.'

const wrappedGeocode = new GeocodeWrapper()
const users = new DB.Users()

function jsonify(obj) {
  return JSON.parse(JSON.stringify(obj))
}

async function geocodeLatLng(lat, lng) {
  jsonify(
    await retry(
      async () => {
        return wrappedGeocode.geocode({lat, lng}, `location`)
      },
      {retries: 10}
    )
  )
}

// throws
async function makeSite(sId, s) {
  const {
    geocode_results = ``,
    site_contact: siteRepName,
    site_owner: siteOwnerName,
    address: siteAddress,
    phone: siteRepPhone,
    email: siteRepEmail,
  } = s

  let site = {
    geocode_results: jsonify(geocode_results),
    siteOwnerName,
    siteAddress,
    siteRepName,
    siteRepEmail,
    siteRepPhone,
    id: sId,
  }

  let dummyAddress = null,
    latitude = null,
    longitude = null

  if (geocode_results.length < 1) {
    console.log(`no geocode results, no project`)
    // this needs to be tested; it was causing an error on project creation when one of the projects had a blank address
    return [{}, {}]
  }

  if (dummyAddress) site.dummyAddress = dummyAddress
  if (latitude && longitude) {
    try {
      site.geocode_results = geocodeLatLng(latitude, longitude)
      site.siteAddress = site.geocode_results.formatted_address
    } catch (e) {
      console.log(`error geocoding project address in makeResources`, e)
    }
  }

  site = await getStaticMapThumbnail(site)
  site.errors = validateResource(site)(resourceType[`site`])

  return site
}

const isEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

// add code to get the organization and add it to each project
const makeResources = (s) => async (sId, pId) => {
  try {
    const site = await makeSite(sId, s)

    const project = {
      id: pId,
      site,
      status: `To Be Scheduled`,
      created: firebase.firestore.Timestamp.now(),
      updated: firebase.firestore.Timestamp.now(),
      organization: auth.sunkaizenUser.organization,
    }

    const {system_installer, field_user} = s

    if (field_user) {
      let field_user_res
      if (isEmail(field_user)) {
        field_user_res = await users.getByEmail(field_user)
      } else {
        field_user_res = await users.get(field_user)
      }
      const {
        data: [inspector],
        ok,
      } = field_user_res
      console.info('Field user Detected:', inspector)
      if (ok) project.inspection = {inspector}
    }

    if (system_installer) {
      let installer_res
      if (isEmail(system_installer)) {
        installer_res = await users.getByEmail(system_installer)
      } else {
        installer_res = await users.get(system_installer)
      }
      const {
        data: [installer],
        ok,
      } = installer_res
      console.info('Installer Detected:', installer)
      if (ok) project.installer = installer
    }

    return [site, project]
  } catch (e) {
    console.log(`failed to generate bulk project in bulkUtilities`, e)
  }
}

export default makeResources
