import React from 'react'
import {store} from './utilities'
import {Provider} from 'react-redux'
import Modal from './Modal'

import './styles.scss'

const EntryPoint = (props) => (
  <Provider store={store}>
    <Modal {...props} />
  </Provider>
)
export default EntryPoint
