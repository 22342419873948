import React from 'react';
import { Button, Popconfirm } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const actionData = (ratingsData, iconsData) => ({
  costOptions: ratingsData.filter(rating => rating.type.toLowerCase() === 'cost').map(rating => rating.name),
  diffiOptions: ratingsData.filter(rating => rating.type.toLowerCase() === 'difficulty').map(rating => rating.name),
  cricticalOptions: ratingsData.filter(rating => rating.type.toLowerCase() === 'criticality').map(rating => rating.name),
  iconOptions: iconsData.map(icon => icon.name),
});

const dropdownDataFunc = (actionData) => [
  {
    name: 'Cost',
    options: actionData.costOptions.sort((a, b) => a.localeCompare(b)), // Alphabetically sorting options
    unquieProp: 'id',
    dbProp: 'cost',
  },
  {
    name: 'Difficulty',
    options: actionData.diffiOptions.sort((a, b) => a.localeCompare(b)),
    unquieProp: 'id',
    dbProp: 'difficulty',
  },
  {
    name: 'Criticality',
    options: actionData.cricticalOptions.sort((a, b) => a.localeCompare(b)),
    unquieProp: 'id',
    dbProp: 'criticality',
  },
  {
    name: 'Icon',
    options: actionData.iconOptions.sort((a, b) => a.localeCompare(b)),
    unquieProp: 'id',
    dbProp: 'icon',
  },
];

const columnsFunc = (selectedOption, handleEditItem, handleDeleteItem, moveUp, moveDown, orderedData) => [
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: (text) => (text ? <img src={text} alt="icon" style={{ width: '50px', height: '50px' }} /> : null),
    hidden: selectedOption !== 'Icon',
    width: '10%',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (text) => <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{text}</div>,
    width: '50%',
  },
  {
    title: 'Actions',
    key: 'actions',
    width: '20%',
    render: (text, record, index) => (
      <span>
        <Button icon="edit" onClick={() => handleEditItem(record)} />
        <Popconfirm
          title="Are you sure to delete this item?"
          onConfirm={() => handleDeleteItem(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button icon="delete" style={{ marginLeft: 8 }} />
        </Popconfirm>
        <Button
          icon="up-square"
          onClick={() => moveUp(index)}
          disabled={index === 0}
          style={{ marginLeft: 8 }}
        />
        <Button
          icon="down-square"
          onClick={() => moveDown(index)}
          disabled={index === orderedData.length - 1}
          style={{ marginLeft: 8 }}
        />
      </span>
    ),
  },
].filter((column) => !column.hidden);

const DEFAULT_TAB_ID = 1;

export { actionData, dropdownDataFunc, DEFAULT_TAB_ID, columnsFunc };
