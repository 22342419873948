// export rowSelection = {
//     onChange: (keys, rows) => {},
//     getCheckboxProps: record => ({
//         disabled: record.disabled,
//         name: record.name
//     })
// }

// export default rowSelection
export default null
