import Papa from 'papaparse'
import flattenProject from './flattenProject'
import {headers, intakeHeaders} from './headerOrder'

export default function createCSV(projects, includeIntakeComments) {
  let allHeaders = includeIntakeComments ? [...headers, ...intakeHeaders] : headers

  let rows = []
  let maxLength = 0
  for (let project of projects) {
    const row = flattenProject(project, allHeaders, includeIntakeComments)
    rows.push(row)
    if (row.length > length) {
      // find the longest row
      maxLength = row.length
    }
  }
  if (includeIntakeComments) {
    const commentsLength = maxLength - headers.length
    const commentHeaders = []
    for (let i = 0; i < commentsLength; i++) {
      if (i % 3 === 0) {
        commentHeaders.push('Question')
      } else if (i % 3 === 1) {
        commentHeaders.push('Answer')
      } else {
        commentHeaders.push('Comments')
      }
    }

    allHeaders = [...allHeaders, ...commentHeaders]
  }

  // merge headers
  rows = [allHeaders, ...rows]
  return Papa.unparse(rows)
}
