import React, {useContext} from 'react'
import {ReducerContext} from '../reducer'
import PopoverContent from './UserPopoverContent'
import {Popover} from 'antd'
import {pushData} from '../utilities'

export default function FieldUserBrief({inspector}) {
  const {state, dispatch} = useContext(ReducerContext)
  const {inspector: selectedInspector} = state
  const {id, name = 'none', type = 'Field User', icon = `https://picsum.photos/100/100`} = inspector
  const push = pushData({state, dispatch})

  return (
    <Popover title="User Information" placement="right" content={<PopoverContent {...{...(inspector || {})}} />}>
      <div
        className="user-brief-container flex-row align-center justify-space-between full-width"
        data-testid="button"
        style={{background: selectedInspector.id === id ? `rgba(255, 255, 0, 0.3)` : `initial`}}
        onClick={() => push({inspector})}
      >
        <div style={{height: '5rem'}}>
          <img src={icon} alt="field user avatar" className="padding-0-5 height-full border-circle" />
        </div>
        <div className="flex-column" style={{flex: 5}}>
          <div>{name}</div>
        </div>
      </div>
    </Popover>
  )
}
