import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {Draggable} from 'react-beautiful-dnd'
import {modify} from '../action_creators'
import Option from './Option'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: '0.2rem',
  margin: '0 0 2rem 0',

  // styles needed for draggables
  ...draggableStyle,
})

const stop = (state) => ({
  field: state.fields[state.active.field] || null,
})
const dtop = {modify}

export function Options({field, provided}) {
  const [duplicates, setDuplicates] = useState({})

  useEffect(
    function () {
      if (!field.options) return
      for (let option of field.options) {
        const d = {}
        d[option.label] = d[option.label] ? d[option.label] + 1 : 1
        setDuplicates(d)
      }
    },
    [field.options]
  )

  return (
    <div ref={provided.innerRef}>
      {!Object.values(duplicates).every((v) => v < 2) && (
        <div className="duplicate-warning">There are duplicate options</div>
      )}
      {(field.options || []).map((o, i) => (
        <Draggable key={i} draggableId={`option${i.toString()}`} index={i}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
              <Option {...{errors: duplicates[o.label] > 1, key: i, index: i}} />
            </div>
          )}
        </Draggable>
      ))}
      {provided.placeholder}
    </div>
  )
}

const WrappedOptions = connect(stop, dtop)(Options)
export default WrappedOptions
