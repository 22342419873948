import React from 'react'
import {capitalizeFirst} from '../utilities'
import {Form, Select, Checkbox} from 'antd'

const SelectField = ({mode, set, field, placeholder, options}) => (
  <Select
    onChange={(e) => set(e)}
    mode={mode}
    value={field}
    size="large"
    placeholder={placeholder}
    style={{border: 'none'}}
    className="full-width"
  >
    {options}
  </Select>
)

export function PermissionsBlock({type, fieldAll, options = {}, check, set, fieldsObject = {}, placeholder}) {
  return (
    <div className="full-width">
      <div className="flex-row align-center full-width">
        <div className="bold flex-row justify-start" style={{flex: 1}}>
          {type}
        </div>
        <Form.Item style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
          <Checkbox checked={fieldAll} onChange={(e) => check(e.target.checked)}>
            Grant all access
          </Checkbox>
        </Form.Item>
      </div>
      <Form.Item style={{width: '100%'}}>
        <SelectField
          {...{
            mode: 'multiple',
            field: Object.keys(fieldsObject),
            set,
            placeholder,
            options: Object.values(options || {}).map((c) => (
              <Select.Option key={c.id}>{capitalizeFirst(c.name || '')}</Select.Option>
            )),
          }}
        />
      </Form.Item>
    </div>
  )
}
