import React from 'react'
import {wrap} from './reducer'
// import Tables from './Tables'
import {Tabs} from 'antd'
import './Analytics.scss'
import {Dropdowns, CIFilters, SOFilters, Tables, Chart, SOTable, OSPlot} from '.'
import {useAllForms, useAllOrgs, useAllUsers, useRollingAverages} from './effects'

const {TabPane} = Tabs

export function Analytics() {
  useAllForms()
  useAllOrgs()
  useAllUsers()
  useRollingAverages()

  return (
    <div className="analytics-page">
      <h1 className="page-title">Analytics</h1>
      <Dropdowns />
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab="Continuing Improvement" key="1" data-cy="continuingImprovement">
          <CIFilters />
          <Chart />
        </TabPane>
        <TabPane tab="Statistical Outliers" key="2" data-cy="statisticalOutliers">
          <SOFilters />
          <SOTable></SOTable>
          {/* Per question view of FET results
                    <Tables /> */}
        </TabPane>
        <TabPane tab="Overall Score" key="3" data-cy="overallScore">
          {/* <OSFilters /> */}
          <OSPlot />
        </TabPane>
      </Tabs>
    </div>
  )
}

const WrappedAnalytics = wrap(Analytics)
export default WrappedAnalytics
