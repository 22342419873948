import React, {useContext} from 'react'
import {ReducerContext} from './reducer'
import {GenericSelector} from './GenericSelector'
import {Button} from 'antd'
import {useOrgRoles, useGetRubrics, useGenerateResults, useGetAnalyticsMetadata} from './effects'
import {db} from '../utilities'

export default function Dropdowns() {
  const {state, dispatch} = useContext(ReducerContext)

  useOrgRoles()
  useGetRubrics()
  useGetAnalyticsMetadata()
  useGenerateResults()

  return (
    <div className="flex-row selectors">
      <div style={{flex: 4}}>
        <GenericSelector
          placeholder={`Select an organziation role`}
          label="Viewing As"
          values={state.orgRoles}
          valueTitle="id"
          selectedValue={state.dropdown.orgRole}
          onChange={(value) => dispatch({type: 'orgRole', value})}
        />
      </div>
      <div style={{flex: 4}}>
        <GenericSelector
          className="first"
          placeholder="Select Form"
          label="Form"
          values={state.forms}
          valueTitle="title"
          selectedValue={state.dropdown.form}
          onChange={(value) => dispatch({type: 'form', value})}
        />
      </div>
      <div style={{flex: 4}}>
        <GenericSelector
          placeholder={state.rubrics ? `Select Rubric` : `Please Select a Form First`}
          label="Rubric"
          values={Object.values(state.rubrics || {}).sort()}
          valueTitle="name"
          selectedValue={state.dropdown.rubric}
          onChange={(value) => dispatch({type: 'rubric', value})}
        />
      </div>
      <Button
        style={{flex: 1}}
        onClick={() => {
          dispatch({type: 'reset'})
        }}
        data-cy="resetFiltersBtn"
      >
        Reset Filters
      </Button>
    </div>
  )
}
