import React, {useState} from 'react'
import {Button, Modal} from 'antd'
import TopLevelState from './TopLevelState'

export default function AddUserButton({success, className, style}) {
  const [done, setDone] = useState(false)
  const [show, setShow] = useState(null)

  return (
    <div>
      <Button className={className} style={style} onClick={() => setShow(true)}>
        Add Bulk Users
      </Button>
      <Modal
        width={'80%'}
        onCancel={() => {
          setDone('clear')
          setShow(false)
        }}
        onOk={() => {
          setDone('done')
          success.map((f) => f())
          setShow(false)
        }}
        visible={show}
      >
        <TopLevelState done={done} />
      </Modal>
    </div>
  )
}
