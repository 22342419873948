import {useContext, useEffect} from 'react'
import {useDBSubscribe} from '../../effects'
import DB from '../../DAL/DB'
import {ReducerContext} from '../reducer'
import { auth } from '../../Auth'

const users = new DB.Users()

export default function useGetUsers() {
  const {state, dispatch} = useContext(ReducerContext)
  const uu = useDBSubscribe(users, {filters: [['type', 'in', ['quality-manager', 'installer']]]})
  useEffect(
    function () {
      const filterTypes = {...state.filterTypes}
      for (let user of uu) {
        if (!['installer', 'quality-manager'].includes(user.type)) {
          continue
        }
        //bravo- if scheduler  , I am disabling it today , I think we can display all quality-mangers and installers even they are not part of org
        // if (auth.sunkaizenUser.type === 'scheduler' && auth.sunkaizenUser.organization.id !== user?.organization?.id ) {
        //   continue
        // } 

        filterTypes[user.type].options.push(user.name)
      }
      for (let user of ['quality-manager', 'installer']) {
        filterTypes[user].options = Array.from(new Set(filterTypes[user].options)).sort()
      }
      dispatch({type: 'filterTypes', value: filterTypes})
    },
    [uu]
  )
}
