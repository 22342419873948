import {useEffect, useContext} from 'react'
import {ReducerContext} from '../reducer'
import DB from '../../DAL/DB'
import {useDBSubscribe} from '../../effects'
import {auth} from '../../Auth'

// const forms = new DB.Forms()
const completedForms = new DB.CompletedForms()

// get and store all forms in the system
export default function useAllForms() {
  const {dispatch} = useContext(ReducerContext)

  // const ff = useDBSubscribe(forms, {filters: [['organization.id', '==', auth.sunkaizenUser.organization.id]]})
  const ff = useDBSubscribe(completedForms, {filters: [['organization.id', '==', auth.sunkaizenUser.organization.id]]})

  useEffect(
    function () {
      const uniqueForms = {}
      for (let f of ff) {
        uniqueForms[f.id] = {id: f.id, title: f.title}
      }
      dispatch({type: 'allForms', value: Object.values(uniqueForms)})
    },
    [ff]
  )
}
