import React from 'react'
import CancellationPhoto from '../CancellationPhoto'
import LabeledSection from './LabeledSection'
import moment from 'moment'

export default function CancelledIntakes({project}) {
  return (
    <div className="flex-column full-width">
      {project.intakeCancellations && (
        <div>
          <h4>Cancelled Intakes</h4>
          <div>
            {project.intakeCancellations.map(({canceledAt, reason, canceledDate, inspector, comment, photo, rescheduled= false, newScheduledDate = null, newInspector = null }, i) => (
              <div
                key={i}
                className="padding-1 background-white margin-bottom-1"
                style={{borderRadius: '1rem', border: '0.1rem solid lightgrey'}}
              >
                {/*console.log(project.intakeCancellations)*/}
                <LabeledSection label={rescheduled ? "Rescheduled on": "Canceled on"}>
                  {moment(canceledAt.toDate()).format('MM/DD/YYYY HH:mm')} 
                  {/*canceledAt.toDate().toLocaleString()*/}
                </LabeledSection>
                {!rescheduled && <LabeledSection label="Reason">{reason}</LabeledSection>}
                {comment && <LabeledSection label="Cancellation Note">{comment}</LabeledSection>}
                {photo && (
                  <div className="flex-column">
                    <div className="padding-right-1 bold">Cancellation Photo</div>
                    <CancellationPhoto className="full-width margin-1" style={{height: '10rem', overflow: 'hidden'}} photo={photo} />
                  </div>
                )}
                <hr style={{border: '1px solid rgba(244, 244, 244)'}} />
                {newScheduledDate && (
                  <LabeledSection label="Now Scheduled For">
                  {moment(newScheduledDate.toDate()).format('MM/DD/YYYY HH:mm')} 
                  {/*newScheduledDate.toDate().toLocaleString()*/}
                </LabeledSection>
                )}
                <LabeledSection label="Was Scheduled For">
                  {canceledDate && moment(canceledDate.toDate()).format('MM/DD/YYYY HH:mm')}
                  {/*canceledDate && canceledDate.toDate().toLocaleString()*/}
                </LabeledSection>
                {newInspector && newInspector.name ? (<LabeledSection label="Field User">{newInspector.name}</LabeledSection>) : (
                  <LabeledSection label="Field User">{inspector && inspector.name}</LabeledSection>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
