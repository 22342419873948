import {db, firebase} from '../../utilities'
import DB from '../../DAL/DB'

const users = new DB.Users()

export default async function setInspector(project, inspector, returns) {
  //console.log('asli',project, inspector)
  if (!inspector || !inspector.id) {
    project.inspection = null
    return
  }

  const calendar = new DB.UserCalendars(inspector.id)

  // check whether the user has changed the inspector; if they have, replace the calendar entry
  const {inspection = {inspector: {}}} = project

  // if the provided inspector is different from the one on the project, then replace the calendar entry with the new user
  if (inspection && inspection.inspector && inspection.inspector.id !== inspector.id) {
    // delete any old calendared inspections for the project
    if (inspection.inspector.id) {
      const {data: appointments, ok} = await calendar.get(null, {filters: [['id', '==', project.id]]})

      console.log(0)
      if (ok && appointments.length > 0) {
        for (let appointment of appointments) {
          await calendar.delete(appointment.id)
        }
      }
    }
  }

  const {data, ok} = await users.get(inspector.id)

  if (!ok) throw new Error('the new inspector for the project is not in the database; this should be impossible')

  const [{name, id, email, phone = null, organization}] = data
  //console.log('data of user',data) 
  if (inspection && inspection?.date && inspection.inspector && inspection.inspector.id !== inspector.id) {
    let newInspector = { id: inspector.id , name: inspector.name, email: inspector.email, organization: data[0].organization}
      //console.log('we will inject')
      const {
        inspector: {name, id},
        date: canceledDate,
      } = inspection
      //console.log('purane wala banda',inspection)
      const dateObj = canceledDate.toDate()
      const intakeCancellation = {canceledAt: firebase.firestore.Timestamp.now(), newScheduledDate:  firebase.firestore.Timestamp.fromDate(dateObj), canceledDate: firebase.firestore.Timestamp.fromDate(dateObj), inspector: {name, id}, reason: '', rescheduled: true, newInspector}
      //console.log('intakeCancellation',intakeCancellation)
      project.intakeCancellations = [...(project.intakeCancellations || []), intakeCancellation].filter((i) => i)
  }

  project.inspection = {...inspection, inspector: {name, id, email, phone, organization}}

  returns.push([calendar.update(project.id, null, project)])
}
