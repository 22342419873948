import React from 'react'
import {connect} from 'react-redux'
import AddRubric from './AddRubric'
import {Card, Form, Divider} from 'antd'
import DeleteButton from '../../DeleteButton'

function stop(state) {
  const scoringSystems = {}
  state.scoringSystems.forEach((s) => {
    scoringSystems[s.id] = s
  })

  return {
    rubrics: state.rubrics,
    scoringSystems,
  }
}

function dtop(dispatch) {
  return {
    setRubrics: (r) => dispatch({type: 'rubrics', value: r}),
  }
}

export function RubricSettings({formId, rubrics, scoringSystems, setRubrics}) {
  const loading = !Object.keys(scoringSystems).length

  function removeRubric({rubricId}) {
    let newRubrics = {...rubrics}
    delete newRubrics[rubricId]
    setRubrics(Object.values(newRubrics))
  }

  return (
    <Card loading={loading} className="container-card">
      <Form>
        {Object.keys(rubrics) &&
          Object.values(rubrics).map((rubric) => (
            <div key={rubric.id}>
              <Form.Item style={{marginTop: `0.5rem`}}>
                <div className="flex-row align-center">
                  <div className="inner-container bold padding-right-1">{rubric.name}</div>
                  <div>
                    <small>{rubric.id}</small>
                  </div>
                  <div style={{flex: 6}}>Scoring System: {(scoringSystems[rubric.scoringSystemId] || {}).name}</div>
                  <div className="delete-wrapper padding-left-1">
                    <DeleteButton
                      style={{height: `3rem`}}
                      icon="minus-circle"
                      size="large"
                      type="secondary"
                      confirmationDisplay={
                        <div>
                          <p>Are you sure you want to remove this Rubric?</p>
                        </div>
                      }
                      onConfirm={() => removeRubric({rubricId: rubric.id})}
                    />
                  </div>
                </div>
              </Form.Item>
              <Divider style={{margin: '.5rem'}} />
            </div>
          ))}
      </Form>
      <div style={{marginTop: `2rem`, border: `1px solid rgba(0,0,0,0.13)`, width: `100%`, display: `block`}} />
      <AddRubric formId={formId} scoringSystems={scoringSystems} existingRubrics={rubrics} />
    </Card>
  )
}

const WrappedRubricSettings = connect(stop, dtop)(RubricSettings)
export default WrappedRubricSettings
