import React, {useReducer} from 'react'

const imageState = {
  project: null,
  pictures: [],
  selected: {},
  marked: {},
  deletables: {},
  hasMore: true,
  active: [],
  viewing: null,
  action: `select`,
  refs: {},
  originals: {},
  done: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case `pictures`:
      return {...state, pictures: action.value}
    case `last`:
      return {...state, last: action.value}
    case `active`:
      return {...state, active: [...state.active, action.id]}
    case `clear`:
      return {...state, active: []}
    case `select`:
      return {
        ...state,
        selected: {...state.selected, [action.id]: action.value},
      }
    case `clearSelected`:
      return {...state, selected: {}}
    case `changeStatus`:
      return {
        ...state,
        originals: {...state.originals, ...action.pictures},
      }
    case `viewImage`:
      return {...state, viewing: action.id}
    case `action`:
      return {...state, action: action.value}
    case `ref`:
      return {
        ...state,
        refs: {...state.refs, [action.id]: action.value},
      }
    case `hasMore`:
      return {...state, hasMore: false}
    case `originals`:
      return {...state, originals: action.value}

    case `done`:
      return {...state, done: true}

    default:
      return state
  }
}

export const ReducerContext = React.createContext(null)

export const wrap = (C) => (props) => {
  const [state, dispatch] = useReducer(reducer, imageState)
  return (
    <ReducerContext.Provider value={{state, dispatch}}>
      <C {...props} />
    </ReducerContext.Provider>
  )
}
