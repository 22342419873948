import {store} from './utilities'
import {modify} from './action_creators'
import DB from '../DAL/DB'
import switchSection from './switchSection'

export default function addSection() {
  const state = store.getState()
  const formSection = new DB.FormSection(state.form.id)
  const id = formSection.makeId()

  store.dispatch(modify('section')(id, {title: 'Untitled Section', id, order: []}))
  store.dispatch(
    modify('form_field')(
      'order',
      [...state.form.order, id].filter((i) => i)
    )
  )
  switchSection(id)
}
