import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'antd'
import CreateInvoicingReports from './TopLevelState'


export default function InvoicingReports({style}) {
  const [visible, setVisible] = useState(false)
  const [done, setDone] = useState(false)

  return (
    <div style={style}>
      <Button disabled={done} onClick={() => setVisible(true)} style={{height: '100%', width: '100%'}}>
        Create Invoicing Report
      </Button>
      {visible && (
        <Modal
          visible={true}
          onOk={() => setDone(true)}
          confirmLoading={done}
          onCancel={() => {
            if (done) {
              setDone(false)
            } else {
              setVisible(false)
            }
          }}
          title={<h3>{'Create Invoicing Report'}</h3>}
          width="80rem"
          okText="Generate"
        >
          <CreateInvoicingReports done={done} setDone={setDone} />
        </Modal>
      )}
     
    </div>
  )
}
