import {message} from 'antd'

import {firebase, db} from './utilities'
import {FUNCTIONS_URL} from './config'
import {auth} from './Auth'

export default class SystemLogger {
  constructor({path, uid}) {
    this.uid = uid
    this.category = ''
    this.categoryId = ''

    this._parsePath(path)
    this.error = this.error.bind(this)
  }

  static subscriptions = {}

  static subscribe = (uid) => (f) => {
    if (!this.subscriptions[uid]) this.subscriptions[uid] = []
    this.subscriptions[uid].push(f)
  }
  static unsubscribe = (uid) => (i) => {
    this.subscriptions[uid].splice(i, 0)
  }

  /**
   * Severity is between 1 and 5, with 5 being the *least* severe.
   * By default, log severity will be least severe (5).
   * Provide a second parameter if you need a higher severity-level.
   */
  log = (message, extended = '', severity = 5, user_index = null) =>
    this._log({type: 'log', message, extended, severity, user_index})

  /** See docs for the `log` method. */
  // this function also dispatches the error to the pub-sub that reports to slack
  async error(message, extended = '', severity = 5, user_index = null) {
    const data = await this._log({type: 'error', message, extended, severity, user_index})
    if (!data) return

    const body = {
      name: data.message,
      source: `(category: ${data.category} resource ID: ${data.categoryId})`,
      message: data.user_index
        ? `user_index: ${data.user_index}; severity: ${data.severity}; creator: ${data.creator}`
        : `severity: ${data.severity}; creator: ${data.creator}`,
      timestamp: data.created.toDate(),
      organization: auth.sunkaizenUser.organization,
    }

    const res = await fetch(`${FUNCTIONS_URL}/utilities/error`, {
      method: 'POST',
      mode: 'cors',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body),
    })

    try {
      const err = await res.json()
      if (err) console.log(err)
    } catch (e) {
      console.log(e)
    }
  }

  /** See docs for the `log` method. */
  event = (message, extended = '', severity = 5, user_index = null) =>
    this._log({type: 'event', message, extended, severity, user_index})

  projectLog =
    (project) =>
    (message, extended = '', severity) => {
      this._log({
        type: 'log',
        message,
        extended,
        severity,
        user_index: this.getProjectUsers(project),
        organization: auth.sunkaizenUser.organization,
      })
    }

  getProjectUsers = (project) => {
    const {
      installer,
      client,
      inspection: {
        inspector: {id: inspectorId = null},
        reviewer: {id: reviewerId = null},
      },
    } = project
    return {installer, client, inspector: inspectorId, reviewer: reviewerId}
  }

  _parsePath = (path) => {
    // I pull the blank and ...rest vars out of the path, just in case, but I never use them
    // 	the linter throws a warning if I don't exclude this line
    // eslint-disable-next-line
    const [blank, category, categoryId = '', ...rest] = path ? path.split('/') : ['', '', '', ['']]

    this.category = category
    this.categoryId = categoryId
  }

  _log = async (resource) => {
    // Temporary hack for allieviating issue where messages aren't showing when
    // a `uid` isn't present.
    if (!this.uid) {
      // console.log('no UID present')

      message.destroy() // Clear existing messages.
      if (resource.type === 'error') {
        message.error(resource.message)
      } else {
        message.info(resource.message)
      }
      return // Exit the function; the lack of a `uid` will prevent things from working correctly.
    }

    if (resource.user_index) {
      Object.entries(this.constructor.subscriptions).forEach(([id, ff]) => {
        if (resource.user_index[id]) ff.map((f) => f(resource.message))
      })
    } else {
      Object.values(this.constructor.subscriptions).forEach((ff) =>
        ff.forEach((f) => {
          f(resource.message)
        })
      )
    }

    if (typeof resource.message !== 'string' && !(resource.message instanceof String)) {
      console.log('you are trying to log a message that is not a string: ', resource.message)
    }

    const docRef = db.collection(this.category === 'projects' ? 'projectTransactionLog' : 'transactionLog').doc()
    const {uid, category, categoryId} = this
    const data = {
      ...resource,
      id: docRef.id,
      creator: uid,
      created: firebase.firestore.Timestamp.now(),
      category,
      categoryId,
      seen: false,
      organization: auth.sunkaizenUser.organization,
    }

    await docRef.set(data, {merge: true})
    return data
  }
}
