import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {modify, deleteField, activate} from '../action_creators'
import {Button, Input} from 'antd'
import DeleteButton from '../../DeleteButton'
import {store} from '../utilities'

const stop = (state) => ({section: state.sections[state.active.section]})
const dtop = (dispatch) => ({
  setSection: (id, value) => dispatch(modify('section')(id, value)),
  deleteSection: (id) => {
    //Delete the field from the "sections" in state
    dispatch(deleteField('section', id))
    //Update the order on the "form" in state to remove this section
    let formOrder = [...store.getState().form.order]
    formOrder.splice(formOrder.indexOf(id), 1)
    dispatch(modify('form_field')('order', formOrder))
    //Deactivate the section
    // dispatch( activate('section', null))
  },
})

export function SectionTitle({section, setSection, deleteSection}) {
  const [editing, setEditing] = useState(false)
  const [title, setTitle] = useState(section.title)

  //Define delete function for the Section
  function deleter() {
    deleteSection(section.id)
  }

  const deleteProps = {
    confirmationDisplay: 'Are you sure you want to delete this section?',
    icon: 'delete',
    size: 'large',
    title: 'Delete',
    type: 'secondary',
    onConfirm: deleter,
  }

  useEffect(
    function () {
      if (title === section.title) return
      setSection(section.id, {...section, title})
    },
    [editing]
  )

  useEffect(
    function () {
      if (section.title) setTitle(section.title)
    },
    [section.id]
  )

  return (
    <div className="flex-row justify-space-between">
      <div className="flex-row">
        <h3 className="margin-right-2" data-testid="sectionTitle">
          {editing ? (
            <Input value={title} defaultValue={section.title} onChange={(e) => setTitle(e.target.value)} />
          ) : (
            section.title
          )}
        </h3>
        <Button
          title="edit"
          icon={editing ? 'check' : 'edit'}
          onClick={() => setEditing(!editing)}
          data-cy="toggleEditSectionBtn"
        />
      </div>
      <DeleteButton {...deleteProps} data-cy="deleteBtn" />
    </div>
  )
}

const WrappedSectionTitle = connect(stop, dtop)(SectionTitle)
export default WrappedSectionTitle
