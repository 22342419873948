import React from 'react'
import {message} from 'antd'
import DB from '../../DAL/DB'
import validateQuestions from './validateQuestions'

const rr = new DB.Rubrics()

export default async function saveRubrics(formId, rubrics, sections, actions, enableAutoRubric) {
  const invalidQuestions = validateQuestions(rubrics, sections)

  if (!invalidQuestions) {
    const hideMessage = message.loading(`Saving...`)

    try {
      //Assemble a list of all rubrics already in the database for this form
      let results = await rr.makeQuery(null, { filters: [['formId', '==', formId]] }).get()

      let existingRubricsForForm = results.docs.map((rubric) => rubric.id)

      let providedRubrics = Object.keys(rubrics)

      //If any rubrics were found in the DB, but not provided to this function,
      // delete them.
      let deletePromises = []

      existingRubricsForForm.forEach((rubricId) => {
        if (!providedRubrics.includes(rubricId)) {
          deletePromises.push(rr.delete(rubricId))
        }
      })

      await Promise.all(deletePromises)

      //Save all of the rubrics provided
      await Promise.all(Object.values(rubrics).map((rubric) => rr.update(rubric.id, null, rubric)))
    } catch (e) {
      console.log(e)
      throw e
    } finally {
      hideMessage()
      message.success(`Rubrics saved`)
    }
    return true
  } else {
    console.info('invalidQuestions:', invalidQuestions)

    const error = <div>
      {Object.values(invalidQuestions).map(({ error }) => <div>{error}</div>)}
    </div>
  
    message.error(error, 25)
  }
}
