import DB from '../../../DAL/DB'

const users = new DB.Users()

export default async function validateFieldUser(fu) {
  if (!fu) throw new Error(`No Field User was assigned to this project`)

  try {
    const {ok, data} = await users.get(null, {
      filters: [
        ['type', '==', 'field-user'],
        ['name', '==', fu],
      ],
    })
    const [user] = data
    if (ok) return user
  } catch (e) {
    console.log(`error validating field user provided`, e)
    throw new Error(`The provided Field User does not exist ${fu}`)
  }
}
