// QM permissions need to now check for organization too

import {auth} from '../../../Auth'

export default function permissions() {
  if (auth && auth.sunkaizenUser) {
    let permissions
    let t = auth.sunkaizenUser.type.slice()

    if (['field-user'].includes(t)) {
      permissions = [{match: {[`user_index.${auth.sunkaizenUser.id}`]: 'inspector'}}]
    }

    if (t === 'quality-manager') {
      permissions = []
      if (!auth.sunkaizenUser.qualificationsAll) {
        permissions = [...permissions, {terms: {[`intake.id`]: Object.keys(auth.sunkaizenUser.qualifications || {})}}]
      }

      if (!auth.sunkaizenUser.clientsAll) {
        permissions = [...permissions, {terms: {[`client.id`]: Object.keys(auth.sunkaizenUser.clients || {})}}]
      }
    }

    if (['client', 'admin', 'super-admin', 'scheduler'].includes(t)) {
      permissions = [{match: {[`user_index.${auth.sunkaizenUser.organization.id}`]: 'inspector'}}]
    }

    return permissions
  } else return
}
