import {baseStore} from './utilities'
import DB from '../../DAL/DB'
import { auth } from '../../Auth'

const projects = new DB.Projects()

export default function thumbnail(parentID) {
  async function saveToDB(metadata) {
    const {
      data: [project],
      ok,
    } = await projects.get(parentID)
    if (!ok) throw new Error('unable to get project in file uploader thumbnail pattern')

    project.site.thumbnail = metadata
    await projects.updatebatch(parentID, null, {...project, updatedBy: auth?.sunkaizenUser?.id, updatedByName: auth?.sunkaizenUser?.name })
  }
  const store = baseStore(`projects/${parentID}/thumbnails/`)

  return {store, saveToDB}
}
