export const cancelIntakeReasons = {
  'Onsite cancellation by homeowner, no reschedule needed': {
    reason: 'Onsite cancellation by homeowner, no reschedule needed',
    options: {required: []},
  },
  'Onsite cancellation by homeowner, reschedule needed': {
    reason: 'Onsite cancellation by homeowner, reschedule needed',
    options: {required: ['comment', 'reschedule']},
  },
  'Onsite cancellation by inspector (e.g., other safety concern, 18+yr old not home)': {
    reason: 'Onsite cancellation by inspector (e.g., other safety concern, 18+yr old not home)',
    options: {required: ['reschedule']},
  },
  'Onsite No-Show': {
    reason: 'Onsite No-Show',
    options: {required: ['photo']},
  },
  'Weather conditions, reschedule requested': {
    reason: 'Weather conditions, reschedule requested',
    options: {required: ['reschedule']},
  },
  'Weather conditions, no reschedule': {
    reason: 'Weather conditions, no reschedule',
    options: {required: []},
  },
}
