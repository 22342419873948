import {getStaticMapThumbnail} from '.'
import GeocodeWrapper from '../../GeocodeWrapper'

export const wrappedGeocode = new GeocodeWrapper()

export default async function makeSiteAddress(args) {
  const {project = {site: {}}, dummyAddress, latitude, longitude, siteAddress} = args
  let {site} = args

  try {
    if (dummyAddress) site.dummyAddress = dummyAddress
    if (latitude && longitude && (latitude !== project.site.latitude || longitude !== project.site.longitude)) {
      site.geocode_results = JSON.parse(
        JSON.stringify(await wrappedGeocode.geocode({lat: latitude, lng: longitude}, `location`))
      )
      site.siteAddress = site.geocode_results.formatted_address
      site = await getStaticMapThumbnail(site)
    } else if (siteAddress !== project.site.siteAddress) {
      // wrappedGeocode.geocode({ siteAddress }, (results, status) => results)
      site.geocode_results = JSON.parse(JSON.stringify(await wrappedGeocode.geocode(siteAddress)))
      site = await getStaticMapThumbnail(site)
    } else {
      site.geocode_results = project.site.geocode_results
      site.thumbnail = project.site.thumbnail
    }

    return site
  } catch (e) {
    console.log(`error in makeSiteAddress`, e)
    throw e
  }
}
