import React, {useState, useContext} from 'react'
import {firebase} from '../../utilities'
import {auth} from '../../Auth'
import {ReducerContext} from '../reducer'

export default function SiteNote({logger, noteKey, save}) {
  const {state, dispatch} = useContext(ReducerContext)
  const [selected, setSelected] = useState(false)

  const note = state.project.siteNotes[noteKey]

  function change(e, i) {
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        logger.event(`${auth.currentUser.displayName} attempted to contact the site`, null, 5, state.project.user_index)
      }

      note.contactAttempted = e.target.checked
      dispatch({type: 'changeNote', index: noteKey, value: note})
    } else {
      note.note = e.target.value
      dispatch({type: 'changeNote', index: noteKey, value: note})
    }
  }

  const created =
    Object.prototype.toString.call(note.created) === '[object Date]'
      ? new firebase.firestore.Timestamp.fromDate(note.created)
      : new firebase.firestore.Timestamp(note.created._seconds, note.created._nanoseconds)

  return (
    <div className="flex-column" onBlur={save}>
      <div className="flex-row align-center">
        <div className="bold">{created.toDate().toDateString()}</div>
        <div className="padding-left-1">{note.author ? note.author : '[Unknown]'}</div>
        <div className="padding-left-right-1">Contact Attempted</div>
        <input
          disabled={note.authorId !== auth.currentUser.uid}
          onChange={(e) => change(e, noteKey)}
          type="checkbox"
          name="contactAttempt"
          checked={note.contactAttempted}
          value="contact_attempted"
          className="padding-left-1"
        />
      </div>
      <div onClick={() => setSelected(!selected)}>
        {note.authorId !== auth.currentUser.uid || !selected ? (
          <div className="padding-1 italic">{note.note || '(Click to Edit)'}</div>
        ) : (
          <div className="flex-row align-center">
            <textarea
              onBlur={() => setSelected(false)}
              onClick={(e) => e.stopPropagation()}
              rows="2"
              cols="75"
              value={note.note}
              onChange={(e) => change(e, noteKey)}
              className="padding-1"
            />
            <div className="padding-1 margin-2 background-tertiary color-white">Save</div>
          </div>
        )}
      </div>
    </div>
  )
}
