import React, {useContext} from 'react'
import {ReducerContext} from './reducer'
import ParetoChart from '../ParetoChart'
import NoData from './NoData'

var paretoData = {}

export default function Chart() {
  const {state} = useContext(ReducerContext)

  paretoData = {}
  if (state.qscores) {
    let showFields = state.qscores.fields
    let selectedSection = state.sections.filter((section) => section.id == state.dropdown.section).filter((e) => e)[0]

    if (selectedSection && selectedSection.id != 'All') {
      showFields = showFields.filter((field) => selectedSection.order.includes(field.field_id))
    }

    showFields.forEach((question_data) => {
      paretoData[question_data.question] = question_data.failed
    })
  }

  let returnDiv
  if (state.qscores) {
    returnDiv = (
      <div style={{float: 'center'}}>
        <ParetoChart data={paretoData} />
        <div>Viewing data calculated on {new Date(state.qscores.created.seconds * 1000).toUTCString()}</div>
      </div>
    )
  } else {
    returnDiv = <NoData />
  }

  return returnDiv
}
