import React from 'react';
import { Select, Icon, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { setField, updateProgress } from '../../action_creators';
import PhotoRequired from './PhotoRequired';
import Followup from './Followup';
import { fieldHasErrors } from '../../utilities';

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  value: state.fields[ownProps.id].value,
});

const dtop = (dispatch) => ({
  setField: ({ id, value }) => {
    dispatch(setField(id, value));
    dispatch(updateProgress());
  },
});

export function Dropdown({
  setField,
  field,
  id,
  question,
  label,
  options = [],
  required,
  tipText,
  value = {},
  photoRequired,
}) {
  let { values = {}, followup = {} } = value || {};
  const ff = {};
  options.forEach((option) => (ff[option.label] = {}));
  followup = { ...ff, ...followup };

  const set = (v) => {
    field.value = { values: { [v]: true }, followup };
    setField({ id, value: field });
  };

  const [currentValue] = Object.keys(values);
  const [selectedOption] = options.filter((option) => option.label === currentValue);

  return (
    <div className="question-container">
      <div className="bold padding-bottom-1 linebreak question-title">
        {question}
        {required && (
          <Tooltip title="Required">
            <Icon
              type="exclamation-circle"
              style={{ fontSize: '1.5rem', padding: '0 0 0 0.5rem', color: 'red' }}
            />
            <span style={{ fontSize: '1rem', color: 'red' }}> Required</span>
          </Tooltip>
        )}
      </div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{ fontWeight: 600 }}>
          {label ? label.toUpperCase() : `no label`}
        </div>
        <div
          className="full-width"
          style={{ border: fieldHasErrors(field) ? '1px solid red' : 'initial' }}
        >
          <Select
            style={{ width: '100%' }}
            placeholder={''}
            onChange={(v) => set(v)}
            value={currentValue}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }} // Ensure dropdown height is sufficient
            dropdownRender={(menu) => (
              <div style={{ maxHeight: 400, overflow: 'auto', whiteSpace: 'normal' }}>
                {menu}
              </div>
            )}
          >
            {options.map((option, i) => (
              <Select.Option
                key={i}
                value={option.label}
                style={{ whiteSpace: 'normal' }} // Ensures the text wraps instead of truncating
              >
                {option.label}
              </Select.Option>
            ))}
          </Select>
          <Followup id={id} option={selectedOption} />
        </div>
        <div className="linebreak">
          <small>{tipText && `${tipText}`}</small>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  );
}

const WrappedDropdown = connect(stop, dtop)(Dropdown);
export default WrappedDropdown;
