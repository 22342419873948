import {auth} from '../../../Auth'

export default function or() {
  if (['field-user'].includes(auth.sunkaizenUser.type))
    return [{match: {[`user_index.${auth.sunkaizenUser.id}`]: 'inspector'}}]

  return [
    {match: {'organization.id': auth.sunkaizenUser.organization.id}},
    {match: {[`user_index.${auth.sunkaizenUser.organization.id}`]: 'inspector'}},
  ]
}
