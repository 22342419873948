import React, {useState, useEffect} from 'react'
import {Input, List} from 'antd'

// onAutocomplete requests that the parent component recalculate what shows in the list
//  based on param search: String

// listField is a component that describes how a list item should display
//  it's just the visual stuff

// resources is the list of values ot display in the dropdown

export default function Autocomplete({onAutocomplete, setValue, resources, listField: L}) {
  const [zero, setZero] = useState(null)
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState(null)

  useEffect(
    function () {
      clearTimeout(zero)
      if (!search) {
        setShow(false)
        return
      }
      setZero(setTimeout(getMore, 500))
    },
    [search]
  )

  function onChange(e) {
    setSearch(e.target.value)
  }

  async function submit() {
    clearTimeout(zero)
    setValue(search)
    // await getMore()
  }

  async function getMore() {
    await onAutocomplete(search)
    setShow(true)
  }

  return (
    <div style={{position: 'relative'}}>
      <Input value={search} onChange={onChange} onSubmit={submit} />
      {show && resources.length > 0 && (
        <List
          className="background-white"
          style={{position: 'absolute', top: '3.0rem', zIndex: 10}}
          renderItem={(item) => (
            <List.Item
              onClick={() => {
                setShow(false)
                setValue(item)
              }}
              style={{cursor: 'pointer'}}
            >
              <L {...{item}} />
            </List.Item>
          )}
          dataSource={resources}
          bordered
        />
      )}
    </div>
  )
}
