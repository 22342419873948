import {combineReducers} from 'redux'

function meta(state = {active: 'select-failures'}, action) {
  switch (action.type) {
    case 'type':
      return {active: action.state}
    case 'resetTicket':
      return {active: action.mode}
    default:
      return state
  }
}

function selectedFailure(state = {}, action) {
  if (action.type === 'resetTicket') return {}
  if (action.type === 'selectedFailure') return action.value || state
  return state
}

function failures(state = {}, action) {
  switch (action.type) {
    case 'failures':
      return action.failures
    case 'failure':
      return {...state, [action.id]: action.value}
    case 'deleteFailure':
      const s = {...state}
      delete s[action.id]
      return s
    case 'resetTicket':
      return {}
    default:
      return state
  }
}

function project(state = {}, action) {
  switch (action.type) {
    case 'resetTicket':
      return {}
    case 'project':
      return action.project
    default:
      return state
  }
}

function ticket(state = {priority: 'Normal'}, action) {
  switch (action.type) {
    case 'setTicket':
      return action.ticket
    case 'ticketField':
      return {...state, [action.id]: action.value}
    case 'resetTicket':
      if (action.mode !== 'create') return state
      return {priority: 'Normal'}
    default:
      return state
  }
}

function createValid(state = false, action) {
  if (action.type === 'resetTicket') return false
  if (action.type === 'createValid') return action.value
  else return state
}

const rootReducer = combineReducers({
  meta,
  selectedFailure,
  failures,
  project,
  ticket,
  createValid,
})

export default rootReducer
