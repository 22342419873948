import {transformCriticalities, validate} from '.'
import DB from '../../DAL/DB'
import {auth} from '../../Auth'

const scoringSystems = new DB.ScoringSystems()

export default function finalize({state, dispatch, logger, type}) {
  return async function () {
    if (type !== 'create') dispatch({type: 'show', value: false})

    try {
      if (!validate(state, logger)) throw new Error('failed validation')

      const {name, percentOverall: percentage_failure, criticalities} = state
      const criticality_map = transformCriticalities(criticalities)
      const {organization} = auth.sunkaizenUser

      const id = type === 'create' ? scoringSystems.makeId() : state.id
      console.info('Update Scoring System:', state)
      await scoringSystems.update(id, null, {id, name, criticality_map, percentage_failure, organization})

      dispatch({type: 'clear'})
    } catch (e) {
      console.log(e)
    }
  }
}
