import React, {useEffect, useState} from 'react'
import {Spin} from 'antd'
import {storage, correctedURL} from '../utilities'

export default function ProjectCancelled({cancellations}) {
  const [url, setUrl] = useState(null)
  const [cancellation, setCancellation] = useState(null)

  useEffect(function () {
    setCancellation(cancellations[cancellations.length - 1])
  }, [])
  useEffect(
    function () {
      (async function () {
        if (!cancellation || !cancellation.photo) return
        const url = correctedURL(cancellation.photo.fullPath)
        setUrl(url)
      })()
    },
    [cancellation]
  )


  return cancellation ? (
    <div
      className="flex-column padding-2 margin-bottom-1"
      style={{borderRadius: '0.5rem', backgroundColor: 'rgba(250, 235, 215, 0.5)'}}
    >
      <h4>This Project was Canceled on {cancellation.canceledAt.toDate().toLocaleString()}</h4>
      <div>Reason: {cancellation.reason}</div>
      {cancellation.comment && <div className="padding-left-1 italic">{cancellation.comment}</div>}
      {cancellation.photo && url && <img style={{height: '10rem'}} alt="cancellation supporting reason" src={url} />}
    </div>
  ) : (
    <Spin size="large" />
  )
}
