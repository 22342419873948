import React from 'react'
import PopoverPermission from './PopoverPermission'

const UserPopoverContent = ({name = 'none', id, geocode_results = {}, qualifications = {}, clients = {}}) => (
  <div className="user-popover-container">
    <div className="top-padding-1 flex-column">
      <div className="bold">Address</div>
      <div>{(geocode_results || {}).formatted_address}</div>
    </div>
    <PopoverPermission name="Qualifications" permissions={Object.values(qualifications)} />
    <PopoverPermission name="Clients" permissions={Object.values(clients)} />
  </div>
)

export default UserPopoverContent
