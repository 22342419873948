import React from 'react'
import {Link} from 'react-router-dom'

const projectsContent = (
  <div>
    <Link to="/projects_inspections">
      <div className="padding-1">Inspections</div>
    </Link>
    <Link to="/projects_requests">
      <div className="padding-1">Requests</div>
    </Link>
  </div>
)

export default projectsContent
