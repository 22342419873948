import columns from './columns'
import onRow from './onRow'
import permissions from './permissions'
import sorter from './sorter'
import rowSelection from './rowSelection'
// import or from './or'

const config = {
  columns,
  onRow,
  permissions,
  // or,
  sorter,
  rowSelection,
  type: 'projectTransactionLog',
}

export default config
