import {makeCSV} from './makeCSV'

// this is a Promise wrapper for FileReader so that we can use Promises.all on an array of files for clarity
export const readUploadedText = (file) => {
  let fr = new FileReader()

  return new Promise((resolve, reject) => {
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.onerror = (e) => reject(console.log(`some kinda error converting file to text: `, e))
    fr.readAsText(file)
  }).then((file) => makeCSV(file))
}
