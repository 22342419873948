import React, {useState, useEffect} from 'react'
import DB from '../../DAL/DB'
import {Required} from '../../utilities'
import AutocompleteField from '../../AutocompleteField'
import {UglyField} from './UglyField'
import {SelectField} from './SelectField'
import {TextField} from './TextField'
import {types} from './addressFieldTypes'

const meta = new DB.Meta()

export default function UserInformation({fields, setField, style, noheader, notype, autocomplete}) {
  const [roles, setRoles] = useState([])

  useEffect(function () {
    return meta.subscribe('roles', null, ({ok, data: [r]}) => {
      if (r) {
        delete r.id
      }
      if (!ok) return
      setRoles(Object.keys(r))
    })
  }, [])

  async function changeAddress(address) {
    const filteredAddress = {}
    address[0].address_components.forEach((c) => {
      if (c.types.some((t) => types[t])) {
        filteredAddress[c.types[0]] = c.short_name
      }
    })
    for (let k in filteredAddress) setField(k)(filteredAddress[k])
    setField(`address`)(address[0].formatted_address)
    setField(`geocode_results`)(address[0])
  }

  //console.log('fields',fields)

  return (
    <div className="outer-container flex-column" style={style}>
      {!noheader && <h3>User Information</h3>}
      <div className="flex-row full-width" style={{margin: `0 0 1rem 0.5rem`}}>
        <div className="flex-column" style={{flex: 20}}>
          <Required />
          <UglyField style={{flex: 20}}>
            <TextField field={fields.name} set={setField(`name`)} placeholder="Full Name" />
          </UglyField>
        </div>
        {!notype && <div style={{flex: 1}}></div>}
        {!notype && (
          <div className="flex-column" style={{flex: 20}}>
            <Required />
            <UglyField>
              <SelectField
                {...{set: setField(`type`), field: fields.type, placeholder: `Select User Type`, options: roles}}
              />
            </UglyField>
          </div>
        )}
      </div>
      <div className="flex-row full-width" style={{margin: `0 0 1rem 0.5rem`}}>
        <div className="flex-column" style={{flex: 20}}>
          <Required />
          <UglyField>
            <TextField field={fields.email} type="email" set={setField(`email`)} placeholder="Email" isdisabled={fields.emailDisabled || false} />
          </UglyField>
        </div>
        <div style={{flex: 1}}></div>
        <div className="flex-column" style={{flex: 20, justifyContent: `flex-end`}}>
          <Required />
          <UglyField>
            <TextField field={fields.phone} set={setField(`phone`)} placeholder="Phone" />
          </UglyField>
        </div>
      </div>
      {fields.type === 'field-user' && <Required />}
      <div className="flex-row full-width" style={{margin: `0 0 1rem 0.5rem`}}>
        <div className="flex-column full-width">
        <Required />
        <UglyField style={{flex: 20}}>
          <AutocompleteField
            full
            edit
            autoComplete={autocomplete}
            style={{flex: 1, padding: `1rem`}}
            submit={changeAddress}
            old_address={fields.address}
            placeholder="Address"
            data-cy="address"
          />
        </UglyField>
        </div>
      </div>
      {fields.type === `client` && (
        <>
        <Required />
        <UglyField className="flex-row full-width" style={{margin: `0 0 1rem 0.5rem`}}>
          <TextField field={fields.company} set={setField(`company`)} placeholder="Company Name" />
        </UglyField>
        </>
      )}
      {/*
					THIS IS TEMPORARY;
					it will need to be replaced by a component that more cleanly handles multiple Ids
					and validates them against the NABCEP database
            */}
      {fields.type === `installer` && (
        <UglyField className="flex-row full-width" style={{margin: `0 0 1rem 0.5rem`}}>
          <TextField field={fields.nabcep_ids} set={setField(`nabcep_ids`)} placeholder="NABCEP Ids, Comma Separated" />
        </UglyField>
      )}
    </div>
  )
}
