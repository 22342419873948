import naturalCompare from 'string-natural-compare'

export function sortImages(a, b) {
  return naturalCompare(a.name, b.name, {caseInensitive: true})

  // // if only one is marked, it automatically wins
  // if (![a, b].every(i => i.marked) || ![a, b].every(i => !i.marked)) return a.marked ? -1 : 1
  // // otherwise, use the sorter function get the result of some bitwise math
  // const sorted = (a.created ? 8 : 4) | (b.created ? 2 : 1)
  // switch (sorted) {
  // case 5:
  //     return naturalCompare(a, b, {caseInensitive: true})
  //     // return (a.name || ``).toUpperCase() < (b.name || ``).toUpperCase() ? -1 : 1
  // case 6:
  //     return -1
  // case 9:
  //     return 1
  // default:
  //     return a.created.seconds - b.created.seconds
  // }
}
