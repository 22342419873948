import BaseModel from './BaseModel';
import { db } from '../utilities';

export default class SpecialAccess extends BaseModel {
  constructor() {
    super(db.collection('special_access'));
  }

  // Method to add a new entry to the special_access collection
  static async add(data: any) {
    try {
      const docRef = await db.collection('special_access').add(data);
      return { id: docRef.id, ...data };
    } catch (error) {
      console.error('Error adding special access data:', error);
      throw new Error('Failed to add special access data');
    }
  }
  // Method to get all entries from the special_access collection
  static async getAll() {
    try {
      const snapshot = await db.collection('special_access').get();
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return data;
    } catch (error) {
      console.error('Error getting special access data:', error);
      throw new Error('Failed to retrieve special access data');
    }
  }
  static async updateStatus(specialurl: any, newStatus: any) {
    try {
      const snapshot = await db.collection('special_access').where('specialurl', '==', specialurl).get();
      if (!snapshot.empty) {
        const doc = snapshot.docs[0];
        await doc.ref.update({ status: newStatus });
        return { success: true };
      } else {
        throw new Error('No matching document found');
      }
    } catch (error) {
      console.error('Error updating special access status:', error);
      throw new Error('Failed to update special access status');
    }
  }

  

  // Method to query special access records by form ID
  static async getByFormId(formId: string) {
    try {
      const snapshot = await db.collection('special_access').where('formid', '==', formId).get();
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error querying special access records:', error);
      throw new Error('Failed to query special access records');
    }
  }

  // Method to disable a special access record by updating its status
  static async disableRecord(recordId: string) {
    try {
      await await db.collection('special_access').doc(recordId).update({ status: 'disabled' });
    } catch (error) {
      console.error('Error disabling special access record:', error);
      throw new Error('Failed to disable special access record');
    }
  }
}
