import React, {useEffect, useState} from 'react'
import {Button, Checkbox} from 'antd'

import './localStyles.css'
import DB from '../../../DAL/DB'
import {capitalizeFirst} from '../../../utilities'
import {auth} from '../../../Auth'
import {TextField, MultiselectField} from '../../utilities'

const organizations = new DB.Organizations()

export default function OrganizationConfiguration() {
  const [organization, setOrganization] = useState({})
  const [editing, setEditing] = useState(false)

  useEffect(
    function () {
      if (editing) return
      if (!organization.id) return
      ;(async function () {
        await organizations.update(organization.id, null, organization)
      })()
    },
    [editing]
  )

  useEffect(
    function () {
      if (!auth.sunkaizenUser.organization) return
      ;(async function () {
        const {
          data: [o],
          ok,
        } = await organizations.get(auth.sunkaizenUser.organization.id)
        if (!ok) return

        setOrganization(o)
      })()
    },
    [auth.sunkaizenUser.organization]
  )

  function change(k, v) {
    const o = {...organization}
    o[k] = v
    setOrganization(o)
  }

  return (
    <div>
      {auth.sunkaizenUser.type === 'super-admin' && (
        <Button onClick={() => setEditing(!editing)}>{editing ? 'Save' : 'Edit'}</Button>
      )}
      {auth.sunkaizenUser.type === 'client' && (
        <Button onClick={() => setEditing(!editing)}>{editing ? 'Save' : 'Edit'}</Button>
      )}
      <div className="container">
        <TextField disabled={true} label={capitalizeFirst('name')} value={organization['name']} />
        {['phone', 'email'].map((f) => (
          <TextField
            disabled={!editing}
            label={capitalizeFirst(f)}
            value={organization[f]}
            change={(value) => change(f, value)}
          />
        ))}
        <MultiselectField
          disabled={!editing}
          label={'Roles'}
          onChange={(v) => change('roles', v)}
          value={organization.roles}
          options={['Installer', 'Inspector', 'Project Reviewer']}
        />
      </div>

      <div className="container">
        <Checkbox
          disabled={!editing}
          checked={organization.third_party_inspector}
          onChange={(e) => {
            change('third_party_inspector', e.target.checked)
          }}
        >
          Identify My Organization as a 3rd Party Inspector
        </Checkbox>
      </div>
    </div>
  )
}
