import React, {useEffect, useState} from 'react'
// import {FULL_TEXT_SEARCH_URL} from "./config"
// import {auth} from './Auth'
import DB from './DAL/DB'
import {Button, Input} from 'antd'
// import ReactJson from 'react-json-view'
import {useDBSubscribe} from './effects'

const projects = new DB.Projects()

function modifyOptions(bool) {
  console.log(bool)
  return bool ? {} : {filters: [['status', '==', 'Completed']]}
}

export default function Testbed() {
  const [options, setOptions] = useState({})
  const pp = useDBSubscribe(projects, options)

  function modify() {
    setOptions(modifyOptions(Object.keys(options).length))
  }

  return (
    <div>
      <Button className="padding-2" onClick={modify}>
        Modify Options
      </Button>
      <h3>Count of projects: {pp.length}</h3>
      {pp.map((p) => (
        <div>{p.id}</div>
      ))}
    </div>
  )
}

// export default function Testbed () {
//     const [v, setV] = useState(null)
//     const [project, setProject] = useState({})

//     async function fetchProject () {
//         console.log('fetching: ', v)

//         const {data: [p], ok} = await projects.get(v)
//         if (ok) {
//             console.log('success')
//             setProject(p)
//             return
//         }

//         setProject({error: 'could not get project'})
//     }

//     const js = JSON.stringify(project)
//     console.log(js.length)

//     return (
//         <div style={{textAlign: 'left'}} >
//             <div className='flex-row' >
//                 <Input value={v} onChange={e => setV(e.target.value)} />
//                 <Button onClick={fetchProject} >Submit</Button>
//             </div>
//             <div className='padding-2' >
//                 <ReactJson name={project.site ? project.site.siteOwnerName : 'none'} src={project} />
//             </div>
//         </div>
//     )
// }

// const [p, setP] = useState([])

// useEffect(function () {
//     (async function () {
//         const body = {
//             from: 0,
//             size: 1000,

//             query: {
//                 bool: {
//                     should: [
//                         {term: {'organization.id.sortable': auth.sunkaizenUser.organization.id}},
//                         // {match: {'client.id.sortable': auth.sunkaizenUser.organization.id}},
//                         // {match: {'inspection.inspector.id.sortable': auth.sunkaizenUser.organization.id}},
//                         // {match: {'installer.id.sortable': auth.sunkaizenUser.organization.id}}
//                     ],
//                     filter: [{ term: { indexType: 'projects' } }],
//                     minimum_should_match: 1
//                 }
//             }
//         }

//         const url = `${FULL_TEXT_SEARCH_URL}/free_query`

//         const res = await (
//             await fetch(url, {
//                 method: `POST`,
//                 mode: `cors`,
//                 headers: {"Content-Type": `application/json`},
//                 body: JSON.stringify(body)
//             })
//         ).json()

//         console.log(res)

//         setP(res.hits.hits)
//     })()
// }, [])

// return (
//     <div>{p.map(pp =>
//         <div className='flex-row full-width justify-space-between' >
//             <div style={{flex: 1}} >{pp._source.id}</div>
//             <div style={{flex: 1}} >{pp._source.status}</div>
//             <div style={{flex: 1}} >{pp._source.intake.title}</div>
//             <div style={{flex: 1}} >{pp._source.organization && pp._source.organization.id}</div>
//         </div>
//     )}</div>
// )
