import React, {useState, useEffect} from 'react'
import {auth} from '../../Auth'
import {db} from '../../utilities'
import {Select} from 'antd'
import {generateProjectShell} from '../../ProjectBuilder/utilities'

export const pickerMap = {
  rubric: {
    modalTitle: `Change Project Rubric`,
    plural: `rubrics`,
    query: (obj) =>
      db
        .collection(`rubrics`)
        .where('organization.id', '==', auth.sunkaizenUser.organization.id)
        .where(`formId`, `==`, obj.id)
        .get(),
    name: (obj) => obj.name,
    save: (pId, rubric) => db.collection(`projects`).doc(pId).set({rubric}, {merge: true}),
  },
  form: {
    modalTitle: `Change Form (This will delete saved intake answers)`,
    plural: `forms`,
    query: () => db.collection(`forms`).get(),
    name: (obj) => obj.title,
    save: async (pId, intake) => {
      const ii = await generateProjectShell(intake)
      await db.collection(`projects`).doc(pId).set({intake: ii, rubric: {}}, {merge: true})
    },
  },
}

export default function Picker({project, type, done}) {
  const [resources, setResources] = useState({})
  const save = async (id) => {
    try {
      if (id) {
        await pickerMap[type].save(project.id, resources[id])
        done()
      } else throw new Error(`no project ID is present; cannot save project`)
    } catch (e) {
      console.log(`failed to change ${type}: `, e)
    }
  }

  useEffect(() => {
    const get = async () => {
      const snaps = await pickerMap[type].query(project.intake)
      const resources = {}
      snaps.docs.map((snap) => (resources[snap.id] = {...snap.data(), id: snap.id}))

      setResources(resources)
    }
    get()
  }, [])

  return (
    <Select onChange={save} style={{width: `100%`}}>
      {Object.values(resources).map((resource) => (
        <Select.Option value={resource.id}>{pickerMap[type].name(resource)}</Select.Option>
      ))}
    </Select>
  )
}
