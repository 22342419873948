import React from 'react'
import {connect} from 'react-redux'
import {Input} from 'antd'
import {setCorrection} from '../action_creators'

const sToP = (state, op) => ({correction: state.project.intake.corrections[op.fieldId]})
const dToP = (dispatch) => ({setCorrection: (id, correction) => dispatch(setCorrection(id, correction))})

export function Corrections({setCorrection, correction = '', fieldId}) {
  return (
    <div
      className="margin-top-1 color-white padding-1 flex-column"
      style={{
        background: 'rgba(255, 0, 0, 0.5)',
        border: '0.1rem solid #C4CDD5',
        borderRadius: '0.2rem',
      }}
    >
      <div style={{fontSize: '1.5rem'}}>Correction Details</div>
      <Input.TextArea
        onChange={(e) => setCorrection(fieldId, e.target.value)}
        value={correction}
        style={{border: 'none'}}
        size="large"
        placeholder="Enter a correction note"
        autoSize={{minRows: 4}}
      />
    </div>
  )
}

const WrappedCorrections = connect(sToP, dToP)(Corrections)
export default WrappedCorrections
