// 	the import style below was breaking the build:
// 		import firebase from "firebase/firebase"
import React from 'react'
import firebase from 'firebase/app'
import _ from 'lodash'
import produce from 'immer'
import {useState} from 'react'
import {createBrowserHistory} from 'history'
import {auth} from './Auth'

// eslint-disable-next-line
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

import {firebaseConfig} from './config'

// export const history = createBrowserHistory()
const history = createBrowserHistory()
const {push: p} = history
history.push = (path) => {
  console.log('deprecated; use top-level history instead of utilities history')
  console.log(path)
  p(path)
}

firebase.initializeApp(firebaseConfig)

export const capitalizeFirst = (name) => name.charAt(0).toUpperCase() + name.slice(1)

const db = firebase.firestore()

// const firestoreSettings = { cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED };
// db.settings(firestoreSettings);
// const settings = { timestampsInSnapshots: true }
// db.settings(settings)

if (process.env.NODE_ENV === `development`) {
  window.db = db
}

const storage = firebase.storage()

export {firebase, db, storage, history}

export const singular = {
  equipment: `equipment`,
  inspections: `inspection`,
  projects: `project`,
  users: `user`,
  sites: `site`,
  tickets: `ticket`,
  jx: `jurisdiction`,
  files: `file`,
  forms: 'form',
  authorizations: 'authorization',
}

// want to create a jx function that hits the google geocoding API so I get a proper location with bounds
// export const submits = {
// 	"user": (collection, body) => firestoreRequestOrganization(body)
// }

export const roleList = [`super-admin`, `admin`, `aggregator`, `inspector`, `quality-manager`, `installer`]

export const projectResourceArray = [`inspections`, `sites`, `users`] //	'equipment',

// these models will have the fields which can be searched for on each model using the map page

/*
// type checking fields:
	<string>: typeof <string> === "string"
	<Date>: <Date> instanceof Date

*/
export const map_db_models = {
  users: {
    created: Date,
    email: `string`,
    name: `string`,
    updated: Date,
  },
  sites: {
    created: Date,
    email: `string`,
    name: `string`,
    phone: `string`,
    updated: Date,
  },
}

export const ago = (timestamp) => {
  const date = timestamp.toDate()
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)

  const intervals = {
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  }

  let counter
  // eslint-disable-next-line
  for (let intv in intervals) {
    counter = Math.floor(seconds / intervals[intv])
    if (counter > 0) {
      return counter + ` ` + intv + `s ago`
    }
  }
}

// currying lets you apply several arguments to the same function at different times
// export const curry = (f, p) => p.reduce((g, h) => g(h), f)

// composition, passes a single argument down through a pipe of functions that can mutate it along the way
// export const compose = (...fs) => fs.reduce((f, g) => (...args) => f(g(...args)))

export const Required = () => (
  <div style={{display: `flex`, flexFlow: `row nowrap`, alignItems: `center`, color: `red`}}>
    <div style={{padding: `0 0 0 0.5rem`}}>* Required</div>
  </div>
)

/**
 * Like `useState`, but instead of only accepting an initial value, it allows
 * for the value given to it to change, and will synchronize its internal state
 * accordingly.
 *
 * `value`: The (possibly changing) value to synchronize the internal state
 * with. Whenever the `value` changes (using deep equality comparison), the
 * `mergeStrategy` is used the alter the `state` returned.
 *
 * `mergeStrategy`: Strategy for merging changes to `value` into the form's
 * internal state. Defaults to `"favorInternalState"`. The options are:
 * * `"favorInternalState"`: Override any conflicting keys with the values in
 *   the internal state.
 * * `"favorExternalState"`: Override any conflicting keys with the values
 *   from `value`.
 * * `"replace"`: Completely replace the internal state with `value` whenever
 *   it changes.
 * * `"controlled"`: Never uses internal state. Always uses `value`.
 */
export function useStateSynchronizedWithInput(value, {mergeStrategy = `favorInternalState`} = {}) {
  const [lastValue, setLastValue] = useState(value)
  const [state, setState] = useState(value)

  if (mergeStrategy === `controlled`) {
    return [value, undefined]
  }

  if (!_.isEqual(value, lastValue)) {
    setLastValue(value)

    if (mergeStrategy === `favorInternalState`) {
      setState({...state, ...value})
    } else if (mergeStrategy === `favorExternalState`) {
      setState({...value, ...state})
    } else if (mergeStrategy === `replace`) {
      setState(value)
    }
  }

  return [state, setState]
}

/**
 * Helper for wiring up change callbacks in forms.
 *
 * Use it as follows:
 *
 * Initialize it for the state that you want to change
 *
 *      const change = onInputChange(someState, setSomeState)
 *
 * Then, read the docs for the `change` function you just produced.
 */
export const onInputChange =
  (state, setState) =>
  /**
   * If you just want to use `event.target.value`, write:
   *
   *      change((value, state) => { state.nestedStuff.someField = value })
   *
   * But if you want a different field, write a string accessor for the event
   * like:
   *
   *      change("target.checked", (value, state) => { state.toggle = value })
   *
   * Note that you can just mutate the state directly. A library makes it so
   * that those changes will not mutate the source.
   */
  (fieldPath, mutateState) =>
  (event) => {
    // Allow omitting fieldPath argument in the common case that it's "target.value".
    if (_.isFunction(fieldPath) && null == mutateState) {
      mutateState = fieldPath
      fieldPath = `target.value`
    }
    const newValue = !_.isEmpty(fieldPath) ? _.get(event, fieldPath) : event
    setState(produce(state, (draft) => mutateState(newValue, draft)))
  }

/**
 * Generates a Universally Unique Identifier. This is great for when you don't
 * have ids to give react children keys.
 *
 * Version 4 of https://www.ietf.org/rfc/rfc4122.txt
 */
export function uuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
}

/**
 * Gets the data out of a DocumentSnapshot or QueryDocumentSnapshots, including
 * the documents `id` as a field in the returned data irrespective of if the
 * document already had an `id` field (ensuring consistency).
 *
 * A thing about firestore is that it does not provide the id of document when
 * you call `.data()` on the snapshot after fetching it. So what we find is that
 * most places in our code, we manually grab that id and merge it into the data.
 * This utility helps to cut down on that noise by solving for the common thing
 * that we'd end up writing manually in most places in our code.
 */
export function dataFromSnapshot(snapshot) {
  if (snapshot.docs) {
    // It's a querySnapshot
    return snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
  }
  return {...snapshot.data(), id: snapshot.id}
}

/**
 * Used to immutably update a value in an array by matching on a key. If
 * `keyOrFn` is not provided, assumes the values are objects and matches on the
 * `id` field. Returns either the unmodified array if no match could be found, or
 * the new array. DOES NOT DEEPLY CLONE: don't treat the return value as if it can
 * be modified without consequence.
 *
 * `keyOrFn` can be a string key, which assumes that the values are all objects
 * with such keys, or arrays where the key is the index.
 *
 * `keyOrFn` can also be a function to extract the key out.
 *
 */
export function updateArray(array, value, keyOrFn) {
  let lookupFunc
  if (keyOrFn == null) {
    lookupFunc = (x) => x.id
  } else if (typeof keyOrFn === `function`) {
    lookupFunc = keyOrFn
  } else {
    lookupFunc = (x) => x[keyOrFn]
  }

  const index = array.findIndex((x) => lookupFunc(x) === lookupFunc(value))

  if (index < 0) {
    return array
  } else {
    return [...array.slice(0, index), value, ...array.slice(index + 1)]
  }
}

/**
 * This relatively simple regular expression matches to the general form of user
 * emails. It can be used to give feedback to users that the email they typed
 * was malformed, but does not of course validate that the email itself is
 * valid. The specification for emails is rather large and this uses a subset of
 * that, albeit the most common one in this domain.
 *
 * If you'd like to more robustly validate emails in the browser, use an `<input
 * type="email">`, and submit it with a form action. Regex is not the correct
 * solution outside of simple user feedback here.
 */
export const emailRx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * Creates a pipeline, taking the given data and passing it into the next
 * function. If your function needs more than 1 parameter, pass it as an array,
 * with the function as the first value, and the other arguments following. The
 * piped value will be passed as the first argument to the function.
 *
 * Example:
 *
 *      pipe(2.4, Math.floor, [Math.pow, 8]) === 256 // True
 */
export function pipe(value, ...funs) {
  let ret = value

  for (let funOrArray of funs) {
    if (_.isArray(funOrArray)) {
      let [fun, ...args] = funOrArray
      ret = fun(ret, ...args)
    } else {
      ret = funOrArray(ret)
    }
  }

  return ret
}

// const corrected = url
//     .replace(/\//g, "%2F")
//     .replace(/,/g, "%2C")
//     .replace(/\+/g, "%2B")
//     .replace(/\s/g, "%20")
export const correctedURL = (url) => {
  const corrected = encodeURIComponent(url)
  return `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${corrected}?alt=media`
}

export const userCategories = {
  admins: () => ['client', 'admin', 'super-admin'].includes((auth.sunkaizenUser || {}).type),
  internal: () =>
    ['client', 'admin', 'super-admin', 'scheduler', 'quality-manager', 'field-user'].includes(
      (auth.sunkaizenUser || {}).type
    ),
  inspector: () => ['client', 'admin', 'super-admin', 'field-user'].includes((auth.sunkaizenUser || {}).type),
  reviewer: () => ['quality-manager'].includes((auth.sunkaizenUser || {}).type),
  outside: () => ['installer', 'client', 'field-user'].includes((auth.sunkaizenUser || {}).type),
}

export function sameOrganization(org1, org2) {
  if (!org1) return false
  if (!org2) return false
  if (org1.id && org2.id && org1.id === org2.id) return true
}


export const timestampToDate = (timestamp) => {
  if (timestamp instanceof firebase.firestore.Timestamp) {
      return timestamp.toDate().toDateString();
  }
    
  // Check if it's an object with seconds and nanoseconds
  if (timestamp && timestamp.seconds && timestamp.nanoseconds) {
  const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  return date.toDateString();
  }
  return 'Invalid date';
}