import {useContext, useEffect} from 'react'
import {useDBSubscribe} from '../../effects'
import DB from '../../DAL/DB'
import {auth} from '../../Auth'
import {ReducerContext} from '../reducer'

const csvFilters = new DB.CSVFilters()

export default function useGetCSVFilters() {
  const {dispatch} = useContext(ReducerContext)

  const ff = useDBSubscribe(csvFilters, {filters: [['user', '==', auth.sunkaizenUser.id]]})

  useEffect(
    function () {
      const filters = {}
      for (let f of ff) {
        filters[f.id] = f
      }
      dispatch({type: 'presetFilters', value: filters})
    },
    [ff]
  )
}
