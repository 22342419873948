import React, {useReducer} from 'react'

/*
    this is not redux's combineReducers; it does not automatically namespace, 
    make sure not to have overlapping state changes in different reducers
    
    this code predated the combineReducers function, and namespacing parts of the reducer would require 
    refactoring a lot of the consuming components
*/
function combineReducers(reducers) {
  return function (state, action) {
    for (let r in reducers) {
      state = reducers[r](state, action)
    }

    return state
  }
}

const alls = {owner: 'All', client: 'All', installer: 'All'}
function setUser(state, action) {
  const dropdown = {...state.dropdown, ...alls}
  console.log('action:', action)
  if (action.value !== 'All') dropdown[action.type] = action.value
  return dropdown
}

function setDropdown(state, action) {
  const returns = {...state, qscores: null}
  switch (action.type) {
    case `client`:
    case `installer`:
    case `owner`:
      return {...returns, dropdown: setUser(state, action)}
    case `aggregation`:
      const {orgRole, form, rubric} = state.dropdown
      return {
        ...returns,
        dropdown: {
          ...alls,
          section: 'All',
          timeFrame: 'All Time',
          orgRole,
          form,
          rubric,
          aggregation: action.value,
        },
      }
    case `form`: {
      const dropdown = {...state.dropdown, form: action.value, section: 'All'}
      delete dropdown.rubric
      return {...returns, dropdown, qscores: null}
    }
    case `orgRole`:
      return {...returns, dropdown: {...alls, orgRole: action.value, section: 'All', timeFrame: 'All Time'}}
    case 'reset':
      return {...returns, dropdown: {...alls, timeFrame: 'All Time'}}
    case `rubric`:
      return {...returns, dropdown: {...state.dropdown, rubric: action.value}}
    case `section`:
      return {...returns, dropdown: {...state.dropdown, section: action.value}}
    case `timeFrame`:
      return {...returns, dropdown: {...state.dropdown, timeFrame: action.value}}
    default:
      return state
  }
}

function allResources(state, action) {
  switch (action.type) {
    case 'allForms':
      return {...state, allForms: action.value}
    case 'allOrgs':
      return {...state, allOrgs: action.value}
    case 'allUsers':
      return {...state, allUsers: action.value}
    default:
      return state
  }
}

function users(state, action) {
  switch (action.type) {
    case `clients`:
      return {...state, clients: action.value}
    case 'installers':
      return {...state, installers: action.value}
    case 'owners':
      return {...state, owners: action.value}
    default:
      return state
  }
}

function loading(state, action) {
  switch (action.type) {
    case `clientsLoading`:
      return {...state, clientsLoading: action.value}
    case 'installersLoading':
      return {...state, installersLoading: action.value}
    case 'ownersLoading':
      return {...state, ownersLoading: action.value}
    case 'sectionsLoading':
      return {...state, sectionsLoading: action.value}
    default:
      return state
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'aggregations':
      return {...state, aggregations: action.value}
    case 'analytics':
      return {...state, analytics: action.value}
    // deprecated
    // case "dropdown":
    //     return {...state, dropdown: action.value, qscores: null}
    case 'final':
      return {...state, final: action.value}
    case 'forms':
      return {...state, forms: action.value}
    case 'organization':
      return {...state, organization: action.value}
    case 'orgAss':
      return {...state, orgAss: action.value}
    case 'orgRoles':
      return {...state, orgRoles: action.value}
    case 'projectAvgs':
      return {...state, projectAvgs: action.value}
    case 'graphData':
      return {...state, graphData: action.value}
    case 'qscores':
      return {...state, qscores: action.value}
    case 'rubrics':
      return {...state, rubrics: action.value}
    case 'sections':
      return {...state, sections: action.value}
    case 'SO_data':
      return {...state, SO_data: action.value}
    case 'timeFrames':
      return {...state, timeFrames: action.value}
    case 'valid':
      return {...state, valid: action.value}
    case 'clearFilter':
    default:
      return state
  }
}

const initialState = {final: {}, dropdown: {}, projectAvgs: [], allOrgs: [], allForms: []}

export const ReducerContext = React.createContext(null)

const reducers = combineReducers([setDropdown, allResources, users, loading, reducer])

// eslint-disable-next-line
export const wrap = (C) => (p) => {
  const [state, dispatch] = useReducer(reducers, initialState)
  return (
    <ReducerContext.Provider value={{state, dispatch}}>
      <C {...p} />
    </ReducerContext.Provider>
  )
}
