import {useEffect, useContext} from 'react'
import {ReducerContext} from '../reducer'

// this is a temporary bypass; remove it once the data model for question_scores is fixed
const bypass = false
//

export default function useDispatchResults(qScores, aggType) {
  const {state, dispatch} = useContext(ReducerContext)

  useEffect(
    function () {
      if (!qScores) return

      //Just in case something when wrong and there is junk data in our collection, we post-filter
      qScores = qScores.filter((qScore) => !!qScore.filters)

      const filterTypes = ['client', 'installer', 'reviewer'].filter((type) => type !== aggType)
      let valid = {}
      if (aggType) valid[aggType] = []

      // this is testing code to get past a temporary bug in the data model --- Chris 8/17/2021
      let validForms, validTimeFrames
      if (bypass) {
        valid.client = [{id: 'All', name: 'All'}]
        valid.installer = [{id: 'All', name: 'All'}]
        valid.reviewer = [{id: 'All', name: 'All'}]
        valid.forms = state.allForms.map((f) => f.id)
        valid.time_frame = ['All Time', '365-day', '180-day', '90-day', '30-day', '7-day']

        validForms = state.allForms
        validTimeFrames = getValidTimeframes(valid.time_frame)
      }
      // this is the production code
      else {
        //Look only at question scores using this rubric and aggregation type
        console.log('qScores:', qScores)
        let filteredScores = qScores.filter((qScore) => qScore.rubric_id == state.dropdown.rubric)
        filteredScores = qScores.filter((qScore) => qScore.aggregation_type == aggType)
        console.log('filteredScores:', filteredScores)

        for (let filterType of filterTypes) {
          console.log('filterType:', filterType)

          let validIds = [...new Set(filteredScores.map((qScore) => qScore.filters[filterType]))]
          console.log('validIds:', validIds)

          let validOrgs = [
            ...state.allOrgs.filter((org) => org.id && validIds.includes(org.id)),
            {id: 'All', name: 'All'},
          ]
          valid[filterType] = validOrgs
        }

        valid.forms = [...new Set(qScores.map((qScore) => qScore.form_id).filter((e) => e))]

        if (state.dropdown.client != 'All') {
          filteredScores = filteredScores.filter((qScore) => qScore.filters.client == state.dropdown.client)
        }
        if (state.dropdown.reviewer != 'All') {
          filteredScores = filteredScores.filter((qScore) => qScore.filters.reviewer == state.dropdown.installer)
        }
        if (state.dropdown.installer != 'All') {
          filteredScores = filteredScores.filter((qScore) => qScore.filters.installer == state.dropdown.installer)
        }

        valid.time_frame = [...new Set(filteredScores.map((qScore) => qScore.filters.time_frame).filter((e) => e))]

        validForms = state.allForms.filter((form) => valid.forms.includes(form.id))
        validTimeFrames = getValidTimeframes(valid.time_frame)
      }

      dispatch({type: 'valid', value: valid})
      dispatch({type: 'clients', value: valid.client})
      dispatch({type: 'owners', value: valid.reviewer})
      dispatch({type: 'installers', value: valid.installer})
      dispatch({type: 'forms', value: validForms})
      dispatch({type: 'timeFrames', value: validTimeFrames})
    },
    [qScores, aggType, state.dropdown.rubric, state.dropdown.client, state.dropdown.owner, state.dropdown.installer]
  )
}

function getValidTimeframes(timeFrames) {
  const allTimeFrames = [
    {id: 'All Time'},
    {id: '365-day', display: '1 year'},
    {id: '180-day', display: '6 month'},
    {id: '90-day', display: '3 month'},
    {id: '30-day', display: '30 day'},
    {id: '7-day', display: '7 day'},
  ]

  return allTimeFrames.filter((tf) => timeFrames.includes(tf.id))
}
