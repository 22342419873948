import {store} from '../utilities'
import {clear} from '../action_creators'

export default function readForm(readDB, id) {
  return function () {
    if (!id) return
    readDB({paths: [{collection: 'forms', doc: id}]}, 'form')
    return () => store.dispatch(clear())
  }
}
