import React from 'react'
import {Checkbox} from 'antd'

export default function Checkboxes({field}) {
  return (
    <div className="flex-column">
      {field.options && field.options.map((option, i) => <Checkbox key={i}>{option.label}</Checkbox>)}
    </div>
  )
}
