import React from 'react'
import {Icon, Popconfirm} from 'antd'
import {auth} from '../Auth'

export default function SiteNote({
  i,
  notes,
  deleteSiteNote,
  changeSiteNote,
  author,
  authorId,
  created,
  note,
  setNotes,
  contactAttempted,
}) {
  return (
    <div key={i} className="flex-column padding-0-5">
      <div className="flex-row justify-space-between align-center">
        <div className="bold padding-right-1">
          {(created.toDate ? created.toDate() : new Date(created._seconds * 1000)).toDateString()}
        </div>
        {auth.currentUser.uid === authorId && (
          <Popconfirm onConfirm={() => deleteSiteNote(i)} title="Are you sure you wish to delete this note?">
            <Icon type="delete" />
          </Popconfirm>
        )}
      </div>
      <div className="flex-column" style={{padding: `0 0 0 0.5rem`}}>
        <div className="padding-right-1 italic">{author || `unnamed`}:</div>
        {auth.currentUser.uid === authorId ? (
          <textarea
            style={{ 
              border: `none`,  
              width: '100%',
              resize: 'vertical'
            }}
            value={note}
            rows="3"
            onChange={(e) => {
              const nn = [...notes]
              nn[i] = {author, authorId, created, note: e.target.value, contactAttempted}
              setNotes(nn)
            }}
            onBlur={(e) => changeSiteNote(e, i)}
          />
        ) : (
          <p>{note}</p>
        )}
        <div className="padding-top-1 flex-row">
          <div className="padding-right-1">Attempted Contact</div>
          <input
            disabled={auth.currentUser.uid !== authorId}
            type="checkbox"
            onChange={(e) => changeSiteNote(e, i)}
            checked={contactAttempted}
          />
        </div>
      </div>
    </div>
  )
}
