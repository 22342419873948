import React, {useContext} from 'react'
import {ReducerContext} from '../reducer'
import {TimeChooser, disabledDate, dateCellRender} from '.'
import {useMakeCalendar, useGetAvailability, useAvailabilityFilter} from './effects'
import {pushData} from '../utilities'
import {Calendar} from 'antd'
import moment from 'moment'

export default function DateTimePicker() {
  const {state, dispatch} = useContext(ReducerContext)
  const push = pushData({state, dispatch})

  const {
    inspectorAvailability: inspector,
    date,
    calendar,
    project: {restrict_schedule},
  } = state

  useAvailabilityFilter()
  useGetAvailability()
  useMakeCalendar()

  function getDisabledDates() {
    if (restrict_schedule) return disabledDate(inspector, date || moment())

    return (currentDate) => {
      // do not disable past dates
      if (currentDate.startOf('day') < moment().startOf('day')) return false
    }
  }

  const restricted = restrict_schedule && state.inspector.id && !inspector

  return (
    <div>
      {state.inspector.id && (
        <div className="calendar-wrapper">
          <Calendar
            defaultValue={date}
            disabledDate={getDisabledDates()}
            onChange={(e) => push({time: null, date: e})}
            dateCellRender={dateCellRender(calendar)}
            fullscreen={false}
          />
        </div>
      )}
      {restricted && <div className="padding-1">Selected Field User has no availability this month</div>}
      {date && <TimeChooser {...{inspector}} />}
    </div>
  )
}
