import {useEffect, useContext, useState} from 'react'
import {auth} from '../../../Auth'
import moment from 'moment'
import DB from '../../../DAL/DB'
import {useDBSubscribe} from '../../../effects'
import {ReducerContext} from '../../reducer'

const calendar = new DB.Calendars()

export default function useGetAvailability() {
  const {state, dispatch} = useContext(ReducerContext)
  const {inspector: ii} = state

  // use now as a default value
  const [now, setNow] = useState(moment(new Date()))
  const date = moment(now)

  const filters = []
  if (auth.sunkaizenUser.type === 'field-user') filters.push([`data.id`, `==`, auth.sunkaizenUser.id])

  date.month(date.month() - 6)
  filters.push(['date', '>', date.toDate()])

  date.month(date.month() + 12)
  filters.push(['date', '<', date.toDate()])

  const availability = useDBSubscribe(calendar, {filters})

  const availabilityMap = {}
  for (let avail of availability) {
    availabilityMap[avail.id] = avail
  }

  const changed = [...new Set(Object.keys(availabilityMap)), ii.id].join(',')

  useEffect(
    function () {
      dispatch({type: 'availability', value: availabilityMap})
    },
    [changed]
  )
}
