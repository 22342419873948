import {emailRx} from '../../utilities'
import DB from '../../DAL/DB'

const users = new DB.Users()

export const toggleEdit = (user, edit, logger, setEdit) => async () => {
  if (edit) {
    // Simple email validation.
    if (user.email && emailRx.test(user.email)) {
      await users.update(user.id, null, user)
    } else {
      logger.error(`"${this.state.user.email}" is not a valid email address`)
      return
    }
  }

  setEdit(!edit)
}

export default toggleEdit
