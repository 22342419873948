const selectedMap = {
  '/': `home`,
  '/projects': `projects`,
  '/users': `users`,
  '/reports': `reports`,
  '/calendar': `calendar`,
  '/analytics': `data`,
  '/logs': `logs`,
  '/forms': `forms`,
  '/schedule': `schedule`,
  '/scoringSystems': `scoring`,
}

export default selectedMap
