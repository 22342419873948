import React, {useEffect, useState} from 'react'
import {Spin} from 'antd'
import {auth} from '../../Auth'
import ProjectStatusBar from '../ProjectStatusBar'
// import VariableFields from "../VariableFields"
// import Notifications from "../Notifications"
// import UserSpoofer from './UserSpoofer'
import get from './get'
// import DevChecker from "./DevChecker"

import ProjectFlowGraphs from './ProjectFlowGraphs'

// const devChecker = new DevChecker()

export default function Dashboard() {
  const [barFilters, setBarFilters] = useState({})
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState(null)
  const [query, setQuery] = useState({})

  async function wrappedGet() {
    setLoading(true)
    const [projects, q] = await get({...query, barFilters})
    setProjects(projects)
    setQuery(q)
    setLoading(false)
  }

  function setFilter(k, v) {
    const ff = {...barFilters}
    ff[k] = v
    setBarFilters(ff)
  }

  useEffect(
    function () {
      wrappedGet()
    },
    [barFilters]
  )

  return (
    <div className="flex-column align-start full-width" style={{padding: '4rem', background: 'rgb(244, 246, 248)'}}>
      <h1 className="page-header">Projects Overview</h1>
      {loading && <Spin size="large" />}
      {projects && (
        <ProjectStatusBar
          auth={auth}
          filters={barFilters}
          projects={Object.values(projects)}
          clearFilters={() => setBarFilters({})}
          setFilters={setFilter}
        />
      )}
      <ProjectFlowGraphs projects={projects} />
    </div>
  )
}
