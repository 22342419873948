import React from 'react';
import { Input, Icon, Tooltip } from 'antd';
import PhotoRequired from './PhotoRequired';
import { connect } from 'react-redux';
import { setField, updateProgress } from '../../action_creators';
import { fieldHasErrors } from '../../utilities';

const stop = (state, ownProps) => ({ field: state.fields[ownProps.id] });
const dtop = (dispatch) => ({
  setField: ({ id, value }) => {
    dispatch(setField(id, value));
    dispatch(updateProgress());
  }
});

export function SingleInput({ setField, field, id, question, label, required, tipText, value = '', photoRequired }) {
  const set = (e) => setField({ id, value: { ...field, value: e.target.value } });

  return (
    <div className="question-container">
      <div className="bold padding-bottom-1 linebreak question-title">
        {question}
        {required && (
          <Tooltip title="Required">
            <Icon type="exclamation-circle" style={{ fontSize: '1.5rem', padding: '0 0 0 0.5rem', color:'red' }} /> <span style={{ fontSize: '1.25rem', color:'red' }}> Required</span>
            </Tooltip>
        )}
      </div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{ fontWeight: 600 }}>
          {(label || '').toUpperCase()}
        </div>
        <div className="full-width input-container">
          <Input.TextArea
            size="large"
            onChange={set}
            value={value}
            placeholder={tipText}
            rows={1}
            style={{ border: fieldHasErrors(field) ? '1px solid red' : '1px solid #d9d9d9' }}
          />
        </div>
        <div className="linebreak">
          <small>{tipText && `${tipText}`}</small>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  );
}

const WrappedSingleInput = connect(stop, dtop)(SingleInput);
export default WrappedSingleInput;
