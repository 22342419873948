import React from 'react'
import Base from './Base'
import {connect} from 'react-redux'

const reversedComponentDisplayNames = {
  'single-input1':'User-Input',
  'single-input': 'Single Input',
  'numeric-input': 'Numeric Input',
  'text-area-field': 'Text Area',
  'radio-button': 'Radio Button',
  dropdown: 'Dropdown',
  checkboxes: 'Checkboxes',
  multiselect: 'Multiselect',
  'date-input': 'Date',
  'time-input': 'Time',
  templated: 'Templated',
}

const stop = (state, ownProps) => ({field: state.fields[ownProps.id]})
function NakedFieldType({id, field = {}}) {
  return <Base id={id} field={field} />
}
const FieldType = connect(stop)(NakedFieldType)

export {reversedComponentDisplayNames, FieldType}
