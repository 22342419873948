import React, {useState, useEffect} from 'react'
import {Menu, Icon} from 'antd'
import {auth} from '../Auth'
import {db} from '../utilities'
import selectedMap from './selectedMap'
import userMap from './userMap'
import {Link} from 'react-router-dom'
import UserInfo from './UserInfo'
import menuItems from './menuItems'

function clipPath(path) {
  const tokens = path.split('/')
  return `/${tokens[tokens.length - 1]}`
}

export default function Sidebar({location}) {
  const [user, setUser] = useState({})
  const [ticketSearch, setTicketSearch] = useState({})

  useEffect(() => {
    //Subsribe to the logged in user's doc
    const unsubscribe = auth.sunkaizenUserSubscribe((skUser) => {
      if (skUser) setUser(skUser)
      else if (user) setUser({})
    })

    //Unsubscribe from user and ticket search on unmount
    return unsubscribe
  }, [user.id])

  useEffect(
    function () {
      if (!(user || {}).id) return
      //Subscribe to all incomplete or rejected tickets assigned to
      // the logged in user
      return db
        .collection(`tickets`)
        .where(`status`, `in`, [`Incomplete`, `Rejected`])
        .where(`assignee`, `==`, user.id)
        .onSnapshot((dbReturn) => {
          setTicketSearch(dbReturn)
        })
    },
    [user]
  )

  return (
    <div className="full-height flex-column" style={{background: `#FBFBFB`}}>
      <h3
        className="flex-row align-center justify-center margin-0 padding-1"
        style={{flex: `15 1 10%`, background: `#FBFBFB`, maxHeight: `7.5rem`}}
      >
        <img style={{height: '100%'}} src="/logo.png" />
      </h3>
      <Menu
        selectedKeys={[selectedMap[clipPath(location.pathname)]]}
        mode="inline"
        className="full-width padding-left-1"
        style={{textAlign: `left`, flexGrow: 1}}
      >
        {user.type && userMap[user.type].map((item) => menuItems(ticketSearch)[item])}
      </Menu>
      <span className="padding-1" style={{width: '100%'}}>
        {user ? (
          <UserInfo />
        ) : (
          <Link to="/login">
            <Icon type="login" /> Sign In
          </Link>
        )}
      </span>
    </div>
  )
}
