import React, {useEffect, useState} from 'react'
import DB from './DAL/DB'
import {Button, Input} from 'antd'
import ReactJson from 'react-json-view'

const projects = new DB.Projects()

export default function ProjectViewer() {
  const [v, setV] = useState(null)
  const [project, setProject] = useState({})

  async function fetchProject() {
    console.log('fetching: ', v)

    const {
      data: [p],
      ok,
    } = await projects.get(v)
    if (ok) {
      console.log('success')
      setProject(p)
      return
    }

    setProject({error: 'could not get project'})
  }

  return (
    <div style={{textAlign: 'left'}}>
      <div className="flex-row">
        <Input value={v} onChange={(e) => setV(e.target.value)} />
        <Button onClick={fetchProject}>Submit</Button>
      </div>
      <div className="padding-2">
        <ReactJson name={project.site ? project.site.siteOwnerName : 'none'} src={project} />
      </div>
    </div>
  )
}
