import React from 'react'
import Condition from './Condition'

const Conditions = ({
  conditionList,
  fields,
  id,
  order,
  sections,
  field,
  conditionQuestions,
  handleConditionSelection,
  handleConditionSelectionV1,
  handleDeleteCondition
}) => {
 return conditionList
.map((rec, index) => (
  <Condition
    key={`action-${rec.questionId}`}
    currentIndex={rec.questionId}
    condition={rec}
    conditionIndex={index}
    fields={fields}
    id={id}
    order={order}
    sections={sections}
    field={field}
    conditionQuestions={conditionQuestions}
    handleConditionSelection={handleConditionSelection}
    handleConditionSelectionV1={handleConditionSelectionV1}
    handleDeleteCondition={handleDeleteCondition}
  />
))
}

export default Conditions
