import React from 'react'
import {Droppable} from 'react-beautiful-dnd'
import {Card} from 'antd'
import Options from './Options'
import Add from './AddOption'

export default function OptionsWrapper() {
  return (
    <Card>
      <Droppable droppableId="optionsDroppable">
        {(provided, snapshot) => <Options {...{provided, snapshot}} />}
      </Droppable>
      <Add />
    </Card>
  )
}
