import React from 'react'
import {Radio} from 'antd'

const Templated = () => (
  <Radio.Group className="flex-column" style={{pointerEvents: `none`}}>
    {[`Pass`, `Fail`, `Missed`, `N/A`].map((event, i) => (
      <Radio value={event} key={i}>
        {event}
      </Radio>
    ))}
  </Radio.Group>
)

export default Templated
