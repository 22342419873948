import DB from '../../DAL/DB'
import {store} from '../reducer'

export default function getFormFields(form) {
  if (!form || !form.id) return

  const sections = new DB.FormSection(form.id)
  const actions = new DB.FormAction(form.id);

  const unsubscribes = []
  const actionData = actions.subscribe(null, null, ({data, ok}) => {
    if (ok) {
      store.dispatch({type: 'actionData', value: data})
    }
  })

  for (let sId of form.order) {
    const sUnsubscribe = sections.subscribe(sId, null, ({data, ok}) => {
      const [{id, title, order}] = data || [{}]
      if (ok) {
        store.dispatch({type: 'section', id: sId, value: {id, title, order}})
      }
    })

    const fields = new DB.FormField(form.id, sId)
    const fUnsubscribe = fields.subscribe(null, null, ({data, ok}) => {
      if (ok) {
        const ff = {}
        for (let field of data) {
          ff[field.id] = field
        }
        store.dispatch({type: 'sectionField', field: 'fields', id: sId, value: ff})
      }
    })

    unsubscribes.push([sUnsubscribe, fUnsubscribe, actionData])
  }

  return () => {
    unsubscribes.map(([s, f, a]) => {
      s()
      f()
      a()
    })
  }
}
