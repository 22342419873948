import {colors} from '../../../config'

const statuses = {'To Be Scheduled': 1, Scheduled: 2, 'In Progress': 3, Review: 4, 'Needs Corrections': 5}

// Implement a layered histogram to show how many projects have been updated / not updated within each status over the past week.
export default function makeSpec(projects = []) {
  projects = projects
    .map((p) => {
      p.order = statuses[p.status]
      return p
    })
    .filter((p) => p.status !== 'Completed')

  return {
    $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
    description: 'Projects updated in the past week',
    data: {values: projects},
    width: 50,
    height: 300,
    params: [
      {
        name: 'highlight',
        select: {type: 'point', on: 'mouseover'},
      },
      {name: 'select', select: 'point'},
    ],
    mark: {type: 'bar', tooltip: true},
    encoding: {
      column: {
        field: 'status',
        header: {title: null, orient: 'bottom'},
      },
      x: {
        field: 'recent',
        axis: null,
      },
      y: {
        aggregate: 'count',
        field: 'recent',
        title: 'Total Projects',
        stack: null,
        axis: {grid: false},
      },
      fillOpacity: {
        condition: {param: 'select', value: 1},
        value: 0.5,
      },
      strokeWidth: {
        condition: [
          {
            param: 'select',
            empty: false,
            value: 2,
          },
          {
            param: 'highlight',
            empty: false,
            value: 1,
          },
        ],
        value: 0,
      },
      color: {
        field: 'recent',
        scale: {range: [colors.primary, colors.tertiary]},
        legend: {
          title: 'Last Updated',
          labelExpr: "{Yes: '≤ a week ago', No: '> a week ago', 'N/A': 'N/A'}[datum.label]",
        },
      },
    },
    config: {
      view: {stroke: 'transparent'},
      axis: {domainWidth: 0},
    },
  }
}
