import {store} from '../utilities'
import {modify} from '../action_creators'

export default function fieldDrop(result) {
  const state = store.getState()
  const section = state.sections[state.active.section]
  const {order} = section

  const [element] = order.splice(result.source.index, 1)
  order.splice(result.destination.index, 0, element)
  store.dispatch(modify({id: section.id, value: {...section, order}, category: 'sections'}))
}
