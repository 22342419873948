import BaseModel from './BaseModel';
import { db, firebase } from '../utilities';

export default class Meta extends BaseModel {
  constructor() {
    super(db.collection('meta'));
  }

  static async getRubricsByFormId(formId: string) {
    const rubricsSnapshot = await db.collection('rubrics').where('formId', '==', formId).get();
    const rubrics: any[] = [];
    rubricsSnapshot.forEach((doc) => {
      rubrics.push({ id: doc.id, ...doc.data() });
    });
    return rubrics;
  }

  static async getOrganizationsByUserId(userId: string) {
    const userDoc = await db.collection('users').doc(userId).get();
    const organizationId = userDoc.data().organization.id;

    // Fetch the org_associations document
    const orgAssociationsDoc = await db.collection('org_associations').doc(organizationId).get();
    const clientWhereOwner = orgAssociationsDoc.data().clientWhereOwner || [];

    // Batch the clientWhereOwner array into chunks of 10
    const fetchOrganizationsBatch = async (batch) => {
      const organizationsSnapshot = await db.collection('organizations')
        .where(firebase.firestore.FieldPath.documentId(), 'in', batch)
        .get();
      const organizations: any[] = [];
      organizationsSnapshot.forEach((doc) => {
        organizations.push({ id: doc.id, ...doc.data() });
      });
      return organizations;
    };

    const organizations = [];
    for (let i = 0; i < clientWhereOwner.length; i += 10) {
      const batch = clientWhereOwner.slice(i, i + 10);
      const batchOrganizations = await fetchOrganizationsBatch(batch);
      organizations.push(...batchOrganizations);
    }
    
    return organizations;
  }
}
