import {firebase} from '../../../utilities'
import {auth} from '../../../Auth'
import DB from '../../../DAL/DB'
import {cancelIntake} from './cancelIntake'
import {cancelProject, requestCancelProject} from './cancelProject'

const projects = new DB.Projects()
const calendars = new DB.Calendars()

export async function saveCancellation(project, selected, required, type, logger) {
  const cancellation = {canceledAt: firebase.firestore.Timestamp.now(), ...required, reason: selected, canceledById: auth?.sunkaizenUser?.id, canceledByName: auth?.sunkaizenUser?.name}
  //console.log('cancellation', cancellation)
  const projectCancellation = type !== 'intake'
  if (projectCancellation) {
    if (
      auth.sunkaizenUser.role === 'admin' ||
      auth.sunkaizenUser.role === 'client' ||
      auth.sunkaizenUser.role === 'super-admin'
    ) {
      cancelProject(project, cancellation)
    } else {
      requestCancelProject(project, cancellation)
    }
  } else cancelIntake(project, cancellation, selected, projectCancellation)

  try {
    // DOE1-516
    await projects.updatebatch(project.id, {noMerge: true}, {...project, updatedBy: auth?.sunkaizenUser?.id, updatedByName: auth?.sunkaizenUser?.name})
    const { ok, data: [calendarEntry] } = await calendars.get(project.id, null)
    if (ok) { 
      //await calendars.update(project.id, null, null) // I can make it completely null but I am not sure if this is  not a great idea 
      await calendars.update(project.id, null, {
        data: project
      })
    }
  } catch (e) {
    const logMessage = projectCancellation ? 'a project' : 'an intake'
    if (projectCancellation) {
      logger.event(`${auth.currentUser.displayName} canceled ${logMessage}`, null, 3)
      console.log(`failed to cancel ${logMessage}`, e)
    } else {
      logger.event(`${auth.currentUser.displayName} canceled ${logMessage}`, null, 3)
      console.log(`failed to cancel ${logMessage}`, e)
    }
  }
}
