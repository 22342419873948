import React, {useState} from 'react'
import {Button, Modal } from 'antd'
import BulkProvider from './BulkProvider'

export default function AddBulkButton({success, className, style}) {
  const [done, setDone] = useState(false)
  const [show, setShow] = useState(null)
  const [disabledOk, setDisabledOk] = useState(true)
  const [loaderOkButton, setLoaderOkButton] = useState(false)

  const handleupdateSiteLength = (length) =>{
    length > 0 ? setDisabledOk(false) : setDisabledOk(true)
  }

  return (
    <div>
      <Button className={className} style={style} onClick={() => setShow(true)}>
        Add Bulk Projects
      </Button>
      <Modal
        width={'80%'}
        footer={[
          <Button key="back" onClick={() => {
            setDone('clear')
            setShow(false)
          }}>
            Cancel
          </Button>,
          <Button key="submit" 
            type="primary" 
            disabled={disabledOk || loaderOkButton } 
            onClick={async() =>  {
            setDone('done')
            setLoaderOkButton(true)
            //calling everything async
            await success.map(async(f) => {
              console.log('Calling success function')
              return await f()
            })
            setTimeout(() => { setShow(false); setLoaderOkButton(false) }, 3000)
          }}>
            Ok
          </Button>,
        ]}
        // onCancel={() => {
        //   setDone('clear')
        //   setShow(false)
        // }}
        // onOk={() => {
        //   setDone('done')
        //   alert('hi hello')
        //   success.map((f) => f())
        //   setShow(false)
        // }}
        visible={show}
      >
        <BulkProvider done={done} loaderOkButton={loaderOkButton} updateSiteLength={handleupdateSiteLength} />
      </Modal>
    </div>
  )
}
