import {modify, activate} from '../action_creators'
import {store} from '../utilities'
import getFormFieldId from './getFormFieldId'

export function createNewField(type = `single-input`) {
  const f = {type, id: getFormFieldId()}
  store.dispatch(modify('field')(f.id, f))
  store.dispatch(activate('field', f.id))
  return f
}

export default function dropCreateField(result) {
  const state = store.getState()
  const section = state.sections[state.active.section]

  const field = createNewField(result.draggableId)
  if (result.destination) section.order.splice(result.destination.index, 0, field.id)

  store.dispatch(modify('section')(section.id, section))
  store.dispatch(activate('field', field.id))
}
