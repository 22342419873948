import rootReducer from './reducer'
import {createStore, applyMiddleware} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {useRef, useEffect} from 'react'

//Returns a reference and a method that focuses on the element with that reference
const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus()
  }

  return [htmlElRef, setFocus]
}

//The main react store for all of forms
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware)) // , loggerMiddleware

/**
 * Hook that alerts clicks outside of the passed ref
 * Callback function is called when user clicks outside of the wrapped div
 */
// function useClickOutside(ref, callback) {
//     useEffect(() => {
//         // Alert if clicked on outside of element
//         function handleClickOutside(e) {
//             if (ref.current) {
//                 let c = false
//                 let n = e.target

//                 // clicked the ref?
//                 if (n.id === ref.current.id) c = true
//                 // crawl up the DOM tree from the clicked element to determine whether the ref is a parent
//                 while (n = n.parentNode) {
//                     if (n.id === ref.current.id) {
//                         c = true
//                         break
//                     }
//                 }

//                 if (!c) callback(e)
//             }
//         }

//         // Bind the event listener
//         document.addEventListener("mousedown", handleClickOutside)
//         return () => {
//             // Unbind the event listener on clean up
//             document.removeEventListener("mousedown", handleClickOutside)
//         }
//     }, [ref])
// }

export {store, useFocus} // , useClickOutside
