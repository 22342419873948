import {baseStore} from './utilities'
import DB from '../../DAL/DB'

const tickets = new DB.Tickets()

export default function ticketPhoto(parentID) {
  async function saveToDB(photo) {
    const {
      data: [t],
      ok,
    } = await tickets.get(parentID)

    if (!ok) throw new Error(`ticket provided to file uploader ${parentID} doesn't exist in DB`)
    if (!t.photos) t.photos = {}
    t.photos[photo.name] = photo

    await tickets.update(parentID, null, {photos: t.photos})
  }
  const store = baseStore(`tickets/${parentID}/photos/`)

  return {store, saveToDB}
}
