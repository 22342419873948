import React, { useState } from 'react'
import { Button, Select } from 'antd'
import Conditions from './Conditions'
import DeleteButton from '../../../DeleteButton'

const ConditionGroup = ({
  currentIndex,
  condition,
  fields,
  id,
  order,
  sections,
  field,
  conditionQuestions,
  handleDeleteConditionGroup,
  updateConditionList,
  handleMustBeTrueCondition,
  cgList,
  isRubric
}) => {

  const [conditionList, setConditionList] = useState(condition.conditionList)
  let isSame = false
  const isMatchingCircularRef = (fieldId, e) => {
   
    if(fields[e]  && fields[e].conditionGroup && fields[e].conditionGroup.cgList) {
      const cgList1 = fields[e].conditionGroup.cgList;
      cgList1.forEach(cgListRec => {
        const conditionList1 = cgListRec.conditionList
        if(conditionList1.find(x => x.selectedQuestion === fieldId)) {
          isSame = true;
        }
      })
    }   
      Object.keys(fields).forEach(rec => {
        if (!isSame && fields[rec] && fields[rec].conditionGroup && 
                      fields[rec].conditionGroup.cgList ){
          const cgList1 = fields[rec].conditionGroup.cgList;
          cgList1.forEach(cl => {
            const conditionList1 = cl.conditionList
            if(conditionList1.find(x => x.selectedQuestion === fieldId)) {
              const childId = fields[rec].id;
              if(childId && e && childId === e) {
                isSame = true;
              } else {
                isMatchingCircularRef(fields[rec].id, e)
              }
            }
          })         
        }
      })
    return isSame
  }
  //onChange={(value, options)=>handleConditionSelection("selectedOption", options, conditionIndex,value)}

  const handleConditionSelection = (fieldName, index, e) => {
    const conditionListUpdated = [...conditionList]
    const conditionQuestionsObj = conditionListUpdated[index];
    if(fieldName === "selectedQuestion") {
      if(isMatchingCircularRef(id, e)){
        alert('Please select another question as the selected question will create a circular reference.')
        conditionQuestionsObj.selectedQuestion = ""
      } else {
        conditionQuestionsObj.selectedQuestion = e
      }
      conditionQuestionsObj.selectedOption = ""
      conditionQuestionsObj.selectedOperand = ""
    } else {
      if(fieldName === "selectedOption") {
        let isMatchFound = false;
        cgList.forEach(rec => {
          const conditionList1 = rec.conditionList; 
          const recFound = conditionList1.find(x => x.selectedQuestion === conditionQuestionsObj.selectedQuestion 
                          && x.selectedOption === e)
          if(recFound) {
            isMatchFound = true;
          }
        })
        if(isMatchFound) {
          conditionQuestionsObj[fieldName] = "";
          alert('Please select different option, same question and option already selected.')
        } else {
          conditionQuestionsObj[fieldName] = e
        }
      } else {
        conditionQuestionsObj[fieldName] = e
      }     
    }
    updateConditionList(conditionListUpdated, isRubric ? currentIndex : index)
  }
  const handleConditionSelectionV1 = (fieldName, optionElement,index, e) => {
    const conditionListUpdated = [...conditionList]
    const conditionQuestionsObj = conditionListUpdated[index];
    if(fieldName === "selectedQuestion") {
      if(isMatchingCircularRef(id, e)){
        alert('Please select another question as the selected question will create a circular reference.')
        conditionQuestionsObj.selectedQuestion = ""
      } else {
        conditionQuestionsObj.selectedQuestion = e
      }
      conditionQuestionsObj.selectedOption = ""
      conditionQuestionsObj.selectedOperand = ""
      
    } else {
      if(fieldName === "selectedOption") {
        let isMatchFound = false;
        cgList.forEach(rec => {
          const conditionList1 = rec.conditionList; 
          const recFound = conditionList1.find(x => x.selectedQuestion === conditionQuestionsObj.selectedQuestion 
                          && x.selectedOption === e)
          if(recFound) {
            isMatchFound = true;
          }
        })
        if(isMatchFound) {
          conditionQuestionsObj[fieldName] = "";
          alert('Please select different option, same question and option already selected.')
        } else {
          conditionQuestionsObj[fieldName] = e
          conditionQuestionsObj.selectedOptionIndex = optionElement.props.index ?? 0;
        }
      } else {
        conditionQuestionsObj[fieldName] = e
        conditionQuestionsObj.selectedOptionIndex = optionElement.props.index ?? 0;

      }     
    }
    updateConditionList(conditionListUpdated, isRubric ? currentIndex : index)
  }
  const handleAddCondition = () => {
    const conditionListUpdated = [...conditionList]
    const conditionQuestionsObj = {
      selectedQuestion: '',
      selectedOperand: '',
      selectedOption: '',
    }
    conditionListUpdated.push(conditionQuestionsObj)
    setConditionList(conditionListUpdated)
    condition.conditionList = conditionListUpdated
    updateConditionList(conditionListUpdated, currentIndex)
  }

  const handleDeleteCondition = (deleteIndex) => {
    let conditionListUpdated = [...conditionList]
    conditionListUpdated = conditionListUpdated.filter((x, index) => index !== deleteIndex)
    setConditionList(conditionListUpdated)
    condition.conditionList = conditionListUpdated
    updateConditionList(conditionListUpdated, currentIndex)

  }

  const renderConditionRender = () => {
    return (
      <Conditions
        conditionList={conditionList}
        fields={fields}
        id={id}
        order={order}
        sections={sections}
        field={field}
        conditionQuestions={conditionQuestions}
        handleConditionSelection={handleConditionSelection}
        handleConditionSelectionV1={handleConditionSelectionV1}
        handleDeleteCondition={handleDeleteCondition}
      />
    )
  }

  function deleter() {
    handleDeleteConditionGroup(currentIndex)
  }

  const deleteProps = {
    confirmationDisplay: 'Are you sure you want to delete this condition Group?',
    icon: 'delete',
    size: 'large',
    title: 'Delete',
    type: 'secondary',
    onConfirm: deleter,
  }

  return (
    <React.Fragment>
      <div className='condition-group'>
        <h4 className='condition-group-title'>{`Condition Group ${currentIndex}`}</h4>

        <div className='condition-group-rel'>
          <span>Must Be True</span>
          <Select
            style={{ width: 70 }}
            key={`mustbeTrue-${condition && condition.mustBeTrueValue || 'all'}`}
            defaultValue={condition && condition.mustBeTrueValue || 'all'}
            className='condition-group-select'
            onChange={(e)=>handleMustBeTrueCondition(currentIndex, e)}
          >
            <Select.Option value={`all`}>{`ALL`}</Select.Option>
            <Select.Option value={`any`}>{`ANY`}</Select.Option>
          </Select>
          <div className='condition-group-delete'> <DeleteButton {...deleteProps} data-cy="deleteBtn" /></div>
        </div>
        {conditionList && conditionList.length > 0 &&
          <React.Fragment key={`action-${conditionList.length}`}>
            {renderConditionRender()}
          </React.Fragment>
        }
        <div className='condition-group-new-group'>
          <Button type="primary" onClick={handleAddCondition}>+ Condition </Button>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ConditionGroup