import {useEffect, useContext} from 'react'
import {ReducerContext} from '../reducer'
import DB from '../../DAL/DB'
import {useDBSubscribe} from '../../effects'

// const forms = new DB.Forms()
const users = new DB.Users()

// get and store all forms in the system
export default function useAllUsers() {
  const {dispatch} = useContext(ReducerContext)

  // const ff = useDBSubscribe(forms, {filters: [['organization.id', '==', auth.sunkaizenUser.organization.id]]})
  const ff = useDBSubscribe(users)

  useEffect(
    function () {
      const uniqueUsers = {}
      for (let f of ff) {
        uniqueUsers[f.id] = {id: f.id, name: f.name}
      }
      dispatch({type: 'allUsers', value: uniqueUsers})
    },
    [ff]
  )
}
