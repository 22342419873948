import {auth} from '../../../Auth'

export default function or() {
  return [
    {match: {'organization.id': auth.sunkaizenUser.organization.id}},
    {match: {'client.id': auth.sunkaizenUser.organization.id}},
    {match: {'inspection.inspector.id': auth.sunkaizenUser.organization.id}},
    {match: {'installer.id': auth.sunkaizenUser.organization.id}},
  ]
}
