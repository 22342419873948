import React from 'react';
import { Checkbox, InputNumber, Tooltip } from 'antd';

export default function Required({ id, field, modify }) {
  console.log('field ::: ' + JSON.stringify(field));

  const handleAllowDecimalsChange = (e) => {
    const allowDecimals = e.target.checked;
    modify({
      key: id,
      value: {
        ...field,
        allowDecimals,
        digitsAllowed: allowDecimals ? field.digitsAllowed : 0,
      },
    });
  };

  return (
    <div className="flex-row padding-top-2 padding-bottom-2">
      <Checkbox
        onChange={(e) => modify({ key: id, value: { ...field, required: e.target.checked } })}
        value={field.required}
        checked={field.required}
        style={{ marginRight: '1rem' }}
        data-cy="required"
      >
        Required
      </Checkbox>
      <Checkbox
        onChange={(e) => modify({ key: id, value: { ...field, photoRequired: e.target.checked } })}
        value={field.photoRequired}
        checked={field.photoRequired}
        style={{ marginRight: '1rem' }}
        data-cy="requirePhoto"
      >
        Require Uploading Photos
      </Checkbox>

      {field.type === 'numeric-input' && (
        <>
          <Checkbox
            onChange={(e) => modify({ key: id, value: { ...field, allowNegatives: e.target.checked } })}
            checked={field.allowNegatives}
            style={{ marginRight: '1rem' }}
          >
            Allow Negatives
          </Checkbox>

          <Checkbox
            onChange={handleAllowDecimalsChange}
            checked={field.allowDecimals}
            style={{ marginRight: '1rem' }}
          >
            Allow Decimals
          </Checkbox>

          <div style={{ marginRight: '1rem' }}>
            <Tooltip title="Number of Digits Allowed">
              <InputNumber
                min={0} // Minimum 1 digit/place
                value={field.digitsAllowed}
                disabled={!field.allowDecimals} // Disable if decimals not allowed
                onChange={(value) => modify({ key: id, value: { ...field, digitsAllowed: value } })}
                placeholder="Digits"
              />
            </Tooltip>
          </div>

          <div style={{ marginRight: '1rem' }}>
            <Tooltip title="Lower Limit">
              <InputNumber
                value={field.lowerLimit}
                onChange={(value) => modify({ key: id, value: { ...field, lowerLimit: value } })}
                placeholder="Lower Limit"
              />
            </Tooltip>
          </div>

          <div>
            <Tooltip title="Upper Limit">
              <InputNumber
                value={field.upperLimit}
                onChange={(value) => modify({ key: id, value: { ...field, upperLimit: value } })}
                placeholder="Upper Limit"
              />
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
}
