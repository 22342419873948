import React, {useContext, useEffect} from 'react'
import {ReducerContext} from './reducer'
import {Table} from 'antd'
import NoData from './NoData'

export default function SOTable() {
  const {state, dispatch} = useContext(ReducerContext)

  //Define function that renders cells based on pvalue
  const renderCell = (pvalue) => {
    // console.log("pvalue:", pvalue)
    let cellStyle = {borderRadius: '15px', textAlign: 'center'}
    pvalue = pvalue.toFixed(6)

    if (!pvalue) {
      return <div style={{backgroundColor: 'darkGray', ...cellStyle}}>No Data</div>
    }

    if (pvalue > 0 && pvalue < 0.15) {
      return <div style={{backgroundColor: 'lightGreen', ...cellStyle}}>{pvalue}</div>
    } else if (pvalue < 0 && pvalue > -0.15) {
      // console.log("rendering light red cell:", pvalue)
      return <div style={{backgroundColor: 'lightCoral', ...cellStyle}}>{pvalue}</div>
    } else {
      return <div style={{backgroundColor: 'lightGray', ...cellStyle}}>{pvalue}</div>
    }
  }

  let rows
  let people
  let columns

  //Columns should be each reviewer, or installer, or client or whatever... by name
  if (state.SO_data) {
    //The users of whatever type we have selected to aggregate by across all answered questions.
    people = []
    rows = []
    let agg_type = state.dropdown.aggregation

    state.SO_data.questions.forEach((question) => {
      // console.log("question:", question)
      // Create one JSON object per question
      let row = question

      question.results.forEach((result) => {
        //Assign negative if score < global avg for the question
        question[result[agg_type]] = result.pvalue * Math.sign(question.global_avg - result.avg)
      })

      rows.push(row)

      //QuestionPeople is the list of all people that answered this question
      let questionPeople = question.results.map((result) => {
        return {
          title: result[agg_type],
          key: result[agg_type],
          dataIndex: result[agg_type],
          render: renderCell,
        }
      })

      // console.log("questionPeople:", questionPeople)

      //Reduce to deduplicated array

      //OldPeople is every user by name that we've already encountered in a previous
      // question's results
      let oldPeople = people.map((person) => person.key)

      //NewPeople is every new user we didn't see in a previous question
      let newPeople = []

      questionPeople.forEach((person) => {
        if (!oldPeople.includes(person.key)) {
          newPeople.push(person)
        }
      })

      // Add new to old
      people = people.concat(newPeople)
    })

    //If there was an error it's possible that database docs get written with "undefined" in each field so filter those out.
    people = people.filter((person) => person.key)

    //Append name to resource for the person
    if (people && people.length > 0) {
      // people = people.map(person => { return {...person, title: state.allUsers[person.key].name}})
      console.log('rows:', rows)
      console.log('people:', people)
      console.log('columns:', columns)
      columns = [{title: 'Question', key: 'question', dataIndex: 'question'}, ...people]
    } else {
      people = null
    }
  }

  let returnDiv = <NoData />

  if (people) {
    returnDiv = (
      <div>
        <Table dataSource={rows} columns={columns}></Table>
      </div>
    )
  }

  return returnDiv
}
