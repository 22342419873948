import React, {useRef} from 'react'
import LabelQuestionTip from './LabelQuestionTip'
import Required from './Required'
import {connect} from 'react-redux'
import {modify} from '../action_creators'

const stop = (state, ownProps) => ({field: state.fields[ownProps.id] || {}})
const dtop = (dispatch) => ({modify: ({key, value}) => dispatch(modify('field')(key, value))})

export function SingleInputPropertyBoxBase({id, field, modify}) {
  return (
    <div style={{overflow: 'scroll'}}>
      <LabelQuestionTip {...{modify, field}} />
      {field && field.type !== 'info-box' &&
      <Required {...{id, modify, field}} />
      }
    </div>
  )
}

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus()
  }

  return [htmlElRef, setFocus]
}

const WrappedSingleInputPropertyBoxBase = connect(stop, dtop)(SingleInputPropertyBoxBase)
export default WrappedSingleInputPropertyBoxBase
