import {useEffect, useState} from 'react'
import DB from '../DAL/DB'
import {FULL_TEXT_SEARCH_URL} from '../config'

function makeQuery(type, options) {
  if (!options) options = {}

  if (!options.size) options.size = 10000

  return {type, ...options}
}

const meta = new DB.Meta()

export default function useElasticSubscribe(collection, options = {}) {
  const [resources, setResources] = useState([])
  const [lastUpdate, setLastUpdate] = useState(null)

  const {transform} = options
  delete options.transform

  async function refresh() {
    const query = makeQuery(collection, options)
    const url = `${FULL_TEXT_SEARCH_URL}/full_search`

    let res = await (
      await fetch(url, {
        method: `POST`,
        mode: `cors`,
        headers: {'Content-Type': `application/json`},
        body: JSON.stringify(query),
      })
    ).json()

    if (transform) res = transform(res)
    setResources(res)
  }

  useEffect(function () {
    return meta.subscribe('elasticUpdates', null, async function ({ok, data}) {
      if (ok) {
        const update = data[collection]

        if (lastUpdate === update) return

        setLastUpdate(update)
        await refresh()
      }
    })
  }, [])

  return resources
}
