import React, {useState} from 'react'
import {Icon, Button, Popconfirm} from 'antd'
import {DeleteOutlined} from '@ant-design/icons'
import {auth} from '../Auth'
import FileUploader, {patterns} from '../NewFileUploader'
import DB from '../DAL/DB'

const tickets = new DB.Tickets()

const helperMessage = (msg) => () =>
  (
    <div className="image-upload-box">
      <div className="row">
        <Icon className="arrow-icon" type="arrow-up" />
        &nbsp;
        <div className="image-upload-message">{msg}</div>
      </div>
      <div>
        <div className="subtitle">drag and drop image anywhere within this box</div>
      </div>
    </div>
  )

function Photo({photo, assignee, locked, deletePhoto}) {
  return (
    <div className="flex-row align-center">
      <img className="padding-1 full-width" key={photo.name} src={photo.url}></img>
      {(assignee === auth.sunkaizenUser.id || ['client', 'admin', 'super-admin'].includes(auth.sunkaizenUser.type)) &&
        !locked && (
          <Popconfirm
            onCancel={() => console.log(`cancelled delete of photo ${photo.name}`)}
            onConfirm={deletePhoto}
            title="Are you sure?"
          >
            <DeleteOutlined style={{fontSize: '2rem'}} />
          </Popconfirm>
        )}
    </div>
  )
}

export default function CompletedWorkDetail({ticket}) {
  const locked = ![`Incomplete`, `Rejected`].includes(ticket.status)
  const [showUploader, setShowUploader] = useState(false)

  async function deletePhoto(id) {
    console.log(id)
    delete ticket.photos[id]
    await tickets.update(
      ticket.id,
      {
        noMerge: true,
      },
      ticket
    )
  }

  console.log(ticket.photos)

  return (
    <div className={`completed-work-details ${(ticket.status || '').toLowerCase()}`}>
      {ticket.photos ? (
        Object.entries(ticket.photos).map(([id, photo]) => (
          <Photo
            deletePhoto={async () => await deletePhoto(id)}
            photo={photo}
            assignee={ticket.assignee}
            locked={locked}
          />
        ))
      ) : (
        <div>None</div>
      )}
      {(ticket.assignee === auth.sunkaizenUser.id ||
        ['client', 'admin', 'super-admin'].includes(auth.sunkaizenUser.type)) &&
        showUploader && (
          <div className="margin-top-2">
            <FileUploader
              parentID={ticket.id}
              pattern={patterns.ticketPhoto}
              helperMessage={helperMessage('Upload a photo')}
            />
          </div>
        )}
      <div className="margin-top-2">
        <Button disabled={locked} onClick={() => setShowUploader(!showUploader)} data-cy="ticketPhotoUploadBtn">
          {showUploader ? `Cancel Upload` : `Upload More Photos`}
        </Button>
      </div>
    </div>
  )
}
