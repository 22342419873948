import React, {useContext, useEffect} from 'react'
// https://github.com/CassetteRocks/react-infinite-scroller
import InfiniteScroll from 'react-infinite-scroller'
import {Spin} from 'antd'
import {ReducerContext, wrap} from './reducer'
import {HeaderBar} from './HeaderBar'
import {MagnifiedImage} from './MagnifiedImage'
import {sortImages} from '../PhotoPool/sortImages'

import './PhotoViewer.css'

// calls 'done' with an array of IDs corresponding to selected photos
export function ImageViewer({photos, done, action = `select`}) {
  const {state, dispatch} = useContext(ReducerContext)

  // if (Object.values(state.originals).some((photo) => photo.delete)) {
  //   done(state.originals)
  // }

  useEffect(function () {
    if (typeof photos !== `object`) throw new Error(`Expecting a map of photos, got: ${photos}`)
    if (typeof done !== `function`) throw new Error(`Expecting a callback function for 'done', got: ${typeof done}`)
    if ([`select`, `delete`].includes(action)) dispatch({type: `action`, value: action})
    else {
      throw new Error(
        `Expected a specific string 'action' value action: ${action}. Valid actions are "delete" and "select"`
      )
    }

    dispatch({type: `originals`, value: photos})
    dispatch({type: `pictures`, value: Object.values(photos).sort(sortImages)})
  }, [])

  useEffect(() => {
    if (state.done) {
      done(state.originals)
    }
  }, [state.done])

  const selectOptions = {
    delete: (id) => {
      console.info('Delete Photo:', id)

      dispatch({
        type: `delete`,
        id,
        value: {
          ...state.originals[id],
          ...{delete: !state.originals[id].delete},
        },
      })
    },
    select: (id) => {
      dispatch({type: `select`, id, value: !state.selected[id]})
    },
  }

  function getPictures(current) {
    if (state.pictures.length && current > state.pictures.length) dispatch({type: `hasMore`, value: false})
    else dispatch({type: `active`, id: current - 1})
  }

  function scroll(e) {
    const current = state.viewing || 0
    const max = state.active[state.active.length - 1]

    if (e.keyCode === 38 && current > 0) {
      dispatch({type: `viewImage`, id: state.viewing - 1})
    } else if (e.keyCode === 40 && current < max) {
      dispatch({type: `viewImage`, id: state.viewing + 1})
    }

    state.refs[current].current.scrollIntoView()
  }

  function finish(reason) {
    dispatch({type: `done`})
  }

  return (
    <div className="photo-viewer">
      <HeaderBar {...{finish}} />
      <div className="main" onKeyDown={scroll} tabIndex="0">
        <div className="image-selector-sidebar" id="image-selector-sidebar">
          <InfiniteScroll
            className="infinite-scroll"
            pageStart={0}
            loadMore={getPictures}
            hasMore={state.hasMore}
            loader={<Spin size="large" />}
            useWindow={false}
            threshold={50}
          >
            {state.active.map((id) => (
              <MagnifiedImage key={id} {...{current: id}} />
            ))}
          </InfiniteScroll>
        </div>
        <div className="content">
          {state.viewing !== null && <div className="selection-info-bar">Click the image below to select it</div>}
          <div className="image-wrapper">
            {state.viewing !== null ? (
              <img
                className={`image` + (state.selected[state.viewing] ? ` selected` : ``)}
                onClick={() => {
                  selectOptions[state.action](state.viewing)
                }}
                src={(state.pictures[state.viewing] || {}).url}
              />
            ) : (
              <div className="select-an-image-text">Click an image to view</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const PhotoViewer = wrap(ImageViewer)
export default PhotoViewer
