// TEST THIS CODE

import {auth} from '../Auth'
import DB from '../DAL/DB'

const projects = new DB.Projects()

function makeTimeFilter(instances, type) {
 if( instances) {
    if (instances.after && type === "after") {
     const dt = new Date(instances[type]._d);
     dt.setHours(0, 0, 0, 0);
     return dt
    }
    if (instances.before && type === "before") {
      const dt = new Date(instances[type]._d);
      dt.setHours(0, 0, 0, 0);
      return dt
    }
  }
  return ''
}

export default async function getProjects({ form, client, created, updated }) {
  const filters = [
    ['intake.id', '==', form],
    ['status', '==', 'Completed'],
    ['organization.id', '==', auth.sunkaizenUser.organization.id],
  ]

  if (client) filters.push(['client.id', '==', client])

  if (created.before) filters.push(['created', '<', makeTimeFilter(created, "before")])
  if (created.after) filters.push(['created', '>', makeTimeFilter(created, "after")])
  if(! (created.before && created.after)){
    if (updated.before) filters.push(['updated', '<=', makeTimeFilter(updated, "before")])
    if (updated.after) filters.push(['updated', '>=', makeTimeFilter(updated, "after")])
  }

  return await projects.get(null, {filters})
}
