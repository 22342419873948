import React, {useEffect, useState, useContext} from 'react'
import {ReducerContext} from '../../reducer'
import {List} from 'antd'
import makePins from './makePins'
import {getCityState, sameDay} from '../../utilities'
import moment from 'moment'

export default function Intakes({directionsLibraries, selectedProject, setSelectedProject}) {
  const {state} = useContext(ReducerContext)
  const {
    calendar,
    mapping: {map},
    date,
  } = state
  const [pins, setPins] = useState({})
  const [todayIntakes, setTodayIntakes] = useState([])
  const current = date ? date : moment()

  // intake effects
  useEffect(
    function () {
      const ti = Object.values(calendar).filter((e) =>
        e?.date && sameDay(moment(e.date.toDate()).startOf('day'), current.startOf('day'))
      )
      setTodayIntakes(ti)
    },
    [calendar, date]
  )
  useEffect(
    function () {
      for (let i in pins) {
        pins[i].setMap(null)
      }
      const p = makePins(todayIntakes, map)
      setPins(p)

      return () => {
        for (let i in p) {
          p[i].setMap(null)
        }
      }
    },
    [todayIntakes]
  )

  function selectProject(entry) {
    if (selectedProject.id === entry.data.id) {
      setSelectedProject({})
      const {directionsRenderer} = directionsLibraries
      directionsRenderer.set('directions', null)
    } else setSelectedProject(entry.data)
  }

  return (
    <div>
      {todayIntakes.length > 0 && (
        <List
          dataSource={todayIntakes}
          renderItem={(entry) => {
            const {date, end} = entry
            const [b, e] = [moment(date.toDate()), moment(end.toDate())]
            return (
              <div
                className="flex-row align-start pointer"
                style={{fontSize: '75%'}}
                onClick={() => selectProject(entry)}
              >
                <div className="padding-right-1">
                  &#8226; {b.format('hh:mm A')} - {e.format('hh:mm A')}
                </div>
                <div className="padding-right-1">{getCityState(entry.data.site.geocode_results)}</div>
              </div>
            )
          }}
        />
      )}
    </div>
  )
}
