import React, {useEffect, useContext} from 'react'
import {ReducerContext} from './reducer'
import {GenericSelector} from './GenericSelector'
import {Button} from 'antd'
import DB from '../DAL/DB'
import './Analytics.scss'
import {db} from '../utilities'
import {auth} from '../Auth'

export default function CIFilters() {
  const {state, dispatch} = useContext(ReducerContext)
  const disabled = !state.dropdown.orgRole || !state.dropdown.form || !state.dropdown.rubric

  //Get form sections and write them to state
  useEffect(
    async function () {
      const selectedForm = state.dropdown.form
      if (selectedForm) {
        dispatch({type: 'sectionsLoading', value: true})
      }
      let {ok: sectionsOk, data: sectionsData} = await new DB.FormSection(selectedForm).get()

      sectionsData = [{id: 'All', title: 'All'}, ...sectionsData]

      dispatch({type: 'sections', value: sectionsData})
    },
    [state.dropdown.form]
  )

  //Given the values of dropdowns on the page, returns a unique document
  const searchQuestionScores = async (dropdowns, user) => {
    let doc_id, qscore
    switch (dropdowns.orgRole) {
      case 'Client':
        //Because documents have unique names to prevent storage bloating, we can reference them directly by ID
        // instead of querying traditionally.
        doc_id = `${dropdowns.rubric}_client_${user.organization.id}_installer_${dropdowns.installer}_reviewer_${dropdowns.owner}_time_frame_${dropdowns.timeFrame}`
        qscore = await db.collection('question_scores').doc(doc_id).get()
        dispatch({type: 'qscores', value: qscore.data()})
        break

      case 'Installer':
        doc_id = `${dropdowns.rubric}_installer_${user.organization.id}_client_${dropdowns.client}_reviewer_${dropdowns.owner}_time_frame_${dropdowns.timeFrame}`
        qscore = await db.collection('question_scores').doc(doc_id).get()
        dispatch({type: 'qscores', value: qscore.data()})
        break

      case 'Project Reviewer':
        doc_id = `${dropdowns.rubric}_reviewer_${user.organization.id}_client_${dropdowns.client}_installer_${dropdowns.owner}_time_frame_${dropdowns.timeFrame}`
        qscore = await db.collection('question_scores').doc(doc_id).get()
        dispatch({type: 'qscores', value: qscore.data()})
        break
      default:
        console.log('No action specified for this orgRole')
    }

    console.log('qscore.exists:', qscore.exists)
  }

  let clientPlaceholder
  let installerPlaceholder
  let ownersPlaceholder
  let sectionPlaceholder
  if (!state.dropdown.orgRole) {
    clientPlaceholder = `Select an organization role`
    installerPlaceholder = `Select an organization role`
  }

  if (state.clientsLoading) {
    clientPlaceholder = '... Loading clients, please wait ...'
  } else {
    clientPlaceholder = 'Select a client'
  }

  if (state.installersLoading) {
    installerPlaceholder = '... Loading installers, please wait ...'
  } else {
    installerPlaceholder = 'Select an installer'
  }

  if (state.ownersLoading) {
    ownersPlaceholder = '... Loading owners, please wait ...'
  } else {
    ownersPlaceholder = 'Select an owner'
  }

  if (state.sectionLoading) {
    sectionPlaceholder = '... Loading sections, please wait ...'
  } else {
    sectionPlaceholder = 'Select a section'
  }

  return (
    <div className="flex-row selectors">
      <GenericSelector
        placeholder={clientPlaceholder}
        label="Client"
        values={state.clients}
        valueTitle="name"
        selectedValue={state.dropdown.client}
        onChange={(value) => dispatch({type: 'client', value})}
        disabled={disabled}
        hidden={state.dropdown.orgRole == 'Client'}
        data-cy="ciClient"
      />
      <GenericSelector
        placeholder={installerPlaceholder}
        label="Installer"
        values={state.installers}
        valueTitle="name"
        selectedValue={state.dropdown.installer}
        onChange={(value) => dispatch({type: 'installer', value})}
        disabled={disabled}
        hidden={state.dropdown.orgRole == 'Installer'}
        data-cy="ciInstaller"
      />
      <GenericSelector
        placeholder={ownersPlaceholder}
        label="Owner"
        values={state.owners}
        valueTitle="name"
        selectedValue={state.dropdown.owner}
        onChange={(value) => dispatch({type: 'owner', value})}
        disabled={disabled}
        hidden={state.dropdown.orgRole == 'Project Reviewer'}
        data-cy="ciOwner"
      />
      <GenericSelector
        placeholder={sectionPlaceholder}
        label="Section"
        values={state.sections}
        valueTitle="title"
        selectedValue={state.dropdown.section}
        disabled={disabled}
        onChange={(value) => dispatch({type: 'section', value})}
        data-cy="ciSection"
      />
      <GenericSelector
        placeholder="Select a Time Frame"
        label="Time Frame"
        values={state.timeFrames}
        valueTitle={(value) => value.display || value.id}
        selectedValue={state.dropdown.timeFrame}
        disabled={disabled}
        onChange={(value) => dispatch({type: 'timeFrame', value})}
        data-cy="ciTimeFrame"
      />

      <Button onClick={() => searchQuestionScores(state.dropdown, auth.sunkaizenUser)} disabled={disabled}>
        See Results
      </Button>
    </div>
  )
}
