import {history} from '../../../history'
import {store} from '../../utilities'
import {setPopup} from '../../action_creators'

export default function onRow(record) {
  return {
    style: {cursor: `pointer`},
    onClick: (e) => {
      if (e.metaKey) return window.open(`/projects/${record.id}/manage`)
      history.push(`/projects/${record.id}/manage`)
    },
    onContextMenu: (e) => {
      e.preventDefault()
      const state = store.getState()

      if (!state.tableVars.popup.x) store.dispatch(setPopup(record, e.clientX, e.clientY))
    },
  }
}
