import DB from '../../DAL/DB'
import {auth} from '../../Auth'

const csvFilters = new DB.CSVFilters()

export default async function savePresetFilter(name, includeIntakeData, filterTypes) {
  if (!name) name = 'unnamed filter'
  const ft = {user: auth.sunkaizenUser.id, filter: filterTypes, name, includeIntakeData: includeIntakeData}
  await csvFilters.create(null, ft)
}
