import SelectFailures from './SelectFailures'
import onOk from './onOk'
import Button from './ModalOpenButton'

// chooseIntakeFailuresAndContinueToTicketCreation({intakeFailuresById})
const configuration = {
  title: 'Choose Intake Failures to Create Tickets',
  ok: onOk,
  okText: 'Continue',
  okButtonDisabled: () => false,
  view: SelectFailures,
  type: 'select-failures',
  button: Button,
}

export default configuration
