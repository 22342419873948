import React from 'react';
import { Required } from '../../utilities';
import AutocompleteField from '../../AutocompleteField';
import { TextField } from './TextField';
import { types } from './addressFieldTypes';

export default function CreateClientUser({
  fields = { type: 'field-user' }, 
  setField = () => {}, 
  style = {}, 
  noheader = false, 
  autocomplete = false,
}) {

  async function changeAddress(address) {
    const filteredAddress = {};
    address[0].address_components.forEach((c) => {
      if (c.types.some((t) => types[t])) {
        filteredAddress[c.types[0]] = c.short_name;
      }
    });
    for (let k in filteredAddress) setField(k)(filteredAddress[k]);
    setField('address')(address[0].formatted_address);
    setField('geocode_results')(address[0]);
  }

  return (
    <div style={{ ...style,  margin: '0 auto' }}>
      {!noheader && <h3 style={{ textAlign: 'left' }}>User Information</h3>}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label 
            htmlFor="email" 
            style={{ fontWeight: 'bold', flex: 1, textAlign: 'left' }}
          >
            Email
          </label>
        </div>
         <TextField 
            id="email"
            field={fields.email} 
            type="email" 
            set={setField('email')} 
            placeholder="Email" 
            style={{ flex: 2, marginLeft: '1rem' }}
          />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label 
            htmlFor="name" 
            style={{ fontWeight: 'bold', flex: 1, textAlign: 'left' }}
          >
            Full Name
          </label>
        </div>
        <TextField 
            id="name"
            field={fields.name} 
            set={setField('name')} 
            placeholder="Full Name" 
            style={{ flex: 2, marginLeft: '1rem' }}
          />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label 
            htmlFor="phone" 
            style={{ fontWeight: 'bold', flex: 1, textAlign: 'left' }}
          >
            Phone
          </label>
         
        </div>
        <TextField 
            id="phone"
            field={fields.phone} 
            set={setField('phone')} 
            placeholder="Phone" 
            style={{ flex: 2, marginLeft: '1rem' }}
          />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label 
            htmlFor="address" 
            style={{ fontWeight: 'bold', flex: 1, textAlign: 'left' }}
          >
            Address
          </label>
        </div>
        <AutocompleteField
            id="address"
            full
            edit
            autoComplete={autocomplete}
            style={{ flex: 2 }}
            submit={changeAddress}
            old_address={fields.address}
            placeholder="Address"
            data-cy="address"
          />
      </div>
    </div>
  );
}
