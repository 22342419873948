export default function validationEffect(args) {
  return function () {
    const {validate, setErrorMessage, clearErrorMessage, validationTimeout, setValidationTimeout, setValid} = args

    if (validationTimeout) clearTimeout(validationTimeout)
    clearErrorMessage()

    const timeout = setTimeout(async () => {
      if (!(await validate())) {
        if (setValid) setValid(false)
        setErrorMessage()
      } else if (setValid) setValid(true)
    }, 2000)
    setValidationTimeout(timeout)
  }
}
