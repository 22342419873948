import React from 'react'
import {wrap} from './reducer'
import {projectDatesToMoment} from './utilities'
import LeftPanel from './LeftPanel/LeftPanel'
import RightPanel from './RightPanel'
import './ScheduleInspections.css'

export function TopLevelState({match, setVisible}) {
  const {params: {id} = {}} = match

  return (
    <div className="flex-row">
      <LeftPanel setVisible={setVisible} />
      <div style={{flex: 10}}>
        <RightPanel posthooks={[projectDatesToMoment]} single={id} />
      </div>
    </div>
  )
}

const WrappedTopLevelState = wrap(TopLevelState)
export default WrappedTopLevelState
