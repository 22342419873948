import React, {useState, useEffect} from 'react'
import {Input} from 'antd'
import validationEffect from './validationEffect'

export default function Title({title, change, setValid}) {
  const [error, setError] = useState(null)
  const [validationTimeout, setValidationTimeout] = useState(0)

  useEffect(
    validationEffect({
      validate: () => !title || (title.length && title.length > 2),
      setErrorMessage: () => setError('The title must contain at least 3 characters'),
      clearErrorMessage: () => {
        if (error) setError(null)
      },
      validationTimeout,
      setValidationTimeout,
      setValid,
    }),
    [title]
  )

  return (
    <div>
      <label>Title</label>
      {error && <div style={{color: 'red', background: 'white', borderRadius: '0.5rem'}}>{error}</div>}
      <Input
        className="input-field"
        size="large"
        value={title}
        placeholder="Title of ticket"
        onChange={(e) => change('title', e.target.value)}
      />
    </div>
  )
}
