import {store} from '../utilities'
import {activate} from '../action_creators'

export default function activateField() {
  return function () {
    const state = store.getState()
    const {active, sections} = state

    const {section: s, field: f} = active
    if (!s || f || !sections[s] || !Array.isArray(sections[s].order)) return

    store.dispatch(activate('field', sections[s].order[0]))
  }
}
