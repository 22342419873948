import {store} from '../../../Reports/ReportsList'
import {history} from '../../../history'
import {auth} from '../../../Auth'

export default function onRow(record) {
  return {
    onClick: () => {
      const state = store.getState()
      if (state[record.id]) return store.dispatch({type: 'delete', id: record.id})
      store.dispatch({type: 'set', id: record.id, value: record})
    },
    onDoubleClick: (e) => {
      if (e.metaKey) return window.open(`/projects/${record.projectId}/manage`)
      history.push(`/projects/${record.projectId}/manage`)
    },
  }
}
