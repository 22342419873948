import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {Button} from 'antd'
import ConditionGroups from './Condition/ConditionGroups'


export function ConditionGroupComponent(props) {
  
  const { fields, field, section, handleUpdateActions, data} = props;
  const { dependentQue} = fields[field ? field.id : 0];
  const [conditionQuestions, setConditionQuestions] = useState(dependentQue ? [dependentQue] : [])
  const {order} = section
  const { conditionGroup } = data
  const cgList1 = conditionGroup  && conditionGroup.cgList;
  const [cgList, setCgList] = useState(cgList1 || []);

  useEffect(() => {
    setCgList(cgList1 || [])
  },[JSON.stringify(cgList1)])

  const handleAddConditionGroup = () => {
   if(!data.conditionGroup) {
     data.conditionGroup = {
      cgList: []
     }
   }
    const cgList = data.conditionGroup && data.conditionGroup.cgList
    const cgListUpdated = [...cgList]
    const obj = { conditionList: [], mustBeTrueValue : 'all' }
    cgListUpdated.push(obj)
    setCgList(cgListUpdated)
    let conditionGroup = {
      cgList: cgListUpdated,
      operand: ''
    };
    if(cgListUpdated.length > 1) {
      conditionGroup.operand = 'and'
    }
    data.conditionGroup = conditionGroup
    updateFieldConditionDetails(data)
  }
  const handleConditionQuestionSelection = (id, e) => {
    console.log('id', e);
  }
  const handleDeleteConditionGroup = (cgDeleteIndex) => {
   const { conditionGroup } = data
   let cgListUpdated = [...conditionGroup.cgList]
   const records = cgListUpdated.filter((x, index) => index !== cgDeleteIndex -1)
   if(records && records.length === 0) {
    cgListUpdated = []
   } else {
    cgListUpdated = cgListUpdated.filter((x, index) => index !== cgDeleteIndex -1)
   }
   conditionGroup.cgList = cgListUpdated
   
   setCgList(cgListUpdated)
   updateFieldConditionDetails(data)
  }
  const updateFieldConditionDetails = (data) => {
    handleUpdateActions(data)
  }
  const updateConditionList =(conditionListUpdated, index) => {
    const { conditionGroup } = data
    let cgListUpdated = conditionGroup.cgList
    let cgListUpdatedRec = cgListUpdated[index - 1]
    cgListUpdatedRec.conditionList = conditionListUpdated
    setCgList(cgListUpdated)
    updateFieldConditionDetails(data)
  }
  const handleMustBeTrueCondition = (index, e) => {
    const { conditionGroup } = data
    let cgListUpdated = conditionGroup.cgList
    let cgListUpdatedRec = cgListUpdated[index-1]
    cgListUpdatedRec.mustBeTrueValue = e
    setCgList(cgListUpdated)
    updateFieldConditionDetails(data)
  }
  const handleGroupCondtionValue = (e) => {
    let { conditionGroup } = data
    conditionGroup.operand = e
    updateFieldConditionDetails(data)
  }

  const renderConditionGroupRender = () => {
    return (
      <ConditionGroups
        cgList={cgList}
        fields={fields}
        id={field.id}
        order={order}
        sections={props.sections}
        isRubric={true}
        field={field}
        conditionQuestions={conditionQuestions}
        selectedOperand={conditionGroup && conditionGroup.operand}
        handleConditionQuestionSelection={handleConditionQuestionSelection}
        handleDeleteConditionGroup={handleDeleteConditionGroup}
        updateConditionList={updateConditionList}
        handleMustBeTrueCondition={handleMustBeTrueCondition}
        handleGroupCondtionValue={handleGroupCondtionValue}
      />
    )
  }
  return (
    <React.Fragment>
          {cgList && cgList.length > 0 &&
            <React.Fragment key={`action-${cgList.length}`}>
              {renderConditionGroupRender()}
            </React.Fragment>
          }
          {cgList && cgList.length < 2 &&
          <div className='rubric-condition-group'>
            <Button type="primary" onClick={handleAddConditionGroup}>+ Condition Group</ Button>
          </div>
        }
   </React.Fragment>
  )
}

export default ConditionGroupComponent

