import {useEffect, useContext} from 'react'
import {ReducerContext} from '../../reducer'
import DB from '../../../DAL/DB'
import {useDBSubscribe} from '../../../effects'

const calendars = new DB.Calendars()

export default function getCalendar() {
  const {state, dispatch} = useContext(ReducerContext)
  const {inspector: ii} = state

  const entries = useDBSubscribe(calendars, {filters: [[`data.inspection.inspector.id`, '==', ii.id]]})
  const entriesMap = {}
  for (let e of entries) {
    entriesMap[e.id] = e
  }

  const changed = [...new Set(Object.keys(entriesMap)), ii.id].join(',')

  useEffect(
    function () {
      dispatch({type: 'calendar', value: entriesMap})
    },
    [changed]
  )
}
