import React, { useState} from 'react'
import {Radio, Tooltip, Icon} from 'antd'

function AutoRubricQuestion({autoRubricQuestion, label, tipText, options,
   handleUpdatedAutoRubric, selectedValue}) {
   
    const selectedOption = selectedValue && selectedValue.selectedOption;
    const [isError, setIsError] = useState(selectedOption ? false : true)

    const handleUpdatedAutoRubricInternal = (e) => {
      handleUpdatedAutoRubric(e)
      setIsError(false)
    }
     
  return (
    <div key={`autoRubric-${selectedValue && selectedValue.selectedOption}`} style={{ marginLeft: 25, marginRight: 15 }} 
        className={ isError ?  'autoRubricValueRequiredError' : ''} >
       <div className="bold flex-row">
          <Tooltip title="Required">
            <Icon type="exclamation-circle" style={{fontSize: '2rem', padding: '0 1rem 0 0'}} />
          </Tooltip>
          <div>REQUIRED</div>
        </div>
      <div className="bold padding-bottom-1">{autoRubricQuestion || ''}</div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1" style={{fontFamily: `Arial, serif`, fontSize: `85%`}}>
          {(label || ``).toUpperCase()}
        </div>
        {/* <div>{tipText && `(${tipText})`}</div> */}
        <div className="full-width">
          <Radio.Group style={{display: `flex`, flexFlow: `column`}} 
          onChange={handleUpdatedAutoRubricInternal} 
          defaultValue={`${selectedValue.name}-${selectedValue.selectedOption}`}>
            {(options || []).map((rec, index) => (
              <Radio value={`${rec.selectedRubric}-${rec.option}`} name={rec.option} key={`${rec.selectedRubric}-${index}`}>
                {rec.option}
              </Radio>
            ))}
          </Radio.Group>
        </div>
        <div className="linebreak">
          <small>{tipText && `${tipText}`}</small>
        </div>
      </div>
    </div>
  )
}

export default AutoRubricQuestion
