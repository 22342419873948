import React from 'react'
import {Icon} from 'antd'
import DeleteButton from '../../DeleteButton'
import {deleteFile} from '../../FileHandling/DeleteFile'

let columns = [
  {title: 'Name', dataIndex: 'name', key: 'name'},
  {
    title: 'Size',
    dataIndex: 'size',
    key: 'size',
    render: (s) => (s > 1000000 ? `${(s / (1024 * 1024)).toFixed(2)} MB` : `${(s / 1024).toFixed(2)} KB`),
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    render: (u) => (u.toDate ? u.toDate().toDateString() : null),
  },
]

function makeActions(projectId) {
  return {
    title: 'Actions',
    key: 'action',
    render: (text, record) => (
      <div>
        <a target="_blank" rel="noopener noreferrer" style={{padding: '0.2rem'}} href={record.url} download>
          <Icon style={{color: 'grey', fontSize: 24}} type="cloud-download" />
        </a>
        <DeleteButton
          customRender
          onConfirm={() => deleteFile('projects', projectId, record.id)}
          okText="Delete"
          cancelText="Cancel"
          confirmationDisplay="Really delete this file? This cannot be undone."
        >
          <Icon
            style={{
              padding: '0.2rem',
              paddingLeft: '0.5rem',
              fontSize: 24,
            }}
            type="delete"
          />
        </DeleteButton>
      </div>
    ),
  }
}

export function makeColumns(projectId) {
  return columns.concat(makeActions(projectId))
}
