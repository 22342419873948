import {history} from '../../../history'

export default function onRow(record) {
  return {
    style: {cursor: `pointer`},
    onClick: (e) => {
      if (e.metaKey) return window.open(`/users/${record.id}/manage`)
      history.push(`/users/${record.id}/manage`)
    },
  }
}
