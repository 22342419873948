import React, { useEffect,useRef } from 'react'
import {connect} from 'react-redux'
import {Icon, Tooltip} from 'antd'
import PhotoRequired from './PhotoRequired'

import {setField} from '../../action_creators'

const stop = (state, ownProps) => ({field: state.fields[ownProps.id]})
const dtop = (dispatch) => ({setField: ({id, value}) => dispatch(setField(id, value))})

export function MultiInput({setField, field, id, question, label, required, tipText, value = ``, photoRequired}) {
  const set = (e) => setField({id, value: {...field, value: e.target.value}})
  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.innerHTML = field &&  field.question || ""
  }, [])

  return (
    <div>
      <div className="bold padding-bottom-1 linebreak question-title">{label}</div>
      <div className="flex-column full-width align-start">
        <div className="full-width input-container" ref={inputEl}
         title={field.tipText}>
        </div>
      </div>
    </div>
  )
}

const WrappedMultiInput = connect(stop, dtop)(MultiInput)
export default WrappedMultiInput