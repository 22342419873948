import React from 'react'

const Field = ({name, value}) => (
  <div className="flex-column padding-top-1 full-width" style={{borderBottom: `0.1rem solid #C4CDD5`}}>
    <div className="padding-top-1 flex-row justify-start" style={{fontSize: `50%`}}>
      {name}
    </div>
    <div className="flex-row justify-start" style={{padding: `0.5rem 0 1rem 0`}}>
      {value}
    </div>
  </div>
)

export default function SiteRepresentative({
  siteRepName = `none`,
  siteRepEmail = `not provided`,
  siteRepPhone = `not provided`,
}) {
  return (
    <div className="flex-column align-start background-white padding-2 margin-bottom-1" style={{fontAlignt: `left`}}>
      <h4>Site Representative</h4>
      <div className="full-width flex-column align-start padding-1" style={{background: `rgba(249, 250, 251, 0.7)`}}>
        <Field name="Site Representative" value={siteRepName} />
        <div className="full-width flex-row">
          <div style={{flex: 40}}>
            <Field name="Site Representative Email" value={siteRepEmail} />
          </div>
          <div style={{flex: 1}}></div>
          <div style={{flex: 40}}>
            <Field name="Site Representative Phone" value={siteRepPhone} />
          </div>
        </div>
      </div>
    </div>
  )
}
