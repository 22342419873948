import BaseModel from './BaseModel'
import {db} from '../utilities'

export default class Rubrics extends BaseModel {
  constructor() {
    super(db.collection('rubrics'))
  }
  static async get(id: string) {
    try {
      const doc = await db.collection('rubrics').doc(id).get();
      if (doc.exists) {
        return { data: doc.data() };
      } else {
        console.error("No such document!");
        return { data: null };
      }
    } catch (error) {
      console.error("Error getting document:", error);
      return { data: null };
    }
  }
}
