import {readUploadedText} from './readUploadedText'
import {validateHeaders} from './validateHeaders'
import {geocodeAddress} from './geocodeAddress'
import {validateType} from './validateType'

export async function upload(files) {
  let promises = files.map((f) => readUploadedText(f))
  let textfiles = await Promise.all(promises)

  const pp = textfiles.map(async (file) => {
    let header = file.shift()
    file = file.filter((f) => f.some((l) => l.length > 0))

    try {
      if (!validateHeaders(header)) {
        console.log(`there's something wrong with the header; canceling`, header)
        return
      }

      const userPromises = geocodeAddress(file)
      const newUsers = await Promise.all(userPromises)

      validateType(newUsers)

      const users = newUsers.filter((i) => i)
      return users
    } catch (e) {
      console.log(`could not geocode all the users`, e)
    }
  })

  return [].concat(...(await Promise.all(pp)))
}
