import {store} from '../../utilities'
import {setFailures, setTicket} from '../../action_creators'
import {history} from '../../../history'
import {makeTicket} from '../../utilities'
import DB from '../../../DAL/DB'
import {firebase} from '../../../../src/utilities'
import { auth } from '../../../Auth'
import P from '../../../Reports/ReportsList'

const tickets = new DB.Tickets()
const projects = new DB.Projects()

export default async function onOk() {
  const state = store.getState()
  const ff = state.failures

  const removeUndefinedKeys = (obj) => {
    const cleanedObj = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] !== undefined) {
        cleanedObj[key] = obj[key];
      }
    });
    return cleanedObj;
  }

  try {
    const ticket = await makeTicket()
    let ticketPayload = {...ticket, assigneeType: ticket.watcherMap[ticket.assignee]?.type || null }
    ticketPayload = removeUndefinedKeys(ticketPayload)
    //console.log('ticketPayload',ticketPayload)

    await tickets.update(ticket.id, null, {...ticketPayload})
   

    try {
      await projects.updatebatch(ticket.project, null, { tickets: firebase.firestore.FieldValue.arrayUnion({ 
        id: ticket.id, 
        title: ticket.title,
        updated: firebase.firestore.Timestamp.now(), // firebase.firestore.FieldValue.serverTimestamp(),  // or firebase.firestore.timestamp.now()
        updatedBy: auth?.sunkaizenUser?.id, 
        updatedByName: auth?.sunkaizenUser?.name
      })  })
    }
    catch (Error) {
      console.log('error',Error)
    }
    
    //console.log('ticketPayload',ticketPayload)

    delete ff[ticket.failure.id]
    store.dispatch(setTicket({...ticketPayload}))
    store.dispatch(setFailures(ff))

    if (!Object.keys(ff).length && state.meta.active === 'create') {
      history.push('/tickets')
    }
  } catch (e) {
    console.log('ticket creation failed', e)
    throw e
  }
}
