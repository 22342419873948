import React from 'react'
import {Checkbox} from 'antd'

export default function Content({setField, options}) {
  return (
    <div>
      <div className="bold padding-top-2">Contents</div>
      <div className="flex-column">
        {[
          {name: 'Intake Photos', value: 'intakePhotos'},
          {name: 'Project History Log', value: 'projectHistoryLog'},
          {name: 'Do not include blank fields in report output', value: 'includeBlankFields'},
        ].map(({name, value}) => (
          <Checkbox
            onChange={(e) => setField(value, e.target.checked)}
            checked={options[value]}
            disabled={value === 'intakePhotos' && options.intakeReport === false}
            style={{margin: '1rem 0 0 0', fontWeight: 'normal'}}
          >
            {name}
          </Checkbox>
        ))}
      </div>
    </div>
  )
}
