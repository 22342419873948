import {questionHeaders as headers, headerMap} from '../../CreateCSVReports/utilities/headerOrder'
import {iterateIntake} from '.'
import iterateQuestions from './iterateQuestions'

export default function formatProjects(projects, form, rubric) {
  if (!projects || !projects.length) return null

  const header = makeHeader(form)
  const rows = projects.map((project) => formatProject(project, form))

  return [header, ...rows]
}

function formatProject(project, form) {
  const hm = headerMap(project)
  const basicInformation = headers.map((h) => hm[h])

  const answers = getAnswers(project, form)

  return [...basicInformation, ...answers]
}

function makeHeader(form) {
  let questionHeaders = []
  try {
    questionHeaders = iterateQuestions(form, (s, f) => {
      return f.question
    })
  } catch (e) {
    console.info('Failed to extract questions from the form:', form, e)
  }

  console.info('Questions extracted:', questionHeaders)

  return [...headers, ...questionHeaders]
}

function getAnswers(project, form) {
  try {
    return iterateIntake(project, form, (s, f) => f.answer)
  } catch {
    return []
  }
}
