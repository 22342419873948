import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {subscribe} from '../HOC/Firebase/SubscribeHOC'
import {Table, Icon} from 'antd'
import {saveAs} from 'file-saver'
import Papa from 'papaparse'
import {history} from '../history'

export function HistoryLog({projectId, makeSubscription, subscribeResources}) {
  const [logs, setLogs] = useState([])

  useEffect(function () {
    return makeSubscription(
      {
        paths: [{collection: 'projectTransactionLog'}],
        options: {where: [{field: 'categoryId', test: '==', value: projectId}]},
      },
      'logs'
    )
  }, [])

  useEffect(
    function () {
      const {logs: ll = []} = subscribeResources
      const logDates = {}
      let k = 0
      for (let log of ll) {
        delete log.extended
        delete log.categoryId
        log.created = log.created.toDate()
        if (log.site) {
          log.siteOwner = log.site.siteOwnerName
          log.site = log.site.siteAddress
        }

        log.key = ++k
        logDates[log.created] = log
      }

      const unsorted = Object.values(logDates)
      const sorted = unsorted.sort((a, b) => (a.created < b.created ? 1 : -1))
      setLogs(sorted)
    },
    [subscribeResources]
  )

  function exportCSV() {
    const ll = Papa.unparse(logs)
    saveAs(new Blob([ll]), 'project_history.csv', {type: 'text/csv'})
  }

  const columns = [
    {title: 'Event', dataIndex: 'message', key: 'message'},
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'date',
      render: (text, record) => {
        return moment(record.created).utc().local().format('MM-DD-YYYY hh:mm:ss a')
      },
    },
    { title: 'Updated By', 
      dataIndex: 'updatedByName', 
      key: 'updatedByName',
      render: (text, record) => {
        return <>{record?.updatedByName ? (<span style={{ cursor: 'pointer' }} onClick={()=>history.push(`/users/${record.updatedBy}/manage`)} >{record.updatedByName}</span>): (<span></span>)}</>
      }
    }

    
  ] //DOE1-516 added updatedBy field to the table

  return (
    <div className="background-white">
      <div className="flex-row align-center justify-space-between" style={{borderBottom: '0.1rem solid #DFE3E8'}}>
        <h3 className="padding-2 margin-0">Project History Log</h3>
        <Icon type="export" style={{fontSize: '3rem', padding: '0 2rem 0 0'}} onClick={exportCSV} />
      </div>
      <div className="padding-2">
        <Table {...{dataSource: logs, columns}} />
      </div>
    </div>
  )
}

const WrappedHistoryLog = subscribe()(HistoryLog)
export default WrappedHistoryLog
