import React from 'react'
import {Select} from 'antd'
import '../../DetailedUserView.css'
import {capitalizationMap} from '../../utilities'

const Dropdown = ({options, edit, type, style, user, select}) => (
  <div className="flex-column field-wrapper" style={style}>
    <div className="info-text">{capitalizationMap[type]}</div>
    <Select className="full-width" defaultValue={'Select a Type'} onChange={select} disabled={!edit} value={user.type}>
      {options.map((option) => (
        <Select.Option key={option}>{option}</Select.Option>
      ))}
    </Select>
  </div>
)

export default Dropdown
