import React from 'react'
import {auth} from '../../Auth'
import Button from '../../TicketBuilder'

// limit ticket creation by organization, except that non-owner clients may also create tickets
export default function AddTicket({project, history, status, rubric}) {
  const userType = [`super-admin`, `admin`, `client`].includes(auth.sunkaizenUser.type)
  const statusValue = status === `Completed` && rubric && rubric.id

  const showTicketButton = [userType, statusValue].every((i) => i)

  return (
    <div>
      {showTicketButton && (
        <div className="padding-left-1 padding-right-1">
          <Button project={project} mode="select-failures" />
        </div>
      )}
    </div>
  )
}
