import React, {useContext} from 'react'
import {DatePicker} from 'antd'
import {ReducerContext} from './reducer'
import {capitalizeFirst} from '../utilities'

export default function DateItem({type}) {
  const {state, dispatch} = useContext(ReducerContext)

  const {before, after} = state[type]

  function setF(bound, m) {
    const values = {before, after}
    values[bound] = m
    dispatch({type, ...values})
  }

  return (
    <div className="flex-row align-center padding-top-0-5 padding-bottom-0-5">
      <div className="padding-right-1">{capitalizeFirst(type)}</div>
      <div className="flex-row full-width" style={{padding: `0 5rem 0 0`}}>
        <DatePicker value={after} onChange={(e) => setF(`after`, e)} style={{flex: 1, margin: `0 1rem 0 0`}} />
        <DatePicker value={before} onChange={(e) => setF(`before`, e)} style={{flex: 1}} />
      </div>
    </div>
  )
}
