import {useContext, useEffect} from 'react'
import {useDBGet} from '../../effects'
import DB from '../../DAL/DB'
import {ReducerContext} from '../reducer'

const meta = new DB.Meta()

export default function useGetProjectStatuses() {
  const {state, dispatch} = useContext(ReducerContext)

  const statuses = useDBGet(meta, 'project_statuses')

  useEffect(
    function () {
      if (Array.isArray(statuses)) return
      delete statuses.id
      const filterTypes = {...state.filterTypes}

      filterTypes.status.options = Object.keys(statuses)
      dispatch({type: 'filterTypes', value: filterTypes})
    },
    [statuses]
  )
}
