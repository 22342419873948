import React from 'react'
import {connect} from 'react-redux'
import {Button} from 'antd'
import {setField, modified} from './action_creators'
import save from './save/save'

const stp = (state) => ({published: state.form.active})
const dtp = (dispatch) => ({
  setField: (value) => dispatch(setField('form_field')(`active`, value)),
  modified: (value) => dispatch(modified(value)),
})

export function Header({setField, published}) {
  function togglePublish() {
    setField(!published)
    save()
    modified(false)
  }

  return (
    <div className="flex-row">
      <h1 className="page-title pageTitle" style={{flex: 1}}>
        Form Builder
      </h1>
      <div className="flex-row">
        <div className="margin-right-0-5">
          <Button
            ghost={false}
            className="thick"
            type={published ? `default` : `primary`}
            size="large"
            onClick={togglePublish}
          >
            {published ? `Unpublish` : `Save and Publish`}
          </Button>
        </div>
      </div>
    </div>
  )
}

const ConnectedHeader = connect(stp, dtp)(Header)
export default ConnectedHeader
