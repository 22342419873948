import React, {useState} from 'react'
import {Modal, Input} from 'antd'
import DB from '../DAL/DB'
import {saveComment} from './utilities'

const tickets = new DB.Tickets()

export default function TicketModal({type, setShow, ticket}) {
  const [comment, setComment] = useState('')

  async function submit(status) {
    ticket.status = status

    await tickets.update(ticket.id, null, ticket)
    if (status !== 'Completed') {
      await saveComment(ticket.id, comment)
      setComment(null)
      setShow(false)
    }
  }

  const actions = {
    accept: () => submit('Completed'),
    reject: () => submit('Rejected'),
    submit: () => submit('Submitted'),
  }

  return (
    <Modal
      visible={type}
      onCancel={() => setShow(null)}
      onOk={actions[type]}
      okText={type === 'resubmit' ? 'Resubmit Ticket' : 'Submit Text'}
      okButtonProps={{disabled: type === 'accept' ? false : !comment || comment.length < 1}}
    >
      {type === 'accept' ? (
        <div>Accepting this ticket will prevent any further changes.</div>
      ) : (
        <div>
          <div>Leave a comment</div>
          <Input.TextArea value={comment} onChange={(e) => setComment(e.target.value)} />
        </div>
      )}
    </Modal>
  )
}
