import {useState, useEffect, useContext} from 'react'
import {ReducerContext} from '../reducer'

export default function useMakeFields() {
  const { state } = useContext(ReducerContext)
    const [fields, setFields] = useState([])

    useEffect(function () {
      const f = ['client', 'completed', 'canceled'].map(
        (name) => state.filterTypes[name]
      )

      setFields(f)
    }, [])

    return fields

}
