import React from 'react'
import {Link} from 'react-router-dom'

export default function AvailabilityCalendarEvent({event}) {
  return (
    <div className="flex-column padding-bottom-2">
      <label>Field User:</label>
      <Link to={`/users/${event.data.id}/manage`}> {event.data.name}</Link>
    </div>
  )
}
