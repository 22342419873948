// QM permissions need to now check for organization too

import {auth} from '../../../Auth'

export default function permissions() {
  if (auth && auth.sunkaizenUser) {
    let permissions
    let t = auth.sunkaizenUser.type.slice()

    // if (!['client', 'admin', 'super-admin'].includes(t)) {
    //     permissions = [{match: {[`user_index.${auth.sunkaizenUser.id}`]: 'client'}}]
    // }

    return permissions
  } else return
}
