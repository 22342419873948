import {auth} from '../../../Auth'

export default function or() {
  const should = []

  // if (['quality-manager'].includes(auth.sunkaizenUser.type))
  //   return [{match: {'organization.id': auth.sunkaizenUser.organization.id}}]
  // if (['field-user'].includes(auth.sunkaizenUser.type))
  //   return [{match: {[`user_index.${auth.sunkaizenUser.id}`]: 'inspector'}}]

  if (['admin', 'super-admin', 'client'].includes(auth.sunkaizenUser.type)) {
    should.push({match: {[`permissions.${auth.sunkaizenUser.organization.id}`]: 'Read'}})
    should.push({match: {[`permissions.${auth.sunkaizenUser.organization.id}`]: 'Unread'}})
  }
  

  return should
}
