import {projectDatesToMoment} from '../utilities'

export default function loadProjectSchedule(project) {
  console.log('project is here',project)
  projectDatesToMoment(project)
  const {inspector = {}, date, duration} = project.inspection || {}
  const obj = {date, hour: date, minute: date, duration, inspector, project}
  console.log('obj',obj) 
  return obj
}
