export default function makeQueryString(filters) {
  const query_strings = [],
    ff = []

  Object.entries(filters).forEach(([key, value]) => {
    if (value.length > 0) {
      if (key === `created`) {
        const r = {}
        if (value[0]) r.gte = value[0].unix()
        if (value[1]) r.lte = value[1].unix()
        ff.push({range: {'created._seconds': r}})
      } else if (key === 'client.id') {
        ff.push({
          match: { 'client.id': value[0] },
        })
      } else if (key === 'inspection.inspector.id') {
        ff.push({
          match: { 'inspection.inspector.id': value[0] },
        })
      } else {
        query_strings.push({query_string: {default_field: key, query: value[0]}})
      }
    }
  })

  return {query_strings, filters: ff, from: 0}
}
