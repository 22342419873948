import {useEffect, useContext} from 'react'
import {ReducerContext} from '../reducer'
import {useDBSubscribe} from '../../effects'
import DB from '../../DAL/DB'

// const analytics = new DB.Analytics()
const rubrics = new DB.Rubrics()

// get rubrics
export default function useGetRubrics() {
  const {state, dispatch} = useContext(ReducerContext)

  const data = useDBSubscribe(rubrics, {filters: [['formId', '==', state.dropdown.form || 'non-existant form']]})

  useEffect(
    function () {
      console.log('setting new rubrics:', data)
      dispatch({type: `rubrics`, value: data})
    },
    [data]
  )
}
