import React, {useState} from 'react'
import {Select} from 'antd'
import { db } from '../../../../utilities'
import { set } from 'lodash'

export default function Status({status, change, ticket, project}) {
  
 


  return (
    <div>
      <label>Status</label>
      <Select
        className="input-field"
        size="large"
        value={status}
        placeholder="Select a status"
        onChange={(e) => change('status', e)}
        style={{width: `100%`}}
      >
       
        <Select.Option key={`Incomplete`} value={`Incomplete`}>
            Incomplete
        </Select.Option>
        <Select.Option key={`Submitted`} value={`Submitted`}>
          Submitted
        </Select.Option>
        <Select.Option key={`Rejected`} value={`Rejected`}>
            Rejected
        </Select.Option>
        <Select.Option key={`Completed`} value={`Completed`}>
            Completed
        </Select.Option>
      </Select>
    </div>
  )
}
