import {useEffect, useContext} from 'react'
import {ReducerContext} from '../../reducer'

function availabilityLimitInspectors(inspectors, availability) {
  const ii = {...inspectors}
  const availabilityByDate = {}
  for (let i in availability) {
    const a = availability[i]
    availabilityByDate[a.data.id] = availabilityByDate[a.data.id] ? [...availabilityByDate[a.data.id], a] : [a]
  }

  let jj = {}
  for (let id in ii) {
    jj[id] = {...ii[id], availability: availabilityByDate[id]}
  }

  return jj
}

export default function useAvailabilityFilter() {
  const {state, dispatch} = useContext(ReducerContext)

  useEffect(
    function () {
      const ii = availabilityLimitInspectors(state.inspectors, state.availability) || {}
      dispatch({type: 'inspectorAvailability', value: ii[state.inspector.id]})
    },
    [state.inspectors, state.inspector.id, state.availability]
  )
}
