import React from 'react'
import CardTitle from './CardTitle'
import {Card} from 'antd'

export default function QuestionWrapper({children, style, question, rubrics}) {
  return (
    <Card
      title={<CardTitle question={question} />}
      loading={!Object.keys(rubrics) || !question || !question.options}
      headStyle={{backgroundColor: `#F0F2F4`}}
      style={style}
      bodyStyle={{padding: 0}}
      data-cy="rubricOptions"
    >
      {children}
    </Card>
  )
}
