import React from 'react'
import {connect} from 'react-redux'
import {saveRubrics} from './utilities'
import {Button} from 'antd'
import './TitleBar.scss'

const buttonPadding = {paddingLeft: `5rem`, paddingRight: `5rem`}

function stop(state) {
  return {
    form: state.form,
    rubrics: state.rubrics,
    sections: state.sections,
    actions: state.actionData.actions
  }
}

export function TitleBar({history, form, rubrics, sections, actions}) {
  async function save() {
    const saved = await saveRubrics(form.id, rubrics, sections, actions, form.enableAutoRubric)
    if (saved) {
      history.push('/forms')
    }
  }

  return (
    <div className="flex-row justify-space-between padding-2 title-bar title-bar-positioning margin-bottom-2">
      <h1 className="padding-0 margin-0 page-title" style={{marginTop: `3px`}}>
        <strong>Form: </strong>
        {form.title}
      </h1>
      <div className="button-container">
        <Button size="large" onClick={() => history.push(`/forms`)} style={{marginRight: `2.5rem`, ...buttonPadding}}>
          Cancel
        </Button>
        <Button size="large" type="primary" onClick={save} style={{...buttonPadding, marginRight: `1.5rem`}}>
          Save and Exit
        </Button>
      </div>
    </div>
  )
}

const WrappedTitleBar = connect(stop)(TitleBar)
export default WrappedTitleBar
