import React from 'react'
import {Button} from 'antd'
import {auth} from '../../Auth'
import {connect} from 'react-redux'

const stop = (state) => ({project: state.project})

export function ReviewOptions({project, toggleReviewMode, reviewMode, corrections, status, finalize}) {
  return (
    <div className="flex-row justify-space-around" style={{flex: 1}}>
      <Button
        type={reviewMode ? 'primary' : 'default'}
        style={{margin: '0.5rem'}}
        onClick={() => toggleReviewMode(!reviewMode)}
      >
        Toggle Review Mode
      </Button>
      <Button
        disabled={corrections < 1 && project.organization.id !== auth.sunkaizenUser.organization.id}
        style={{margin: '0.5rem'}}
        onClick={() => finalize(corrections < 1, status)}
        type="primary"
        data-cy="returnToFuOrFinalizeBtn"
      >
        {corrections > 0 ? 'Return to Field User for Corrections' : 'Finalize'}
      </Button>
    </div>
  )
}

const WrappedReviewOptions = connect(stop)(ReviewOptions)
export default WrappedReviewOptions
