import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import {
  buildQuery,
  pathMustNotEndInADocumentMsg,
  createFunctionsFromPathsMapping,
  setTerminalDocumentForPaths,
} from './common'

/**
 * The destroy HOC (so named because 'delete' is a reserved keyword) provides a
 * component with deletion functionality after providing a
 * `mapDeletionFunctionsToPaths` property. See the documentation for CreateHOC
 * to understand more, as the architecture is very similar between components.
 *
 * The major difference between them is that the deletion functions take either
 * a string id, or an object with an `id` property, and then delete that
 * document from the collection specified by the paths given.
 */
export const destroy = () => (Component) => {
  function DestroyHOC({childRef, mapDeletionFunctionsToPaths = {}, ...props}) {
    const deletionFunctions = createFunctionsFromPathsMapping({
      mapFunctionsToPaths: mapDeletionFunctionsToPaths,
      makeFunction: prepareDocumentDeletion,
    })

    return <Component ref={childRef} {...props} deletionFunctions={deletionFunctions} />
  }

  DestroyHOC.propTypes = {
    childRef: PropTypes.object,
    mapDeletionFunctionsToPaths: PropTypes.object,
  }

  return React.forwardRef(function ForwardRefDestroyHOC(props, ref) {
    return <DestroyHOC {...props} childRef={ref} />
  })
}

/**
 * Returns a deletion function that takes an object with an id or a string id
 * and deletes the corresponding document from the (sub)collection at the path
 * specified.
 *
 * The preparation part is to perform some initial validation on the `paths`.
 * The deletion function that is returned also contains further validation to
 * facilitate easier use by programmers.
 */
export function prepareDocumentDeletion(paths) {
  const lastPathObject = _.last(paths)
  if (lastPathObject.doc) {
    throw new Error(pathMustNotEndInADocumentMsg(`delete`, `deletion`, lastPathObject))
  }

  const deleteDocument = (documentOrId) => {
    let id
    if (!_.isString(documentOrId)) {
      if (!_.isObject(documentOrId)) {
        throw new Error(
          `You must provide either an object with an id or a string id to delete a document from firebase.`
        )
      } else if (!_.has(documentOrId, `id`)) {
        throw new Error(
          `The object you provided for deletion does not contain an id property, so its document cannot be deleted from firebase.`
        )
      } else if (!_.isString(documentOrId.id)) {
        throw new Error(
          `The object you are trying to delete has non-string id property. The id must be a string corresponding to a firebase document id.`
        )
      }
      id = documentOrId.id
    } else {
      id = documentOrId
    }

    const pathForDeletion = setTerminalDocumentForPaths(paths, id)
    const query = buildQuery({paths: pathForDeletion})
    return query.delete()
  }

  return deleteDocument
}
