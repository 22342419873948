import GeocodeWrapper from '../../../GeocodeWrapper'
import retry from 'async-retry'
import {useLoggerEffect} from '../../../effects'

const wrappedGeocode = new GeocodeWrapper()

export default async function geocodeSites(file) {
  const logger = useLoggerEffect
  const results = []
  const failed = []
  let count = 0
  for (let row of file) {
    count = count + 1
    const [address, site_owner, site_contact, phone, email, system_installer, field_user] = row
    try {
      let retryCount = 0
      const geocode_results = await retry(
        async () => {
          if (retryCount > 0) {
            console.info('Retry ', address, retryCount)
          }
          retryCount++
          await new Promise((resolve) => setTimeout(resolve, 500))
          let result
          try {
            result = await wrappedGeocode.geocode(address)
            return result
          } catch (e) {
            console.error('Geocode failed:', e)
            if (e == 'ZERO_RESULTS') {
              return null
            } else {
              throw e
            }
          }
        },
        {retries: 10}
      )
      console.info('Geocoded: ', count, '/', file.length)

      if (geocode_results) {
        results.push({
          geocode_results,
          address,
          site_owner,
          site_contact,
          phone,
          email,
          system_installer,
          field_user,
        })
      } else {
        failed.push(address)
      }
    } catch (e) {
      // todo: display error on screen
      logger.log(`${address} failed to geocode`)
      console.log(e)
    }
  }

  if (failed.length > 0) {
    alert('The following addresses are not processed:\n' + failed.join(', \n'))
  }

  return results
}
