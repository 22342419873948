import React from 'react'

export default function ProjectOwner({owner}) {
  return (
    <div className="flex-column align-start background-white padding-2 margin-bottom-1" style={{fontAlign: `left`}}>
      <h4>Project Owner</h4>
      <div className="full-width flex-column align-start padding-1" style={{background: `rgba(249, 250, 251, 0.7)`}}>
        {owner}
      </div>
    </div>
  )
}
