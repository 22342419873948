import React from 'react';
import { Tabs } from 'antd';
import { StickyContainer } from 'react-sticky';
import Section from './Section';
import { connect } from 'react-redux';
import { setActive, highlightErrors } from './action_creators';
import { TabBar } from './SectionsTabBar';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { saveIntake } from './utilities';

const mapStateToProps = (state) => ({
  active: state.active.section,
  project: state.project,
});

const mapDispatchToProps = (dispatch) => ({
  setActive: (e) => {
    dispatch(setActive({ name: 'section', value: e }));
  },
  highlightErrors: (errors) => dispatch(highlightErrors(errors || {})),
});

export function Sections({ active, setActive, project, highlightErrors }) {
  const handlePrevClick = () => {
    const currentIndex = project.intake.order.indexOf(active);
    if (currentIndex > 0) {
      const prevTabKey = project.intake.order[currentIndex - 1];
      setActive(prevTabKey);
    }
  };

  const handleNextClick = async () => {
    const currentIndex = project.intake.order.indexOf(active);
    
    // Save the current section before moving to the next one
    await saveIntake();  // Ensure this captures data from the current section
  
    if (currentIndex < project.intake.order.length - 1) {
      const nextTabKey = project.intake.order[currentIndex + 1];
      setActive(nextTabKey);
    }
  };
  

  return (
    <StickyContainer data-cy="intakeSections">
      <Tabs
        renderTabBar={(props, Default) => <TabBar {...{ props, Default, highlightErrors, project }} />}
        activeKey={active}
        defaultActiveKey="0"
        onChange={setActive}
        tabBarStyle={{ borderBottom: 'none' }} // Custom style to remove bottom border
      >
        {project.intake.order.map((k, index) => (
          <Tabs.TabPane
            key={k}
            tab={<span style={{ fontWeight: 'bold' }}>{project.intake.sections[k].title}</span>}
          >
            <div className="flex-row align-center justify-space-between" style={{ marginBottom: '1rem' }}>
              <button
                type="button"
                className={`ant-btn ant-btn-primary ${index === 0 ? 'd-none' : ''}`}
                onClick={handlePrevClick}
                style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem', color: 'white', gap: '0.5rem' }}
              >
                <LeftOutlined style={{ color: 'white', fontSize: '1.2rem' }} />
                Previous
              </button>
              <h3 className="bold flex-grow-1 text-center" style={{ fontSize: '1.5rem', margin: 0 }}>
                {project.intake.sections[k].title}
              </h3>
              <button
                type="button"
                className={`ant-btn ant-btn-primary ${
                  active === project.intake.order[project.intake.order.length - 1] ? 'd-none' : ''
                }`}
                onClick={handleNextClick}
                style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem', color: 'white', gap: '0.5rem' }}
              >
                Next
                <RightOutlined style={{ color: 'white', fontSize: '1.2rem' }} />
              </button>
            </div>
            <Section section={project.intake.sections[k]} />
            <div className="flex-row align-center justify-space-between" style={{ marginTop: '1rem' }}>
              <button
                type="button"
                className={`ant-btn ant-btn-primary ${index === 0 ? 'd-none' : ''}`}
                onClick={handlePrevClick}
                style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem', color: 'white', gap: '0.5rem' }}
              >
                <LeftOutlined style={{ color: 'white', fontSize: '1.2rem' }} />
                Previous
              </button>
              <h3 className="bold flex-grow-1 text-center"></h3>
              <button
                type="button"
                className={`ant-btn ant-btn-primary ${
                  active === project.intake.order[project.intake.order.length - 1] ? 'd-none' : ''
                }`}
                onClick={handleNextClick}
                style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem', color: 'white', gap: '0.5rem' }}
              >
                Next
                <RightOutlined style={{ color: 'white', fontSize: '1.2rem' }} />
              </button>
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </StickyContainer>
  );
}

const WrappedSections = connect(mapStateToProps, mapDispatchToProps)(Sections);
export default WrappedSections;
