import {store} from '../../utilities'
import {useEffect} from 'react'
import {setDropdowns} from '../../action_creators'

export default function useUserEffect(subscribe, subscribeResources) {
  useEffect(function () {
    return subscribe(
      {
        paths: [{collection: 'users'}],
        options: {where: [{field: 'type', test: 'in', value: ['field-user', 'installer']}]},
      },
      'users'
    )
  }, [])

  useEffect(
    function () {
      store.dispatch(setDropdowns('users', subscribeResources.users))
    },
    [subscribeResources.users]
  )
}
