import React from 'react'
import {Card, Icon} from 'antd'
import {capitalizeFirst} from '../utilities'
import './Forms.css'

export default function NoContent({parent, child, childs}) {
  let copy = `Drag a ${capitalizeFirst(child)} type from the side bar on the right to get started.`

  if (capitalizeFirst(parent) == 'Form') {
    copy = 'Use the `+` icon in the upper right to start a new section'
  }

  return (
    <Card style={{textAlign: `center`}}>
      <div style={{background: `#FBFBFB`, border: `0.1rem dashed #C4CDD5`, padding: `5rem`}}>
        <Icon
          type="form"
          style={{
            fontSize: `2.5rem`,
            background: `#C4CDD5`,
            color: `#FFF`,
            padding: `2rem`,
            borderRadius: `5rem`,
            margin: `3rem`,
          }}
        />
        <h3>
          This {capitalizeFirst(parent)} Does Not Have Any {capitalizeFirst(childs)}
        </h3>
        <p>{copy}</p>
      </div>
    </Card>
  )
}
