import React, {useState, useEffect} from 'react'
import {Button, Modal} from 'antd'
import CancelProjectModal from './CancelProjectModal'
import DB from '../DAL/DB'
import AddProjectButton from '../ProjectBuilder/ProjectCreator/AddProjectButton'
import {auth} from '../Auth'
import {history} from '../history'
import {devChecker} from '../effects'

const projects = new DB.Projects()

export default function Header({id, status, organization, allowCreateProject}) {
  const [cancel, setCancel] = useState(false)
  const [dev, setDev] = useState(false)
  const [userType, setUserType] = useState(''); 

  useEffect(devChecker(auth.sunkaizenUser.id, setDev), [auth.sunkaizenUser.id])

  async function success() {
    console.log('yay success')
    await projects.updatebatch(id, null, {status: 'To Be Scheduled', updatedBy: auth?.sunkaizenUser?.id, updatedByName: auth?.sunkaizenUser?.name })
  }
  
  useEffect(() => {
    setUserType(auth.sunkaizenUser?.type || ''); 
  }, []);

  let isAdmin = ['admin', 'super-admin'].includes((auth.sunkaizenUser || {}).type)
  const isRequested = status === 'Requested'

  if (['client'].includes((auth.sunkaizenUser || {}).type)) {
    isAdmin = auth.sunkaizenUser.organization.id === organization
  }


  return (
    <div>
      <div className="flex-row padding-right-2 padding-bottom-1 justify-space-between align-center">
        <h1 className="page-header">Project Details</h1>
        {dev && <Button onClick={() => history.push(`/projects/${id}/logs`)}>Review Project Logs</Button>}
        {isRequested && auth.sunkaizenUser.organization.id === organization && isAdmin && (
          <div className="flex-row">
            <AddProjectButton
              type="primary"
              success={[success]}
              id={id}
              size="large"
              title="Accept Project"
              className="margin-right-2"
              allowCreateProject={allowCreateProject}
              />
            <Button size="large" onClick={() => setCancel(true)}>
              Reject Project
            </Button>
          </div>
        )}
        <div className="flex-row justify-space-between align-center">

        <div  className="padding-right-2 padding-left-1">
          {userType=='field-user' && allowCreateProject && <AddProjectButton allowCreateProject={allowCreateProject}id={id} size="large" title="Create a Project like this" />}
          </div>
        {!isRequested && isAdmin && <AddProjectButton id={id} size="large" title="Edit" allowCreateProject={allowCreateProject} />}
        </div>
      </div>
      <Modal
        title={
          <div>
            <div>Reject Project</div>
            <div style={{fontSize: '1rem', fontWeight: 'normal', color: 'grey'}}>
              Warning: Rejecting this project will cancel it
            </div>
          </div>
        }
        visible={cancel}
        okText="Reject Project"
        onOk={() => setCancel(false)}
        onCancel={() => setCancel(false)}
        footer={null}
      >
        <CancelProjectModal id={id} done={() => setCancel(false)} />
      </Modal>
    </div>
  )
}
