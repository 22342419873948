import Create from './Create'
import onOk from './onOk'
import Button from './ModalOpenButton'
import disabled from './okButtonDisabled'

const configuration = {
  title: 'Create Ticket',
  ok: onOk,
  okText: 'Create',
  okButtonDisabled: disabled,
  view: Create,
  type: 'create',
  button: Button,
}

export default configuration
