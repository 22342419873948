export function makeQualifications(forms) {
  const qualifications = {}
  forms.forEach((form) => {
    qualifications[form.id] = {name: form.title || '', id: form.id}
  })

  return qualifications
}

export function makeClients(users) {
  const clients = {}
  for (let user of users) {
    if (user.type !== 'client') continue
    clients[user.id] = {name: user.name, id: user.id}
  }

  return clients
}
