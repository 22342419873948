import {useEffect, useContext} from 'react'
import {ReducerContext} from '../../reducer'

export default function useSubscribeResources(subscribeResources) {
  const {dispatch} = useContext(ReducerContext)

  useEffect(
    function () {
      for (let k in subscribeResources) {
        const subscribeMap = {}
        for (let r of subscribeResources[k]) {
          subscribeMap[r.id] = r
        }

        if (k === 'availability') {
          console.log(subscribeResources[k])
          console.log(k, subscribeMap)
        }

        dispatch({type: k, value: subscribeMap})
      }
    },
    [subscribeResources]
  )
}
