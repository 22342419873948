import React, {useReducer} from 'react'
import AddScoringSystem from './AddScoringSystem'

const initialState = {
  show: false,
  title: 'Create New Scoring System',
  name: '',
  percentOverall: null,
  criticalities: [],
  id: null,
}
function reducer(state, action) {
  switch (action.type) {
    case 'show':
      return {...state, show: action.value}
    case 'merge':
      delete action.type
      return {...state, ...action.value}
    case 'title':
      return {...state, title: action.value}
    case 'clear':
      return {...state, name: '', percentOverall: null, criticalities: [], id: null}
    case 'loadRows':
      return {...state, criticalities: action.value}
    case 'name':
      return {...state, name: action.value}
    case 'percentOverall':
      return {...state, percentOverall: action.value}
    case 'addRow':
      const c = [...state.criticalities]
      if (c.length < 10) c.push({name: '', limit: false, value: null})
      return {...state, criticalities: c}
    case 'changeRow':
      const d = [...state.criticalities]
      d[action.index] = action.value
      return {...state, criticalities: d}
    case 'deleteRow':
      const e = [...state.criticalities]
      e.splice(action.value, 1)
      return {...state, criticalities: e}
    default:
      return state
  }
}

export const ReducerContext = React.createContext(null)

export default function Provider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ReducerContext.Provider value={{state, dispatch}}>
      <AddScoringSystem {...props} />
    </ReducerContext.Provider>
  )
}
