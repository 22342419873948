import React, {useState, useEffect} from 'react'
import {auth} from './Auth'
import {Spin} from 'antd'

function checkType(type, user) {
  return type ? type.includes(user.type) : user
}
function checkOrganization(organization, user, shared) {
  if (!organization || shared.includes(user.type) || user.organization.id === organization) return true
  else return false
}

export default function CheckRegistration({Component, type, shared = [], ...rest}) {
  const {history, match} = rest

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(
    function () {
      return auth.sunkaizenUserSubscribe((u) => {
        if (!auth.loadingSolarFIT && !u) {
          if (auth.currentUser) history.push('/register')
          else history.push('/login')
        }

        setUser(u)
        setLoading(false)
      })
    },
    [auth.sunkaizenUser]
  )

  return (
    <div className="full-width full-height">
      {loading && <Spin size="large" />}
      {user && (
        <div>
          {checkType(type, user) && checkOrganization(match.params.organization, user, shared) ? (
            <Component {...rest} />
          ) : (
            <h1 className="page-header padding-3">You are not authorized to view this page</h1>
          )}
        </div>
      )}
    </div>
  )
}
