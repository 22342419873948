import React from 'react'
import Chart from './Chart'

function insertDelimiterAt(text, delimAt = 35, delimiter = `<br>`) {
  let textWords = text.split(` `)

  let charsSinceLastDelim = 0
  let index = 0
  while (index < textWords.length) {
    let word = textWords[index]
    charsSinceLastDelim += word.length

    if (charsSinceLastDelim >= delimAt) {
      charsSinceLastDelim = 0
      textWords.splice(index, 0, delimiter)
    }

    index++
  }

  return textWords.join(` `)
}

export default function ParetoChart({data = {}, layout = {height: 600, width: 1200}}) {
  const entries = Object.entries(data)

  entries.sort((a, b) => {
    if (a[1] === b[1]) return 0
    else if (a[1] < b[1]) return 1
    else return -1
  })

  let x = []
  const y = []

  for (let [xVal, yVal] of entries) {
    x.push(xVal)
    y.push(yVal)
  }

  //Make the labels look good
  x = x.map((label) => insertDelimiterAt(label))

  const total = y.reduce((a, c) => a + c, 0)
  let intermediate = 0
  const percentages = y.map((v) => {
    intermediate = intermediate + v
    return (intermediate / total) * 100
  })

  const graphData = [
    {
      x,
      y,
      yaxis: `y2`,
      type: `bar`,
    },
    {
      x,
      y: percentages,
      type: `scatter`,
    },
  ]

  const graphLayout = {
    grid: {
      rows: 2,
      columns: 1,
      pattern: `coupled`,
    },
    yaxis: {
      title: `Percentage of Failures`,
      side: `right`,
    },
    yaxis2: {
      title: `Number of Failures`,
    },
    xaxis: {
      tickangle: 0,
    },
    showlegend: false,
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    ...layout,
  }

  return <Chart options={graphData} layout={graphLayout} style={{margin: 'auto'}} />
}
