import React from 'react'
import {ScheduleInspectionModal} from '../../ScheduleInspections'
import {Icon} from 'antd'
import {auth} from '../../Auth'
import NotScheduledFormattingWrapper from './NotScheduleFormattingWrapper'

export default function NotScheduled({id, organizationId, inspector = {}, client}) {
  function showButton() {
    let show = false
    if (!inspector) return show
    // installers and QMs cannot schedule, ever
    if (['installer', 'quality-manager'].includes(auth.sunkaizenUser.type)) return show

    // the inspector for the project can schedule
    if (auth.sunkaizenUser.id === inspector.id) show = true
    // if the logged-in user shares an organization with the project, and there's either no inspector, or the inspector shares an organization with them, they can schedule
    if (
      inspector.organization &&
      auth.sunkaizenUser.organization.id === organizationId &&
      inspector.organization.id === organizationId
    )
      show = true
    // if the logged-in user shares the project inspector's organization, they can schedule
    if (inspector.organization && auth.sunkaizenUser.organization.id === inspector.organization.id) show = true
    // if the logged-in user is the client, and the project is self-inspected, they can schedule
    if (client.id === auth.sunkaizenUser.organization.id && client.id === inspector.id) show = true

    return show
  }

  const showScheduleIntakeButton = showButton()

  return (
    <div className="full-width flex-row padding-1 margin-bottom-1">
      <NotScheduledFormattingWrapper>
        <div style={{padding: '0 0 1.5rem 0'}}>
          <div
            className="padding-2 flex-row align-center justify-center"
            style={{width: '5rem', height: '5rem', background: '#C4CDD5', borderRadius: '50%'}}
          >
            <Icon className="color-white" type="calendar" />
          </div>
        </div>
        {showScheduleIntakeButton && (
          <div className="flex-column align-center">
            <div style={{padding: '0 0 0.5rem 0'}}>You Have Not Scheduled An Intake for this Project</div>
            <div style={{color: '#8A8C8F', padding: '0 0 1.5rem 0'}}>
              During scheduling you will find available inspectors
            </div>
            <ScheduleInspectionModal
              style={{backgroundColor: '#6DBE44', color: 'white', border: '0.1rem solid #4B9F21'}}
              id={id}
            >
              Schedule Intake
            </ScheduleInspectionModal>
          </div>
        )}
      </NotScheduledFormattingWrapper>
    </div>
  )
}
