import React from 'react'
import {Button} from 'antd'
import DeleteButton from '../../DeleteButton'
import {FieldType} from '../FieldTypes'
import {connect} from 'react-redux'
import {activate, deleteField, modify, edit} from '../action_creators'
import {duplicateField, FieldDetail} from '.'

const stop = (state) => ({
  editing: state.editing,
  selected: state.active.field,
  activeSection: state.active.section,
  section: state.sections[state.active.section],
  sections: state.sections,
  rubrics: state.rubrics
})
const dtop = (dispatch) => ({
  modify: (type) => (f, v) => dispatch(modify(type)(f, v)),
  activate: (k, v) => dispatch(activate(k, v)),
  edit: (value) => dispatch(edit(value)),
  deleteField: (f, k) => dispatch(deleteField(f, k)),
})

export function FormItem(props) {
  // inherited
  const {loading, setLoading, id = '', type, fields} = props
  // stop
  const {editing, selected, section, activeSection, sections, rubrics} = props
  // dtop
  const {edit, activate, deleteField, modify} = props

  const disabled = loading === id

  async function duplicate() {
    setLoading(id)
    try {
      duplicateField(id)
    } catch (e) {
      console.log('an error occurred while duplicating a field', e)
    } finally {
      setLoading(null)
    }
  }
  function fieldCheckInSection(id) {
    let fieldExist = false
    Object.keys(sections).forEach(secRec => {
      if(!fieldExist){
        const {order} = sections[secRec]
        if(order.find(x => x === id)){
          fieldExist = true
        }
       }
    })
    return fieldExist
  }
  function deleter() {
    //delete the field from the fields object in state
    let recordFound = false;
    Object.keys(fields).forEach(rec => {
      if(!recordFound && rec !== id && fields[rec] && fields[rec].conditionGroup && fields[rec].conditionGroup.cgList){
        const cgList = fields[rec].conditionGroup.cgList
        cgList.forEach(x => {
          if(x.conditionList && x.conditionList.length > 0 && 
            x.conditionList.find(cc => cc.selectedQuestion === id)) {
              // checking additional condition - does field is active in sections
              if(fieldCheckInSection(rec)){
                recordFound = true;
            }
          }
        })
      }
    
    if(!recordFound) {
        const {rubrics: formRubrics} = rubrics
        if(formRubrics && Object.keys(formRubrics).length > 0) {
          // Rubrics present for this form
          Object.keys(formRubrics).forEach(frRec => {
            const {questions: formRubricQuestions } = formRubrics[frRec]
            if(formRubricQuestions && Object.keys(formRubricQuestions).length > 0) {
              Object.keys(formRubricQuestions).forEach(frqRec => {
                const {OptionActions: formRubricOptionActions} = formRubricQuestions[frqRec]
                if(formRubricOptionActions && Object.keys(formRubricOptionActions).length > 0) {
                  Object.keys(formRubricOptionActions).forEach(froARec => {
                    const frOAData = formRubricOptionActions[froARec]
                    if(frOAData && frOAData.length > 0) {
                      frOAData.forEach(froADRec => {
                        const {conditionGroup} = froADRec
                        if(conditionGroup && conditionGroup.cgList){
                          const cgList = conditionGroup.cgList
                          cgList.forEach(x => {
                            if(x.conditionList && x.conditionList.length > 0 && 
                              x.conditionList.find(cc => cc.selectedQuestion === id)) {
                                  recordFound = true;
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }
     }
  })

    if(recordFound) {
      alert('unable to delete the question, as this question is dependent to other question.')
    } else {
    deleteField('field', id)
    //Remove the field from the order array of the current section
    let sectionCopy = {...section}
    let fieldOrder = [...sectionCopy.order]
    fieldOrder.splice(fieldOrder.indexOf(id), 1)
    sectionCopy.order = fieldOrder

    modify('section')(activeSection, sectionCopy)
    //Set active field to null
    activate('field', null)
    }
  }

  const deleteProps = {
    confirmationDisplay: 'Delete?',
    icon: 'delete',
    size: 'large',
    title: 'Delete',
    type: 'secondary',
    onConfirm: deleter,
  }

  return (
    <div
      className={`flex-row padding-2 margin-top-2 ${selected === id ? ' formBuilderItemSelected' : ''}`}
      data-cy="formItem"
      // this blocks the field-edit closing function that's on TopLevelState
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {editing === id && <FieldDetail />}
      {editing !== id && (
        <div id={id} className="flex-row justify-space-between full-width" onClick={() => activate('field', id)}>
          <FieldType type={type} id={id} />
          <div className="flex-column">
            <Button
              title="Edit"
              icon="edit"
              size="large"
              onClick={() => {
                edit(id)
              }}
            />
            <Button disabled={disabled} title="Duplicate" onClick={duplicate} icon="copy" size="large" />
            <DeleteButton {...deleteProps} />
          </div>
        </div>
      )}
    </div>
  )
}

const WrappedFormItem = connect(stop, dtop)(FormItem)
export default WrappedFormItem
