import React, {useEffect, useContext} from 'react'
import './Analytics.scss'

export default function NoData() {
  return (
    <div
      style={{
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '10rem',
        color: 'gray',
      }}
    >
      No Data
    </div>
  )
}
