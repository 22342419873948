import React from 'react'
import ConditionGroup from './ConditionGroup'
import { Select } from 'antd'

const ConditionGroups = ({
  cgList,
  fields,
  id,
  order,
  sections,
  field,
  conditionQuestions,
  selectedOperand,
  handleConditionQuestionSelection,
  handleDeleteConditionGroup,
  updateConditionList,
  handleMustBeTrueCondition,
  handleGroupCondtionValue,
  isRubric
}) => {
  return cgList
    .map((rec, index) => (
      <React.Fragment key={`conditonListG-${cgList.length}-${index}`}>
        <ConditionGroup
          key={`action-${rec.id}`}
          currentIndex={index + 1}
          condition={rec}
          fields={fields}
          id={id}
          order={order}
          sections={sections}
          field={field}
          conditionQuestions={conditionQuestions}
          handleConditionQuestionSelection={handleConditionQuestionSelection}
          handleDeleteConditionGroup={handleDeleteConditionGroup}
          updateConditionList={updateConditionList}
          handleMustBeTrueCondition={handleMustBeTrueCondition}
          cgList={cgList}
          isRubric={isRubric}
        />
        {cgList.length > 1 && cgList.length !== index + 1 &&
          <Select
           key={`condition-${index}-${selectedOperand}`}
           defaultValue={`${selectedOperand || 'and' }`}
           className='condition-group-add-cond' 
           onChange={handleGroupCondtionValue}
          >
            <Select.Option value={`or`}>{`OR`}</Select.Option>
            <Select.Option value={`and`}>{`AND`}</Select.Option>
          </Select> 
        }
      </React.Fragment>
    ))
}

export default ConditionGroups
