import {auth} from '../../Auth'

export function organizationFilter(query) {
  query.filters.push({match: {'organization.id': auth.sunkaizenUser.organization.id}})
  return query
}

export function requestFilter(query) {
  query.filters.push({match: {status: 'Requested'}})
  query.type = 'projects'
  return query
}

export function inspectionsFilter(query) {
  query.type = 'projects'
}

export function clientFilter(query) {
  query.filters.push({match: {'client.id': auth.sunkaizenUser.client.id}})
  return query
}
