import React, {useContext, useState, useEffect} from 'react'
import {ReducerContext} from './reducer'
import CalendarEntry from './CalendarEntry'
import {toFirebaseTimestamp} from './utilities'
import {auth} from '../Auth'
import moment from 'moment'
import {Modal, DatePicker, Button} from 'antd'

export default function CalendarAvailability() {
  const {state, dispatch} = useContext(ReducerContext)

  const [show, setShow] = useState(false)
  const [start, setStart] = useState(moment().hour(9).minute(0))
  const [end, setEnd] = useState(moment().hour(17).minute(0))

  useEffect(() => {
    if (!state.event.start) return
    const d = state.event.start.getDate()
    setStart(moment().date(d).hours(9).minutes(0).seconds(0))
    setEnd(moment().date(d).hours(17).minutes(0).seconds(0))
  }, [state.event.start])

  async function confirm() {
    const {startTimestamp: date, endTimestamp: endDate, duration} = toFirebaseTimestamp(start, end)

    const calendarEntry = new CalendarEntry({date, endDate, duration, type: 'Availability'})
    calendarEntry.build({user: auth.sunkaizenUser})
    await calendarEntry.commit()

    dispatch({type: 'selected', value: calendarEntry.id})
    setShow(false)
  }

  return (
    <div>
      {auth.sunkaizenUser.type === 'field-user' && (
        <div className={'calendar-button-menu'}>
          <Button
            icon="plus-circle"
            size="large"
            title={auth.sunkaizenUser.type === 'field-user' ? '' : 'You must be a Field User to create an event'}
            disabled={!auth.sunkaizenUser === 'field-user'}
            onClick={() => setShow(true)}
          >
            Add Availability
          </Button>
        </div>
      )}
      <Modal className="calendar" visible={show} onCancel={() => setShow(false)} onOk={confirm} okText="Add Event">
        <DatePicker.RangePicker
          dropdownClassName="calendar" // CSS overrides omit the unneeded seconds dropdown
          showTime
          format="MMM DD hh:mm a"
          value={[moment(start), moment(end)]}
          onChange={([start, end]) => {
            setStart(start)
            setEnd(end)
          }}
        />
      </Modal>
    </div>
  )
}
