import {useEffect, useContext} from 'react'
import DB from '../../DAL/DB'
import {useDBSubscribe} from '../../effects'
import {auth} from '../../Auth'
import {ReducerContext} from '../reducer'

const organizations = new DB.Organizations()

// Get all orgs
export default function useAllOrgs() {
  const {dispatch} = useContext(ReducerContext)

  const orgs = useDBSubscribe(organizations)
  useEffect(
    function () {
      const oMap = {}
      for (let o of orgs) {
        oMap[o.id] = o
      }
      const userOrg = oMap[auth.sunkaizenUser.organization.id]

      dispatch({type: 'organization', value: userOrg})
      dispatch({type: 'allOrgs', value: orgs})
    },
    [orgs]
  )
}
