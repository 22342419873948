import {auth} from '../../../Auth'

export default function permissions() {
  if (auth && auth.sunkaizenUser) {
    let permissions
    let t = auth.sunkaizenUser.type.slice()

    if (!['super-admin', 'admin', 'client'].includes(t)) {
      permissions = [{match: {[`recipient`]: auth.sunkaizenUser.id}}]
    }

    return permissions
  } else return
}
