import React from 'react'
import {Select} from 'antd'
import _ from 'lodash'

export function GenericSelector({
  values = [],
  label = undefined,
  selectedValue,
  valueTitle,
  placeholder,
  onChange,
  className,
  hidden = false,
  disabled = false,
}) {
  let visibility = 'visible'
  if (hidden == true) {
    visibility = 'hidden'
  }

  let returnDiv
  if (hidden) returnDiv = <div style={{flex: 0}}></div>
  else {
    returnDiv = (
      <div style={{display: 'flex', flexDirection: `row`, flexWrap: 'nowrap', flex: 1}}>
        <div className="selector-label">{label ? `${label} :` : ``}</div>
        <Select
          className={`selector ` + className || ``}
          value={selectedValue}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        >
          {values &&
            values.map((value) => (
              <Select.Option value={value.id} key={value.id}>
                {_.isFunction(valueTitle) ? valueTitle(value) : value[valueTitle]}
              </Select.Option>
            ))}
        </Select>
      </div>
    )
  }

  return returnDiv
}
