const setLoaded = (sl) => (e) => {
  if (e.key === `googleMapsLoaded` && e.newValue === `true`) {
    sl(true)
  }
}

export default function mapsLoaded(setLoading) {
  const sl = setLoaded(setLoading)
  return function () {
    if (!window.google || !window.google.maps) {
      setLoading(true)
      window.addEventListener(`storage`, sl)
    }

    window.removeEventListener('storage', sl)
    setLoading(false)
  }
}
