import {store} from '../reducer'

export default function change(rId, question, option, value, isOptionActions) {
  if (!question) return

  const {rubrics} = store.getState()
  const rubric = rubrics[rId]
  
  if(isOptionActions){
    const q = rubric.questions[question.id]  || {options: {}, OptionActions: {}}
    if(!q.OptionActions) {
      q.OptionActions = {};
    }
    q.OptionActions[option] = value
    store.dispatch({type: 'rubricQuestion', id: rubric.id, value: q, question: question.id})
  } else {
    const q = rubric.questions[question.id] || {options: {}, OptionActions: {}}
    q.options[option] = value
    if(!q.OptionActions) {
      q.OptionActions = {};
    }
    store.dispatch({type: 'rubricQuestion', id: rubric.id, value: q, question: question.id})
  }
}

export function handleChangeRubricActions(rId, question, option, value) {
  if (!question) return

  const {rubrics} = store.getState()
  const rubric = rubrics[rId]

  const q = rubric.questions[question.id] || {selectedActions: {}}
  if(!q.selectedActions) {
    q.selectedActions = {};
  }
  q.selectedActions[option] = value

  store.dispatch({type: 'rubricQuestionActions', id: rubric.id, value: q, question: question.id})
}
