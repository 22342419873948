import React, {useContext, useEffect} from 'react'
import {ReducerContext} from './Provider'
import DecoratedInput from './DecoratedInput'
import CriticalityRow from './CriticalityRow'
import {Button} from 'antd'

import './styles.css'

export default function InputForm({type}) {
  const {state, dispatch} = useContext(ReducerContext)

  useEffect(
    function () {
      if (Number(state.percentOverall) < 0) dispatch({type: 'percentOverall', value: 0})
      if (Number(state.percentOverall) > 100) dispatch({type: 'percentOverall', value: 100})
    },
    [state.percentOverall]
  )

  const disabled = type !== 'create'

  return (
    <div className="grid-container">
      <div className="name">
        <DecoratedInput
          disabled={disabled}
          title="Name"
          value={state.name}
          change={(e) => dispatch({type: 'name', value: e.target.value})}
        />
      </div>
      <div className="percent">
        <DecoratedInput
          disabled={disabled}
          title="Percent overall required to pass (Optional)"
          value={state.percentOverall}
          type="number"
          change={(e) => dispatch({type: 'percentOverall', value: e.target.value})}
        />
      </div>
      <div className="blank" />
      <div className="table-header-value bold">Value</div>
      <div className="table-header-failures bold">Number of Failures Permitted</div>
      <div className="field-rows">
        {state.criticalities.map((criticality, i) => (
          <CriticalityRow {...{key: i, i, disabled, ...criticality}} />
        ))}
      </div>
      {!disabled && (
        <div className="add-row-button">
          <Button size="large" onClick={() => dispatch({type: 'addRow'})} data-cy="addValueBtn">
            Add Value
          </Button>
        </div>
      )}
    </div>
  )
}
