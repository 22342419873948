import React, {useReducer} from 'react'
import {deepCopy} from './utilities/deepCopy'

const filterTypes = {
  completed: {type: 'completed', name: 'Date Completed', options: [], instances: ['all']},
  canceled: {type: 'canceled', name: 'Date Canceled', options: [], instances: ['all']},
  client: {type: 'client', name: 'Client', options: [], instances: ['all']},
}

const initialState = {
  users: {},
  forms: {},
  statuses: {},
  filters: {},
  subscriptions: [],
  filterTypes: deepCopy(filterTypes),
  presetFilters: {},
  includeIntakeComments: false,
  reportType: 'normal'
}

function reducer(state, action) {
  switch (action.type) {
    case 'users':
      return {...state, users: action.value}
    case 'forms':
      return {...state, forms: action.value}
    case 'statuses':
      const statuses = {...state.statuses}
      statuses.instances = action.value
      return {...state, statuses}
    case 'filters':
      return {...state, filters: action.value}
    case 'subscriptions':
      return {...state, subscriptions: action.value}
    case 'filterTypes':
      return {...state, filterTypes: action.value}
    case 'includeIntakeData':
      return {...state, includeIntakeData: action.value}
    case 'includeIntakeComments':
      return {...state, includeIntakeComments: action.value}
    case 'reportType':
      return {...state, reportType: action.value}
    case 'clearState':
      //console.info('Clear CSV Report state')
      const ft = state.filterTypes
      for (let f in ft) {
        ft[f].instances = ['all']
      }
      return {...state, filterTypes: ft}
    default:
      return state
  }
}

export const ReducerContext = React.createContext(null)
export const wrap = (C) => (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ReducerContext.Provider value={{state, dispatch}}>
      <C {...props} />
    </ReducerContext.Provider>
  )
}
