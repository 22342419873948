export function cancelProject(project, cancellation) {
  const cancellations = [].concat(project.cancellations, cancellation).filter((i) => i)
  project.cancellations = cancellations
  project.status = 'Canceled'
  project.updated = cancellation.canceledAt
}

export function requestCancelProject(project, cancellation) {
  const cancellations = [].concat(project.cancellations, cancellation).filter((i) => i)
  project.cancellations = cancellations
  project.status = 'Cancel Requested'
  project.updated = cancellation.canceledAt
}
