import {auth} from '../../../Auth'

export default function or() {
  const should = [{match: {watchers: auth.sunkaizenUser.id}}]

  if (['admin', 'super-admin', 'client'].includes(auth.sunkaizenUser.type)) {
    should.push({term: {'organization.id.sortable': auth.sunkaizenUser.organization.id}})
  }

  return should
}
