import {store} from './utilities'
// import {firebase} from "../utilities"
// import {FULL_TEXT_SEARCH_URL} from "../config"
import strategyMap from './strategies'
import {onChange} from './onChange'

const queryEnum = {
  size: true,
  from: true,
  total: true,
  status: true,
  query_strings: true,
  filters: true,
  fullTextQuery: true,
  updateQuery: true,
}

export const setQueryParams = (query) => {
  for (const t in query) {
    if (!queryEnum[t]) throw new Error(`this is not a valid field: ${t}`)
  }
  return {type: `updateQuery`, query}
}

const tableControlsEnum = {filters: true, sorter: true, pagination: true, loading: true, lock: true}
export const setTableControls = (tableControls) => {
  store.getState()

  for (const t in tableControls) {
    if (!tableControlsEnum[t]) throw new Error(`this is not a valid field: ${t}`)
  }

  return {type: `updateTableControls`, tableControls}
}

export const pruneResource = (id) => ({type: `prune`, id})
export const setResources = (resources) => ({type: 'resources', resources})

export const setTabs = (tabs) => ({type: 'tabs', tabs})

export const setLoading = (bool) => ({type: 'loading', bool})

export const select = (status) => (dispatch) => {
  const state = store.getState()
  if (status !== state.status) {
    dispatch({type: `status`, value: status})
    dispatch({type: `from`, value: 0})
    onChange(state.tableControls)
  }
}

export const clearQuery = () =>
  setQueryParams({from: 0, total: 0, status: `All`, query_strings: [], filters: [], fullTextQuery: ``})

export function filterBarSelect(status) {
  return {type: 'status', value: status}
}

export function lockQueue(bool) {
  return {type: 'loading', bool}
}
export function setLock(lock) {
  return {type: 'lock', lock}
}

const strategiesEnum = {
  none: true,
  projects: true,
  tickets: true,
  inspections: true,
  requests: true,
  users: true,
  forms: true,
  authorizations: true,
  reports: true,
  projectTransactionLog: true,
}
export function setStrategy(strategy) {
  if (strategiesEnum[strategy]) {
    const tableStrategy = strategyMap[strategy]
    return {type: 'tableStrategy', tableStrategy}
  } else throw new Error('attempted to instantiate table with invalid strategy', strategy)
}

export function configure(configuration) {
  return {type: 'tableStrategy', tableStrategy: configuration}
}

export function setPopup(record, x, y) {
  return {type: 'setPopup', value: {record, x, y}}
}
