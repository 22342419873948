import React, {useEffect, useState, useContext} from 'react'
import {ReducerContext} from '../../reducer'
import FieldUserBrief from '../FieldUserBrief'
import {updateDirections} from '../../utilities'
import Availability from './Availability'
import Intakes from './Intakes'

export default function SelectedFieldUser({inspector}) {
  const {state} = useContext(ReducerContext)
  const [directionsLibraries, setDirectionsLibraries] = useState({})
  const [selectedProject, setSelectedProject] = useState({})

  // map effects
  useEffect(function () {
    const directionsService = new window.google.maps.DirectionsService()
    const directionsRenderer = new window.google.maps.DirectionsRenderer()
    directionsRenderer.setMap(state.mapping.map)

    setDirectionsLibraries({directionsService, directionsRenderer})
  }, [])
  useEffect(updateDirections(selectedProject, state, directionsLibraries), [selectedProject])

  return (
    <div className="flex-column align-start">
      <FieldUserBrief inspector={inspector} />
      {/* <div className="bold padding-bottom-1 padding-top-1">Schedule</div> */}
      <Availability />
      <Intakes {...{directionsLibraries, setSelectedProject, selectedProject}} />
    </div>
  )
}
