import React, {useState, useEffect} from 'react'
import {Input} from 'antd'
import validationEffect from './validationEffect'

export default function Description({description, change, setValid}) {
  const [error, setError] = useState(null)
  const [validationTimeout, setValidationTimeout] = useState(0)

  useEffect(
    validationEffect({
      validate: () => !description || (description.length && description.length > 2),
      setErrorMessage: () => setError('The description must contain at least 3 characters'),
      clearErrorMessage: () => {
        if (error) setError(null)
      },
      validationTimeout,
      setValidationTimeout,
      setValid,
    }),
    [description]
  )

  return (
    <div>
      <label>Description</label>
      {error && <div style={{color: 'red', background: 'white', borderRadius: '0.5rem'}}>{error}</div>}
      <Input.TextArea
        className="input-field"
        size="large"
        value={description}
        placeholder="Describe work required"
        onChange={(e) => change('description', e.target.value)}
        autoSize={{minRows: 5, maxRows: 12}}
      />
    </div>
  )
}
