import {createStore, combineReducers} from 'redux'

function rubrics(state = {}, action) {
  switch (action.type) {
    case 'rubrics':
      return action.value
    case 'rubric':
      return {...state, [action.id]: action.value}
    case 'rubricField':
      return {...state, [action.id]: {...state[action.id], [action.field]: action.value}}
    case 'enableRubricActions': 
      return {...state, ...action.value}
    case 'enableAutoRubricValues': 
       return action.value
    case 'rubricQuestion':
      console.log(action)

      state[action.id].questions[action.question] = action.value

      console.log(state[action.id].questions)

      return {...state}
     case 'rubricQuestionActions':
        state[action.id].questions[action.question] = action.value
        return {...state}
    default:
      return state
  }
}

function scoringSystems(state = {}, action) {
  switch (action.type) {
    case 'scoringSystems':
      return action.value
    case 'scoringSystem':
      return {...state, [action.id]: action.value}
    case 'scoringSystemField':
      return {...state, [action.id]: {...state[action.id], [action.field]: action.value}}
    default:
      return state
  }
}

function forms(state = {}, action) {
  switch (action.type) {
    case 'forms':
      return action.value
    // case 'form': return {...state, [action.id]: action.value}
    // case 'formField': return {...state, [action.id]: {...state[action.id], [action.field]: action.value}}
    default:
      return state
  }
}

function sections(state = {}, action) {
  switch (action.type) {
    case 'sections':
      return action.value
    case 'section':
      return {...state, [action.id]: {...state[action.id], ...action.value}}
    case 'sectionField':
      return {...state, [action.id]: {...state[action.id], [action.field]: action.value}}
    default:
      return state
  }
}

function form(state = {}, action) {
  switch (action.type) {
    case 'form':
      return action.value
    case 'formField':
      return {...state, [action.id]: action.value}
    case 'enableAutoRubricOption':
        return {...state, enableAutoRubric: action.value || false}
    default:
      return state
  }
}


function actionData(state = {actions: []}, action) {
  switch (action.type) {
    case 'actionData':
      return { ...state, actions: action.value }
    default:
      return state
  }
}

const reducer = combineReducers({rubrics, scoringSystems, forms, sections, form, actionData})

export const store = createStore(reducer)
