// Section.js

import React from 'react';
import { connect } from 'react-redux';
import Field from './Field';

const sToP = (state, ownProps) => ({
  fields: state.fields,
  section: ownProps.section,
});

const Section = ({ section, fields }) => (
  <div>
    <div>{section && (section.order || []).map((fId, i) => <Field key={i} fieldId={fId} />)}</div>
  </div>
);

const WrappedSection = connect(sToP)(Section);
export default WrappedSection;
