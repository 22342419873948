import React, {useEffect, useState} from 'react'
import {Spin} from 'antd'
import TopLevelState from './TopLevelState'
import {subscribe} from '../HOC/Firebase/SubscribeHOC'

function ResourceGetter({match, location, history, makeSubscription, subscribeResources}) {
  // TODO: move this to top level HOC?
  const [error, setError] = useState(null)

  const {id: doc} = match.params
  useEffect(
    function () {
      return makeSubscription({paths: [{collection: 'projects', doc}]}, 'projects', (err) => {
        console.info('makeSubscription error:', err.code)
        setError(err.code)
      })
    },
    [doc]
  )

  const [resource] = subscribeResources['projects'] || []

  if (error) {
    if (error == 'permission-denied') {
      return (
        <div className="padding-3">
          <h2>You do not have permission to manage this project.</h2>
        </div>
      )
    } else {
      return (
        <div className="padding-3">
          <h2>Something went wrong, please try reloading the page.</h2>
        </div>
      )
    }
  }

  return resource ? (
    <TopLevelState {...{resource, match, location, history}} />
  ) : (
    <div className="padding-3">
      <Spin size="large" />
    </div>
  )
}

const WrappedResourceGetter = subscribe()(ResourceGetter)
export default WrappedResourceGetter
