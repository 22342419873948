import React from 'react'
import {deleteSubscription, saveToDb} from '.'
import {Menu} from 'antd'

const dayMap = {
  daily: `Daily`,
  monthly: `Monthly`,
  0: `Sunday`,
  1: `Monday`,
  2: `Tuesday`,
  3: `Wednesday`,
  4: `Thursday`,
  5: `Friday`,
  6: `Saturday`,
}

export default function HeaderMenu({filter, filterSubscriptions}) {
  const f = saveToDb(filter)
  const subscriptions = {}
  filterSubscriptions.map((sub) => {
    if (sub.title === filter.name) subscriptions[sub.frequency] = sub
  })

  return (
    <Menu>
      {[`daily`, `monthly`, 0, 1, 2, 3, 4, 5, 6].map((frequency) => (
        <Menu.Item
          style={{backgroundColor: subscriptions[frequency] ? `lightgrey` : `initial`}}
          onClick={
            subscriptions[frequency] ? () => deleteSubscription(subscriptions[frequency].id) : () => f(frequency)
          }
        >
          {dayMap[frequency]}
        </Menu.Item>
      ))}
    </Menu>
  )
}
