import {useState, useEffect, useRef} from 'react'
import {fillAddress, getAddress} from '.'
import useAutocomplete from './useAutocomplete'

export default function useAddress({submit, full, old_address, onChange = () => null}) {
  const autocompleteRef = useRef(null)
  const [address, setAddress] = useState(undefined)

  function onChangeAddress(e) {
    setAddress(e.target.value)
  }

  function submitAddress(a) {
    if (a) {
      const [address, geocode_results] = getAddress(a, full)

      setAddress(address)
      return submit(geocode_results, true, address)
    }
    submit(a, false)
  }

  const autocomplete = useAutocomplete(autocompleteRef)

  useEffect(
    function () {
      if (!autocomplete) return
      autocomplete.addListener(`place_changed`, fillAddress(autocomplete, submitAddress))
    },
    [autocomplete]
  )

  useEffect(
    function () {
      setAddress(old_address)
    },
    [old_address]
  )
  useEffect(
    function () {
      onChange(address)
    },
    [address]
  )

  return [address, onChangeAddress, autocompleteRef]
}
