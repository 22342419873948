import {store} from '../utilities'
import query from './query'
import queryTabs from './queryTabs'

export default async function update() {
  const state = store.getState()
  if (state.tableVars.tableStrategy.tabs) {
    queryTabs()
  }
  query()
}
