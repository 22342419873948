import React from 'react'
import {Form, Input} from 'antd'
import {fieldNameConverter} from '../../validators'
import {setField} from '../action_creators'
import {connect} from 'react-redux'
import {Required} from '../utilities'

const mapStateToProps = (state) => ({fields: state.fields})
const mapDispatchToProps = (dispatch) => ({setField: (field) => (value) => dispatch(setField(field, value))})

const SiteRepresentative = ({fields, setField}) => {
  if (!fields) fields = {}
  const requiredRep = fields.sameRep

  return (
    <div
      className="flex-column align-start margin-bottom-1"
      style={{
        background: `#F4F6F8`,
        padding: `0 2rem 2rem 2rem`,
        margin: `0 0 1rem 0`,
      }}
    >
      <h4>Site Contact Information</h4>
      <div className="full-width flex-column align-start">
        {requiredRep && (
          <div className="full-width">
            <Required />
            <Form.Item className="margin-bottom-1 full-width">
              <Input
                size="large"
                onChange={(e) => {
                  console.log(1)
                  setField(`siteRepName`)(e.target.value)
                }}
                value={fields.siteRepName}
                placeholder={fieldNameConverter[`siteRepName`]}
                autoComplete="new-password"
                data-cy="siteRepName"
              />
            </Form.Item>
          </div>
        )}
        <div className="flex-row justify-space-between full-width">
          <div style={{flex: 10}}>
            <Required />
            <Form.Item className="margin-bottom-1 padding-right-1" style={{flex: 1}}>
              <Input
                size="large"
                placeholder={fieldNameConverter[`siteRepEmail`]}
                onChange={(e) => setField(`siteRepEmail`)(e.target.value)}
                data-cy="siteRepEmail"
                value={fields.siteRepEmail}
                autoComplete="new-password"
              />
            </Form.Item>
          </div>
          <div style={{flex: 1}}></div>
          <div style={{flex: 10}}>
            <Required />
            <Form.Item className="margin-bottom-1 padding-left-1" style={{flex: 1}}>
              <Input
                value={fields.siteRepPhone}
                onChange={(e) => setField(`siteRepPhone`)(e.target.value)}
                size="large"
                placeholder={fieldNameConverter[`siteRepPhone`]}
                data-cy="siteRepPhone"
                autoComplete="new-password"
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  )
}

const WrappedSiteRepresentative = connect(mapStateToProps, mapDispatchToProps)(SiteRepresentative)
export default WrappedSiteRepresentative
