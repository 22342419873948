import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Select, Input, Upload, Table, message, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Ratings from '../DAL/Ratings';
import Icons from '../DAL/Icons';
import { actionData, dropdownDataFunc, columnsFunc } from './actionsDataJSON';
import { useSelector } from 'react-redux';

const { Option } = Select;

const ManageActionFields = ({
  ratingsData,
  iconsData,
  setRatingsData,
  setIconsData,
}) => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Cost');
  const [image, setImage] = useState(null);
  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const form = useSelector((state) => state.form);
  const ratings = new Ratings();
  const icons = new Icons();
  const actionDataValues = actionData(ratingsData, iconsData);
  const dropdownData = dropdownDataFunc(actionDataValues);
  const [orderedData, setOrderData] = useState([]);

  const fetchData = async () => {
    const ratingsData = await ratings.getAllRatings(form.id);
    const iconsData = await icons.getAllIcons(form.id);

    // Set the ratings and icons data
    setRatingsData(ratingsData);
    setIconsData(iconsData);

    // Initialize dataSource and orderedData based on selectedOption
    if (selectedOption.toLowerCase() === 'icon') {
      const orderedIcons = [...iconsData].sort((a, b) => a.order - b.order);
      setDataSource(orderedIcons);
      setOrderData(orderedIcons);
    } else {
      const filteredRatings = ratingsData
        .filter((rating) => rating.type.toLowerCase() === selectedOption.toLowerCase())
        .sort((a, b) => a.order - b.order);
      setDataSource(filteredRatings);
      setOrderData(filteredRatings);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedOption]);

  const handleManage = (e) => {
    setSelectedOption(e);
    if (e.toLowerCase() === 'icon') {
      const orderedIcons = [...iconsData].sort((a, b) => a.order - b.order);
      setDataSource(orderedIcons);
      setOrderData(orderedIcons);
    } else {
      const filteredRatings = ratingsData
        .filter((rating) => rating.type.toLowerCase() === e.toLowerCase())
        .sort((a, b) => a.order - b.order);
      setDataSource(filteredRatings);
      setOrderData(filteredRatings);
    }
  };

  const handleCancel = () => {
    setIsAddModalVisible(false);
    setImage(null);
    setItemName('');
    setDescription('');
    setEditingItem(null);
  };

  const handleAddIcon = async () => {
    try {
      await icons.createIcon({
        name: itemName,
        description: description,
        image: image,
        status: true,
        formId: form.id,
      });
      message.success('Icon added successfully!');
      await fetchData(); // Refetch data to update the table
      handleCancel();
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  };

  const handleAddRating = async () => {
    try {
      await ratings.createRating({
        name: itemName,
        description: description,
        type: selectedOption,
        status: true,
        formId: form.id,
      });
      message.success(`${selectedOption} rating added successfully!`);
      await fetchData(); // Refetch data to update the table
      handleCancel();
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  };

  const handleAdd = () => {
    if (selectedOption === 'Icon') {
      handleAddIcon();
    } else {
      handleAddRating();
    }
  };

  const handleImageUpload = ({ file }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        if (img.width <= 100 && img.height <= 100) {
          setImage(e.target.result);
        } else {
          message.error('Image must be less than 100x100 pixels');
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const isAddButtonDisabled = () => {
    if (selectedOption === 'Icon') {
      return !image || !itemName.trim();
    }
    return !itemName.trim();
  };

  const handleEditItem = (record) => {
    setEditingItem(record);
    setItemName(record.name);
    setDescription(record.description);
    setImage(record.image || null);
    setIsAddModalVisible(true);
  };

  const handleUpdateItem = async () => {
    if (editingItem) {
      try {
        if (selectedOption === 'Icon') {
          await icons.updateIcon(editingItem.id, {
            name: itemName,
            description: description,
            image: image,
            formId: form.id,
          });
          await fetchData(); // Refetch data to update the table
        } else {
          await ratings.updateRating(editingItem.id, {
            name: itemName,
            description: description,
            type: selectedOption,
            formId: form.id,
          });
          await fetchData(); // Refetch data to update the table
        }
        message.success(`${selectedOption} successfully updated!`);
        handleCancel();
      } catch (error) {
        console.error(error);
        message.error(error.message);
      }
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      if (selectedOption === 'Icon') {
        await icons.deleteIcon(id);
      } else {
        await ratings.deleteRating(id);
      }
      await fetchData(); // Refetch data to update the table
      message.success(`${selectedOption} successfully deleted!`);
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  };

  const saveOrderToCollection = async (data, type) => {
    try {
      if (type === 'icons') {
        await Promise.all(
          data.map((item) => {
            if (item.id) {
              return icons.updateIconOrder(item.id, { order: item.order });
            }
          })
        );
      } else if (type === 'ratings') {
        await Promise.all(
          data.map((item) => {
            if (item.id) {
              return ratings.updateRatingOrder(item.id, item.order);
            }
          })
        );
      }
      //message.success("Order saved successfully!");
    } catch (error) {
      console.error("Error saving order:", error);
      message.error("Failed to save the order.");
    }
  };
  
  const moveUp = (index) => {
    if (index === 0) return;
    const newOrder = [...orderedData];
    [newOrder[index], newOrder[index - 1]] = [newOrder[index - 1], newOrder[index]];
    newOrder.forEach((item, idx) => (item.order = idx));
    setOrderData(newOrder);
    saveOrderToCollection(newOrder, selectedOption.toLowerCase() === 'icon' ? 'icons' : 'ratings');
  };
  
  const moveDown = (index) => {
    if (index === orderedData.length - 1) return;
    const newOrder = [...orderedData];
    [newOrder[index], newOrder[index + 1]] = [newOrder[index + 1], newOrder[index]];
    newOrder.forEach((item, idx) => (item.order = idx));
    setOrderData(newOrder);
    saveOrderToCollection(newOrder, selectedOption.toLowerCase() === 'icon' ? 'icons' : 'ratings');
  };

  const columns = columnsFunc(selectedOption, handleEditItem, handleDeleteItem, moveUp, moveDown, orderedData);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <Select
          placeholder="Select Option"
          style={{ width: 'calc(100% - 180px)', marginRight: '8px' }}
          onChange={handleManage}
          value={selectedOption}
        >
          {dropdownData.map((ddD) => (
            <Option key={ddD.name} value={ddD.name}>
              {ddD.name}
            </Option>
          ))}
        </Select>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsAddModalVisible(true)}
          style={{ width: '150px' }}
        >
          Add New {selectedOption}
        </Button>
      </div>
      {selectedOption && (
        <Table
          className="custom-table"
          columns={columns}
          dataSource={orderedData}
          style={{ backgroundColor: 'white' }}
          pagination={false}
        />
      )}
      <Modal
        title={editingItem ? `Edit ${selectedOption}` : `Add New ${selectedOption}`}
        visible={isAddModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical">
          {selectedOption === 'Icon' && (
            <Form.Item label="Upload Image" name="image">
              <Upload
                beforeUpload={() => false}
                showUploadList={false}
                onChange={handleImageUpload}
                accept="image/*"
              >
                <Button>Upload Image</Button>
              </Upload>
              {image && <img src={image} alt="icon" style={{ width: '100px', height: '100px' }} />}
            </Form.Item>
          )}
          <Form.Item label="Name" name="name">
            <Input value={itemName} onChange={(e) => setItemName(e.target.value)} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={editingItem ? handleUpdateItem : handleAdd}
              disabled={isAddButtonDisabled()}
            >
              {editingItem ? 'Update' : 'Add'}
            </Button>
            <Button onClick={handleCancel} style={{ marginLeft: '8px' }}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export { ManageActionFields };
