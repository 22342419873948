import React, {useEffect, useState} from 'react'
import {Button, Select} from 'antd'
import {saveCancellation, cancelIntakeReasons, cancelProjectReasons, requirementsMap} from './utilities'
import {loggerEffect} from '../../effects'
import {read} from '../../HOC/Firebase/ReadHOC'

function CancelProject({id, type, done, readDB, readResults}) {
  const [logger, setLogger] = useState(null)
  const [selected, setSelected] = useState(undefined)
  const [required, setRequired] = useState({})

  useEffect(
    function () {
      readDB({paths: [{collection: `projects`, doc: id}]}, 'project')
    },
    [id]
  )
  // the SystemLogger splits the provided path by '/' and discards the first element
  useEffect(loggerEffect(setLogger, `blank/projects/${id}/`), [])

  function change(type, e) {
    setRequired({...required, [type]: e})
  }
  async function save() {
    const {
      project: [project],
    } = readResults
    if (!project) throw new Error('cancelling a project/intake that has not been downloaded')

    try {
      await saveCancellation(project, selected, required, type, logger)

      setSelected(undefined)
      setRequired({})
      if (done) done()
    } catch (e) {
      console.log(e)
    }
  }

  let disabled = !selected

  const reasons = type === 'intake' ? cancelIntakeReasons : cancelProjectReasons

  if (selected) {
    const {options: {required: rr = []} = {required: []}} = reasons[selected]

    for (let key of rr) {
      if (key === 'reschedule') continue
      else {
        disabled = !required[key]
      }
    }
  }

  return (
    <div className="flex-column">
      <Select placeholder="Reasons for Cancelling" value={selected} onChange={setSelected}>
        {Object.values(reasons).map((reason, i) => (
          <Select.Option value={reason.reason} key={i}>
            {reason.reason}
          </Select.Option>
        ))}
      </Select>
      <div className="padding-top-2">
        {selected &&
          reasons[selected].options.required.map((req, i) => {
            const Req = requirementsMap[req]
            return <div key={i}>{required && <Req {...{id, type: req, change, value: required[req]}} />}</div>
          })}
      </div>
      <div className="flex-row justify-end">
        <div className="padding-1">
          <Button onClick={save} disabled={disabled}>
            Confirm Cancellation
          </Button>
        </div>
      </div>
    </div>
  )
}

const WrappedCancelProject = read()(CancelProject)
export default WrappedCancelProject
