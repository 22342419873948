import React from 'react';

// This component will display label and user entry, used in actions for action strategy information, etc.
const InputCtrlAction = ({ name, handleChange, uniqueValue, defaultValue }) => {
  return (
    <div className="input-ctrl-action">
      <div className="label-row">
        <label className="actionStrategyLabel question-title">
          {name}<span style={{ color: 'red' }}>*</span>
        </label>
      </div>
      <div className="input-row">
        <input
          className="ant-input ant-input-lg actionStrategyInput"
          key={`input-${uniqueValue}-${defaultValue}`}
          id={`input-${uniqueValue}-${defaultValue}`}
          name={`input-${uniqueValue}-${defaultValue}`}
          maxLength={500}
          placeholder={`please enter ${name}`}
          defaultValue={defaultValue}
          onBlur={handleChange}
          type="text"
        />
      </div>
    </div>
  );
}

export default InputCtrlAction;
