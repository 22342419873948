import React from 'react'

export default function SiteInformation({sites = [], loaderOkButton}) {
  return (
    <div style={{borderRadius: `0.5rem`, padding: `2rem`, margin: `2rem 0 0 0`, backgroundColor: `rgb(244, 246, 248)`}}>
      <h3>Uploaded Projects</h3>
      <div
        style={{
          backgroundColor: `white`,
          border: `0.1rem solid lightgrey`,
          padding: `1.5rem`,
          borderRadius: `0.5rem`,
          opacity: loaderOkButton ? 0.5 : 1
        }}
        data-cy="bulkProjects"
      >
        {sites.map(
          ({geocode_results, site_owner, site_contact, address, phone, email, system_installer, field_user}, i) => (
            <div key={i}>
              <div style={{padding: `0 0 1rem 0`}}>#{i + 1}</div>
              <div style={{padding: `0 1rem 0 0`, fontWeight: `bold`}}>Geocoded Address</div>
              <div>{geocode_results && geocode_results.formatted_address}</div>
              <div style={{padding: `0 1rem 0 0`, fontWeight: `bold`}}>Your Address</div>
              <div>{address}</div>
              <div
                style={{
                  display: `flex`,
                  flexFlow: `row wrap`,
                  justifyContent: `space-between`,
                  padding: `2rem 0 2rem 0`,
                }}
                data-cy="bulkSiteContactRow"
              >
                <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                  <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>Site Owner</div>
                  <div>{site_owner}</div>
                </div>
                <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                  <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>Site Contact</div>
                  <div>{site_contact}</div>
                </div>
                <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                  <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>Phone</div>
                  <div>{phone}</div>
                </div>
                <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                  <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>Email</div>
                  <div>{email}</div>
                </div>
              </div>
              <div
                style={{
                  display: `flex`,
                  flexFlow: `row wrap`,
                  justifyContent: `space-between`,
                  padding: `2rem 0 2rem 0`,
                }}
              >
                <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                  <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>System Installer</div>
                  <div>{system_installer}</div>
                </div>
                <div style={{display: `flex`, flexFlow: `row nowrap`}}>
                  <div style={{fontWeight: `bold`, padding: `0 1rem 0 0`}}>Field User</div>
                  <div>{field_user}</div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}
