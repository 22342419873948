import DB from '../../DAL/DB'
import {auth} from '../../Auth'

const projects = new DB.Projects()
const tempProjects = new DB.TempProjects()

export default async function checkFormIntegrity(project, data) {
  let modified = false

  for (let section in project.intake.sections) {
    if (!project.intake.order.includes(section)) {
      if (!modified) {
        await tempProjects.update(project.id, null, project)
      }
      modified = true

      delete project.intake.sections[section]
    }
  }

  data.intake = project.intake

  if (modified) {
    await projects.updatebatch(project.id, null, {...data, updatedBy: auth?.sunkaizenUser?.id, updatedByName: auth?.sunkaizenUser?.name })
  }

  return project
}
