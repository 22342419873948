import React from 'react'
// import PropTypes from "prop-types"

import ChooseSavedFilter from './ChooseSavedFilter'
import Searchbar from './Searchbar'
import SavedFilters from './SavedFilters'
import ResourceCreate from './ResourceCreate'
import TabFilters from './TabFilters'
import Alternate from './Alternate'
import {auth} from '../Auth'


export default function FilterMeta() {
  
  // console.info('user:', auth.sunkaizenUser)

  return (
    <div className="flex-column" style={{padding: '1rem'}}>
      <TabFilters />
      <div className="flex-row align-center">
        <div style={{flex: 6}}>
          <div className="flex-row align-center" style={{padding: '0.2rem', borderRadius: '0.1em', fontSize: '75%'}}>
            <ChooseSavedFilter style={{flex: 20}} />
            <Searchbar style={{flex: 40}} />
          </div>
        </div>
        {auth.sunkaizenUser && ['admin', 'super-admin', 'client'].includes(auth.sunkaizenUser.type) && (
          <ResourceCreate />
        )}
        <SavedFilters />
        <Alternate />
      </div>
    </div>
  )
}
