import {store} from '../utilities'
import {modify} from '../action_creators'

export default function optionDrop(result) {
  console.log(result)
  if (!result.draggableId.includes('option')) return

  const state = store.getState()
  const field = state.fields[state.active.field]
  const {options, id} = field

  const [element] = options.splice(result.source.index, 1)
  options.splice(result.destination.index, 0, element)

  store.dispatch(modify('field')(id, {...field, options}))
}
