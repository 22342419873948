import {store} from '../utilities'
import {FULL_TEXT_SEARCH_URL} from '../../config'
import {setTabs} from '../action_creators'
import tabsPostProcessor from './tabsPostProcessor'
import get from './get'
import preProcess from './preProcess'

export default async function queryTabs() {
  const state = store.getState()
  const {or = () => [], permissions = () => []} = state.tableVars.tableStrategy || {}
  const p = permissions() || []
  // p.push({match: {'organization.id': auth.sunkaizenUser.organization.id}})

  const body = {
    type: state.tableVars.tableStrategy.type,
    sortField: `${state.tableVars.tableStrategy.tabs}.sortable`,
    filters: p,
    or: or(),
  }

  preProcess(body)
  body.permissions = [...body.filters]
  delete body.filters

  console.log(body)

  const url = `${FULL_TEXT_SEARCH_URL}/counts`
  console.info('Counts body:', body)
  const res = await get(url, body)

  const counts = tabsPostProcessor(res)
  store.dispatch(setTabs(counts))
}
