import React from 'react'
import {Form, Select} from 'antd'
import {renderOptions} from './util'

// component will display label with select control used in actions like Cricitically, cost in action page
const SelectControlAction = ({name, options, handleChange, uniqueValue, defaultValue}) => {
  return (
    <div key={`selectControl-${name}-${uniqueValue}`}>
      <div key={`selectControllabel-${name}-${uniqueValue}`} className="actionDropdownLabel">
        <label className="padding-right-30 actionDropdownLabelProp question-title">{name}</label>
      </div>
      <Form.Item
        key={`form-${uniqueValue}-${defaultValue}`}
        className="full-width margin-bottom-1 padding-right-1 actionDropdownValue"
      >
        <Select
          initialValue={`Select ${name} Type`}
          size="medium"
          key={`selectControl-${uniqueValue}-${defaultValue}`}
          defaultValue={defaultValue || `-- select --`}
          onChange={handleChange}
          placeholder={`please select ${name}`}
          className="full-width"
        >
          {renderOptions(options)}
        </Select>
      </Form.Item>
    </div>
  )
}

export default SelectControlAction
