export default function iterateIntake(project, cb) {
  const {intake, rubric, intakeResult} = project
  const {sections, order} = intake
  const {rawData} = intakeResult || {}
  const {scored} = rawData

  if (!rubric) {
    throw new Error(`project ${project.id} has no rubric`)
  }
  if (!intakeResult) {
    throw new Error(`project ${project.id} has no intakeResult`)
  }
  if (!rawData) {
    throw new Error(`project ${project.id} has an intakeResult, but it contains no rawData`)
  }
  if (!scored) {
    throw new Error(`project ${project.id} has no scored questions`)
  }

  const couplets = []
  for (let sId of order) {
    const section = sections[sId]
    for (let fId of section.order) {
      if (scored[fId]) {
        const {id, label, question, value, score} = scored[fId]
        const [answer] = Object.keys(value.values)
        couplets.push([section, {id, label, question, answer, score}])
      }
    }
  }

  const result = []
  for (let [section, field] of couplets) {
    result.push(cb(section, field))
  }

  return result
}
