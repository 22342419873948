import React, {useState, useEffect, useReducer} from 'react'
import {simpleGet, loggerEffect} from '../../effects'
import {upload} from './upload'
import {finalize} from './finalize'
import FileUploader from '../../FileHandling/FileUploader'
import Permissions from '../Permissions'
import {message} from './modalMessage'
import {auth} from '../../Auth'
import UserInformation from './UserInformation'

function reducer(state, action) {
  switch (action.type) {
    case 'setField':
      return {...state, fields: {...state.fields, [action.field]: action.value}}
    case 'clearAll':
      return {users: [], fields: []}
    case 'setUsers':
      console.log(action)
      return {...state, users: action.value}
    default:
      return state
  }
}

export default function BulkUserImport({done}) {
  const [logger, setLogger] = useState(null)
  const [state, dispatch] = useReducer(reducer, {users: [], fields: {}})
  const [geocoder, setGeocoder] = useState(null)
  const [organization, setOrganization] = useState(null)

  useEffect(
    function () {
      switch (done) {
        case 'done':
          save(true)
        case 'clear':
          dispatch({type: 'clearAll'})
        default:
          return
      }
    },
    [done]
  )

  // useEffect(function () { if (done) (async function () {await saveAllUsers(true)})() }, [done])
  useEffect(loggerEffect(setLogger), [])
  useEffect(simpleGet('organizations', (auth.sunkaizenUser.organization || {}).id, setOrganization), [
    auth.sunkaizenUser,
  ])
  useEffect(
    function () {
      if (geocoder) return
      if (window.google.maps) {
        window.removeEventListener(`storage`, setLoaded)
        setGeocoder(new window.google.maps.Geocoder())
      } else {
        window.addEventListener('storage', setLoaded)
      }
    },
    [window.google && window.google.maps]
  )

  function setLoaded(e) {
    if (e.key === `googleMapsLoaded`) {
      setGeocoder(new window.google.maps.Geocoder())
    }
  }
  const setField = (field) => {
    return function (value) {
      dispatch({type: 'setField', field, value})
    }
  }

  async function uploadCSV(files) {
    const users = await upload(files)
    dispatch({type: 'setUsers', value: users})
  }

  async function save(done = true) {
    try {
      const [results, total] = await finalize(done, state.users, organization, state.fields)

      if (results.every((r) => !r)) {
        logger.event(`successfully created bulk users`, `inserted ${total} users into the database`, 3)
      } else {
        throw new Error(`failed to commit all the db objects`)
      }

      dispatch({type: 'clearAll'})
    } catch (e) {
      logger.error(`failed to commit all users to the database`, JSON.stringify(e, null, 2), 5)
      console.log(e)
    }
  }

  return (
    <div className="fullHeight">
      <h3 className="padding-1">Permissions only apply to field users and quality managers</h3>
      <Permissions setField={setField} fields={state.fields} />
      <FileUploader submit={uploadCSV} helperMessage={message} collection="none" type="none" id="none" />
      {state.users.length > 0 && <UserInformation users={state.users} />}
    </div>
  )
}
