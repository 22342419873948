import DB from '../../DAL/DB'
import {auth} from '../../Auth'

export default async function saveComment(tId, comment) {
  const ticketComments = new DB.TicketComments(tId)
  const ticketComment = {
    author: auth.sunkaizenUser.id,
    authorMap: {
      id: auth.sunkaizenUser.id,
      name: auth.sunkaizenUser.name,
      email: auth.sunkaizenUser.email,
    },
    text: comment,
    created: new Date(),
  }

  if (!(await ticketComments.create(null, ticketComment))) throw new Error('failed to create ticket comment')
}
