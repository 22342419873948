import {firebase} from '../../utilities'
import DB from '../../DAL/DB'
import { auth } from '../../Auth'

const projects = new DB.Projects()

export function initializeProject(project) {
  project.startedAt = firebase.firestore.Timestamp.now()
  project.status = `In Progress`
  return projects.updatebatch(project.id, null, {...project, updatedBy: auth?.sunkaizenUser?.id, updatedByName: auth?.sunkaizenUser?.name })
}
