import {useState, useEffect, useContext} from 'react'
import {ReducerContext} from '../reducer'
import {ENVIRONMENT} from '../../config'
import DB from '../../DAL/DB'
import {auth} from '../../Auth'

const meta = new DB.Meta()

export default function useGenerateQuery(props) {
  const {single, search, queryTypes, resultsElastic} = props
  const {state, dispatch} = useContext(ReducerContext)
  const [term, setTerm] = useState('')

  function generateQuery() {
    if (auth.sunkaizenUser.type == 'field-user') {
      return new queryTypes.project({
        query: term,
        sort: [{[`created._seconds`]: 'desc'}],
        filters: [{terms: {[`status.sortable`]: ['To Be Scheduled', 'Scheduled']}}],
        or: [
          {match: {[`user_index.${auth.sunkaizenUser.id}`]: 'inspector'}},
        ],
        size: 100, // TODO: we need to query all projects by pagination in the search method
      })
    } else {
      return new queryTypes.project({
        query: term,
        sort: [{[`created._seconds`]: 'desc'}],
        filters: [{terms: {[`status.sortable`]: ['To Be Scheduled', 'Scheduled']}}],
        or: [
          {match: {'organization.id': auth.sunkaizenUser.organization.id}},
          {match: {[`user_index.${auth.sunkaizenUser.organization.id}`]: 'inspector'}},
          {match: {[`user_index.${auth.sunkaizenUser.id}`]: 'inspector'}},
        ],
        size: 100,
      })
    }
  }

  useEffect(
    function () {
      if (single) return

      const q = generateQuery()
      search(q)
    },
    [term, state.updateProjects]
  )

  useEffect(
    function () {
      if (single) return

      const projectsMap = {}
      for (let p of resultsElastic || []) {
        if (p) projectsMap[p.id] = p
      }
      console.info('projectsMap:', projectsMap)

      dispatch({type: 'projects', value: projectsMap})
    },
    [resultsElastic]
  )

  // refresh whenever the database reports an elastic update
  useEffect(function () {
    return meta.subscribe('elasticUpdates', null, function ({ok, data: [elasticUpdates]}) {
      const q = generateQuery()
      if (ok) {
        search(q)
      }
    })
  }, [])

  return setTerm
}
