import {BUCKET} from '../../../config'
import {auth} from '../../../Auth'

export default function addUrl(res) {
  if (res.hits && Array.isArray(res.hits.hits)) {
    for (let hit of res.hits.hits) {
      if (!hit._source.url) {
        const filePath = hit._source.fullPath.replace(/\//g, '%2F').replace(/,/g, '%2C')
        hit._source.url = `https://firebasestorage.googleapis.com/v0/b/${BUCKET}/o/${filePath}?alt=media`
      }

      hit._source.status = (hit._source.permissions || {})[auth.sunkaizenUser.id]
    }
  }

  return res
}
