import _ from 'lodash'
import React from 'react'
import {Popconfirm, Button} from 'antd'

import {auth} from './Auth'
import SystemLogger from './SystemLogger'

/**
 * This is a button modified to have a PopConfirm trigger on click, and to
 * support logging the resources that were removed. Use `popconfirmProps` to pass any
 * props to the Popconfirm (note: PopConfirm's `title` is instead `confirmationDisplay`
 *
 * OPT `customRender`: Render children directly inside of a popconfirm without
 * placing them inside of a Button component.
 * OPT `collection`: The firebase collection the value comes from. When combined
 * with `documentId`, the component will store the deletion even in logs.
 * OPT `documentId`: The id of the document being deleted.
 * OPT `confirmationDisplay`: The confirmation message displayed when the user
 * clicks the button; any ReactNode is valid (so JSX or strings). Defaults to
 * "Are you sure you want to delete this?".
 * REQ `onConfirm`: Callback for handling deletion confirmation.
 * OPT `onCancel`: Callback for handling deletion cancellation.
 * OPT `okText`: Text for proceeding with deletion
 * OPT `cancelText`: Text for cancelling deletion.
 * OPT `popconfirmProps`: Give props directly to the popconfirm if needed.
 */
export default function DeleteButton({
  collection,
  documentId,
  confirmationDisplay,
  onConfirm,
  onCancel,
  children,
  okText,
  cancelText,
  popconfirmProps = {},
  customRender = false,
  ...additionalProps
}) {
  function addLogging(f) {
    function g(...args) {
      if (_.isString(collection) && _.isString(documentId)) {
        const logger = new SystemLogger({uid: auth.sunkaizenUser.id, path: `/${collection}/${documentId}`})
        logger.log(`Resource (${documentId}) of type (${collection}) Updated`)
      }
      f(...args)
    }

    return g
  }
  const onConfirmWrapped = addLogging(onConfirm)

  return (
    <Popconfirm
      title={confirmationDisplay || `Are you sure you want to delete this?`}
      onConfirm={onConfirmWrapped}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      {...popconfirmProps}
    >
      {customRender ? (
        children
      ) : (
        <Button type="danger" {...additionalProps}>
          {children}
        </Button>
      )}
    </Popconfirm>
  )
}
