import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Modal, Input} from 'antd'
import DB from './DAL/DB'
import {auth} from './Auth'

const EmailLinks = new DB.EmailLinks()

const EmailLogin = () => {
  const [email, setEmail] = useState(undefined)
  const [link, setLink] = useState(window.location.href)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    (async function () {
      try {
        if (auth.isSignInWithEmailLink(link)) {
          const {
            ok,
            data: [{email, link}],
          } = await EmailLinks.get(null, {filters: [['link', '==', window.location.href]]})
          if (ok) {
            setLink(link)
            if (email) setEmail(email)
            else setShowModal(true)
          }
        } else {
          console.log('the URL provided is not a sign-on link')
        }
      } catch (e) {
        console.log('failed to get email link from database', e)
      }
    })()
  }, [link])

  useEffect(() => {
    (async () => {
      if (email) await auth.signInWithEmailLink(email, link)
    })()
  }, [])
  // console.log(window.location.href)
  // console.log(email, showModal)

  return (
    <div>
      <h1 className="page-header" style={{padding: `3rem`}}>
        You are now logged in
      </h1>
      <Modal
        title="Enter your Email Address"
        visible={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      >
        <Input placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
      </Modal>
    </div>
  )
}

EmailLogin.propTypes = {
  auth: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
}

export default EmailLogin
