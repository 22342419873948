// https://stackoverflow.com/questions/57707494/whats-the-proper-way-to-implement-a-custom-click-handler-in-vega-lite
// https://vega.github.io/vega-lite/examples/#bar-charts
// https://vega.github.io/vega-lite/docs/selection.html#type
// https://github.com/vega/vega-embed

import React, {useEffect, useRef, useState} from 'react'
import {useLoggerEffect, useElasticSubscribe} from '../../../effects'
import embed from 'vega-embed'
import makeSpec from './makeSpec'
import transform from './transform'
import CompletedProjects from './CompletedProjects'
import PerWeek from './PerWeek'
import moment from 'moment'
import {auth} from '../../../Auth'

const opts = {
  patch: (spec) => {
    spec.signals.push({
      name: 'barClick',
      value: 0,
      on: [{events: 'rect:mousedown', update: 'datum'}],
    })
    return spec
  },
}

export default function ProjectFlowGraphs({test, projects}) {
  const [clicked, setClicked] = useState(null)
  const [spec, setSpec] = useState(undefined)
  const graph = useRef(null)
  const logger = useLoggerEffect('')

  if (!projects) projects = {}

  projects = Object.values(projects)
    .map((project) => {
      project.updated = moment.unix(project.updated._seconds)
      project.completed_on = project.completed_on ? moment.unix(project.completed_on._seconds) : undefined

      return project
    })
    .filter((p) => {
      return !['Cancelled', 'Requested'].includes(p.status)
    })

  // uses test harnesss data if present; otherwise, gets data from elastic
  useEffect(
    function () {
      if (!test) {
        projects = transform(projects)
        const spec = makeSpec(projects)
        setSpec(spec)
      } else projects = test
    },
    [projects.length > 0]
  )

  useEffect(
    function () {
      (async function () {
        if (!graph || !graph.current) return
        if (!spec) return

        const result = await embed(graph.current, spec, opts)
        result.view.addSignalListener('barClick', (e, v) => {
          console.log(v)
          setClicked(`${v.status}, "${v.recent}", ${v.__count}`)
        })

        if (logger) await logger.log(`updated project flow graphs`)
      })()
    },
    [graph.current, projects]
  )

  return (
    <div className="full-width">
      <div className="full-width flex-row padding-1">
        {/* <div style={{width: "20%"}} ><CompletedProjects projects={projects} /></div> */}
        {/* <h4 className='padding-1' >{`Selected Column: ${clicked}`}</h4> */}
        <PerWeek projects={projects} />
        <div className="background-white padding-1">
          <h4>Projects in Status</h4>
          <div className="full-width" ref={graph} />
        </div>
      </div>
    </div>
  )
}
