import React from 'react'
import {Select} from 'antd'
import {capitalizeFirst} from '../../utilities'

export function SelectField({set, field, placeholder, options}) {
  return (
    <Select
      onChange={(e) => set(e)}
      value={field}
      size="large"
      placeholder={placeholder}
      style={{border: `none`, width: `100%`}}
    >
      {options
        .sort((a, b) => (a > b ? 1 : -1))
        .map((t, i) => (
          <Select.Option key={i} style={{border: `none`}} value={t}>
            {capitalizeFirst(t)}
          </Select.Option>
        ))}
    </Select>
  )
}
